import React from "react";
import R14, {
  View,
  StyleSheet,
  AsyncStorage
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { MESSAGE_TYPE } from "../../constant/app.data.constant";
import PopupModalComponent from "../../components/PopupModalComponent";
import TableListComponent from "../../components/TableListComponent";
import ManagePaymentFilterComponent from "../../components/PCH/ManagePaymentFilterComponent";
import {analyticsLogs} from "../../utils/AnalyticLogs";

import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import DateUtils from "../../utils/DateUtils";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";

const editIcon = require("../../assets/images/edit.png");

export default R14.connect(
  class ManagePaymentScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        managePayments: [],
        isPopUpVisiable: false,
        loaderVisible: false,
      };
      this.claimStatus = [CommonString.AcceptedLabel, CommonString.ReceivedLabel, CommonString.SubmittedLabel, CommonString.MailedLabel];
      this.searchType = {
        both: "Both",
        claim: "Claim",
        payment: "Payment"
      }
    }

    componentDidMount = async () => {
      try {
        const { mobileNumber, email, uid } = await this.props.app.dm.rest.getUser();
        const claimFilter = {
          filter: {
            mobileNumber: { eq: mobileNumber },
            email: { eq: email },
            pchSubmittedStatus: { in: this.claimStatus }
          },
          sort: { field: "createdAt", order: "DESC" },
        };
        const paymentFilter = {
          filter: {
            createdBy: { eq: uid },
            isDeleted: { neq: "true" }
          },
          sort: { field: "paidDate", order: "DESC" },
        };
        const items = await this.getClaimsAndPayment(this.searchType.both, claimFilter, paymentFilter);
        this.setState({ managePayments: items, loaderVisible: false })
      }
      catch (err) {
        this.setState({ loaderVisible: false })
        console.log(err)
      }
    };

    resetForm = async () => {
        const { mobileNumber, email, uid } = await this.props.app.dm.rest.getUser();
        const claimFilter = {
          filter: {
            mobileNumber: { eq: mobileNumber },
            email: { eq: email },
            pchSubmittedStatus: { in: this.claimStatus }
          },
          sort: { field: "createdAt", order: "DESC" },
        };
        const paymentFilter = {
          filter: {
            createdBy: { eq: uid },
            isDeleted: { neq: "true" }
          },
          sort: { field: "paidDate", order: "DESC" },
        };
        const items = await this.getClaimsAndPayment(this.searchType.both, claimFilter, paymentFilter);
        this.setState({ managePayments: items, loaderVisible: false })
    }

    getClaimsAndPayment = async (
      searchType,
      claimFilter = {},
      paymentFilter = {},
      isSearched = false,
      claimFields = "uid email mobileNumber billStatus pchSubmittedStatus ssId dOB createdAt doctorUid isPatientRequest submittedDate userProfileUid poBoxNo parentName parentUid providerName isProcessed mergedFileName mergedUnsecuredFileName smsMessageId mailMessageId insuranceMember {uid memberId firstName lastName} pchInsuranceDetail {uid name groupNumber address1 address2 city state zipCode} uploadedDateTime documentId isSuperBill processFileName insuranceMemberTreatedAt {label value} insuranceMemberClaimsAmount insuranceMemberTreatedOn insuranceMemberTreatedOnFrom insuranceMemberTreatedOnTo"
    ) => {
      let items = this.props.filterBills;
      this.setState({ loaderVisible: true });
      if (searchType === this.searchType.both || searchType === this.searchType.claim) {
        items = await this.props.app.dm.uploadBillDomain.getBills(claimFilter, claimFields);
        const promises = items.map(async (item) => {
          // paymentFilter.filter.uploadBillUid.eq = item.uid;
          const claimPayments = await this.props.app.dm.claimPaymentDomain.getClaimPayments({filter: {
            ...paymentFilter.filter,
            uploadBillUid: {eq: item.uid}
          },
          sort: { field: "paidDate", order: "DESC" },
        });
          if(!!isSearched && !!claimPayments && !!claimPayments.nodes && !claimPayments.nodes.length && (paymentFilter.filter.fromDateOfService || paymentFilter.filter.paidDate || paymentFilter.filter.checkNo)) return false;
          claimPayments.nodes.forEach(payment => {

            let patientResponsibility = item.patientResponsibility || 0;
            if (!!payment.coinsurance) patientResponsibility = patientResponsibility + +payment.coinsurance;
            if (!!payment.copay) patientResponsibility = patientResponsibility + +payment.copay;
            if (!!payment.deductible) patientResponsibility = patientResponsibility + +payment.deductible;
            item.patientResponsibility = patientResponsibility;

            item.paidAmount = !!payment.paidAmount ? (+payment.paidAmount + +(item.paidAmount || 0)) : item.paidAmount;
            item.paidDate = !payment.paidDate ? item.paidDate : (item.paidDate ? `${item.paidDate}:${DateUtils.formatDateInMMDDYYYY(payment.paidDate)}` : `${DateUtils.formatDateInMMDDYYYY(payment.paidDate)}`);
            item.checkNo = !payment.checkNo ? item.checkNo : (item.checkNo ? `${item.checkNo}:${payment.checkNo}` : `${payment.checkNo}`);
          });

          return {
            "icn": item.processFileName || "",
            "practiceGroup": !!item.parentName ? item.parentName : "N/A",
            "provider": item.providerName || "",
            "patientName": item.insuranceMember ? `${item.insuranceMember.firstName} ${item.insuranceMember.lastName}` : "",
            "insurance": item.pchInsuranceDetail.name + "/" + (item.pchInsuranceDetail.address1 ? item.pchInsuranceDetail.address1 : "") + (item.pchInsuranceDetail.address2 ? (", " + item.pchInsuranceDetail.address2) : "") + (item.pchInsuranceDetail.city ? (", " + item.pchInsuranceDetail.city) : "") + (item.pchInsuranceDetail.state ? (", " + item.pchInsuranceDetail.state) : "") + (item.pchInsuranceDetail.zipCode ? (", " + item.pchInsuranceDetail.zipCode) : ""),
            "dateOfService": `${DateUtils.formatDate(item.insuranceMemberTreatedOnFrom)}-${DateUtils.formatDate(item.insuranceMemberTreatedOnTo)}`, //"10/01/2023-11/12/2023",
            "fromDateOfService": DateUtils.formatDate(item.insuranceMemberTreatedOnFrom),
            "toDateOfService": DateUtils.formatDate(item.insuranceMemberTreatedOnTo),
            "totalClaimAmount": !!item.insuranceMemberClaimsAmount ? item.insuranceMemberClaimsAmount : "0",
            "paid": `${item.paidAmount || "0"}`,
            "patientResponsibility": `${item.patientResponsibility || "0"}`,
            "balance": `${+item.insuranceMemberClaimsAmount - +(item.patientResponsibility || "0") - +(item.paidAmount || "0")}` || "",
            "checkNo": `${item.checkNo || ""}`,
            "paidDate": `${item.paidDate || ""}`,
            "uid": item.uid,
            "claimPayments": claimPayments.nodes,
            "processFileName": item.processFileName,
            "submittedDate": item.submittedDate,
            "parentName": item.parentName,
            "providerName": item.providerName,
            "uploadedDateTime": item.uploadedDateTime,
            "pchSubmittedStatus": item.pchSubmittedStatus,
            "participatingPayer": item.participatingPayer,
            "pchInsuranceDetail": item.pchInsuranceDetail,
            "insuranceMember": item.insuranceMember,
            "mergedFileName": item.mergedFileName,
            "documentId": item.documentId,
            "dOB": item.dOB,
            "ssId": item.ssId,
            "isProcessed": item.isProcessed,
            "isSuperBill": item.isSuperBill,
            "mergedUnsecuredFileName": item.mergedUnsecuredFileName,
          }
        });
        items = await Promise.all(promises);
        items = items.filter(Boolean);
        return items;
      } else if (searchType === this.searchType.payment) {
        const claimPayments = await this.props.app.dm.claimPaymentDomain.getClaimPayments(paymentFilter);
        const { uid } = await this.props.app.dm.rest.getUser();
        const groupClaimsWithSameClaim = claimPayments.nodes.reduce((acc, current) => {
          const existingGroup = acc.find(group => group[0].uploadBillUid === current.uploadBillUid);
          if (existingGroup) existingGroup.push(current);
          else acc.push([current]);
          return acc;
        }, []);

        const promises = groupClaimsWithSameClaim.map(async (payments) => {
          const billUid = payments[0].uploadBillUid;
          let uploadedBill = await this.props.app.dm.uploadBillDomain.getBills({filter: {uid: {eq: billUid}}}, claimFields);
          uploadedBill = uploadedBill[0];
          const claimPayments = await this.props.app.dm.claimPaymentDomain.getClaimPayments({filter: {
            createdBy: { eq: uid },
            isDeleted: { neq: "true" },
            uploadBillUid: {eq: uploadedBill.uid}
            },
            sort: { field: "paidDate", order: "DESC" },
          });
          claimPayments.nodes.forEach(payment => {
            let patientResponsibility = uploadedBill.patientResponsibility || 0;
            if (!!payment.coinsurance) patientResponsibility = patientResponsibility + +payment.coinsurance;
            if (!!payment.copay) patientResponsibility = patientResponsibility + +payment.copay;
            if (!!payment.deductible) patientResponsibility = patientResponsibility + +payment.deductible;
            uploadedBill.patientResponsibility = patientResponsibility;

            uploadedBill.paidAmount = !!payment.paidAmount ? (+payment.paidAmount + +(uploadedBill.paidAmount || 0)) : uploadedBill.paidAmount;
            uploadedBill.paidDate = !payment.paidDate ? uploadedBill.paidDate : (uploadedBill.paidDate ? `${uploadedBill.paidDate}:${DateUtils.formatDateInMMDDYYYY(payment.paidDate)}` : `${DateUtils.formatDateInMMDDYYYY(payment.paidDate)}`);
            uploadedBill.checkNo = !payment.checkNo ? uploadedBill.checkNo : (uploadedBill.checkNo ? `${uploadedBill.checkNo}:${payment.checkNo}` : `${payment.checkNo}`);
          });
          return {
            "icn": uploadedBill.processFileName || "",
            "practiceGroup": !!uploadedBill.parentName ? uploadedBill.parentName : "N/A",
            "provider": uploadedBill.providerName || "",
            "patientName": uploadedBill.insuranceMember ? `${uploadedBill.insuranceMember.firstName} ${uploadedBill.insuranceMember.lastName}` : "",
            "insurance": uploadedBill.pchInsuranceDetail.name + "/" + (uploadedBill.pchInsuranceDetail.address1 ? uploadedBill.pchInsuranceDetail.address1 : "") + (uploadedBill.pchInsuranceDetail.address2 ? (", " + uploadedBill.pchInsuranceDetail.address2) : "") + (uploadedBill.pchInsuranceDetail.city ? (", " + uploadedBill.pchInsuranceDetail.city) : "") + (uploadedBill.pchInsuranceDetail.state ? (", " + uploadedBill.pchInsuranceDetail.state) : "") + (uploadedBill.pchInsuranceDetail.zipCode ? (", " + uploadedBill.pchInsuranceDetail.zipCode) : ""),
            "dateOfService": `${DateUtils.formatDate(uploadedBill.insuranceMemberTreatedOnFrom)}-${DateUtils.formatDate(uploadedBill.insuranceMemberTreatedOnTo)}`, //"10/01/2023-11/12/2023",
            "fromDateOfService": DateUtils.formatDate(uploadedBill.insuranceMemberTreatedOnFrom),
            "toDateOfService": DateUtils.formatDate(uploadedBill.insuranceMemberTreatedOnTo),
            "totalClaimAmount": !!uploadedBill.insuranceMemberClaimsAmount ? uploadedBill.insuranceMemberClaimsAmount : "0",
            "paid": `${uploadedBill.paidAmount || ""}`,
            "patientResponsibility": `${uploadedBill.patientResponsibility || ""}`,
            "balance": `${+uploadedBill.insuranceMemberClaimsAmount - +(uploadedBill.patientResponsibility || "0") - +(uploadedBill.paidAmount || "0")}` || "",
            "checkNo": `${uploadedBill.checkNo || ""}`,
            "paidDate": `${uploadedBill.paidDate || ""}`,
            "uid": uploadedBill.uid,
            "claimPayments": claimPayments.nodes,
            "processFileName": uploadedBill.processFileName,
            "submittedDate": uploadedBill.submittedDate,
            "parentName": uploadedBill.parentName,
            "providerName": uploadedBill.providerName,
            "uploadedDateTime": uploadedBill.uploadedDateTime,
            "pchSubmittedStatus": uploadedBill.pchSubmittedStatus,
            "participatingPayer": uploadedBill.participatingPayer,
            "pchInsuranceDetail": uploadedBill.pchInsuranceDetail,
            "insuranceMember": uploadedBill.insuranceMember,
            "mergedFileName": uploadedBill.mergedFileName,
            "documentId": uploadedBill.documentId,
            "dOB": uploadedBill.dOB,
            "ssId": uploadedBill.ssId,
            "isProcessed": uploadedBill.isProcessed,
            "isSuperBill": uploadedBill.isSuperBill,
            "mergedUnsecuredFileName": uploadedBill.mergedUnsecuredFileName,
          }
        });
        items = await Promise.all(promises);
        items = items.filter(Boolean);
        return items;
      }
    }

    onActionClick = async (item, act) => {
      if (act === "view" || act === "delete") {
        this.props.app.nav.to("viewPaymentRoute", { uid: item.uid });
        await AsyncStorage.setItem("viewPayment", JSON.stringify(item));
      } else if (act === "add") {
        this.props.app.nav.to("applyPaymentRoute", { uid: item.uid });
        await AsyncStorage.setItem("applyPayment", JSON.stringify(item));
      }
    }

    handleSubmitFilter = async (form) => {
      try {
        let searchType = this.searchType.both;
        const { mobileNumber, email, uid } = await this.props.app.dm.rest.getUser();
        const claimFilter = {
          filter: {
            mobileNumber: { eq: mobileNumber },
            email: { eq: email },
            pchSubmittedStatus: { in: this.claimStatus }
          },
          sort: { field: "createdAt", order: "DESC" }
        };
        const paymentFilter = {
          filter: {
            createdBy: { eq: uid },
            isDeleted: { neq: "true" }
          },
          sort: { field: "paidDate", order: "DESC" },
        };

        const {
          insuranceName = {},
          checkNumber = "",
          fromDateOfService = "",
          toDateOfService = "",
          fromPaidDate = "",
          toPaidDate = "",
        } = form.values;

        if(!!checkNumber) {
          paymentFilter.filter.checkNo = {like: `%${checkNumber}%`};
          searchType = this.searchType.payment;
        }
        if(!!fromPaidDate && !!toPaidDate) {
          let toDate = new Date(toPaidDate);
          toDate = toDate.setDate(toDate.getDate() + 1);
          paymentFilter.filter.paidDate = { between: [ new Date(fromPaidDate), new Date(toDate)]};
          searchType = this.searchType.payment;
        }
        else if(!!fromPaidDate) {
          paymentFilter.filter.paidDate = { gte: new Date(fromPaidDate)};
          searchType = this.searchType.payment;
        }
        else if(!!toPaidDate) {
          let toDate = new Date(toPaidDate);
          // toDate = toDate.setDate(toDate.getDate() + 1);
          paymentFilter.filter.paidDate = { lte: new Date(toDate)};
          searchType = this.searchType.payment;
        }
        
        if(!!fromDateOfService && !!toDateOfService) {
          let toDate = new Date(toDateOfService);
          toDate = toDate.setDate(toDate.getDate() + 1);
          claimFilter.filter.insuranceMemberTreatedOnFrom = { between: [ new Date(fromDateOfService), new Date(toDate)]};
          claimFilter.filter.insuranceMemberTreatedOnTo = { between: [ new Date(fromDateOfService), new Date(toDate)]};
          searchType = this.searchType.claim;
        }
        else if(!!fromDateOfService) {
          claimFilter.filter.insuranceMemberTreatedOnFrom = { gte: new Date(fromDateOfService)};
          searchType = this.searchType.claim;
        }
        else if(!!toDateOfService) {
          let toDate = new Date(toDateOfService);
          // toDate = toDate.setDate(toDate.getDate() + 1);
          claimFilter.filter.insuranceMemberTreatedOnTo = { lte: new Date(toDate)};
          searchType = this.searchType.claim;
        }

        if(!!insuranceName && !!insuranceName.value) {
          claimFilter.filter.insuranceUid = { eq: insuranceName.value};
          searchType = this.searchType.claim;
        }

        const items = await this.getClaimsAndPayment(searchType, claimFilter, paymentFilter, true);
        this.setState({ managePayments: items, loaderVisible: false });
        analyticsLogs(CommonString.MANAGE_PAYMENT, CommonString.FILTER_PAYMENT, this.props.app);
        return false;
      }
      catch (err) {
        this.setState({ loaderVisible: false })
        console.log(err)
        return false;
      }
    }

    render() {
      const { isUberDocs } = this.props.app.dm.userSession.state;
      const { managePayments, isPopUpVisiable, loaderVisible } = this.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      return (
        <View style={[styles.moopSection, clientStyle.secondaryBgColor]}>
          <ManagePaymentFilterComponent
            handleReset={() => this.resetForm()}
            insurances={this.props.insurances}
            userLoginDetail={this.props.userLoginDetail}
            handleSubmitFilter={this.handleSubmitFilter}
          />
          <View style={[commonStyles.paddingTop15]}>
            <TableListComponent
              dataList={managePayments}
              pagination={false}
              height={styles.maxHeight250}
              labelKeys={["ICN#", "Practice Group", "Provider", "Patient Name", "Insurance/ Address", "DOS (From–To)", "Claim Amount", "Paid", "Responsibility", "Balance", "Check Number", "Paid Date", "Action"]}
              labelValues={["icn", "practiceGroup", "provider", "patientName", "insurance", "dateOfService", "totalClaimAmount", "paid", "patientResponsibility", "balance", "checkNo", "paidDate", "view:add:delete"]}
              visibility={!loaderVisible}
              icon={editIcon}
              onActionClick={(item, act) => this.onActionClick(item, act)}
              message={"No claim bills found."}
              dateTypeFields={["paidDate"]}
              amountTypeFields={["totalClaimAmount", "paid", "patientResponsibility", "balance"]}
            />
          </View>
          <PCHLoaderComponent isVisiable={loaderVisible} labelText={"Please wait"} clientStyle={clientStyle} />
          <PopupModalComponent
            visibility={isPopUpVisiable}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
            messageType={MESSAGE_TYPE.delete}
            headingIcon={true}
            heading={"Delete CPT"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={`Are you sure you want to delete?`}
            okLabel={"No"}
            handleOk={() => alert('no')}
            closeLabel={"Yes"}
            handleClose={() => alert('yes')}
          />
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  pageTitle: {
    marginTop: 15,
    display: "flex",
    justifyContent: "space-between",
    minHeight: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
          marginBottom: 10,
        };
      }
    },
  },
  moopSection: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    // flex: 1,
    // alignItems: "flex-start",
    // flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 16,
        };
      }
    },
  },
  maxHeight250: {
    height: 250,
    maxHeight: 250,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // height: "65vh",  // commented due to Native scroll issues
          // maxHeight: "65vh",  // commented due to Native scroll issues
          height: "auto",
          maxHeight: "auto",
        };
      }
    }
  }
});
