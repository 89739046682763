import React, { Component } from "react";
import R14, { StyleSheet, View, Text, ActivityIndicator, PopUpMenu, Platform, Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import CustomPopupComponent from '../components/CustomPopupComponent'
import HtmlDeCode from "../utils/HtmlDecode";
import PopupModalComponent from "./PopupModalComponent";
import { CommonString } from "../constant/appCommonText";
import { MESSAGE_TYPE } from "../constant/app.data.constant";
import { analyticsLogs } from "../utils/AnalyticLogs";

export default R14.connect(
  class BlockMenu extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isVisible: false,
        isDirectroy: false,
        isLoading: false,
        menuItems: [],
      };
    }

    componentDidMount() {
      if (!this.props.directory) {
        if (!!this.props.billData.threadHeader.isExternalCustomer)
          this.setState({
            menuItems: [
              { label: "Download", value: "Download" },
            ],
          });
      } else {
        if (this.props.status === "blocked") {
          this.setState({ menuItems: [{ label: "Unblock", value: "unblocked" }] });
        } else {
          this.setState({ menuItems: [{ label: "Block", value: "blocked" }] });
        }
      }
    }

    onSelect = () => {
      this.setState({ isVisible: true });
    };

    onCancel = () => {
      analyticsLogs(CommonString.PAM_LIST, `${CommonString.CANCEL_BLOCK_PAM_MODAL_POP_UP}`, this.props.app);
      this.setState({
        isVisible: false,
        isDirectroy: false,
      });
    };

    sendMessage = async (message) => {
      try {
        const pam = this.props.biller;
        let currentDate = new Date();
        let sendMessage = await this.props.app.dm.billerDirectoryDomain.updatePamMessageStatus({ status: message, textToPayPreference: !!pam.textToPayPreference ? pam.textToPayPreference : "none" }, pam);
        if (!!sendMessage && !!sendMessage.pamId) {
          await this.props.app.dm.billerDirectoryDomain.updatePamMessages({ uid: pam.uid, status: message, statusUpdatedTime: currentDate });
          if (message === "blocked") {
            analyticsLogs(CommonString.PAM_LIST, `${CommonString.PAM_BLOCKED}`, this.props.app);
            this.props.app.dm.commonDomain.showToastr("success", CommonString.BlockedAccount);
          }
          else {
            analyticsLogs(CommonString.PAM_LIST, `${CommonString.PAM_UNBLOCKED}`, this.props.app);
            this.props.app.dm.commonDomain.showToastr("success", CommonString.UnBlockedAccount);
          }
          const { pamList, searchPamList } = this.props.app.dm.billerDirectoryDomain.state;
          if (!!pamList && !!pamList.length) {
            let ind = pamList.findIndex(x => x.uid === pam.uid);
            pamList[ind].status = message;
            pamList[ind].blockedDate = message === "unblocked" ? null : currentDate;
          }
          if (!!searchPamList && !!searchPamList.length) {
            let ind = searchPamList.findIndex(x => x.uid === pam.uid);
            searchPamList[ind].status = message;
            searchPamList[ind].blockedDate = message === "unblocked" ? null : currentDate;;
          }
          if (message === "unblocked") {
            const { dateTimeHistorys } = this.props.app.dm.dashboard.state;
            if (!!dateTimeHistorys && dateTimeHistorys.length > 0) {
              dateTimeHistorys[0].simpleMessageLastDateTime = pam.blockedDate;
              dateTimeHistorys[0].pamMessageLastDateTime = pam.blockedDate;
              dateTimeHistorys[0].billLastDateTime = pam.blockedDate;
              dateTimeHistorys[0].notificationLastDateTime = pam.blockedDate;
              dateTimeHistorys[0].activeBillerLastDateTime = pam.blockedDate;
              this.setState({
                dateTimeHistorys: dateTimeHistorys
              });
              this.props.app.dm.dashboard.updateMsgDateHistory(dateTimeHistorys[0]);
            }
          }
          await this.props.app.dm.billerDirectoryDomain.setState({ pamList, searchPamList })
          this.setState({
            isVisible: false,
            menuItems: message === "unblocked" ? [{ label: "Block", value: "blocked" }] : [{ label: "Unblock", value: "unblocked" }]
          });
        }
        else
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      } catch (err) {
        console.log("error", err);
        return new Error(err);
      }
    }

    onDirectoryBlock = async () => {
      try {
        await this.sendMessage("blocked");
      } catch (error) {
        console.log("Status Error: ", error);
      }
    };

    blockStatus = async () => {
      this.setState({
        isVisible: true,
        isDirectroy: true,
      });
    };

    unblockStatus = async (message) => {
      try {
        await this.sendMessage(message);
      } catch (error) {
        console.log("Status Error: ", error);
      }
    };

    downloadFile = async ({ fileName, threadId, endToEndIdentification }) => {
      this.setState({
        isLoading: true,
      });
      try {
        let base64Format = await this.props.app.dm.billDetails.getInvoiceData(endToEndIdentification);
        if (!!base64Format && 'success' in base64Format && base64Format.success === true) {
          const fileObj = {
            content: `${Platform.OS === 'web' ? 'data:application/octet-stream;base64,' : ''}${(base64Format && base64Format.data) ? base64Format.data : base64Format}`,
            contentType: fileName.split('.')[1] || "pdf",
            filename: fileName
          };
          HtmlDeCode.downloadFile(fileObj);
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.NoBillDocument);
        }
      } catch (err) {
        this.setState({
          isLoading: false,
        });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
    };

    onMenuChange = async (val) => {
      const { directory } = this.props;
      if (directory) {
        if (val === "blocked") {
          this.blockStatus();
        } else {
          this.unblockStatus(val);
        }
      } else {
        if (val === "blocked") {
          this.onSelect();
        } else if (val === "Download") {
          const { billData } = this.props;
          const { threadId, biller, endToEndIdentification } = billData;
          this.downloadFile({
            fileName: `${biller.billerName}_${threadId}.pdf`,
            threadId: threadId,
            endToEndIdentification
          });
        } else if (val === "View") {
        }
      }
    };

    renderMenuItem() {
      const { menuItems } = this.state;
      return (
        <PopUpMenu controlIcon="dotsVertical" controlIconSize="small" key="trippleDot" direction="downLeft" style={[styles.green]} controlWidthCover={false}>
          {menuItems.map((el, key) => (
            <Touchable key={`__${key}`} style={[styles.menuItemWrapper]} value={el.value} onPress={() => this.onMenuChange(el.value)}>
              <View style={[customCommonStyles.customPopupmenuItemHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading]}>{el.label}</Text>
              </View>
            </Touchable>
          ))}
        </PopUpMenu>
      );
    }

    onClickPopupMenu = async () => {
      if (!!this.props.app.dm.userSession.state.leftMenuOverlay && this.props.app.dm.notificationDomain.state.isMobileDevice)
        this.props.app.dm.userSession.setState({
          leftMenuToggle: false,
          leftMenuOverlay: false,
        });
    }

    render() {
      const { isVisible, isDirectroy, popupMenuStyle, menuItems } = this.state;
      return (
        <>
          <View style={[commonStyles.marLAuto, styles.popupMenu, popupMenuStyle]}>
            {this.state.isLoading ? (
              <View style={styles.loading}>
                <ActivityIndicator size="large" color="#337aff" />
              </View>
            ) : null}
            {Platform.OS === "ios" ? (
              <CustomPopupComponent menuItem={menuItems} handleClick={this.onMenuChange} onClickPopupMenu={this.onClickPopupMenu} leftMenuOverlay={this.props.app.dm.userSession.state.leftMenuOverlay} />
            ) : (
              this.renderMenuItem()
            )}
          </View>
          <PopupModalComponent
            visibility={!!isVisible}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.modalBodyWrapper]}
            messageType={MESSAGE_TYPE.warning}
            headingIcon={true}
            heading={"Block PAM"}
            headerStyle={[commonStyles.marginBottom10, ]}
            bodyContent={`Do you want to block PAM?`}
            okLabel={!!isDirectroy ? "Confirm" : ""}
            handleOk={!!isDirectroy ? this.onDirectoryBlock : null}
            modalPopupOkBtn={[commonStyles.width85]}
            closeLabel={"Cancel"}
            handleClose={this.onCancel}
            modalPopupCancelBtn={[commonStyles.width85]}
          />
        </>
      );
    }
  }
);

const styles = StyleSheet.create({


  popupMenu: {
    // marginTop: -6,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // bottom: 20,
          marginTop: -9,
          maxHeight: 20,
          marginBottom: 5,
        };
      }
    },
  },
  popupContainer: {
    // marginBottom: 40
  },
  loading: {
    width: "100%",
    position: "absolute",
    left: -200,
    right: 60,
    top: 200,
    bottom: 0,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  popupMenus: {
    zIndex: 999999
  },
  menuWrap: {
    padding: 0,
  },
  menuItemWrapper: {
    // borderBottom: "1px solid #ccc",
    paddingTop: 1,
    paddingRight: 10,
    paddingBottom: 1,
    paddingLeft: 10,
    zIndex: 9999999,
  },
  radioButtonsClass: {
    marginBottom: 0,
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
    // borderBottom: "1px solid #ccc",
  },
  green: {
    marginLeft: 0,
    marginRight: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  confirmBtn: {
    width: "auto"
  },
  marginTopBottom20: {
    marginTop: 20,
    marginBottom: 20,
  },
  modalBodyWrapper: {
    minHeight: 132,
  },
});
