import R14 from "../core";
import { PAM_CREATION } from "../constant/app.constant";
import CommonUtility from "../utils/CommonUtility";

export default class BillerDirectoryDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      pamList: [],
      searchPamList: [],
    };
  }

  allPamFields = `uid pamId billerId payerId billerUid totalMessages readMessages accountNumber`; 
  
  async getPamMessagesList(isUpdate) {
    let pams = [];
    const { pid } = await this.dm.rest.getUser();;
    let currentDate = new Date();
    let res = await this.dm.dashboard.getAllDateTimeHistory();
    try {
      let x = new Date(
        !!res.length && !!res[0].pamMessageLastDateTime ? res[0].pamMessageLastDateTime : `01/01/${currentDate.getFullYear()}`
      );
      x.setHours(x.getHours() - 5);
      x.toUTCString();
      let fromDt = parseInt((x.getTime() / 1000).toFixed());
      let nowDt = parseInt((currentDate.getTime() / 1000).toFixed());
      pams = await this.dm.rest.get(`${PAM_CREATION}/?from=${fromDt}&to=${nowDt}`);
      pams = !!pams && pams.length > 0 ? pams : [];
      return pams;
    } catch (error) {
      console.log("Thread List ::: ", error);
      return [];
    }
    finally{
      let payload = { payerId: pid };
      if (!!res.length && !!pams && pams.length > 0 && !!isUpdate) {
        currentDate.toUTCString(); 
        payload.pamMessageLastDateTime = currentDate;
        payload.uid = res[0].uid;
        const { dateTimeHistorys } = this.dm.dashboard.state;
        if(!!dateTimeHistorys && dateTimeHistorys.length > 0)
        {
          dateTimeHistorys[0].pamMessageLastDateTime = currentDate
          this.dm.dashboard.setState({
            dateTimeHistorys : dateTimeHistorys
          });
        }
        this.dm.dashboard.updateMsgDateHistory(payload);
      }
    }
  }

  async moreInfoMessages(pamId, pid) {
    try {
      return await this.dm.rest.get(`${PAM_CREATION}/${pamId}`, { pamId, pid });
    } catch (error) {
      console.log("Thread List ::: ", error);
    }
  }
  async updatePamStatus(status, pamId, pid) {
    try {
      return await this.dm.rest.patch(`${PAM_CREATION}/${pamId}`, status, { pamId, pid });
    } catch (error) {
      console.log("Thread List ::: ", error);
    }
  }

  async createMoreInfoMessages(pamId, mimId, moreInfoMessage, pid) {
    try {
      return await this.dm.rest.post(`${PAM_CREATION}/${pamId}/more-information/${mimId}`, moreInfoMessage, {
        pamId,
        mimId,
        pid
      });
    } catch (error) {
      console.log("Thread List ::: ", error);
    }
  }

  async createPamMessages(payload, fields = "uid") {
    const {pid} = await this.dm.rest.getUser();
    payload.actionBy = pid;
    await CommonUtility.htmlDecode(payload);
    try {
      let res = await this.api.mutate(
        `mutation CreatePamMessage($payload: CreatePamMessageInput){
          createPamMessage(input: $payload){
            pamMessage{
              ${fields}
            }
          }
        }`,
        {
          payload,
        }
      );
      return res.data.createPamMessage ? res.data.createPamMessage.pamMessage : null;
    } catch (err) {
      return new Error(err);
    }
  }

  async createPamMesagePayload(message, pamId, payerId) {
    let payload;
    let mimId = this.createMimIDFromDate(pamId);
    let res;
    payload = {
      moreInfoMessage: message,
    };
    res = await this.createMoreInfoMessages(pamId, mimId, payload, payerId);
    return res;
  }

  async updatePamMessageStatus(payload, pam) {
    await CommonUtility.htmlDecode(payload);
    try {
      return await this.updatePamStatus(payload, pam.pamId, !!pam.payerId ? pam.payerId : pam.biller.payerId);
    } catch (err) {
      return null;
    }
  }

  async updatePamMessages(payload, fields = "uid") {
    const { pid } = await this.dm.rest.getUser();
    payload.actionBy = pid;
    await CommonUtility.htmlDecode(payload);
    try {
      let res = await this.api.mutate(
        `mutation UpdatePamMessage($payload: UpdatePamMessageInput){
          updatePamMessage(input: $payload){
            pamMessage{
              ${fields}
            }
          }
        }`,
        {
          payload,
        }
      );
      
      const pamList = this.state.pamList.map(pamItem => {
        if(pamItem.uid === (res.data.updatePamMessage && res.data.updatePamMessage.pamMessage.uid)){
          return {
            ...pamItem,
            ...payload
          }
        }
        return pamItem
      });

      this.setState({
          pamList: pamList
      })

      return res.data.updatePamMessage ? res.data.updatePamMessage.pamMessage : null;
    } catch (err) {
      return new Error(err);
    }
  }

  async createUpdatePamMessage(el) {
    if (el.pamId === "pamnew not found") return false;
    else {
      let hasPamDetail = await this.getPamMessagesByPamId(el.pamId, "uid");
      let payload = {
        totalMessages: el.totalMessages,
        billerUid: el.billerUid,
        status: el.status,
        statusUpdatedTime: el.statusUpdatedTime,
      };
      // To fix unread pam blinking issue
      if(el.readMessages) payload.readMessages = el.readMessages;
      await CommonUtility.htmlDecode(payload);
      return !!hasPamDetail && !!hasPamDetail.length
        ? await this.updatePamMessages({ ...payload, uid: hasPamDetail[0].uid })
        : await this.createPamMessages({
          ...payload,
          pamId: el.pamId,
          payerId: el.payerId,
          billerUid: el.billerUid,
          readMessages: 0,
          accountNumber: el.accountNumber,
          status: el.status,
        });
    }
  }


  async getPamMessagesByPamId(pamId, fields = "uid pamId billerUid accountNumber textToPayPreference totalMessages readMessages status blockedDate") {
    const { pamList } = this.state;
    if (!!pamList && pamList.length > 0) {
      let pams = pamList.filter(x => x.pamId === pamId);
      if (pams.length === 0) {
        let options = {
          filter: {
            pamId: { eq: pamId },
          },
        };
        let res = await this.api.qry(
          `query PamMessages($filter: PamMessageFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          pamMessages(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          nodes {
            ${fields}
          }
        }
      }`,
          options
        );
        return res.data.pamMessages.nodes;
      }
      else return pams;
    }
    else {
      let options = {
        filter: {
          pamId: { eq: pamId },
        },
      };
      let res = await this.api.qry(
        `query PamMessages($filter: PamMessageFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
        pamMessages(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          nodes {
            ${fields}
          }
        }
      }`,
        options
      );
      return res.data.pamMessages.nodes;
    }
  }

  async getPamMessages(
     fieldsStr = "uid payerId pamId billerUid accountNumber textToPayPreference totalMessages readMessages status blockedDate"
  ) {
    const linkedPids = await this.dm.rest.getLinkedPID();
    let pidBUs = await this.dm.rest.getLinkedPIDBU();

    let options = {
      filter: {
        payerId: { in: linkedPids.split(',') },
      },
    };
    let result = await this.api.qry(
      `query PamMessages($filter: PamMessageFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
        pamMessages(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    let pamList = result.data.pamMessages.nodes;
    let list = await this.dm.rest.getBillers();
    for (let i = 0; i < pamList.length; i++) {
      // eslint-disable-next-line no-loop-func
      let biller = list.find(x=> x.uid === pamList[i].billerUid);
      pamList[i].biller = biller;
    }
    pamList = pamList.filter((el) => {
      return pidBUs.some((f) => {
        return ((!!el.biller && f.buId === el.biller.buId && f.pid === el.payerId && f.billerId === el.biller.billerId) || !f.buId);
      });
    });
    this.setState({ pamList: pamList });
    return pamList;
  }

  async pamDirectoryNotification(pams, pids) {
    try {
      let newThreads = [];
      let complieList = this.state.pamList;
      if (!!complieList && complieList.length > 0) {
        let compilePAMs = complieList.map(x => { return x.pamId });
        newThreads = pams.filter(x => !compilePAMs.includes(x.threadId));
      }
      else
        newThreads = pams;
      if (!!newThreads && newThreads.length > 0) {
        const billerList = await this.dm.rest.getBillers();
        for (let notificationPAMs of newThreads) {
          let pamDetails = await this.moreInfoMessages(notificationPAMs.threadId, notificationPAMs.payerId);
          if (!!pamDetails && pamDetails.status === "requested") {
            let billerDetail = {};
            if (!!billerList && billerList.length > 0)
              billerDetail = billerList.find((itm) => itm.billerId === pamDetails.billerId);
            /**
             * new biller comes and requested for pam
             * and you are logged in and that time your biller is empty or it will have previous biller
             ***/
            if (!!billerDetail && !!billerDetail.uid) {
              pamDetails.billerUid = billerDetail.uid;
            } else {
              // adding new biller if biller is not present in DB
              let billerOptions = await this.dm.billRequest.getBillerCSEPaymentToggle(
                {
                  isMessagingConfig: true,
                  billerPIDList: [{ pid: pamDetails.billerId, buId: pamDetails.buid }],
                  from: 1,
                  to: parseInt((new Date().getTime() / 1000).toFixed())
                });

                let activeBiller = null;
                if (!!billerOptions && billerOptions.length > 0) {
                  activeBiller = billerOptions.pop();
                }

              if (!!activeBiller) {
                if (!billerDetail) billerDetail = {};
                billerDetail.messagingToggleValue = activeBiller.messagingToggleValue || true;
                billerDetail.isPaymentDecline = activeBiller.isPaymentDecline || true;
                billerDetail.isPaymentDueDateExtensions = activeBiller.isPaymentDueDateExtensions || true;
                billerDetail.isPaymentsFull = activeBiller.isPaymentsFull || true;
                billerDetail.isPaymentsPartial = activeBiller.isPaymentsPartial || true;
                billerDetail.billerName = activeBiller.billerName || billerDetail.billerName;

                pamDetails.industryName = activeBiller.industryName || billerDetail.industryName;
                pamDetails.buId = activeBiller.buId || billerDetail.buId;
              }

              let newBiller = await this.dm.queryDomain.addUpdateBiller({ ...pamDetails, biller: billerDetail });
              pamDetails.billerUid = newBiller.uid;
              billerDetail = newBiller;
            }
            /**
             * then the above block of code is required, bcoz user not need to logout and re login to get the biller details.
             * */
            pamDetails.totalMessages = 1;
            pamDetails.totalMessages = !!pamDetails.moreInfos && !!pamDetails.moreInfos.length ? pamDetails.moreInfos.filter((msg) => !!msg.moreInfoReply).length + 1 : 1;
            await this.createUpdatePamMessage(pamDetails);
          }
          await this.dm.queryDomain.getBiller({ filter: { payerId: { in: pids } } });
          await this.getPamMessages();
        }
      }
    } catch (error) {

    }
  }

  async displayBillerDirectoryData(recall = false) {
    let reCalledBiller = recall;
    const linkedPids = await this.dm.rest.getLinkedPID();
    let resData = await this.getPamMessagesList(true);
    if (!!resData && resData.length > 0) {
        const billerList = await this.dm.rest.getBillers();
        for (let ele of resData) {
          if (!!ele) {
            ele.buId = ele.buid;
            CommonUtility.htmlDecode(ele)
            // delete ele.buid;
            let pamDetails = null;
            if(ele.status === "requested")
              pamDetails = await this.moreInfoMessages(ele.pamId, ele.payerId);
            let billerDetail = {};
            if (!!billerList && billerList.length > 0)
              billerDetail = billerList.find(             
              (x) => x.billerId === ele.billerId && x.payerId === ele.payerId && x.buId === ele.buId && x.billerRef === ele.billerRef && x.payerRef === ele.payerRef);
            
              let billerOptions = await this.dm.billRequest.getBillerCSEPaymentToggle(
              {
                isMessagingConfig : true,
                billerPIDList: [{ pid : ele.billerId, buId: ele.buId }],
                from: 1,
                to: parseInt((new Date().getTime() / 1000).toFixed())
              });

            let activeBiller = null;
            if (!!billerOptions && billerOptions.length > 0) {
              activeBiller = billerOptions.pop();
            }

            if (!!billerDetail && !!billerDetail.uid && !!activeBiller && billerDetail.billerName === activeBiller.billerName) {
              ele.billerUid = billerDetail.uid;
            } else {
              reCalledBiller = true;
              // adding new biller if biller is not present in DB
              if(!!activeBiller)
              {
                if(!billerDetail) billerDetail = {};
                billerDetail.messagingToggleValue = activeBiller.messagingToggleValue;
                billerDetail.isPaymentDecline = activeBiller.isPaymentDecline;
                billerDetail.isPaymentDueDateExtensions = activeBiller.isPaymentDueDateExtensions;
                billerDetail.isPaymentsFull = activeBiller.isPaymentsFull;
                billerDetail.isPaymentsPartial = activeBiller.isPaymentsPartial;
                billerDetail.billerName = activeBiller.billerName || ele.billerName;
              }
              
              let newBiller = await this.dm.queryDomain.addUpdateBiller({ ...ele, biller: billerDetail });
              ele.billerUid = newBiller.uid;
              billerDetail = newBiller;
            }
            ele.totalMessages = 1;
            if (!!pamDetails && pamDetails.pamId && pamDetails.moreInfos && !!pamDetails.moreInfos.length) {
              reCalledBiller = true;
              await this.dm.queryDomain.addUpdateBiller({ ...pamDetails, biller: billerDetail });
              ele.totalMessages = !!pamDetails.moreInfos ? pamDetails.moreInfos.filter((msg) => !!msg.moreInfoReply).length + 1 : 1;
            }
            await this.createUpdatePamMessage(ele);
          }
      }
    } 
    if ((!!resData && resData.length > 0) || !!reCalledBiller) {
      await this.dm.queryDomain.getBiller({ filter: { payerId: { in: linkedPids.split(',') } } });
      await this.getPamMessages();
    }
  }

  getRandomString(length) {
    var randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  createMimIDFromDate(pamId) {
    let sufix = this.getRandomString(7);
    let pam = pamId.split("-");
    const mimId = "MSG-" + pam[1] + "-" + sufix;
    return mimId;
  }
}
