import React from "react";
import R14, {
    View,
    StyleSheet,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";

import TableListComponent from "../../components/TableListComponent";
import SepaPaymentOption from "./SepaPaymentOption";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import PopupModalComponent from "../PopupModalComponent";
import {FULL_PAYMENT, INSTALLMENT} from "../../constant/sepa.constant";
import { CommonString } from "../../constant/appCommonText";
import CommonUtility from "../../utils/CommonUtility";
import { analyticsLogs } from "../../utils/AnalyticLogs";
const { MESSAGE_TYPE } = require("../../constant/app.data.constant")
const eyeIcon = require("../../assets/images/eye.png");

const FullPaymentKeys = ["Created Date", "Execution Date", "Amount"];
const FullPaymentValues = ["createdOn", "executionDate", "billAmount"]
const InstallmentKeys = ["Instalment", "Execution Date", "Instalment Amt"];
const InstallmentValues = ["installment", "executionDate", "billAmount"];

export default R14.connect(
    class SepaBillsComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                loaderVisible: false,
                isVisibleSepaPaymentOption: false,
                selectedPaymentOption: {},
                isConfirmationVisible: false,
                paymentsList: [] ,
                paymentOptions: [],
                paymentLabelKeys: FullPaymentKeys,
                paymentLabelValues: FullPaymentValues,
            };
        }


        // componentDidMount = async () => this.handlePaginationCall(1, "", this.props.isDashboard);

        handlePaginationCall = async(currentPage, search, isDashboard) => {
            const {resultsPerPage } = this.props.app.dm.sepa.state;
            const payload = {"page": currentPage, "resultsPerPage": resultsPerPage, "sort": [{"field": "billDate", "order": "ASC"}] }
            this.props.app.dm.sepa.setState({currentPage});
            if(!!isDashboard){
                this.setState({loaderVisible: true});
                await this.props.app.dm.sepa.syncSEPABills(payload);
            } else if (!!search && !isDashboard) {
                this.setState({loaderVisible: true});
                payload.search = search;
                await this.props.app.dm.sepa.getSPABillsList(payload);
            }
            this.setState({loaderVisible: false});
        }

        componentWillUnmount(){
            this.props.app.dm.sepa.setState({
               sepaBills: [] 
            });
        }

        onToggleBookmark = async (item) => {
            analyticsLogs(this.props.isDashboard ? CommonString.SEPA_DASHBOARD : CommonString.SEPA_ALL_BILLS, !item.bookmark ? CommonString.Prioritize_Bill : CommonString.Removed_Priority, this.props.app);
            this.setState({ loaderVisible: true });
            let bill = await this.props.app.dm.sepa.toggleBookmark(item.uid);
            if (bill) {
                let { sepaBills, totalBills } = this.props.app.dm.sepa.state;
                if (!!sepaBills.length) {
                    let ind = sepaBills.findIndex((el) => el.uid === item.uid);
                    if (ind >= 0) {
                        sepaBills[ind].bookmark = !sepaBills[ind].bookmark;
                        let data = sepaBills;
                        const { isDashboard } = this.props;
                        if (!!isDashboard)
                        {
                            data = sepaBills.filter((x) => !!x.bookmark);
                            totalBills = totalBills - 1;
                        }
                        await this.props.app.dm.sepa.setState({ sepaBills: data, totalBills: totalBills });
                    }
                }
            }
            this.setState({ loaderVisible: false });
        }

        onActionClick = (item) => {
            if (item.selectedPaymentType) {
                analyticsLogs(this.props.isDashboard ? CommonString.SEPA_DASHBOARD : CommonString.SEPA_ALL_BILLS, `${CommonString.BILL_DETAILS_ACTION}`, this.props.app);
                this.props.app.dm.sepa.setState({
                    c_bill: item.resourceId
                })
                this.props.app.nav.to("sepaBillDetailsRoute", { resourceId: item.resourceId });
                return false;
            }
            else {
                const paymentOptions = [
                    { label: `OneTime Payment (${CommonUtility.formatAmount(item.fullPayAmount)})`, selectedChk: FULL_PAYMENT, value: FULL_PAYMENT },
                    { label: `Instalment Payment (${CommonUtility.formatAmount(item.installmentAmount)})`, selectedChk: FULL_PAYMENT, value: INSTALLMENT }
                ]
                this.setState({
                    isVisibleSepaPaymentOption: true,
                    selectedPaymentOption: item,
                    paymentsList: item.fullPay,
                    paymentOptions: paymentOptions,
                    
                })
            }
        }

        onCancelSepaPayment = () => {
            this.setState({
                isVisibleSepaPaymentOption: false,
                selectedPaymentOption: {},
                isConfirmationVisible: false,
                paymentLabelKeys: FullPaymentKeys,
                paymentLabelValues: FullPaymentValues,
            });
            return false;
        }

        onApplySepaPayment = (item) => {
            this.setState({
                isConfirmationVisible: true,
                selectedPaymentOption: item
            })
            return false;
        }

        onCloseConfirmationPopup = () => {
            this.setState({ isConfirmationVisible: false });
            return false;
        }

        onProceedConfirmation = async () => {
            const { selectedPaymentOption, paymentOptions } = this.state;
            const payload = { bid: selectedPaymentOption.uid }
            if (paymentOptions[0].value === FULL_PAYMENT && paymentOptions[0].selectedChk === FULL_PAYMENT) payload.pmtInfId = selectedPaymentOption.fullPay[0].pmtInfId;
            else payload.pmtInfId = selectedPaymentOption.installment[0].pmtInfId;

            let result = await this.props.app.dm.sepa.updateSelectedPmtType(payload);
            if (!!result) {
                analyticsLogs(this.props.isDashboard ? CommonString.SEPA_DASHBOARD : CommonString.SEPA_ALL_BILLS, `${CommonString.Payment_Option_Selected} ${paymentOptions[0].selectedChk}`, this.props.app)
                this.setState({
                    isVisibleSepaPaymentOption: false,
                    isConfirmationVisible: false,
                    selectedPaymentOption: {}
                });
                this.props.app.dm.sepa.setState({
                    c_bill: selectedPaymentOption.resourceId
                })
                this.props.app.nav.to("sepaBillDetailsRoute", { resourceId: selectedPaymentOption.resourceId });
                return false;
            }
            else {
                this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
                return false;
            }
        };

        onPaymentChange = (items = []) => {
            const {selectedPaymentOption} =  this.state;
            items.forEach(item => {
                if (item.value === FULL_PAYMENT && item.selectedChk === FULL_PAYMENT) {
                    this.setState({
                        paymentsList: selectedPaymentOption.fullPay,
                        paymentLabelKeys: FullPaymentKeys,
                        paymentLabelValues: FullPaymentValues,
                        paymentOptions: items,
                    })
                } else if (item.value === INSTALLMENT && item.selectedChk === INSTALLMENT) {
                    this.setState({
                        paymentsList: selectedPaymentOption.installment,
                        paymentLabelKeys: InstallmentKeys,
                        paymentLabelValues: InstallmentValues,
                        paymentOptions: items,
                    })
                }
            });
        }

        getNext = async () => {
            let { currentPage, totalBills, resultsPerPage, sepaBlockStatus } = this.props.app.dm.sepa.state;
            const totalPages = Math.ceil(totalBills / resultsPerPage);
            if (currentPage <= totalPages) {
                currentPage = currentPage + 1;
                this.handlePaginationCall(currentPage, sepaBlockStatus.toUpperCase(), this.props.isDashboard);
            }
        }

        getPrevious = async () => {
            let { currentPage, sepaBlockStatus } = this.props.app.dm.sepa.state;
            if (currentPage >= 1) {
                currentPage = currentPage - 1;
                if (currentPage > 0) this.handlePaginationCall(currentPage, sepaBlockStatus.toUpperCase(), this.props.isDashboard);
            }
        }

        getFirst = async () => {
            const { sepaBlockStatus} = this.props.app.dm.sepa.state;
            this.handlePaginationCall(1, sepaBlockStatus.toUpperCase(), this.props.isDashboard);
        }

        getLast = async () => {
            let { totalBills, resultsPerPage, sepaBlockStatus } = this.props.app.dm.sepa.state;
            const currentPage = Math.ceil(totalBills / resultsPerPage);
            this.handlePaginationCall(currentPage, sepaBlockStatus.toUpperCase(), this.props.isDashboard);
        }

        render() {
            const { isUberDocs } = this.props.app.dm.userSession.state;
            const { loaderVisible, isVisibleSepaPaymentOption, selectedPaymentOption, isConfirmationVisible, paymentsList, paymentOptions, paymentLabelKeys, paymentLabelValues } = this.state;
            let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
            const { totalBills, resultsPerPage, currentPage, sepaBills } = this.props.app.dm.sepa.state; 
            const totalPages = Math.ceil(totalBills / resultsPerPage);
            let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
            let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
            let rowItems = endIndex - startIndex + 1;
            return (
                <>
                    <View style={[styles.dataListingSection, clientStyle.secondaryBgColor, this.props.customStyles]}>
                        <TableListComponent
                            dataList={sepaBills}
                            pagination={true}
                            containerStyle={!!sepaBills && sepaBills.length > 0 ? commonStyles.marginTop15: null}
                            height={[styles.maxHeight250, !sepaBills || !sepaBills.length ? styles.customMarginLeftRight16 : null, this.props.customHeights]}
                            labelKeys={["Biller", "Receive Date", "Amount", "Status", "Payment Type", "Expiry Date", "Action"]}
                            labelValues={["billerName", "billDate", "billAmount", "billStatus", "paymentType", "expiryDate", "Details"]}
                            visibility={!loaderVisible}
                            dateTypeFields={["billDate", "expiryDate"]}
                            dateFormat={"DD/MM/YYYY"}
                            amountTypeFields={["billAmount"]}
                            textOnly={false}
                            icon={eyeIcon}
                            onActionClick={(item, act) => this.onActionClick(item, act)}
                            message={ this.props.isDashboard ? "No priority bills found.": "No bills found."}
                            onToggleBookmark={(item, act) => this.onToggleBookmark(item, act)}
                            onProfileIcon={(item, act) => this.onActionClick(item, act)}

                            totalPages={totalPages}
                            startIndex={startIndex}
                            endIndex={endIndex}
                            rowItems={rowItems}
                            currentPage={currentPage}
                            totalBills={totalBills}
                            getFirst={() => this.getFirst()}
                            getLast={() => this.getLast()}
                            getNext={() => this.getNext()}
                            getPrevious={() => this.getPrevious()}
                        />
                    </View>
                    <PCHLoaderComponent isVisiable={loaderVisible} labelText={"Please wait"} clientStyle={clientStyle} />
                    <SepaPaymentOption
                        isVisiable={isVisibleSepaPaymentOption}
                        item={selectedPaymentOption}
                        onCancel={this.onCancelSepaPayment}
                        onApply={() => this.onApplySepaPayment(selectedPaymentOption)}
                        paymentsList={paymentsList} 
                        onPaymentChange={this.onPaymentChange}
                        paymentOptions={paymentOptions}
                        paymentLabelKeys={paymentLabelKeys}
                        paymentLabelValues={paymentLabelValues}
                    />
                    <PopupModalComponent
                        visibility={isConfirmationVisible}
                        bodyWrapper={[styles.bodyWrapper]}
                        messageType={MESSAGE_TYPE.info}
                        headingIcon={true}
                        heading={""}
                        modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
                        bodyContent={"Are you sure you want to continue with selected payment type?"}
                        okLabel={"Proceed"}
                        handleOk={() => this.onProceedConfirmation()}
                        modalPopupOkBtn={[commonStyles.btnWidth108]}
                        closeLabel={"Cancel"}
                        handleClose={this.onCloseConfirmationPopup}
                        modalPopupCancelBtn={[commonStyles.btnWidth108]}
                    /></>)
        }
    });

const styles = StyleSheet.create({
    dataListingSection: {
        backgroundColor: "#fff",
        marginTop: 16,
        marginRight: 16,
        marginBottom: 16,
        marginLeft: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    height: "auto",
                };
            }
        },
    },
    maxHeight250: {
        maxHeight: 250,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    maxHeight: "auto",
                };
            }
        }
    },
    bodyWrapper: {
        width: 280,
    },
    customMarginLeftRight16: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 16,
                    marginRight: 16,
                };
            }
        } 
    }
});
