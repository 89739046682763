import React from "react";
import R14, { View, StyleSheet } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import PCHLoaderComponent from "../components/PCH/PCHLoaderComponent";
import { MESSAGE_TYPE } from "../constant/app.data.constant";
import PopupModalComponent from "../components/PopupModalComponent";

export default R14.connect(
    class TextToPayScreen extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                osName: "",
                isLoading: true
            };
        }

        async componentDidMount() {       
            this.onRedirectDeepLinking();
        }

        handleRedirect = async () => {
            const {isUserAlreadyLoggedIn} = this.props.app.dm.textToPay.state
            if (isUserAlreadyLoggedIn) {
                this.props.app.nav.to('dashboard')
            } else {
                this.props.app.nav.to('login')
            }
        }

        onRedirectDeepLinking = async () => {
            //expected url domain/t2p/encrypted value
            const URL = this.props.app.route._location.pathname;
            const messageMetaIdentifier = await this.props.app.dm.textToPay.decryptTextToPayMetadata(URL)
            if (!!messageMetaIdentifier && messageMetaIdentifier.length === 4) {
                this.props.app.dm.textToPay.getMessageMetadata(messageMetaIdentifier)
            } else {
                this.props.app.dm.textToPay.updateState(true, messageMetaIdentifier[0].trim())
            }
        };

        renderErrorPopup() {
            const {isUserAlreadyLoggedIn} = this.props.app.dm.textToPay.state;
            const btnText = isUserAlreadyLoggedIn ? 'Go to Dashboard' : 'Go to Login'
            return (
                <PopupModalComponent
                    visibility={true}
                    bodyWrapper={[commonStyles.smallModalBodyWrapper]}
                    messageType={MESSAGE_TYPE.info}
                    headingIcon={true}
                    heading={"Delete CPT"}
                    modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
                    bodyContent={"Please connect with the Biller to validate the Payment details"}
                    okLabel={`${btnText}`}
                    handleOk={this.handleRedirect}
                    closeLabel={""}
                    handleClose={null}
                />
            )
        }

        render() {
            const { showErrorPopup } = this.props.app.dm.textToPay.state
            return (
                <View style={styles.container}>
                    {!!showErrorPopup && this.renderErrorPopup()}
                    <PCHLoaderComponent isVisiable={this.state.isLoading && !showErrorPopup} labelText={"Please wait"} />
                </View>
            );
        }
    }
);

const styles = StyleSheet.create({
    container: {
        display: "flex",
        justifyContent: "center",
        marginTop: 50,
    },
});
