import React from "react";
import R14, {
  View,
  StyleSheet,
  Button
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { MESSAGE_TYPE } from "../../constant/app.data.constant";
import PopupModalComponent from "../../components/PopupModalComponent";
import {analyticsLogs} from "../../utils/AnalyticLogs";

import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";
import ManagePaymentDetailsComponent from "../../components/PCH/ManagePaymentDetailsComponent";
import EditManagePaymentComponent from "../../components/PCH/EditManagePaymentComponent";
import ViewSubmittedClaimComponent from "../../components/PCH/ViewSubmittedClaimComponent";
import DateUtils from "../../utils/DateUtils";
import HtmlDecode from '../../utils/HtmlDecode';
import TableListComponent from "../../components/TableListComponent";
import PdfListModalComponent from "../../components/PdfListModalComponent";
import ModalPopUp from "../../components/ModalPopUp";
import PDFViewerComponent from "../../components/PCH/PDFViewerComponent";

export default R14.connect(
  class ApplyManagePaymentScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        billUid: this.props.billUid,
        uploadedBill: this.props.uploadedBill,
        claimPayments: [],
        totalCount: !!this.props.uploadedBill && !!this.props.uploadedBill.claimPayments ? this.props.uploadedBill.claimPayments.length : 0,
        isConfirmationPopUp: false,
        isVisiable: false,
        selectedItem: {},
        isEditPaymentVisible: false,
        errorMessage: "",
        isViewClaim: false,
        isPdfConfirmation: false,
        selectedItemType: "",
        pdfs: [],
        isDocVisible: "",
        fileContent: "",
        signedUrl: "",
        selectedBill: "",
        fileContentUnsecure: ""
      };
    }

    onActionClick = async (item, act) => {
      if(act === "view") this.props.app.nav.to("viewPaymentRoute");
      else if(act === "action") this.props.app.nav.to("applyPaymentRoute");
    }

    onLoaderHanlde = async(status) => {
        this.setState({isVisiable: (status === false || status === true) ? status : !this.state.isVisiable});
    }

    removeCommas = (formattedNumber = "") => {
      return !!formattedNumber ? formattedNumber.toString().replace(/,/g, "") : "";
    };

    numberWithCommas = (number = "") => {
      const num = this.removeCommas(number);
      return !!num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
    };

    viewPayments = async() => {
      const { uploadedBill } = this.props;
      if(!!uploadedBill && !!uploadedBill.claimPayments && uploadedBill.claimPayments.length) {
        const payments = uploadedBill.claimPayments.map(bill => {
          return {
            ...bill,
            paidDate: DateUtils.formatDateInMMDDYYYY(bill.paidDate)
          }
        });
        this.setState({claimPayments: payments})
        return false;
      }
      this.props.app.dm.commonDomain.showToastr("error", "No payment found");
      return false;
    }

    handleSubmitPayment = async(form, documentId) => {
      const {billUid, uploadedBill} = this.state;
      const { 
        checkNumber,
        paidDate,
        paidAmount = "0",
        deductible = "",
        copay = "",
        coinsurance = "",
        comments = ""
      } = form.values;
      const {userLoginDetail} = this.props;

      const bill = {
        uploadBillUid: billUid,
        checkNo: checkNumber,
        documentId,
        comments,
        paidDate: new Date(paidDate),
        paidAmount: this.removeCommas(paidAmount),
        deductible: this.removeCommas(deductible),
        copay: this.removeCommas(copay),
        coinsurance: this.removeCommas(coinsurance),
        memberClaimsAmount: !!uploadedBill.totalClaimAmount ? uploadedBill.totalClaimAmount : "0",
        fromDateOfService: new Date(uploadedBill.fromDateOfService),
        toDateOfService: new Date(uploadedBill.toDateOfService),
        createdBy: userLoginDetail.uid,
        createdAt: new Date(),
      };
      this.onLoaderHanlde(true);
      const res = await this.props.app.dm.claimPaymentDomain.createClaimPayment(bill);
      if(res && res.uid){
        analyticsLogs(CommonString.MANAGE_PAYMENT, CommonString.ADDED_PAYMENT, this.props.app);
        this.setState({
          isEditPaymentVisible: true
        });
      } else {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
      this.onLoaderHanlde(false);
      return false;
    }

    onCancelPayment = async() => {
        this.props.app.nav.back();
    }

    handleOk = async(item, act) => {
        if(!!this.state.isEditPaymentVisible){
            this.props.app.nav.back();
        }
        this.setState({
            isEditPaymentVisible: false,
            selectedItem: {}
        })
    }

    showError = (errorMessage) => {
        this.setState({
            errorMessage: errorMessage
        })
    }

    onViewEOB = async (item, type = "View") => {
        const { documentId } = item;
        if (!documentId) return false;
        this.setState({ isVisiable: true });
        const filter = {
            filter: {
                documentId: {
                    eq: documentId
                }
            },
            sort: {
                field: "seqNo",
                order: "ASC"
            }
        }
        let documentLogs = await this.props.app.dm.uploadBillDomain.documentLogs(filter);
        this.setState({ isPdfConfirmation: true, selectedItem: item, selectedItemType: type, pdfs: documentLogs, isVisiable: false })
    }


    onDownloadOrViewBillDocuments = async (selectedBill, docAction) => {
        try {
            this.setState({ isVisiable: true, selectedBill });
            if (!!true) {
                let options = {
                    responseType: "arraybuffer",
                    headers: {
                        "content-type": "application/pdf",
                    }
                };
                let signedUrl = await this.props.app.dm.uploadBillDomain.getObjectSignedUrl(selectedBill.mergedFileName);
                let response = await this.props.app.dm.rest.getS3(signedUrl, options);
                let billDocuments = Buffer.from(response.data, 'binary').toString('base64');

                if (!!billDocuments) {
                    if (docAction === "View") {
                        this.setState({ fileContent: billDocuments, signedUrl: signedUrl, isDocVisible: true, fileContentUnsecure: billDocuments, isVisiable: false });
                    } else {
                        const item = {
                            content: `data:application/octet-stream;base64,${billDocuments}`,
                            contentType: 'PDF',
                            filename: selectedBill.mergedFileName
                        };
                        HtmlDecode.downloadFile(item);
                        this.setState({ isVisiable: false })
                    }
                } else this.props.app.dm.commonDomain.showToastr("error", CommonString.FileNotFound);
            } else this.props.app.dm.commonDomain.showToastr("error", CommonString.FileInProcess);

            this.setState({ isVisiable: false })
        } catch (error) {
            this.setState({ isVisiable: false })
            console.log(error)
        }
    };

    renderPDFViewer(clientStyle, isMobileDevice, ) {
        const { isDocVisible, fileContent, signedUrl, selectedBill, fileContentUnsecure } = this.state;
        return (
            <ModalPopUp
                visibility={isDocVisible}
                isFooter={true}
                bodyWrapper={[commonStyles.largModalBodyWrapper, clientStyle.secondaryBgColor]}
                heading={"View Document"}
                handleCancel={() => this.setState({ isDocVisible: false })}
                animationType="fade"
                closeButton={customCommonStyles.viewPDFCloseBtn}
            >
                <View>
                    <PDFViewerComponent closePDF={() => this.setState({ isDocVisible: false })} isMobileDevice={isMobileDevice} bills={selectedBill} signedUrl={signedUrl} dataSource={fileContent} dataSourceUnsecured={fileContentUnsecure} dataSourceType={selectedBill.dataSourceType || "pdf"} disableFileName={true} />
                </View>
            </ModalPopUp>
        );
    }

    render() {
      const { isUberDocs } = this.props.app.dm.userSession.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
      const {uploadedBill} = this.props;
      const {
        isVisiable,
        isEditPaymentVisible,
        errorMessage,
        isViewClaim,
        claimPayments,
        selectedItem,
        isPdfConfirmation,
        selectedItemType,
        pdfs
      } = this.state;

      return (
        <>
          {isViewClaim ? <ViewSubmittedClaimComponent uploadBill={uploadedBill} userLoginDetail={this.props.userLoginDetail} managePayment={true} onViewClaimBack={() => this.setState({ isViewClaim: false })} /> : null}
          <View style={[styles.moopSection, clientStyle.secondaryBgColor]}>
            <ManagePaymentDetailsComponent
              icn={uploadedBill.icn || ""}
              providerGroup={uploadedBill.providerGroup || ""}
              provider={uploadedBill.provider || ""}
              dateOfService={uploadedBill.dateOfService || ""}
              totalClaimAmount={uploadedBill.totalClaimAmount || ""}
              paid={uploadedBill.paid || ""}
              patientResponsibility={uploadedBill.patientResponsibility || ""}
              balance={uploadedBill.balance || ""}
              viewClaim={() => this.setState({ isViewClaim: true })}
              viewPayments={claimPayments && claimPayments.length ? null : (res) => this.viewPayments(res)}
            />
            <EditManagePaymentComponent
              handleSubmitPayment={this.handleSubmitPayment}
              onCancelPayment={this.onCancelPayment}
              numberWithCommas={this.numberWithCommas}
              onLoaderHanlde={this.onLoaderHanlde}
              showError={this.showError}
              hideComponent={claimPayments && claimPayments.length}
            />
            {this.renderPDFViewer(clientStyle, isMobileDevice, isUberDocs)}
            <PdfListModalComponent
              isPdfConfirmation={isPdfConfirmation}
              clientStyle={clientStyle}
              commonFontStyles={commonFontStyles}
              selectedItem={selectedItem}
              selectedItemType={selectedItemType}
              pdfs={pdfs}
              onDownloadOrViewBillDocuments={this.onDownloadOrViewBillDocuments}
              onHidePdfConfimation={() => this.setState({ isPdfConfirmation: false })}
              headerText={"EOB Document(s)"}
            />
            <View style={[commonStyles.paddingTop15, claimPayments && claimPayments.length ? null : commonStyles.displayNone]}>
              <TableListComponent
                dataList={claimPayments}
                pagination={false}
                labelKeys={["Check Number", "Paid Date", "Paid Amount", "Deductible", "Copay", "Coinsurance", "View"]}
                labelValues={["checkNo", "paidDate", "paidAmount", "deductible", "copay", "coinsurance", ""]}
                visibility={false}
                onViewClick={(documentId) => this.onViewEOB(documentId)}
                message={"No payment found"}
                containerStyle={styles.containerStyle}
                height={styles.containerHeight}
                dateTypeFields={["paidDate"]}
                amountTypeFields={["paidAmount", "deductible", "copay", "coinsurance",]}
              />
            </View>
            <Button
              title={"Back"}
              style={[
                commonStyles.blueBorderButton,
                customCommonStyles.noBoxShadow,
                commonStyles.btnHeight24,
                styles.customBackButton,
                claimPayments && claimPayments.length ? null : commonStyles.displayNone
              ]}
              onPress={() => this.setState({ claimPayments: [] })}
              titleTextStyle={[
                commonStyles.blueBorderButtonColor,
                commonStyles.fontWeight500,
                commonFontStyles.workSans,
                commonStyles.exSmallHeading
              ]}
            />
          </View>
          <PCHLoaderComponent isVisiable={isVisiable} labelText={"Please wait"} clientStyle={clientStyle} />
          <PopupModalComponent
            visibility={!!isEditPaymentVisible || !!errorMessage}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
            messageType={MESSAGE_TYPE.info}
            headingIcon={true}
            heading={!!errorMessage ? "Error" : "Payment Applied"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={!!errorMessage ? errorMessage : `Your Payment has been applied successfully`}
            okLabel={"Ok"}
            handleOk={() => !!errorMessage ? this.setState({ errorMessage: "" }) : this.handleOk('ok')}
          />
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  pageTitle: {
    marginTop: 15,
    display: "flex",
    justifyContent: "space-between",
    minHeight: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
          marginBottom: 10,
        };
      }
    },
  },
  moopSection: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    // flex: 1,
    // alignItems: "flex-start",
    // flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 16,
        };
      }
    },
  },
  customBackButton: {
    width: 108,
    maxWidth: 108,
    marginBottom: 24,
    marginRight: 16,
    marginLeft: "auto"
  }
});