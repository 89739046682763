import R14, { AsyncStorage, AsyncSessionStorage } from "../core";
import RSAencryption from "../utils/RSAencryption";
import CommonUtility from "../utils/CommonUtility";
import { BILL_OPTS, LOGIN_TYPE, USER_TYPE } from "../constant/app.data.constant";
import { CommonString } from "../constant/appCommonText";
export default class UserSessionDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this._metadata = {
      accessToken: null,
      refreshToken: null,
      userDetails: null,
    };
    this.state = {
      userDetail: {},
      initialized: false,
      loggedIn: false,
      leftMenuToggle: true, //true //false,
      leftMenuOverlay: false,
      facebookMediaLoginPID: false,
      googleMediaLoginPID: false,
      pchRegistrationTypeProvider: false,
      pchRegistrationTypePatient: false,
      subRoleUpdate: false,
      isProfileDetailsOpen: false,
      isLoggedOut: false,
      isTooltipOpen: false,
      isUberDocs: false, //false //true
      linkedPayers: [],
      userRoles: "",
      isExternalLogin: false,
      userOrgs: [],
      defaultOrg: null,
      userBUDetails: [],
      isLoading: false,
    };
  }

  async domainDidLoad() {
    return await this.init();
  }

  async init() {
    if (this.state.initialized) return true;
    let access_token =
      (await AsyncSessionStorage.getItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN)) || null;
    let refresh_token =
      (await AsyncSessionStorage.getItem(CommonString.LOCAL_STORAGE_REFRESHTOKEN)) || null;
    let userDetails = await this.dm.rest.getUser();
    let userRoles = await AsyncStorage.getItem("userRoles") || "";
    this.setState({
      initialized: true,
      access_token,
      refresh_token,
      userDetails,
      userRoles,
    });
    this.setLoggedIn(access_token ? true : false);
    return true;
  }

  async loginAuth(
    loginInput,
    fields = "uid pid payerId lastLoginDate sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType firstName lastName email mobileNumber addressLine1 addressLine2 town state country postCode EIN  parentUid parentUserDetail { uid email firstName lastName} subRole {appName appRole} countryObject{label value}  stateObject{label value} cityObject{label value} isActive"
  ) {
    await CommonUtility.htmlDecode(loginInput);
    // Encrypt Username and Password
    fields = "uid pid payerId lastLoginDate sessionString changePasswordNow googleProfileID appleProfileID userType firstName lastName email mobileNumber addressLine1 addressLine2 town state country postCode EIN parentUid parentUserDetail { uid email firstName lastName} subRole {appName appRole} countryObject{label value}  stateObject{label value} cityObject{label value} isActive"

    if (loginInput.signInLocation === USER_TYPE.XBP)
      fields = "uid pid payerId lastLoginDate sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType firstName lastName email mobileNumber addressLine1 addressLine2 town state country postCode subRole {appName appRole} countryObject{label value}  stateObject{label value} cityObject{label value} isActive"

    let encryptUsername = RSAencryption.EnctyptString(loginInput.username, true);
    let encryptPassword = !!loginInput.password
      ? loginInput.bEncryptRequired === undefined || loginInput.bEncryptRequired === true
        ? (!!await CommonUtility.getWithExpiry(loginInput.signInLocation === USER_TYPE.XBP ? "XBPUsername" : "username") ? loginInput.password : RSAencryption.EnctyptString(loginInput.password, true))
        : loginInput.password
      : "";
    let data = {
      username: encryptUsername,
      password: encryptPassword,
      socialProfileId: loginInput.socialProfileId,
      loginType: !!loginInput.loginType ? loginInput.loginType : LOGIN_TYPE.NORMAL,
      signInLocation: loginInput.signInLocation,
    };

    let res = await this.api.mutate(
      `mutation Authenticate($input: EncryptedAuthUserInput!) {
        authenticate(input: $input){
          access_token refresh_token error success
          message userLoginDetail {
             ${fields}
            }
        }
    }`,
      {
        input: data,
      }
    );
    // if (res.data === null)// throw new Error("Auth failed due to invalid credentials/absolute PID")
    if (res.data.authenticate.error || res.data.authenticate.success === false) throw res.data.authenticate;
    return this._processAuthResult(res.data.authenticate, loginInput.signInLocation);
  }

  async _processAuthResult(res, signInLocation, loginType) {
    if (!res.access_token || !res.refresh_token) throw new Error("Unknown Auth Error: No Access.");
    const { access_token, refresh_token, userLoginDetail, linkedPayers, userBUDetails } = res;
    await AsyncSessionStorage.setItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN, access_token);
    await AsyncSessionStorage.setItem(CommonString.LOCAL_STORAGE_REFRESHTOKEN, refresh_token);
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN, access_token);
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_REFRESHTOKEN, refresh_token);
    this.setState({ access_token, refresh_token });
    //Enforcing to generate new tokens.
    this.setLoggedIn(this.getAccessToken());
    if (userLoginDetail.userType !== USER_TYPE.XBP_ADMIN) {
      if (signInLocation === USER_TYPE.XBP) {
        userLoginDetail.userType = USER_TYPE.XBP;
      }
      else if (signInLocation === USER_TYPE.CLAIMS) {
        if (userLoginDetail.userType === USER_TYPE.PCH_PATIENT || userLoginDetail.userType === USER_TYPE.PCH_PROVIDER) {
        }
        else if (!!userLoginDetail.subRole && userLoginDetail.subRole.length > 0) {
          let subRole = userLoginDetail.subRole.filter((ele) => ele.appName === signInLocation)[0];
          userLoginDetail.userType = !!userLoginDetail.userType && userLoginDetail.userType !== "XBP" ? userLoginDetail.userType
            : subRole.appRole;
        }
        else {
          userLoginDetail.userType = "";
          this.setState({
            subRoleUpdate: true,
          });
        }
      }
    }

    // check if bu details length is greater than 1
    let filteredUserBUDetails = []
    if(userBUDetails.length >= 2){
      filteredUserBUDetails = userBUDetails.filter(bu => {
        if(!bu.buId || bu.buId === "0" || !bu.billerRef){
          // call delete api
          this.api.mutate(
            `mutation DeleteUserBUDetail($uid: ID!){
              deleteUserBUDetail(uid: $uid){
                success             
              }
            }`,
            { uid: bu.uid }
          );
          return false
        } return true
      });
    } else filteredUserBUDetails = userBUDetails;

    await this.updateSMBOrganization(userLoginDetail);
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_LINKPAYER, RSAencryption.EnctyptString(JSON.stringify(linkedPayers || [])));
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_BUDETAILS, RSAencryption.EnctyptString(JSON.stringify(filteredUserBUDetails || [])));
    await AsyncStorage.setItem("loginType", !!loginType ? loginType : signInLocation);
    this.setState({
      linkedPayers: linkedPayers,
      userBUDetails: filteredUserBUDetails,
      isUberDocs: loginType === LOGIN_TYPE.UBERDOC ? true : false,
      leftMenuToggle: ![LOGIN_TYPE.UBERDOC, LOGIN_TYPE.NONREGISTER].includes(loginType),
      isExternalLogin: [LOGIN_TYPE.UBERDOC, LOGIN_TYPE.NONREGISTER].includes(loginType)
    });
    let userType = !!userLoginDetail ? userLoginDetail.userType : null;
    if (userType === USER_TYPE.PCH_PATIENT || userType === USER_TYPE.PCH_PROVIDER || userType === USER_TYPE.XBP_ADMIN) return this.isLoggedIn;
    else {
      if(!!!userLoginDetail.changePasswordNow && !!userLoginDetail.resourceAccess && (userLoginDetail.resourceAccess.length === 0 || !!userLoginDetail.resourceAccess.includes(BILL_OPTS[0].value)))
          this.dm.user.getBillerListFromPam();
      return this.isLoggedIn;
    }
  }

  async updateSMBOrganization(userLoginDetail, defaultOrg = null) {
    let userRoles = await AsyncStorage.getItem("userRoles") || "";
    if (!!defaultOrg) {
      userLoginDetail.defaultOrg = defaultOrg;
      this.setState({
        defaultOrg: defaultOrg
      });
    }
    else if ([USER_TYPE.SMB, USER_TYPE.XBPSMB].includes(userRoles)) {
      let orgs = await this.dm.smbApi.getUserOrgList({ email: userLoginDetail.email });
      let defaultOrg = null;
      if(!!userLoginDetail.defaultOrg)
          defaultOrg =  orgs.find(x=> x.pid === userLoginDetail.defaultOrg.pid);

       defaultOrg = (orgs.length > 0 && !defaultOrg) ? orgs[0] : defaultOrg
      this.setState({
        userOrgs: orgs,
        defaultOrg: defaultOrg
      });
      userLoginDetail.defaultOrg = defaultOrg;
    }
    else
      userLoginDetail.defaultOrg = defaultOrg;
    this.setState({
        userLoginDetail: userLoginDetail
      });
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_PROFILE, RSAencryption.EnctyptString(JSON.stringify(userLoginDetail)));
  }

  async logout(access, refresh) {
    let access_token = !!access ? access : await this.getAccessToken();
    let refresh_token = !!refresh ? refresh : await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_REFRESHTOKEN);
    let clientId = await AsyncStorage.getItem("CLIENT_ID");
    let redirectUri =  await AsyncStorage.getItem("REDIRECT_URI");
    
    try {
      await this.dm.dashboard.resetDashboardDomain(); 
      let res = false;
      if (!!access_token && clientId) {
        res = await this.dm.kcAPI.logOut(
          {
            clientId: clientId,
            refresh_token: refresh_token,
            redirectUri: redirectUri,
            access_token: access_token
          });
      }
      if (!!res || access_token || clientId === null) {
        await this.removeToken();
        this.setLoggedIn(false);
      }
    } catch (error) {

    }
  }

  setLoggedIn(loggedIn) {
    this.setState({
      loggedIn: loggedIn,
    });
  }

  get isLoggedIn() {
    return this.state.access_token ? true : false;
  }

  getUserRoles() {
    return !!this.state.userRoles ? this.state.userRoles : "";
  };

  async getAccessToken() {
    let access_token = await AsyncSessionStorage.getItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN);
    if (!!!this.state.access_token && !!access_token) {
      this.setState({ access_token: access_token });
    }
    return this.state.access_token;
  }

  async removeToken() {
    await AsyncSessionStorage.removeItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN);
    await AsyncSessionStorage.removeItem(CommonString.LOCAL_STORAGE_REFRESHTOKEN);
    await AsyncSessionStorage.removeItem("CLIENT_ID");
    await AsyncSessionStorage.removeItem("REDIRECT_URI");
  }

  async createSessionString(loginUserdetail) {
    let reqBody = {
      uid: loginUserdetail.uid,
      payerId: loginUserdetail.payerId,
      sessionString: CommonUtility.generate20Character(),
    };
    try {
      await this.dm.userRegistration.updateUserCustomDetails(reqBody, true);
      return true;
    } catch (error) {
      return error;
    }
  }

  async isSessionStringValid(validateToken = false) {
    try {
      const { uid, sessionString, kcId } = await this.dm.rest.getUser();
      let loginUserdetail = await this.dm.kcAPI.tokenIntrospect((!!uid ? uid : kcId), validateToken);
      if (!!loginUserdetail && !!loginUserdetail.tokenIsValid) {
        loginUserdetail.isConcurrentLogin = !(loginUserdetail.sessionString === sessionString);
        return loginUserdetail;
      } else {
        return loginUserdetail;
      }
    } catch (error) {
      return error;
    }
  }

  async issueNewToken() {
    return this.dm.kcAPI.refreshAuthToken();
  }
  
}
