import React, { Component } from "react";
import R14, { StyleSheet, View, Text, Touchable, Image, FlatList, Platform } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
import { responsiveTableStyle } from "../assets/css/responsiveTableStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import TooltipComponent from "./TooltipComponent";
import Linking from "../utils/Linking";
import NoRecordsFound from "../components/NoRecordsFound";
import { CommonString } from "../constant/appCommonText";
import PopupModalComponent from "./PopupModalComponent";
import { MESSAGE_TYPE } from "../constant/app.data.constant";
import { analyticsLogs } from "../utils/AnalyticLogs";

const deleteIcon = require("../assets/images/delete.png");
const varifed = require("../assets/images/selete_icon_active.png");
const notVarifed = require("../assets/images/unverifiedicon.png");

export default R14.connect(
  class MyBankListComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        paymentIntents: this.props.payerBankAccounts,
        isDeleteConfirmationVisible: false,
        deletingAccount: null,
        showTooltip: false,
        errorMessage: null
      };
    }

    onVerify = async (isVerfied, verfiyLink) => {
      if (isVerfied) {
        analyticsLogs(CommonString.MY_BANK, `${CommonString.ALREADY_VERIFIED_BANK}`, this.props.app);
        this.props.app.dm.commonDomain.showToastr("success", CommonString.AccountAlreadyVerified)
      } else {
        analyticsLogs(CommonString.MY_BANK, `${CommonString.VERIFY_BANK}`, this.props.app);
        if(!!verfiyLink) Linking.LinkingURL(verfiyLink);
      }
    }

    // set state for delete popup model
    handleDeletePopup = async (account) => {
      // show delete confirmation pop up model and set deleting Account in state
      if (account && account.bankName) {
        analyticsLogs(CommonString.MY_BANK, `${CommonString.OPEN_DELETE_BANK_POPUP}`, this.props.app);
        this.setState({
          isDeleteConfirmationVisible: !this.state.isDeleteConfirmationVisible,
          deletingAccount: account
        });
      } else {
        analyticsLogs(CommonString.MY_BANK, `${CommonString.CLOSE_DELETE_BANK_POPUP}`, this.props.app);
        this.setState({
          isDeleteConfirmationVisible: !this.state.isDeleteConfirmationVisible,
          deletingAccount: null
        });
      }
    };

    handleErrorPopUp = (message) => {
      if(message && typeof message === 'string'){
        this.setState({
          errorMessage: message,
          isDeleteConfirmationVisible: false,
          deletingAccount: null
        })
      } else {
        this.setState({
          errorMessage: null,
          isDeleteConfirmationVisible: false,
          deletingAccount: null
        })
      }
    }

    handleTooltip() {
      this.setState({ showTooltip: !this.state.showTooltip });
    }

    onDeleteConfirmed = async (account) => {
      // check payment is already paid or not
      const getAlreadyPaidBill = await this.props.app.dm.dashboard.getBillReference({
        filter: {
          achBankAccountId: { eq: account.intentId }
        },
        page: 1,
        resultsPerPage: 1,
      }, "uid");

      if(!!getAlreadyPaidBill && getAlreadyPaidBill.length ){
        this.handleErrorPopUp('Bank cannot be deleted. Payments were attempted with this bank.');
        return false;
      }

      const { uid } = account;
      // delete account by uid
      let payload = {
        isActive: false,
        uid: uid
      }
      await this.props.app.dm.achPaymentDomain.updatePayerBankAccount(payload);
      this.setState({
        paymentIntents: this.state.paymentIntents.filter(intents => intents.uid !== uid)
      });
      // Close confirmation popup model
      this.handleDeletePopup();
      analyticsLogs(CommonString.MY_BANK, `${CommonString.BANK_DELETED}`, this.props.app);
      return this.props.app.dm.commonDomain.showToastr("success", `${account.bankName} deleted successfully.`);

    }

    
    // On error
    errorMessageView = (message) => {
      return (
        <PopupModalComponent
          visibility={message}
          modalOuterWrapper={styles.modalOuterWrapper}
          bodyWrapper={styles.bodyWrapper}
          messageType= {MESSAGE_TYPE.delete}
          headingIcon={true}
          heading={"Delete Bank Account"}
          onCrossPress={this.handleErrorPopUp}
          modalContent={commonStyles.marginTop10}
          bodyContent={`${message}`}
          okLabel={"Ok"}
          handleOk={() => this.handleErrorPopUp()}
         />
      )
    }

    // Delete confirmation popup model with Yes/No buttons
    deleteConfirmationView = (account) => {
      return (
        <PopupModalComponent
          visibility={this.state.isDeleteConfirmationVisible}
          modalOuterWrapper={styles.modalOuterWrapper}
          bodyWrapper={styles.bodyWrapper}
          messageType= {MESSAGE_TYPE.delete}
          headingIcon={true}
          heading={"Delete Bank Account"}
          onCrossPress={this.handleDeletePopup}
          modalContent={commonStyles.marginTop10}
          bodyContent={`Are you sure you want to delete the ${this.state.deletingAccount && this.state.deletingAccount.bankName}`}
          closeLabel= {"No"}
          handleClose = {this.handleDeletePopup}
          okLabel={"Yes"}
          handleOk={() => this.onDeleteConfirmed(account)}
         />
      )
    }

    render() {
      const getData = this.state.paymentIntents;
      const { leftMenuToggle } = this.props.app.dm.userSession.state;
      let maxLimit = 35;

      return (
        <>
          {!getData || !getData.length ? (
              <View style={[styles.noItemsComponent]}>
                <NoRecordsFound message={"No banks to display."} />
              </View>
          ) : (
              <View style={[styles.greyBackground]}>
                <View style={[responsiveTableStyle.myBankMainSection]}>
                  {/* Web View Start */}
                  <View style={[ Platform.OS !== "web" ? responsiveTableStyle.table : styles.table, !!this.state.paymentIntents.length <= 0 ? responsiveTableStyle.tablepadding : null, commonStyles.flex1]}>
                    {!!getData.length > 0 ? (
                      <View
                        style={[
                          responsiveTableStyle.thead,
                          commonStyles.justifyContentSpaceBetween,
                          commonStyles.margin0
                        ]}>
                        <View
                          style={[
                            commonStyles.dFlex,
                            commonStyles.flexGrow1,
                            responsiveTableStyle.tdLeftSection,
                            responsiveTableStyle.billerDirectoryBillerLeftSection,
                            commonStyles.alignItemsCenter,
                          ]}>

                          <View
                            style={[
                              styles.billerDetails,
                              commonStyles.dFlex,
                              commonStyles.flexGrow1,
                              styles.billerDirectoryBillerDetailsTh,
                              styles.leftMenuTogglebillerDirectoryBillerDetailsTh,
                            ]}>
                            <View
                              style={[
                                styles.billerName,
                                commonStyles.dFlex,
                                responsiveTableStyle.billerDirectoryBillrName,
                                responsiveTableStyle.leftMenuToggleDashboardBillname,
                                styles.leftMenuToggleisBillerDirectoryBillname,
                                leftMenuToggle ? null : styles.leftMenuToggleOffisBillerDirectoryBillname,
                                commonStyles.alignItemsCenter
                              ]}>
                              <View style={[responsiveTableStyle.th]}>
                                <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Biller Name</Text>
                              </View>
                            </View>


                            <View
                              style={[
                                styles.th,
                                styles.billerSubj,
                                commonStyles.flexGrow1,
                                styles.makePayBillerSubj,
                                leftMenuToggle ? styles.leftMenuToggleMakePayBillerSubj : null,
                              ]}>
                              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Bank Name</Text>
                            </View>

                            <View
                              style={[
                                styles.th,
                                styles.dateAndTime,
                                commonStyles.flexGrow1,
                                styles.makePayDateAndTime,
                              ]}>
                              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Account#</Text>
                            </View>

                            <View
                              style={[
                                styles.th,
                                styles.dueOn,
                                commonStyles.flexGrow1,
                                styles.dashboardDueOn,
                              ]}>
                              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Routing#</Text>
                            </View>
                            <View
                              style={[
                                styles.th,
                                styles.dueOn,
                                commonStyles.flexGrow1,
                                styles.varifedStatus,
                              ]}>
                              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Verification Status</Text>
                            </View>
                          </View>
                          <View
                            style={[
                              commonStyles.dFlex,
                              styles.tdRightSection,
                              styles.billerDirectorytdRightSection,
                              styles.dashboardMakePaymenttdRightSection,
                              styles.billerDirectoryMakePaymenttdRightSection,
                              styles.simpleMessageBillRightSectionTHead,
                              styles.makePayBillRightSectionTHead,
                              leftMenuToggle ? styles.leftMenuToggleMakePayBillRightSectionTHead
                                : null,]}>
                            {/* <View
                              style={[
                                styles.th,
                                styles.billAmount,
                                styles.thBillAmount,
                              ]}>
                              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey, commonStyles.darkGrayColor]}>Verification</Text>
                            </View> */}
                            <View
                              style={[
                                styles.th,
                                styles.billAmount,
                                styles.thBillAmount,
                              ]}>
                              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey, commonStyles.darkGrayColor]}>Actions</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : null}

                    {/* List */}
                    <View style={[customCommonStyles.myBankPageTboday]}>
                      <FlatList
                        style={[
                          // customCommonStyles.banklistComponent,
                          Platform.OS !== "web" ? responsiveTableStyle.tableCustomPadding :
                         styles.tableCustomPadding,
                          !!getData.length === 0 ? customCommonStyles.customDashboardNoListView : null,
                          getData.length === 1 ? styles.singleBillAggregation : null,
                          getData.length === 0 ? responsiveTableStyle.simpleBillMakePaymentNoListView : null,
                          !Platform.OS !== "web" && styles.noPadding
                        ]}
                        ListEmptyComponent={
                          <View >
                          </View>
                        }
                        data={getData}
                        keyExtractor={(el, i) => i}
                        renderItem={({ item, index }) => (
                          <View
                            style={[
                              Platform.OS !== "web" ? responsiveTableStyle.tr : styles.tr,
                              commonStyles.flex1,
                              commonStyles.flexDirectionRow,
                            //   commonStyles.flexWrap,
                              Platform.OS !== "web" ? responsiveTableStyle.myBankDirectoryTr : styles.myBankDirectoryTr,
                              getData.length - 1 === index ? responsiveTableStyle.borderBottom1 : null,
                            //   !Platform.OS !== "web" && styles.billerDirectoryTr
                            ]}>

                            <View
                              style={[
                                Platform.OS !== "web" ? responsiveTableStyle.billerDetails :
                                styles.billerDetails,
                                customCommonStyles.xbpCustomBillerDetails,
                                commonStyles.flex1,
                                commonStyles.flexGrow1,
                                Platform.OS !== "web" ? responsiveTableStyle.customFlexDirection : styles.customFlexDirection,
                                Platform.OS !== "web" ? responsiveTableStyle.billerDirectoryBillerDetails : styles.billerDirectoryBillerDetails,
                                leftMenuToggle ? null : Platform.OS !== "web" ? responsiveTableStyle.leftMenuToggleBillerDirectoryBillerDetails : styles.leftMenuToggleBillerDirectoryBillerDetails,

                              ]}>

                              {/* Biller Name */}
                              <View
                                style={[
                                  Platform.OS !== "web" ? responsiveTableStyle.billerName : styles.billerName,
                                  commonStyles.dFlex,
                                  commonStyles.margin0,
                                  Platform.OS !== "web" && commonStyles.marLT0,
                                  commonStyles.alignItemsStart,
                                  Platform.OS !== "web" ? responsiveTableStyle.dashboardBillerName : styles.dashboardBillerName,
                                  Platform.OS !== "web" ? responsiveTableStyle.billerDirectoryTdBillrName : styles.billerDirectoryTdBillrName,
                                ]}>
                                <View
                                  style={[
                                    Platform.OS !== "web" ? responsiveTableStyle.td : styles.td,
                                    commonStyles.flex1,
                                    commonStyles.alignItemsStart,
                                    Platform.OS !== "web" ? responsiveTableStyle.billerDirectoryBillerName : styles.billerDirectoryBillerName,
                                  ]}>
                                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, Platform.OS !== "web" ? responsiveTableStyle.billName : styles.billName, customCommonStyles.wordBreakWord, commonStyles.flexDirectionRow, commonStyles.flexGrow0]}>
                                    <View style={[Platform.OS !== "web" ? responsiveTableStyle.billNameText : styles.billNameText, commonStyles.wordBreakWord,]}>
                                      <View style={[
                                        customCommonStyles.textOverflowEllipsis]}>
                                        <Text
                                          style={[
                                            commonMetricsStyle.heading6,
                                            commonFontStyles.workSans,
                                            commonStyles.wordBreakWord,
                                            Platform.OS !== "web" ? responsiveTableStyle.billerDirectoryBillerNameText : styles.billerDirectoryBillerNameText,
                                            Platform.OS !== "web" ? responsiveTableStyle.boldTextInMView : styles.boldTextInMView,
                                            commonStyles.tableValue,
                                            commonStyles.dBlock
                                          ]}
                                          numberOfLines={1}>
                                          {/* {item.biller.billerName} */}
                                          {Platform.OS !== "web" ?
                                            !!item.billerName && item.billerName.length > maxLimit ? `Biller : ${item.billerName.substring(0, maxLimit - 3)}...` : `Biller : ${item.billerName}` : `${item.billerName}`}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>

                              {/* Bank Name */}
                              <View
                                style={[
                                  Platform.OS !== "web" ? responsiveTableStyle.td : styles.td,
                                  commonStyles.flexGrow1,
                                  Platform.OS !== "web" ? responsiveTableStyle.tdType : styles.tdType,
                                  Platform.OS !== "web" ? responsiveTableStyle.billerSubj : styles.billerSubj,
                                  Platform.OS !== "web" ? responsiveTableStyle.tdBillerSubj : styles.tdBillerSubj,
                                  Platform.OS !== "web" ? responsiveTableStyle.makePayTdBillerSubj : styles.makePayTdBillerSubj,
                                ]}>
                                <View>
                                  <View>
                                    <Text
                                      style={[Platform.OS !== "web" ? responsiveTableStyle.cardSubHeadingText : styles.cardSubHeadingText, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.wordBreakWord, commonStyles.tableValue]}
                                      numberOfLines={1}>
                                      {/* {item.subject} */}
                                      {/* {item.subject.length < 35 ? `${item.subject}` : `${item.subject.substring(0, 16)}...`} */}
                                      {Platform.OS !== "web" ?
                                        (!!item.bankName && item.bankName.length > maxLimit ? `Bank : ${item.bankName.substring(0, maxLimit - 3)}...` : `Bank : ${item.bankName}`) : item.bankName}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                              {/* Account Number */}
                              <View
                                style={[
                                  Platform.OS !== "web" ? responsiveTableStyle.td : styles.td,
                                  commonStyles.flexGrow1,
                                  Platform.OS !== "web" ? responsiveTableStyle.dateAndTime : styles.dateAndTime,
                                  Platform.OS !== "web" ? responsiveTableStyle.tdDateAndTime : styles.tdDateAndTime,
                                  Platform.OS !== "web" ? responsiveTableStyle.makePayTdDateAndTime : styles.makePayTdDateAndTime,
                                ]}>
                                <Text style={[commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.fullWidth, commonStyles.tableValue]}>
                                  {Platform.OS !== "web" ? `Account# : ${item.accountNumber}` : item.accountNumber}
                                </Text>
                              </View>

                              {/* Routing Number */}
                              <View style={[Platform.OS !== "web" ? responsiveTableStyle.td : styles.td, commonStyles.flexGrow1,Platform.OS !== "web" ? responsiveTableStyle.dateAndTime : styles.tdDueIn,
                                  Platform.OS !== "web" ? responsiveTableStyle.tdDateAndTime : styles.dueOn,
                                  Platform.OS !== "web" ? responsiveTableStyle.makePayTdDateAndTime : styles.makePayTdDueIn, commonStyles.textAlignLeft]}>
                                <View>
                                  <Text style={[commonStyles.darkGrayColor, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.tableValue]}>
                                    { Platform.OS !== "web" ? `Routing# : ${item.routingNumber}` : item.routingNumber}
                                  </Text>
                                </View>
                              </View>

                              <View style={[Platform.OS !== "web" ? responsiveTableStyle.td : styles.td, commonStyles.flexGrow1, styles.varifedStatusTd, commonStyles.textAlignLeft]}>
                               { 
                               ["Pending", "Verified"].includes(item.verificationStatus) ?   
                                <Text style={[commonStyles.darkGrayColor, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.tableValue]}>
                                  { Platform.OS !== "web" ? `Status: ${item.verificationStatus}` : item.verificationStatus}
                                </Text> 
                                :
                                <TooltipComponent
                                  verificationTip={true}
                                  source="mybank"
                                  row = {index}
                                  verificationTipCss={[customCommonStyles.myBankPageTooltipStyles]}
                                  verificationStatus={!!item.verificationStatus? item.verificationStatus : "Pending"}
                                  isMobileDevice={Platform.OS !== "web"}
                                  toolTipMessage={item.message} 
                                  isTop3Entry={index < 3}
                                  priorityInfoTooltipStyle={[
                                    customCommonStyles.priorityInfoTooltipStyle, index < 3 ? customCommonStyles.priorityInfoTooltipStyleTop3entry : null,   
                                    item.verificationStatus === "Blocked" || item.verificationStatus === "Elapsed" ? customCommonStyles.blockpriorityInfoTooltipStyle: null,
                                    (item.verificationStatus === "Blocked" || item.verificationStatus === "Elapsed") && index < 3 ? null : customCommonStyles.priorityInfoTooltipBottomStyle]}
                                  arrowHolderStyle={[customCommonStyles.myBankArrowHolderStyle]}
                                  customArrowPosition={[index < 3 ? customCommonStyles.priorityArrowTooltipStyleTop3entry : customCommonStyles.priorityArrowTooltipStyle]}
                                  />
                              }
                              </View>
                            </View>
                            {/* Action buttons */}
                            <View
                              style={[
                                  commonStyles.flex1,
                                Platform.OS !== "web" ? responsiveTableStyle.tdRightSection : styles.tdRightSection,
                                commonStyles.flexGrowUnset,
                                Platform.OS !== "web" ? responsiveTableStyle.billerDirectorytdRightSection : styles.billerDirectorytdRightSection,
                                commonStyles.justifyContentFlexStart,
                                leftMenuToggle ? null : Platform.OS !== "web" ? responsiveTableStyle.leftMenuToggleTdRightSection : styles.leftMenuToggleTdRightSection
                              ]}>
                              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                                {true ? (
                                  <View style={[commonStyles.marginRight16]}>
                                    <Touchable onPress={() => this.onVerify(item.isBankVerified, item.metaData)}>
                                        <View>
                                            <Image source={item.isBankVerified ? varifed : notVarifed} style={commonStyles.icon18} />
                                        </View>
                                    </Touchable>
                                  </View>
                                ) : null}
                                {/* Delete icon */}
                                <Touchable onPress={() => this.handleDeletePopup(item)}>
                                  <View>
                                    <Image source={deleteIcon} style={commonStyles.icon18} />
                                  </View>
                                </Touchable>
                              </View>

                            </View>
                          </View>
                        )}
                      />
                    </View>
                    {/* Delete confirmation popup model */}
                    {this.state.isDeleteConfirmationVisible && this.deleteConfirmationView(this.state.deletingAccount)}
                    {this.state.errorMessage && this.errorMessageView(this.state.errorMessage)}
                  </View>
                </View>
              </View>
            )}
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  // Web table Styles starts
  table: {
    marginTop: 0,
    // marginRight: 16,
    // marginBottom: 16,
    // marginLeft: 16,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderTopWidth: 0,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          borderWidth: 0,
          marginRight: 0,
          marginLeft: 0,
        };
    },
  },
  leftMenuToggleBillerDirectoryTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1537 && width <= 1600) {
        return {
          width: "51.8%",
        };
      }
    },
  },
  leftMenuToggleOffBillerDirectoryTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "52.2%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "51.8%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "51.5%",
        };
      }
      if (width >= 991 && width <= 1024) {
        return {
          width: "67%",
        };
      }
    },
  },
  leftMenuToggleSimpleMessageScreenTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1537 && width <= 1600) {
        return {
          width: "26%",
        };
      }
    },
  },
  billerDirectoryBillerDetailsTh: {
    width: "17.3%",
  },
  leftMenuTogglebillerDirectoryBillerDetailsTh: {
    width: "17.3%",
  },
  billerSubj: {
    width: "32%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "24%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "30.2%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "25.7%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "30%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "31%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 85,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  makePayBillerSubj: {
    width: "22.5%",
    paddingLeft: 10,
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "16.6%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "31.2%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "25.7%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "21%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "21.9%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 82,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  leftMenuToggleMakePayBillerSubj: {
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "16%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "31.2%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "21.5%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "22%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "22.5%",
        };
      }
      if (width >= 1025 && width <= 1280) {
        return {
          width: "23.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 60,
        };
      }
    }
  },
  leftMenuToggleOffisBillerDirectoryBillname: {
    width: "23%",
    screen: ({ width }) => {
      if (width >= 1920) {
        return {
          width: "18.4%",
        };
      }
      if (width >= 1600 && width <= 1919) {
        return {
          width: "22.8%",
        };
      }
      if (width >= 1537 && width <= 1599) {
        return {
          width: "53%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "39.7%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "18.1%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "20.3%",
        };
      }
      if (width >= 1025 && width <= 1280) {
        return {
          width: "25%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "17.5%",
        };
      }
    },
  },
  dateAndTime: {
    width: "19%",
    // marginLeft: 9,
    marginRight: 9,
    screen: ({ width }) => {
      if (width >= 1601 && width >= 1920) {
        return {
          width: "14%",
        };
      }
      if (width >= 1537 && width >= 1600) {
        return {
          width: "20.2%",
        };
      }
      if (width >= 1441 && width >= 1536) {
        return {
          width: "25%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "19.8%",
        };
      }
      if (width >= 1025 && width <= 1280) {
        return {
          width: "20%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "8.5%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  dashboardDueOn: {
    width: "20.5%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "15.4%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "21.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "20.4%",
        };
      }
      if (width >= 1025 && width <= 1280) {
        return {
          width: "21%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "7.5%",
        };
      }
    },
  },
  varifedStatus: {
    width: "20.5%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "15.4%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "21.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "20.4%",
        };
      }
      if (width >= 1025 && width <= 1280) {
        return {
          width: "21%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "15%",
        };
      }
    },
  },
  tdRightSection: {
    width: "30.7%",
    justifyContent: "space-between",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 16,
          flexDirection: "column",
          textAlign: "right",
          alignItems: "flex-end",
          width: "37%",
        };
      if (width >= 992 && width <= 1024) {
        return {
          flexWrap: "wrap",
        };
      }
    },
  },
  billerDirectorytdRightSection: {
    minWidth: 90,
    width: 90,
    margin: 0,
    padding: 0,
    screen: ({ width }) => {
      if (width >= 991 && width <=1024) {
        return {
          minWidth: 107,
        };
      }
      if (width <= 991) {
        return {
          minWidth: 85,
          width: 85,
        };
      }
    },
  },
  dashboardMakePaymenttdRightSection: {
    width: "35.8%",
    screen: ({ width }) => {
      if (width >= 1441 && width <= 1600) {
        return {
          width: "34.8%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "34.3%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "34.8%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "28%",
        };
      }
    },
  },
  billerDirectoryMakePaymenttdRightSection: {
    minWidth: 45,
    width: 45,
    // marginRight: 16,
    // screen: ({ width }) => {
    //   if (width >= 992 && width <= 1024) {
    //     return {
    //       width: 90,
    //     };
    //   }
    // },
  },
  simpleMessageBillRightSectionTHead: {
    width: "90px",
    minWidth: 90,
    flexGrow: 0,
    // marginRight: 16,
    // screen: ({ width }) => {
    //   if (width >= 991 && width <= 1024) {
    //     return {
    //       width: "11%",
    //     };
    //   }
    // },
  },
  makePayBillRightSectionTHead: {
    width: "27.2%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "15%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "15%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "20.8%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "15%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "15%",
        };
      }
      if (width >= 1025 && width <= 1280) {
        return {
          width: "15%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "15%",
        };
      }
    },
  },
  leftMenuToggleMakePayBillRightSectionTHead: {
    width: "15.2%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "15%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "15%",
        };
      }
    }
  },
  billAmount: {
    width: "47%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
          marginBottom: 10,
        };
    },
  },
  thBillAmount: {
    width: "44%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "43%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "43%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "44%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "44%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "43%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "43%",
        };
      }
      if (width <= 991)
        return {
          width: "100%",
        };
    },
  },
  leftMenuToggleisBillerDirectoryBillname: {
    width: "24.7%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "18.4%",
        };
      }
      if (width >= 1600 && width <= 1919) {
        return {
          width: "22.5%",
        };
      }
      if (width >= 1537 && width <= 1599) {
        return {
          width: "20.4%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "20.4%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "18%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "20%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "17%",
        };
      }
    },
  },
  singleBillAggregation: {
    border: 0,
  },
  simpleBillMakePaymentNoListView: {
    marginTop: 50,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingRight: 0,
          paddingLeft: 0,
        }
      }
    }
  },
  myBankDirectoryTr: {
    flexWrap: "wrap",
    borderColor: "#dadada",
    borderBottomWidth: 1,
    borderStyle: "solid",
    minHeight: 30,
    alignItems: 'center',
    borderTopWidth: 0,
    // borderBottom: "1px solid #dadada",
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          justifyContent: "space-between"
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          minHeight: "auto",
        };
      }
      if (width <= 992) {
        return {
          paddingTop: 10,
          paddingRight: 10,
          paddingBottom: 16,
          paddingLeft: 16,
          flexDirection: 'row',
        };
      }
    },
  },
  borderBottom1: {
    borderBottomWidth: 1,
    borderColor: "#dadada",
    borderStyle: "solid",
  },
  thLeftSection: {
    width: "54%",
  },
  tdLeftSection: {
    width: "51%",
    paddingLeft: 15,
    // alignItems: "center",
    screen: ({ width }) => {
      if (width <= 359) {
        return {
          width: "34%",
          flexDirection: "row",
        };
      }
      if (width <= 991) {
        return {
          width: "34%",
          alignItems: "flex-start",
          flexDirection: "row",
        };
      }
      if (width >= 359 && width <= 991) {
        return {
          width: "45%",
          flexDirection: "row",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "56%",
        };
      }
    },
  },
  billerDirectoryBillerLeftSection: {
    width: "46.8%",
  },
  billerDirectoryBillerThLeftSection: {
    marginRight: 16,
    minWidth: 105,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "1.8%",
        };
      }
      if (width <= 991) {
        return {
          width: "2%",
          minWidth: 110,
          maxWidth: 110,
        }
      } else {
        return {
          width: '0%'
        }
      }
    },
  },
  billerDetails: {
    width: "57%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          // alignItems: "self-start",
          width: "90%",
        };
      }
      if (width <= 991) {
        return {
          flexDirection: "column",
          width: "80%",
          alignItems: "baseline",
          marginLeft: 0,
        };
      }
    },
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column"
        }
      }
    }
  },
  billerDirectoryBillerDetails: {
    width: "79%",
    alignItems:"center",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "75%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "77%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "77.5%",
          marginLeft: 0,
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "78.5%",
          marginLeft: 0,
        };
      }
      if (width >= 993 && width <= 1024) {
        return {
          width: "82%",
          marginLeft: 0,
        };
      }
      if (width <= 992) {
        return {
          width: "80%",
        //   paddingLeft: 16
          // marginLeft: 80,
        };
      }
    },
  },
  leftMenuToggleBillerDirectoryBillerDetails: {
    width: "52%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "75%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "76%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "76.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "77.5%"
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "80%",
          justifyContent: "flex-start",
        };
      }
      if (width <= 991) {
        return {
          width: "40%",
          justifyContent: "flex-start",
        };
      }
    }
  },
  billerName: {
    width: "30.2%",
    paddingRight: 10,
    alignItems: "center",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "28%",
        };
      }
      if (width >= 1538 && width <= 1600) {
        return {
          width: "28.8%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "29%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "43%",
        };
      }
      if (width >= 992) {
        return {
          marginLeft: 0,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  dashboardBillerName: {
    // width: "26%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "25%",
        };
      }
      if (width <= 991) {
        return {
          marginLeft: 0,
          paddingRight: 0,
          height: "auto"
        }
      }
    },
  },
  billerDirectoryTdBillrName: {
    width: "36%",
    marginLeft: 16,
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "27.2%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "32%",
        };
      }
      if (width >= 1376 && width <= 1440) {
        return {
          width: "27.7%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "30%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "18.5%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          marginLeft: 0,
        };
      }
    },
  },
  billerDirectoryBillerName: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          alignItems: "flex-start",
        };
      }
    },
  },
  billerDirectoryBillerNameText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // width: 230,
    display: "block",
  },
  tdBillerSubj: {
    width: "29%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 90,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  makePayTdBillerSubj: {
    width: "28%",
    screen: ({ width }) => {
      if (width >= 1441 && width <= 1600) {
        return {
          width: "39%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "28%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 79,
        };
      } else if (width <= 991) {
        return {
          width: "100%",
        }
      } else {
        return {
          // marginLeft: '18%'
        }
      }
    },
  },
  cardSubHeadingText: {
    color: "#444444",
    display: "block",
    fontSize: 14,
  },
  tdDateAndTime: {
    width: "27.33%",
    marginLeft: 15,
    marginRight: 15,
    screen: ({ width }) => {
      if (width <= 1024 && width >= 992) {
        return {
          width: 90,
          marginLeft: 22,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
        };
      }
    },
  },
  makePayTdDueIn: {
    width: "29%",
    screen: ({ width }) => {
      if (width >= 1367 && width <= 1440) {
        return {
          width: "30%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 95,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  varifedStatusTd: {
    width: "29%",
    screen: ({ width }) => {
      if (width >= 1367 && width <= 1440) {
        return {
          width: "30%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 134,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  titleInMobile: {
    display: "none",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "block",
        };
    },
  },
  boldTextInMView: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontWeight: 500,
          color: "#182832",
        }
      }
    }
  },


  // Ends web table styles


  borderBottomSpace15: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    paddingBottom: 5,
    marginBottom: 15,
  },
  selectedState: {
    borderColor: "#4dc5f2",
  },
  greyBackground: {
    screen: ({ width }) => {
      if (width >= 992){
        return {
          backgroundColor: 'rgb(244, 244, 244)',
          height:"100%"
        };
      }
    }
    // height: 0
  },
  bodyWrapper: {
    width: 280,
    minHeight: 180
  },
  modalOuterWrapper: {
    backgroundColor: "#00000059",
  },
  closeBtn: {
    position: "relative",
    zIndex: 9999,
  },
  sessionModelHeaderStyle: {
    marginTop: -20,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
  },
  payNowWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 15,
  },
  buttons: {
    width: 90,
    minWidth: "40%",
  },
  btnTexClr: {
    color: "#333",
  },
  noItemsComponent: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 16,
    marginRight: 16,
    backgroundColor: "#fff",
    paddingTop: 10,
    flexGrow: 1,
    screen: ({ width, height }) => {
        if (width <= 991)
        return {
            marginLeft: 0,
            margin: 0,
        };
        if (width > 991)
        return {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#e2e2e2",
        } 
    },
  },
  noPadding: {
    padding: 0,
  },
  tableCustomPadding: {
      screen: ({ width }) => {
        if (width <= 991){
            return {
                paddingLeft: 16,
                position: "relative"
            };
        }
    }
  },
});
