const CommonString = {
  SomethingWentWrong: "Something went wrong! Please try again.",
  InvalidUberDocToken: "Invalid Token or Appointment details. Please re-check.",
  InvalidAccess: "Invalid Access. Please contact Support.",
  CameraAccessDenied:"Your browser is not allowed access to your Camera. Please allow access.",
  CameraNotFound:"No Camera found on your system.",
  InvalidEmail: "Enter a valid email",
  InActiveAccount: "Your account has been deactivated.",
  InvalidBill: "Invalid Bill Reference.",
  DuplicateAccount: "Account already exists.",
  AddAccount: "Account added successfully.",
  TransactionFailed: "Transaction Failed.",
  NoBillDocument: "No Bill docs available.",
  BlockedAccount: "You have blocked the Account.",
  UnBlockedAccount: "You have unblocked the Account.",
  AcceptedAccount: "You have accepted the Request.",
  DeclinedAccount: "You have declined the Request.",
  DeclinedBill: "You have declined to pay the bill.",
  InvalidFileFormat: "Invalid format, Valid format PDF, XML, EXCEL, WORD.",
  MaxTwoMBAllowed: "Maximum 2MB file size allowed.",
  MaxTenMBAllowed: "Maximum 10MB file size allowed.",
  MaxTwoAttachmentAllowed: "Maximum 2 attachments allowed.",
  MaxOneAttachmentAllowed: "Maximum 1 attachments allowed.",
  FileNotFound: "File does not exist.",
  FileNameLength: "Maximum allowed filename is 35 characters.",
  InvalidPDFFileFormat: "Invalid Format, Please attach the file in PDF format only.",
  FileInProcess: "Please wait file is being processed.",
  SessionUpdated: "Session updated.",
  InvalidQRCode: "Invalid QR code.",
  AccountAlreadyVerified: "Account already verified.",
  AccountNotYetVerified: "Selected Bank is not yet verified. Please check your emails for the verification process.",
  PayerPreferences: "Payer Preferences updated successfully",
  NoBillApplicable: "No Bills applicable for Bill Payment.",
  NoBillFound: "No bill found.",
  TreatmentAdded: "Treatment address added successfully.",
  TreatmentUpdated: "Treatment address updated successfully.",
  TreatmentDeleted: "Treatment address deleted successfully.",
  MinOneTreatment: "At least one primary address required.",
  ProviderAdded: "Provider added successfully.",
  ProviderUpdated: "Provider updated successfully.",
  ProviderCanDelete: "Provider cannot be deleted. Provider has uploaded/sent Bills to patients.",
  ProviderDeleted: "Provider deleted successfully.",
  InsuranceAdded: "Insurance added successfully.",
  InsuranceUpdated: "Insurance updated successfully.",
  InsuranceDeleted: "Insurance deleted successfully.",
  InsuranceCanNotDelete: "Insurance cannot be deleted. Claims have been submitted to this Insurance.",
  MemberAdded: "Member added successfully.",
  MemberUpdated: "Member updated successfully.",
  MemberDeleted: "Member deleted successfully.",
  DuplicateFile: "The same file has been already added.",
  MinOneMember: "At least one primary member is required.",
  IncorrectSSID: "Incorrect SSID",
  IncorrectDOB: "Incorrect DOB",
  LoggedIn: "You are successfully logged in.",
  EmailNotMatch: "Email address does not match.",
  GoogleSync: "Google Profile is synced.",
  AppleSync: "Apple Profile is synced.",
  AmazonSync: "Amazon Profile is synced.",
  //26/05/2023
  CardExpiryError: "Invalid Expiry Date",
  CardTypeError: "Please select card type",
  AccountBillerMisMatch: "Specified account# is not mapped to the selected Biller.",
  MessageSent: "Message sent to biller successfully",
  MessageFail: "Message could not be sent to biller. Please re-check.",
  ExtensionSent: "Your Due date Extension requested successfully.",
  ExtensionFail: "Your Extension request could not be sent successfully.",
  ContentExceed140: "Content exceeds 140 characters.",
  ContentExceed100: "Content exceeds 100 characters.",
  BillerPreferences: "Biller Preferences re-synced successfully.",
  UserAdded: "User added successfully.",
  UserUpdated: "Profile updated successfully.",
  ClaimReceived: "Your claim received successfully.",
  DuplicateEIN: "Employer Identification Number already exist. Please re-check.",
  UploadProfile: "Please upload profile image.",
  UploadFile: "Please upload the bill.",
  EINMissing: "EIN is missing, please add EIN.",
  PhoneExistsWithPractice: "Specified phone is already registered with practice group.",
  EmailExistsWithPractice: "Specified email is already registered with practice group.",
  BillUploaded: "Bill uploaded successfully.",
  NoRecord: "No existing records.",
  ErrorOnFileUpload: "File could not be uploaded. Please delete and re-upload.",
  ContentExceed250:"Content exceeds 250 characters.",
  POBOXValidation: "Separate PO boxes with only a single semicolon.",
  POBoxLength: "Each PO Box should not exceed 10 digits.",
  //29/05/2023
  Min2Max70AlphaNumericChar: "Min 2 - Max 70 alphanumeric allowed",
  Max20AlphNumericChar: "Max 20 alphanumeric allowed",
  Max125AlphaNumericChar: "Max 125 alphanumeric allowed",
  NPI10Digits: "NPI must be 10 digits",
  EnterZIPCode: "Please enter ZIP code",
  ZipCode5: "Zip code must be 5 or 9 digits",
  FileTypes: "image/jpeg,image/jpg,image/png",
  XBPMessageFileType: "application/pdf,text/xml,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  POBoxInsurance: "PO Box# should be available on front/back of your Insurance card. Alternatively available in 'Evidence of Coverage' document.",
  NoMemberFound: "No member found.",
  NoInsuranceCompanyFound: "No insurance companies found.",
  NoCptFound: "No CPT details found.",
  AddProviderError: "Please add provider",
  SelectVisitType: "Select visit type",
  NoRecordFound: "No records found.",
  EnterNoteToBiller: "Enter the Notes To Biller",
  AmountPayableExceedError: "Amount should not be greater than the Amount Payable.",
  InvalidAmount: "Enter valid amount",
  LinkPayer: "Link Payer",
  EnterAddressRequiredAlert : "Please update address, city, state & zipcode of patient before uploading any claim.",
  ProviderFullNameError: "Provider full name can't exceed 75 characters.",
  //Required:
  MessageRequired: "Please enter the message",
  DateFormatRequired:"Date format MM/DD/YYYY",
  MemberIdRequired : "Enter Member ID",
  FirstNameRequired : "Enter First Name",
  LastNameRequired: "Enter Last Name",
  DOBRequired : "Enter DOB",
  SSIDRequired: "Enter SSID (last 4 digits)",
  SSIDRequiredLabel: "Enter SSID* ",
  DOBCanNotFuture: "DOB cannot be in future",
  DOBCanNotOlderThan125: "DOB cannot be older than 125 years",
  InvoiceCanNotOlderThan125: "Invoice cannot be older than 125 years",
  MIN2MAX50Validation: "Min 2 - Max 50 alphanumeric allowed",
  MIN2MAX15Validation: "Min 2 - Max 15 alphanumeric allowed",
  POBoxRequired: "Enter PO Box No or Address",
  FullNameExceed: "First+Last name Max 65 alphanumeric allowed",
  PasswordValidation: "Enter a valid password",
  PasswordMisMatch:"Passwords do not match",
  ExpiryRequired: "Select the expiry date",
  //29/05/2023
  MIN5MAX20DigitValidation: "Min 5 - Max 20 digits allowed",
  MIN10Digit: "Min 10 digits of mobile number required",
  MIN16Digit: "16 digits allowed",
  MIN1MAX12Digit: "Only 1 to 12 digits allowed",
  OnlyDigit: "Invalid PO Box",
  MIN2MAX60Validation: "Min 2 - Max 60 characters with 0-9 @-_+&* allowed",
  MIN2MAX60WithoutSpecial: "Min 2 - Max 60 alphanumeric allowed",
  MIN6MAX50: "Min 6 - Max 50 alphanumeric allowed",
  PID16Length: "PID must be 16 digits",
  MIN2Max6Digit: "Min 2 - Max 6 Digits",
  MIN6Max50Digit: "Min 6 - Max 50 Digits",
  MIN2Max150Validation: "Min 2 - Max 150 alphanumeric allowed",
  EINLength: "EIN must be 9 digits",
  ContentExceed75: "Content exceeds 75 characters",
  MAX50: "Max 50 alphanumeric allowed",
  MIN8MAX11: "Min 8 - Max 11 alphanumeric allowed",
  MIN1MAX8: "Min 1 - Max 8 digits allowed",
  MAX5Digit: "Max 5 digits allowed",
  Only9Digit: "Max 9 digits allowed",
  MIN10MAX50: "Min 10 – Max 50 alphanumeric allowed",
  MIN6MAX17: "Min 6 - Max 17 digits allowed",
  MIN2MAX30: "Min 2 - Max 30 alphanumeric allowed",
  MIN1MAX10Digit: "Min 1 - Max 10 digits allowed",
  MAX15: "Max 15 alphanumeric allowed",
  MIN2MAX26: "Min 2 – Max 26 alphanumeric allowed",
  Only4Digit: "SSID must be 4 digits",
  MIN1MAX999:"Min 1 - Max 999 allowed",
  MIN1MAX9999999999: "Min 1 - Max 9999999999 allowed",
  MIN2MAX300 : "Min 2 - Max 300 characters with &=_'-+,<>. allowed",
  LinkedBiller: "Linked Biller has been submitted successfully.",
  SelectBillerRequired: "Select Biller",
  SelectAccountRequired: "Select Account",
  EnterBankNameRequired: "Enter Bank Name",
  EnterRoutingNoRequired: "Enter Routing Number",
  EnterAccountNoRequired: "Enter Account Number",
  EnterProviderNameRequired: "Enter Provider Name",
  EnterNPIRequired: "Enter NPI",
  EnterProviderAddressRequired: "Enter Provider Address",
  EnterProviderZIPRequried: "Enter Provider ZIP",
  EnterNameRequired: "Enter Name",
  EnterFirstNameRequired: "Enter First Name",
  EnterMiddleNameRequired: "Enter Middle Name",
  EnterLastNameRequired: "Enter Last Name",
  EnterAddressRequired:"Enter Address",
  SelectCountryRequired: "Select Country",
  SelectStateRequired: "Select State",
  SelectCityRequired: "Select City",
  EnterZipCodeRequired: "Enter Zip Code",
  EnterInsuranceCompanyRequired: "Enter Insurance Company Name",
  EnterSearchTextRequired: "Enter Search Text",
  SelectCptRequired: "Select CPT",
  EnterDateOfServiceRequired: "Enter Date of Service",
  Min1Max5UnitsRequired: "Min 1 - Max 5 digits allowed.",
  Min1Max8FeeDigitsRequired: "Min 1 - Max 8 (including 2 decimals) digits allowed",
  MIN1MAX10DigitAmount: "Min 1 - Max 10 (including 2 decimals) digits allowed",

  CardHolderNameRequired: "Enter the Cardholder Name",
  EnterCardNoRequired: "Enter the Card Number",
  SelectIndustryRequired: "Select Industry",
  SelectBillerBURequired: "Select the Biller (Business unit)",
  SelectProviderRequired: "Select Provider",
  EnterSubjectRequired: "Enter the Subject",
  EnterMessageRequired: "Enter the Message",
  IntendedPaymentRequired: "Select the intended payment date (mm/dd/yyyy)",
  EnterAmountRequired: "Enter the Amount",
  EnterCurrentPasswordRequired: "Enter current password",
  EnterNewPasswordRequired: "Enter new password",
  EnterConfirmPasswordRequired: "Enter confirm password",
  SelectCountryCodeRequired: "Select Country Code",
  EnterMobileNoRequired: "Enter Mobile Number",
  EmailRequired: "Enter Email",
  EnterEINRequired: "Enter EIN",
  SelectProviderNameRequired: "Select Provider Name",
  EnterPatientMobileNoRequired: "Enter Patient's Mobile Number",
  EnterPatientEmailRequried: "Enter Patient's Email",
  EnterOneTimeCodeRequired: "Enter One-time code",
  EnterValidOTPRequired: "Enter Valid One-time code",
  CheckFutureDateEntered: "Entered date is a future date",
  // label
  BillerNameLabel: "Biller Name",
  BillerAccountLabel: "Biller Account", 
  isPrimaryInsuranceLabel: "IsPrimary Insurance",
  ChatInputLabel: "Enter Message",
  BillerBULabel: "Biller (Business unit)",
  BankNameLabel: "Bank Name",
  RoutingNoLabel: "Routing Number",
  AccountNoLabel: "Account Number",
  ProviderNameLabel: "Provider Name",
  ProviderFirstName: "Provider First Name",
  ProviderMiddleName: "Provider Middle Name",
  ProviderLastName: "Provider Last Name",
  NPILabel: "NPI#",
  AddressLabel: "Address",
  CountryLabel: "Country",
  StatesLabel: "State",
  CityLabel: "City",
  ZipLabel: "ZIP",
  ZipCodeLabel: "Zip Code",
  StateLicenseLabel: "State License Details",
  SpecialitiesLabel: "Specialities",
  OtherSpecialitiesLabel: "Other Specialities",
  PrimaryAddressLabel: "Primary Address",
  ChooseInsuranceLabel : "Choose Insurance",
  UploadCardLabel: "Upload Card (*.jpg,*jpeg,*.png)",
  FileTypeLabel: "jpeg,jpg,png",
  InsuranceCompanyLabel: "Insurance Company Name",
  GroupNumberLabel: "Group Number",
  POBoxLabel: "PO Box No*",
  InsuranceCompanyAddressLabel: "Insurance Co Physical Address*",
  MemberIdLabel: "Member Id",
  MemberFirstNameLabel: "Member First Name",
  MemberLastNameLabel: "Member Last Name",
  DOBLabel: "DOB(MM/DD/YYYY)",
  SearchInsuranceLabel: "Search Insurance",
  RejectedLabel: "Rejected",
  AcceptedLabel: "Accepted",
  SubmittedLabel: "Submitted",
  ReceivedLabel: "Received",
  MailedLabel: "Mailed",
  DraftLabel: "Draft",
  AllLabel: "All",
  NewLabel: "New",
  DateOfServiceLabel: "Date of Service*(MM/DD/YYYY)",
  TotalAmountLabel: "Total Amount*($)",
  SuperBillLabel: "Super Bill",
  ProviderLabel: "Provider (NPI)", 
  PatientTreatedOnLabel: "Patient Treated On(MM/DD/YYYY)",
  PatientTreatedAtLabel: "Patient Treated At",
  PatientTreatmentLabel: "Enter Treatment Address",
  ProviderAddressLabel: "Provider Address",
  CardHolderNameLabel: "Cardholder Name",
  CardNumberLabel: "Card Number",
  IndustryLabel: "Industry",
  EnterAccountNoLabel: "Enter Account No.",
  SubjectLabel: "Subject",
  EnterMessageLable: "Enter Message",
  XBPMessageFileTypeLabel: "pdf,xml,excel,word",
  IntendedPaymentLabel: "Intended Payment Date",
  NoteToBillerLabel: "Notes To Biller*",
  EnterAmountLabel: "Enter Amount",
  CurrentPasswordLabel: "Current Password",
  NewPasswordLabel: "New Password",
  ConfirmPasswordLabel: "Confirm Password",
  MobilerNumberLabel: "Mobile Number",
  TaxIdNumber: "Tax Identification Number",
  Address1Label: "Address Line 1",
  Address2Label: "Address Line 2",
  FirstNameLabel: "First Name",
  LastNameLabel: "Last Name",
  EmailLabel: "Email",
  EINLabel: "EIN",
  PatientMobileNoLabel: "Patient's Mobile Number",
  PatientEmailLabel: "Patient's Email",
  PatientSsidLabel: "Patient's SSID (last 4 digits)",
  PatientDobLabel: "Patient's DOB(MM/DD/YYYY)",
  ModifierMaxLimitError: "Max 4 modifier can be selected",
  InvalidTreatmentDate:"Invalid treatment date with selected Diagnostic(s)",
  InvalidDOS:"Invalid DOS for selected CPT",
  InvalidCPT:"Invalid CPT for selected DOS",
  InvalidDiagnosis:"Invalid diagnostic(s) on treated date",
  // SMB Required
  PaymentAmountRequired: "Enter Payment Amount",
  PaymentDateRequired: "Date format MM/DD/YYYY",
  ReferenceNoRequired: "Enter Reference Number",
  SelectApprover: "Select Approver",
  VendorRequired: "Select Vendor",
  OrgNameRequired: "Select Org Name",
  PaymentStatusRequired: "Select Payment Status",
  InvoiceNumberRequired: "Enter Invoice Number",
  InvoiceAmountRequired: "Enter Invoice Amount",
  ToDueDateRequired:"Enter To Due Date",
  FromDueDateRequired:"Enter From Due Date",
  DateCanNotFuture: "Date can not be in future",
  BillApproved: "Bill Approved Successfully",
  BillDeclined: "Bill Declined Successfully",
  PaymentExceedError: "Payment amount can't be greater than due amount",
  AmountRequired: "Enter Amount",
  SelectPaymentModeRequired: "Select Payment Mode",
  SelectPaymentStatusRequired: "Select Payment Status",
  BusinessNameRequired: "Select Business Name",
  AmountAndAppliedAmountError: "Amount & Total Applied amount are not same.",
  PayableAmountError: "Payable amount can't be more than total due amount",
  InvoiceAmountMustBeGreater: "Invoice must be greater than discount",
  SelectPaymentDate: "Select Payment Date",
  AppliedAmountMoreThanAmount: "Applied amount is more than payment amount.",
  AppliedAmountLessThanAmount: "Amount is not fully balanced with invoices.",
  // SMB Label
  PaymentAmountLabel: "Payment Amount",
  PaymentDateLabel: "Payment Date",
  ReferenceNoLabel: "Reference Number",
  NoPendingInvoice: "No pending invoice found.",
  NoInvoiceFound: "No invoice found.",
  XBPClaimSupportEmail:"dl.xbp.claims.support@exelaonline.com",
  BillCreatedMessage: "Bill Created Successfully.",
  RecordPaymentMessage: "Record Payment Successfully.",

  // Start: MixPanel Group/EventName
  PAGE_NAVIGATION: 'Page_Navigation',
  MANAGE_PROVIDER: 'Manage_Provider',
  MANAGE_INSURANCE: 'Manage_Insurance',
  UPLOAD_BILL: 'Upload_Bill',
  CLAIM_BILL: 'Claim_Bill',
  PROVIDER_UPLOAD_BILL: 'Provider_Upload_Bill',
  MANAGE_PAYMENT: 'Manage_Payment',
  CLAIM_DASHBOARD: 'Dashboard',
  RTP_DASHBOARD: "RTP_Dashboard",
  SEPA_DASHBOARD: "SEPA_Dashboard",
  RTP_ALL_BILLS: "RTP_All_Bills",
  SEPA_ALL_BILLS: "SEPA_Bills",
  BULK_PAYMENT: "Bulk_Payment",
  RTP_BILL_DETAILS: "RTP_Bill_Details",
  DECLINE_BILL: 'Decline_Bill',
  EXTENSION_BILL: 'Extension_Bill',
  PART_PAY_BILL: 'Bill_Pay_Part',
  FULL_PAY_BILL: 'Bill_Payment',
  SEPA_BILL_DETAILS: "SEPA_Bill_Details",
  PAM_LIST: 'Payer_Authorization',
  PAM_DETAILS: 'PAM_Message',
  SIMPLE_MESSAGE_LIST: 'Message_Biller',
  SIMPLE_MESSAGE_DETAILS: 'Message',
  CREATE_NEW_MESSAGE: 'Add_Message',
  FIND_AND_LINK_BILLER: 'Find_And_Link_Biller',
  MY_BANK: 'My_Banks',
  VIEW_PROFILE: 'Profile',
  EDIT_PROFILE: 'Edit_Profile',
  LINK_PAYER: 'Link_Payer',
  HEADER_RIGHT: 'Header_Right',
  BILL_HISTORY_PAGE: 'Bill_History',
  MESSAGE_SECTION: 'Message_Section',
  ACH_PAY: 'ACH_Pay',

  SMB_RECEIVE_PAYMENT: 'Receive_Payments',
  VENDOR_STATEMENT: 'Vendor_Statements',
  SMB_MAKE_PAYMENT: 'Make_Payments',
  APPROVE_BILL: 'Approve_Bill',
  SMB_UPLOAD_BILL: 'Upload_Bill',
  ORGANIZATION: 'Organizations',
  Switch_Organization: "Switch_Organization",
  Scan_QR_Code: 'Scan_QR_Code',
  Payer_Registration: "Payer_Registration",
  LOG_OUT: 'Log_Out',
  // End: MixPanel Group/EventName

  // Start: MixPanel actions
  INSURANCE_SELECTED_ACTION: 'Insurance Selected',
  SUBMIT_BILL_ACTION: 'Submit Bill Button Pressed',
  BILL_SUBMITTED_ACTION: 'Bill Submitted',
  PAYMENT_PROCESSED_ACTION: 'Payment Processed',
  PROVIDER_BILL_SUBMITTED_ACTION: 'Provider Bill Submitted',

  ADD_PROVIDER_ACTION: "Add Provider Button Pressed",
  PROVIDER_ADDED_ACTION: "Provider Added",
  PROVIDER_DELETED_ACTION: "Provider Deleted",
  ADD_ADDRESS_ACTION: "Add Treatment Address Button Pressed",
  ADDRESS_ADDED_ACTION: "Treatment Address Added",
  ADDRESS_DELETED_ACTION: "Treatment Address Deleted",

  ADD_INSURANCE_ACTION: "Add Insurance Button Pressed",
  INSURANCE_ADDED_ACTION: "Insurance Added",
  INSURANCE_DELETED_ACTION: "Insurance Deleted",
  SHARED_INSURANCE_ADDED_ACTION: "Shared Insurance Added",
  ADD_MEMBER_ACTION: "Add Member Button Pressed",
  MEMBER_ADDED_ACTION: "Member Added",
  MEMBER_DELETED_ACTION: "Member Deleted",

  FILTER_PAYMENT: "Filtered Payment Bills",
  DELETE_PAYMENT: "Deleted Payment Bills",
  VIEW_PAYMENT: "View Payment Button Pressed",
  ADD_PAYMENT: "Added Payment Button Pressed",
  ADDED_PAYMENT: "Added Payment",

  CLICKED_SUBMIT_BUTTON: "Clicked Submit Button",

  DOWNLOAD_CSV: "Download CSV File",

  VIEW_ALL_BILLS_ACTION: "View All",
  BILL_DETAILS_ACTION: "Bill Details",
  Click_on_Prioritized_Bill: "Click on Prioritized Bill",
  Clicked_on_Bill_History_Icon: "Clicked on Bill History icon",
  CLICKED: 'Clicked',
  BLOCK_NAVIGATION_ACTION: "Block",
  BULK_PAY_ACTION: "Bulk Pay",
  PAY_NOW_ACTION: "Pay Now",
  PAY_ACTION: "Pay",
  BOOKMARK_CLICK_ACTION: "Bookmark",
  Prioritize_Bill: "Prioritize Bill",
  Removed_Priority: "Removed Priority",
  RTP_Bills: "Clicked Bills",
  SEPA_Bills: "SEPA Bills",
  Payment_Option_Selected: "Payment Option Selected as",
  Rejected_Bill: "Rejected Bill",
  Applied_Amount_Updated: "Applied Amount Updated",
  SEARCH_ICON_ACTION: "Perform Search",

  FILTER_OPENED_ACTION: "Filter Opened",
  FILTER_CANCELLED_ACTION: "Filter Cancelled",
  Filter_Removed_Action: "Filter Removed",
  All_Filter_Removed_Action: "All Filters Removed",
  APPLIED_FILTER_ACTION: "Filter Applied",

  MESSAGE_SENT: 'Message Sent',
  ATTACHMENT_DOWNLOADED: 'Attachment Downloaded',
  ATTACHMENT_ADDED: 'Attachment Added',
  ATTACHMENT_REMOVED: 'Attachment Removed',
  BILLER_SELECTED: 'Biller Selected',
  LINK_BILLER: 'Link Biller',
  SEARCHED_LINK_BILLER: 'Searched Biller',
  RESET_FORM: 'Reset Form',
  Reset_Search: 'Reset Search',
  Reset_Info: "Reset Info",
  CLICKED_ON: 'Clicked On',
  SELECTED_EDIT_PROFILE: 'Edit Profile Selected',
  SELECTED_CHANGE_PASSWORD: 'Change Password Selected',
  SELECTED_LINK_PAYER: 'Link Payer Selected',
  CLOSE_OTP_POPUP: 'Close OTP Popup',

  FILE_UPLOADED: 'File Uploaded',
  CLICKED_ON_CANCEL_BUTTON: 'Clicked On Cancel Button',

  Biller_Selected_From_List: "Biller Selected From List",
  CANCEL_BLOCK_PAM_MODAL_POP_UP: 'Cancel Block PAM Modal Pop Up',
  PAM_BLOCKED: 'PAM Blocked',
  PAM_UNBLOCKED: 'PAM Unblocked',
  RESYNC_PAYMENT_PREFERENCE: 'Resync Payment Preference',
  OPEN_PREFERENCE_POP_UP: 'Open Preference Pop Up',
  PAM_PREFERENCE_UPDATED: 'PAM Preference Updated',
  DECLINE_PAM: 'Decline PAM',
  ACCEPT_PAM: 'Accept PAM',

  SIMPLE_MESSAGE_DETAILS_ACTION: 'Message Details',
  CREATE_NEW_MESSAGE_ACTION: 'Create New Message',
  VERIFY_BANK: 'Verify Bank',
  ALREADY_VERIFIED_BANK: 'Already Verified Bank',
  OPEN_DELETE_BANK_POPUP: 'Open Delete Bank Popup',
  BANK_DELETED: 'Bank Deleted',
  CLOSE_DELETE_BANK_POPUP: 'Close Delete Bank Popup',
  PROFILE_UPDATED: 'Profile Updated',
  LINK_PAYER_OTP_SENT: 'Link Payer OTP Sent',
  PAYER_SELECTED: 'Payer Selected',
  PAYER_UNSELECTED: 'Payer Unselected',
  CLICKED_NOTIFICATION: 'Clicked Notification',
  CLICKED_QR_SCAN: 'Clicked on Scan QR code icon',
  CLICKED_SWITCH_ORG: 'Clicked Switch Organization',
  User_Logged_Out: 'User Logged Out',
  Clicked_Log_Out: 'Clicked Log Out',
  New_Bank_Added: "New Bank Added",
  BILL_HISTORY: 'Bill History',
  BILL_HISTORY_DETAIL: 'Bill History Detail',
  OPEN_CONFIRMATION: 'Open Confirmation Modal',
  OPEN_DECLINE_CONFIRMATION: 'Opened Decline Confirmation Popup',
  CLOSED_DECLINE_CONFIRMATION: 'Clicked Cancel on Decline Confirmation Popup',
  CLOSE_CONFIRMATION: 'Close Confirmation Modal',
  BILL_DECLINED_SUCCESSFULLY: 'Bill Declined Successfully',
  BILL_EXTENSION_REQUESTED: 'Bill Extension Requested',
  BILL_PAID_SUCCESSFULLY: 'Bill Paid Successfully',
  ACCOUNT_DELETED: 'Account Deleted',
  ACCOUNT_ADDED: 'Account Added',
  FILE_DELETED: 'File Deleted',
  SELECTED: 'Selected',
  OPEN_REASON: 'Open Reason Modal',
  PAYMENT_STATUS_CHANGED: 'Payment Status Selected',
  APPROVAL_STATUS_CHANGED: 'Approval Status Selected',
  BUSINESS_NAME_SELECTED: 'Business Name Selected',
  EDIT_BILL_ICON: 'Click Edit Invoice',
  CLOSE_EDIT_FORM: 'Close Edit Form',
  SUBMIT_EDIT_BILL: 'Submit Edit Bill',
  SEARCH_VENDOR: 'Search Vendor',
  Search: 'Search',
  VENDOR_SELECTED: 'Vendor Selected',
  ORG_NAME_SELECTED: 'Org Name Selected',
  SELECTED_ALL_BILLS: 'Select All Bills',
  UNSELECTED_ALL_BILLS: 'Unselect All Bills',
  SELECT_BILL: 'Select Bill',
  UNSELECT_BILL: 'Unselect Bill',
  SUBMITTED_MARK_AS_PAID: 'Submitted Mark as Paid',
  PAYMENT_DONE: 'Payment Done',
  CLICKED_ON_MAKE_PAYMENT: 'Clicked on Make Payment',
  CANCEL_MAKE_PAYMENT: 'Cancel Make Payment',
  APPROVER_SELECTED: 'Approver Selected',
  APPROVED_BILL: 'Approved Bill',
  DECLINED_BILL: 'Declined Bill',
  DISCARD_FORM: 'Discard Changes',
  ORGANIZATION_SWITCHED: 'Organization Switched',

  MANAGE_CLIENT: "MANAGE_CLIENT",
  UPDATE_CLIENT_PAGE: "UPDATE_CLIENT",
  ADD_CLIENT_PAGE: "ADD_CLIENT",
  VIEW_CLIENT_PAGE: "VIEW_CLIENT",
  ADD_CLIENT: "Click Add Client",
  ADD_NEW_CLIENT: "New Client Added",
  ADD_NEW_CLIENT_CONFIG: "Client Configuration Added",
  EDIT_CLIENT: "Click Edit Client",
  NEXT_CLIENT: "Navigate to Client Configuration",
  CANCEL_CLIENT: "Cancel Client Configuration",
  UPDATE_CLIENT: "Client Updated",
  UPDATE_CLIENT_CONFIG: "Client Configuration Updated",
  DELETE_CLIENT_CONFIG: "Client Configuration Deleted",
  VIEW_CLIENT: "Click View Client",
  DELETE_CLIENT: "Click Delete Client",

  Registered_New_Payer: 'Registered New Payer',
  Clicked_Cancel: 'Clicked Cancel',
  Payer_Linked_Successfully: "Payer Linked Successfully",
  // End: MixPanel actions

  LOCAL_STORAGE_BUDETAILS: "_bu",
  LOCAL_STORAGE_PROFILE: "_pro",
  LOCAL_STORAGE_LINKPAYER: "_lnkp",
  LOCAL_STORAGE_BILLER: "_bilr",
  LOCAL_STORAGE_ACCESSTOKEN: "accessToken",
  LOCAL_STORAGE_REFRESHTOKEN: "_rftkn",
  LOCAL_STORAGE_BILLRECEIPTS: "smbReceipts",
  LOCAL_STORAGE_TABSELECTION: "_tb",
  LOCAL_STORAGE_SIGNINLOCATION: "_sign_in_log",

};

export {
  CommonString
};
