import React from "react";
import R14, {
  StyleSheet,
  View,
  Image,
  Text,
  DataTable,
  SafeAreaView
} from "../core";
import NoRecordsFound from "./NoRecordsFound";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { MESSAGE_TYPE } from "../constant/app.data.constant";
import PopupModalComponent from "./PopupModalComponent";
import Touchable from "./TouchableComponent";
import DateUtils from "../utils/DateUtils";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

const deleteIcon = require("../assets/images/delete.png");
const editIcon = require("../assets/images/edit.png");
const addIcon = require("../assets/images/plus_solid.png");
const viewIcon = require("../assets/images/eye.png");

export default R14.connect(
  class ClientDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isVisible: false,
        selectItem: null
      }
      this.handleDeleteClientPress = this.handleDeleteClientPress.bind(this);
      this.handleViewClientPress = this.handleViewClientPress.bind(this);
      this.handleEditClientPress = this.handleEditClientPress.bind(this);
      this.handleAddClientPress = this.handleAddClientPress.bind(this);
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.client.find(
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: !!search ? search : "",
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase()
            }
          ],
          totalCount: options.totalCount || false
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("clients");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      switch (columnName) {
        case "name":
          ret = (
            <View>
              <Text style={[commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.exSmallHeading]}>
                {row.name}
              </Text>
            </View>
          );
          break;
        case "createdOn":
          ret = (
            <View>
              <Text style={[commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.exSmallHeading]}>
                {DateUtils.formatDate(row.createdOn)}
              </Text>
            </View>
          );
          break;
        case "action":
          ret = (
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
              <Touchable onPress={() => this.handleEditClientPress(row)} disabled={row.isDefault}>
                <View>
                  <Image source={editIcon} style={commonStyles.icon20} />
                </View>
              </Touchable>
              {row.isDefault ?
                <Touchable style={commonStyles.marginLeft15} onPress={() => this.handleViewClientPress(row)}>
                  <View>
                    <Image source={viewIcon} style={commonStyles.icon20} />
                  </View>
                </Touchable> :
                <Touchable style={commonStyles.marginLeft15} onPress={() => this.handleDeleteClientPress(row)}>
                  <View>
                    <Image source={deleteIcon} style={commonStyles.icon20} />
                  </View>
                </Touchable>
              }
            </View>
          );
          break;
        default:
          ret = <View>
            <Text style={[commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.exSmallHeading]}>{content}</Text></View>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }

    async handleAddClientPress() {
      analyticsLogs(CommonString.MANAGE_CLIENT, CommonString.ADD_CLIENT, this.props.app);
      this.props.app.nav.to("addClientRoute");
      return false;
    }

    async handleDeleteClientPress(row) {
      analyticsLogs(CommonString.MANAGE_CLIENT, CommonString.DELETE_CLIENT, this.props.app);
      this.setState({
        selectItem: row,
        isVisible: true
      })
      return false;
    }

    async handleEditClientPress(row) {
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.client.setState({ client: row });
      this.props.app.nav.to("editClientRoute");
      analyticsLogs(CommonString.MANAGE_CLIENT, CommonString.EDIT_CLIENT, this.props.app);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return false;
    }

    async handleViewClientPress(row) {
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.client.setState({ client: row });
      this.props.app.nav.to("viewClientRoute");
      analyticsLogs(CommonString.MANAGE_CLIENT, CommonString.VIEW_CLIENT, this.props.app);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return false;
    }


    onConfirm = async () => {
      const { selectItem } = this.state;
      if (!!selectItem) {
        this.props.app.ui.progressIndicator.show();
        await this.props.app.dm.client.addUpdateClient({ uid: selectItem.uid, isActive: false, name: selectItem.name });
        await this.dataTable.refresh();
        this.onCancel();
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      }
    }

    onCancel = async () => {
      this.setState({
        selectItem: null,
        isVisible: false
      })
    }
    
    render() {
      const { resultsPerPage } = this.props.app.dm.client.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      let columns = {
        name: {
          label: "Name",
          sortable: false
        },
        description: {
          label: "Description",
          sortable: false
        },
        createdOn: {
          label: "Created On",
          sortable: false
        },
        action: {
          width: 100,
          label: "Action",
          sortable: false
        }
      }

      if (!!isMobileDevice) { delete columns.description; delete columns.createdOn; }
      return (
        <SafeAreaView style={[styles.screenLayout]}>
          <DataTable
            name='clients'
            columns={columns}
            searchable
            initializer={this.initializer}
            rowKeyExtractor={this.rowKeyExtractor}
            pageLoader={this.pageLoader}
            TableEmptyComponent={<NoRecordsFound noRecords={[commonStyles.marLT0, commonStyles.marRT0]} message={"No Clients found"} />}
            cellRenderer={this.cellRenderer}
            initialPage={1}
            initialRowsPerPage={resultsPerPage}
            initialTotalRows={resultsPerPage}
            // headerTitle={this.props.title || this.props.r14.navigation.currRoute._config.label}
            initialSortColumnName='type'
            initialSortDirection='asc'
            headerControlsRight={[
              <Touchable onPress={() => this.handleAddClientPress()}>
                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.marginRight15]}>
                  <Image source={addIcon} style={commonStyles.icon16} />
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.marginLeft5]}>Add Client</Text>
                </View>
              </Touchable>
            ]}
          />
          <PopupModalComponent
            visibility={!!this.state.isVisible}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.modalBodyWrapper]}
            messageType={MESSAGE_TYPE.info}
            headingIcon={true}
            heading={"Delete Client"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={`Do you want to delete Client?`}
            okLabel={"Yes"}
            handleOk={this.onConfirm}
            modalPopupOkBtn={[commonStyles.width76]}
            closeLabel={"No"}
            handleClose={this.onCancel}
            modalPopupCancelBtn={[commonStyles.width76]}
          /> </SafeAreaView>
      );
    }
  }
);
const styles = StyleSheet.create({
  screenLayout: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    height: 'fit-content',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 0,
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 70,
          borderColor: "#fff",
        };
    }
  },
  modalBodyWrapper: {
    minHeight: 132,
  },
});