import React from "react";
import R14, {
  StyleSheet,
  Button,
  Image,
  Text,
  View,
  Touchable,
  TextInputField,
  Form,
  SubmitButton,
  SelectMenuField,
  Checkbox,
  FlatList,
  Platform,
  AsyncStorage,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import CommonValidators from "../../utils/CommonValidators";
import NoRecordsFound from "../NoRecordsFound";
import { DEFAULT_COUNTRY, SEARCH_TYPE, USER_TYPE, MESSAGE_TYPE, CURRENCY } from "../../constant/app.data.constant";
import ModalPopUp from "../../components/ModalPopUp";
import InAppCameraComponent from "../InAppCameraComponent";
import FileUploadComponent from "../FileUploadComponent";
import DeviceUtils from "../../utils/DeviceUtils";
import HtmlDeCode from "../../utils/HtmlDecode";
import PCHLoaderComponent from "./PCHLoaderComponent";
import CommonUtility from "../../utils/CommonUtility";
import TooltipComponent from "../TooltipComponent";
import ReactDatePicker from "./ReactDatePicker"
import DateUtils from "../../utils/DateUtils";
import RadioButtons from "../RadioButtons";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";
import PopupModalComponent from "../PopupModalComponent";
import { analyticsLogs } from "../../utils/AnalyticLogs";
import Linking from "../../utils/Linking";
import RSAencryption from "../../utils/RSAencryption";

const cameraIcon = require("../../assets/images/cameraIcon.png");
const attachedFileIcon = require("../../assets/images/attachedFileIcon.png");
const eye = require("../../assets/images/eye.png");
const deleteIcon = require("../../assets/images/delete.png");
const editIcon = require("../../assets/images/edit.png");
const plusSolidIcon = require("../../assets/images/plus_solid.png");

const arrowLeft = require("../../assets/images/icPrevious.png");
const arrowRight = require("../../assets/images/icNext.png");
const arrowFirst = require("../../assets/images/icFirst.png");
const arrowLast = require("../../assets/images/icLast.png");
const searchIcon = require("../../assets/images/search.png");
const inquiryIcon = require("../../assets/images/edit_bank.png");
const selectedCountry = DEFAULT_COUNTRY;
export default R14.connect(
  class AddInsurance extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isPrimary: "false",
        displayType: this.props.DisplayType,
        actionMode: "",
        insuranceUid: "",
        isConfirmationVisible: false,
        addedHeight: 0,
        isCamVisible: false,
        attachments: [],
        isAddedNewItems: false,
        osName: "",
        hasPermission: false,
        isVisible: false,
        isViewFileModal: false,
        isImageConfirmationModal: false,
        alertMessage: "",
        isAlertVisible: false,
        sharedType: { value: "No", label: "Selected Insurance" },
        isChooseInsurance: false,
        searchText: "",
        poBoxAttribute: { required: CommonString.POBoxRequired },
        addressAttribute: { required: CommonString.POBoxRequired },
        oldCountry: null,
        oldState: null,
        isAddInsurance: false,
        isViewInsurance: false,
        isEditInsurance: false,
        isAddMember: false,
        isEditMember: false,
        selectedRelationInsured: "",
        selectedPrimaryPolicy: "yes",
        selectedGender: "",
        membersList: [],
        selectedInsurance: {},
        relationInsuredError: false,
        isGenderError: false,
        showDobDatePicker: false,
        addressConfirmed: false,
        isAddressUpdation: "",
        addressConfirmationMessage: "",
        loaderVisible:false,
        paymentConfirmation: false
      };

      this.form = null;
      this.countriesList = [];
      this.statesList = [];
      this.citiesList = [];

      const { defaultMode } = this.props;
      if (defaultMode === "Add") {
        this.addInsurance();
      }
      this.child = React.createRef();
    }

    componentDidMount = async () => {
      try {
        this.setState({
          osName: Platform.OS,
        });
        const { defaultMode } = this.props;
        if (defaultMode === "add") {
          this.addInsurance();
          this.setState({ actionMode: "add", isAddInsurance: true });
        } else {
          let filterForm = this.props.app.ui.form("frmInsFilter");
          filterForm.elmts.ddlSharedType.setValue(this.state.sharedType);
          filterForm.elmts.ddlSharedType.setItems([{ value: "Yes", label: "Search Insurance" }, { value: "No", label: "Selected Insurance" }]);
          this.setState({ actionMode: "Listing" });
        }
      } catch (error) {
        this.setState({ actionMode: "Listing" });
      }
      setTimeout(() => {
        this.ResetFilterForm();
      }, 100);
    };

    componentWillUnmount = async () => {
      if ((!!this.state.sharedType && this.state.sharedType.value === "Yes") || !!this.state.searchText) {
        this.ResetFilterForm();
      }
    }

    toggleIsPrimary = async () => {
      if (this.state.isPrimary === "true") this.setState({ isPrimary: "false" });
      else this.setState({ isPrimary: "true" });
    };

    toggleChooseInsurance = async () => {
      this.setState({ isChooseInsurance: !this.state.isChooseInsurance });
    };

    onChangeSearchText = async (val) => {
      this.setState({
        searchText: val
      })
    }

    validatePOBOX(val, sharedInc) {
      if (!!!!val) {
        if (val.toLowerCase().indexOf('pobox ') === 0 || val.toLowerCase().indexOf('po box ') === 0) return val;
        else if (sharedInc) return `PO BOX ${val}`;
        else if (val.toLowerCase().indexOf('pobox ') !== 0 || val.toLowerCase().indexOf('po box ') !== 0) return 'PO BOX ';
        return `PO BOX ${val}`;
      }
      return sharedInc ? '' : `PO BOX `;
    }

    onChangePOBox = async (val) => {
      this.form = this.props.app.ui.form("insuranceAdd");
      val = this.validatePOBOX(val);
      if (!!val) {
        this.form.elmts.poBoxNo.setValue(val);
        this.setState({
          addressAttribute: { required: false },
          poBoxAttribute: { required: false }
        });
        this.form.elmts.address1.setError(null);
      }
      else {
        this.form.elmts.poBoxNo.setValue(val);
        this.setState({
          addressAttribute: { required: CommonString.POBoxRequired }
        });
      }
    }

    onFocusPOBox = async () => {
      this.form = this.props.app.ui.form("insuranceAdd");
      const { selectedInsurance } = this.state;
      let isParticipant = (!!selectedInsurance && selectedInsurance.isParticipant) ? selectedInsurance.isParticipant : false
      if (!!this.form) {
        let { poBoxNo } = this.form.values;
        poBoxNo = this.validatePOBOX(poBoxNo, isParticipant);
        this.form.elmts.poBoxNo.setValue(poBoxNo);
      }
    }

    onChangeAddress = async (val) => {
      this.form = this.props.app.ui.form("insuranceAdd");
      if (!!val) {
        this.setState({
          addressAttribute: { required: false },
          poBoxAttribute: { required: false }
        });
        this.form.elmts.poBoxNo.setError(null);
      }
      else {
        this.setState({
          poBoxAttribute: { required: CommonString.POBoxRequired }
        });
      }
    }

    onSearchSharedInsurance = async (val, searchText) => {
      try {
        this.setState({
          loaderVisible: true,
          sharedType: val
        });

        if (!!!searchText) {
          let filterForm = this.props.app.ui.form("frmInsFilter");
          filterForm.elmts.searchInsName.setValue(null);
        }

        this.props.app.dm.addInsuranceDomain.setState({ currentPage: 1, isFetched: false });
        const { currentPage, resultsPerPage } = this.props.app.dm.addInsuranceDomain.state;
        let filter = {
          search: searchText,
          sort: { field: "createdAt", order: "DESC" },
          page: currentPage,
          resultsPerPage: resultsPerPage
        };

        if (val.value === "Yes") {
          await this.props.app.dm.addInsuranceDomain.getMstInsuranceDetails(filter);
        }
        else {
          const userLoginDetail = this.props.userLoginDetail;
          filter.userId = userLoginDetail.uid;
          await this.props.app.dm.addInsuranceDomain.getPchInsuranceDetails(filter);
        }
        this.setState({
          loaderVisible: false
        })

      } catch (error) {
        this.setState({
          loaderVisible: false
        })
      }
    }

    filterInsuranceDetail = async () => {
      try {
        let filterForm = this.props.app.ui.form("frmInsFilter");
        const { searchInsName, ddlSharedType } = filterForm.values;
        await this.onSearchSharedInsurance(ddlSharedType, searchInsName);
      } catch (error) {

      }
    }

    ResetFilterForm = async () => {
      let val = { value: "No", label: "Selected Insurance" };
      const { sharedType, searchText } = this.state;
      this.setState({
        searchText: ""
      });
      try {
        let filterForm = this.props.app.ui.form("frmInsFilter");
        filterForm.elmts.ddlSharedType.setValue(val);
        filterForm.elmts.searchInsName.setValue(null);
        if ((!!sharedType && sharedType.value === "Yes") || !!searchText) {
          this.setState({
            sharedType: val
          });
          this.onSearchSharedInsurance(val);
        }
      } catch (error) {
        this.onSearchSharedInsurance(val);
      }
    }
    // Clear Validation Message
    clearAllValidationMessages = async () => {
      try {

        let docForm = this.props.app.ui.form("insuranceAdd");
        this.setState({
          isPrimary: "false", attachments: [], selectedInsurance: null,
          isChooseInsurance: false,
          isGenderError: false,
          relationInsuredError: false,
          selectedGender: ""
        });
        docForm.elmts.name.setValue("");
        // docForm.elmts.insuranceCompanyID.setValue("");
        docForm.elmts.groupNumber.setValue("");
        docForm.elmts.address1.setValue("");
        // docForm.elmts.address2.setValue("");
        docForm.elmts.city.setValue(null);
        docForm.elmts.states.setValue(null);
        docForm.elmts.country.setValue(null);
        docForm.elmts.zipCode.setValue("");
        // docForm.elmts.employerName.setValue("");
        docForm.elmts.memberID.setValue("");
        docForm.elmts.memberFirstName.setValue("");
        docForm.elmts.memberLastName.setValue("");
        docForm.elmts.dob.setValue("");
        docForm.elmts.poBoxNo.setValue(this.validatePOBOX(''));
        // docForm.elmts.memberMiddleInitial.setValue("");
        //Set error to null
        docForm.elmts.name.setError(null);
        // docForm.elmts.insuranceCompanyID.setError(null);
        docForm.elmts.groupNumber.setError(null);
        docForm.elmts.address1.setError(null);
        // docForm.elmts.address2.setError(null);
        docForm.elmts.city.setError(null);
        docForm.elmts.states.setError(null);
        docForm.elmts.country.setError(null);
        docForm.elmts.zipCode.setError(null);
        docForm.elmts.memberID.setError(null);
        docForm.elmts.memberFirstName.setError(null);
        docForm.elmts.memberLastName.setError(null);
        docForm.elmts.dob.setError(null);
        docForm.elmts.poBoxNo.setError(null);
        docForm.reset();
        // docForm.elmts.memberMiddleInitial.setError(null);        
      } catch (error) {
        console.log(error)
      }
    };

    imageConfirm = async () => {
      const { isUberDocs } = this.props.app.dm.userSession.state;
      const { insuranceReqBody, insuranceUid, selectedInsurance, selectedGender, sharedType } = this.state
      this.setState({
        loaderVisible: true
      });

      if (this.state.actionMode === "Add" || (!!selectedInsurance && !!selectedInsurance.isParticipant && selectedInsurance.serialNo > 0)) {
        let dob = insuranceReqBody.dob;
        delete insuranceReqBody.dob;
        let InsuranceRes = await this.props.app.dm.addInsuranceDomain.insertInsuranceDetail(insuranceReqBody);
        if (!!InsuranceRes.uid) {
          // Add member details
          this.props.app.dm.addInsuranceDomain.insertMembereDetail({
            insuranceId: InsuranceRes.uid,
            memberId: insuranceReqBody.memberID,
            firstName: insuranceReqBody.memberFirstName,
            lastName: insuranceReqBody.memberLastName,
            isPrimaryPolicyHolder: "yes",
            dob: dob,
            gender: selectedGender,
            updatedAt: insuranceReqBody.updatedAt,
            updatedBy: insuranceReqBody.updatedBy,
          });

          const { insuranceItems, totalBills, resultsPerPage } = this.props.app.dm.addInsuranceDomain.state;
          insuranceItems.unshift(InsuranceRes);
          let totalIns = totalBills + 1;
          if (resultsPerPage < totalIns)
            insuranceItems.pop();

          this.props.app.dm.addInsuranceDomain.setState({
            insuranceItems: insuranceItems,
            totalBills: totalIns,
            totalPages: Math.ceil(totalIns / resultsPerPage),
            isFetched: true
          });

          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          const mixPanelAction = !!sharedType && sharedType.value === "Yes" ? CommonString.SHARED_INSURANCE_ADDED_ACTION : CommonString.INSURANCE_ADDED_ACTION;
          analyticsLogs(CommonString.MANAGE_INSURANCE, mixPanelAction, this.props.app);
          this.props.app.dm.commonDomain.showToastr("success", CommonString.InsuranceAdded);
          this.imageNotConfirm();
          this.ResetFilterForm();
          if (!!isUberDocs) {
            // move to view insurance screen
            this.updateInsurance({ uid: InsuranceRes.uid });
          } else {
            this.onCancel();
            return false;
          }
        } else {
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
      }
      else {
        insuranceReqBody.uid = insuranceUid;
        delete insuranceReqBody.dob;
        this.props.app.dm.addInsuranceDomain.updateInsuranceDetail(insuranceReqBody);
        const { insuranceItems } = this.props.app.dm.addInsuranceDomain.state;
        if (!!insuranceItems.length) {
          let ind = insuranceItems.findIndex((el) => el.uid === insuranceUid);
          insuranceItems[ind] = { ...insuranceItems[ind], ...insuranceReqBody };
        }
        this.props.app.dm.addInsuranceDomain.setState({
          insuranceItems: insuranceItems
        });

        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        this.props.app.dm.commonDomain.showToastr("success", CommonString.InsuranceUpdated);
        this.imageNotConfirm();
        this.ResetFilterForm();
        if (!!isUberDocs) {
          // move to view insurance screen
          this.updateInsurance({ uid: insuranceUid });
        } else {
          this.onCancel();
          return false;
        }
      }
      return false;
    }
    imageNotConfirm = () => {
      this.setState({
        isImageConfirmationModal: false,
        loaderVisible: false
      })
    }

    onAddMember = async () => {
      const docForm = this.props.app.ui.form("insuranceAdd");
      docForm.elmts.memberID.setValue("");
      docForm.elmts.memberFirstName.setValue("");
      docForm.elmts.memberLastName.setValue("");
      docForm.elmts.dob.setValue("");
      analyticsLogs(CommonString.MANAGE_INSURANCE, CommonString.ADD_MEMBER_ACTION, this.props.app);
      this.setState({
        isAddInsurance: false,
        isViewInsurance: false,
        isEditInsurance: false,
        isAddMember: true,
        isEditMember: false,
        memberUid: "",
        selectedRelationInsured: "",
        selectedPrimaryPolicy: this.state.membersList.length ? "no" : "yes",
        selectedGender: "",
      })
    }

    handleEditInsurance = async () => {
      try {
        this.setState({
          isAddInsurance: false,
          isViewInsurance: false,
          isEditInsurance: true,
          isAddMember: false,
          isEditMember: false
        })
      } catch (error) {
        console.log(error)
      }
    }

    onGenderChange = (val) => {
      this.setState({
        selectedGender: val,
        isGenderError: false,
      });
    };

    onPrimaryPolicyChange = (val) => {
      // const isAlreadyPrimaryPolicyHolder = this.state.membersList.some(member => member.isPrimaryPolicyHolder == "yes" && member.uid !== this.state.memberUid);
      // if(isAlreadyPrimaryPolicyHolder){
      // this.props.app.dm.commonDomain.showToastr("error", "Primary member already specified. Please change existing Primary to Non-primary.");
      // return;
      // } else {
      this.setState({
        selectedPrimaryPolicy: val,
        selectedRelationInsured: val === "yes" ? "" : this.state.selectedRelationInsured
      });
      // }
    };

    onRelationInsuredChange = (val) => {
      this.setState({
        selectedRelationInsured: val,
        relationInsuredError: false,
      });
    };

    openConfirmationEditMember = (form) => {
      const { memberID, memberFirstName, memberLastName, dob } = form.values;
      const memberUid = this.state.memberUid;
      const gender = this.state.selectedGender;
      const isPrimaryPolicyHolder = this.state.selectedPrimaryPolicy;
      const relationToInsured = this.state.selectedRelationInsured;

      if (!!!memberID) form.elmts.memberID.setError(CommonString.MemberIdRequired);
      if (!!!memberFirstName) form.elmts.memberFirstName.setError(CommonString.FirstNameRequired);
      if (!!!memberLastName) form.elmts.memberLastName.setError(CommonString.LastNameRequired);
      if (!!!dob) form.elmts.dob.setError(CommonString.DOBRequired);
      if (!!!gender || (isPrimaryPolicyHolder === "no" && !!!relationToInsured)) {
        this.setState({
          isGenderError: !gender ? true : false,
          relationInsuredError: isPrimaryPolicyHolder === "no" && !!!relationToInsured ? true : false
        })
      }


      if (!memberID || !memberFirstName || !memberLastName || !dob || !gender || !isPrimaryPolicyHolder || !gender || (isPrimaryPolicyHolder === "no" && !!!relationToInsured)) return false
      if (!(memberID.length > 1)) form.elmts.memberID.setError(CommonString.MIN2MAX15Validation);
      if (!(memberFirstName.length > 1)) form.elmts.memberFirstName.setError(CommonString.MIN2MAX50Validation);
      if (!(memberLastName.length > 1)) form.elmts.memberLastName.setError(CommonString.MIN2MAX50Validation);
      if (!(memberID.length > 1) || !(memberFirstName.length > 1) || !(memberLastName.length > 1)) return false;

      const memberDetails = this.state.membersList.find(el => el.isPrimaryPolicyHolder === "yes" && el.uid && el.uid !== memberUid);
      if (this.state.selectedPrimaryPolicy === "yes" && memberDetails) {
        this.setState({
          isConfirmationVisible: true,
          isGenderError: false,
          relationInsuredError: false
        })
      } else {
        this.handleAddMemberSubmit(form)
      }
      return false
    }

    handleAddMemberSubmit = async (form) => {
      try {
        const { memberID, memberFirstName, memberLastName, dob } = form.values;
        const insuranceId = this.state.insuranceUid;
        const memberUid = this.state.memberUid;
        const gender = this.state.selectedGender;
        const isPrimaryPolicyHolder = this.state.selectedPrimaryPolicy;
        const relationToInsured = this.state.selectedRelationInsured;

        if (!!!memberID) form.elmts.memberID.setError(CommonString.MemberIdRequired);
        if (!!!memberFirstName) form.elmts.memberFirstName.setError(CommonString.FirstNameRequired);
        if (!!!memberLastName) form.elmts.memberLastName.setError(CommonString.LastNameRequired);
        if (!!!dob) form.elmts.dob.setError(CommonString.DOBRequired);
        if (!!!gender || (isPrimaryPolicyHolder === "no" && !!!relationToInsured)) {
          this.setState({
            isGenderError: !gender ? true : false,
            relationInsuredError: isPrimaryPolicyHolder === "no" && !!!relationToInsured ? true : false,
          })
        }


        if (!memberID || !memberFirstName || !memberLastName || !dob || !gender || !isPrimaryPolicyHolder || !gender || (isPrimaryPolicyHolder === "no" && !!!relationToInsured)) return false
        if (!(memberID.length > 1)) form.elmts.memberID.setError(CommonString.MIN2MAX15Validation);
        if (!(memberFirstName.length > 1)) form.elmts.memberFirstName.setError(CommonString.MIN2MAX50Validation);
        if (!(memberLastName.length > 1)) form.elmts.memberLastName.setError(CommonString.MIN2MAX50Validation);
        if (!(memberID.length > 1) || !(memberFirstName.length > 1) || !(memberLastName.length > 1)) return false;

        this.setState({
          loaderVisible: true,
        });

        const reqBody = {
          insuranceId: insuranceId,
          memberId: memberID,
          firstName: memberFirstName,
          lastName: memberLastName,
          dob: dob,
          gender: gender,
          isPrimaryPolicyHolder: isPrimaryPolicyHolder,
          relationToInsured: relationToInsured,
          updatedAt: new Date().toUTCString(),
          updatedBy: this.props.userLoginDetail.uid,
        };

        let memberRes = {};
        if (memberUid) {
          reqBody.uid = memberUid;
          memberRes = await this.props.app.dm.addInsuranceDomain.updateMemberDetail(reqBody);
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          this.props.app.dm.commonDomain.showToastr("success", CommonString.MemberUpdated);
          const members = this.state.membersList;

          // update primary address as non primary
          if (isPrimaryPolicyHolder && isPrimaryPolicyHolder === "yes") {
            const primaryMemberDetails = members.find(member => member.isPrimaryPolicyHolder === "yes" && member.uid !== memberUid);
            if (primaryMemberDetails) {
              this.props.app.dm.addInsuranceDomain.updateMemberDetail({
                uid: primaryMemberDetails.uid,
                isPrimaryPolicyHolder: "no",
                relationToInsured: "other",
                updatedAt: new Date().toUTCString(),
                updatedBy: this.props.userLoginDetail.uid,
              });

              const memberInd = members.findIndex(el => el.isPrimaryPolicyHolder === "yes");
              members[memberInd] = { ...primaryMemberDetails, isPrimaryPolicyHolder: "no", relationToInsured: "other" };
            }
          }

          const memberInd = members.findIndex(el => el.uid === memberUid);
          members[memberInd] = memberRes;
          this.setState({
            loaderVisible: false,
            isAddInsurance: false,
            isViewInsurance: true,
            isEditInsurance: false,
            isAddMember: false,
            isEditMember: false,
            selectedRelationInsured: "",
            selectedPrimaryPolicy: "yes",
            selectedGender: "",
            memberUid: "",
            membersList: members,
            isConfirmationVisible: false
          });
        } else {
          const members = this.state.membersList;
          memberRes = await this.props.app.dm.addInsuranceDomain.insertMembereDetail(reqBody);
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          analyticsLogs(CommonString.MANAGE_INSURANCE, CommonString.MEMBER_ADDED_ACTION, this.props.app);
          this.props.app.dm.commonDomain.showToastr("success", CommonString.MemberAdded);

          // update primary address as non primary
          if (isPrimaryPolicyHolder && isPrimaryPolicyHolder === "yes") {
            const primaryMemberDetails = members.find(member => member.isPrimaryPolicyHolder === "yes" && member.uid && member.uid !== memberUid);
            if (primaryMemberDetails) {
              this.props.app.dm.addInsuranceDomain.updateMemberDetail({
                uid: primaryMemberDetails.uid,
                isPrimaryPolicyHolder: "no",
                relationToInsured: "",
                updatedAt: new Date().toUTCString(),
                updatedBy: this.props.userLoginDetail.uid,
              });

              const memberInd = members.findIndex(el => el.isPrimaryPolicyHolder === "yes");
              members[memberInd] = { ...primaryMemberDetails, isPrimaryPolicyHolder: "no" };
            }
          }

          this.setState({
            loaderVisible: false,
            isAddInsurance: false,
            isViewInsurance: true,
            isEditInsurance: false,
            isAddMember: false,
            isEditMember: false,
            selectedRelationInsured: "",
            selectedPrimaryPolicy: "yes",
            selectedGender: "",
            memberUid: "",
            membersList: [memberRes, ...members],
            isConfirmationVisible: false
          });
        }
        return false
      } catch (error) {
        console.log(error);
        this.setState({
          loaderVisible: false
        });
      }
    }

    validateZipCode = (value, isShared = false) => {
      const { selectedInsurance } = this.state;
      if ((!!selectedInsurance && selectedInsurance.isParticipant) || isShared) {
        return true;
      } else {
        let isValid = /^\d{5}-\d{4}$|^\d{5}$|^\d{9}$/gi.test(value);
        if (isValid) return true;
        return false;
      }
    }

    handleSubmit = async (form) => {
      try {
        if (this.state.isViewInsurance) {
          return this.updateInsurance({ uid: this.state.insuranceUid });
        } else if (this.state.isAddMember || this.state.isEditMember) {
          return this.openConfirmationEditMember(form)
        };

        const { isUberDocs } = this.props.app.dm.userSession.state;
        const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
        const userLoginDetail = this.props.userLoginDetail;
        let userUID = !!userLoginDetail ? userLoginDetail.uid : null;
        const { attachments, insuranceUid, selectedInsurance, isEditInsurance } = this.state;
        const isSharedType = !!this.state.sharedType && this.state.sharedType.value === "Yes" ? true : !!selectedInsurance && !!selectedInsurance.isParticipant ? true : false;
        const companyName = form.values.name;

        const isCompanyNameError = !isSharedType && (!companyName || companyName.length > 50 || companyName.length < 2) ? true : false;
        if (!!attachments && attachments.length > 2) {
          let error = "Maximum 2 cards can be uploaded.";
          if (isCompanyNameError) {
            form.elmts.name.setError(null);
            form.elmts.name.setError(CommonString.MIN2MAX50Validation)
          };
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: error
            })
          }
          //  alert(error)
          return false;
        }
        if (isCompanyNameError) {
          form.elmts.name.setError(null);
          form.elmts.name.setError(CommonString.MIN2MAX50Validation)
          return false;
        } else {
          form.elmts.name.setError(null);
        }
        // attachments.map((item) => {
        //   delete item.contentUri;
        //   delete item.content;
        //   delete item.documentId;
        //   return item;
        // });
        let { name, groupNumber, address1, city, states, country, zipCode, memberID, memberFirstName, memberLastName, poBoxNo, dob } = form.values;
        let gender = this.state.selectedGender;
        const validZipCode = this.validateZipCode(zipCode);
        if (!validZipCode || (!!!address1 && poBoxNo.trim() === "PO BOX") || ((!isEditInsurance || (!!this.state.sharedType && this.state.sharedType.value === "Yes")) && (!memberLastName || !memberFirstName || !memberID || !dob || !gender))) {
          if (!!!address1 && poBoxNo.trim() === "PO BOX") {
            form.elmts.address1.setError(CommonString.POBoxRequired)
            form.elmts.poBoxNo.setError(CommonString.POBoxRequired)
          };
          if (!!!memberID) form.elmts.memberID.setError(CommonString.MemberIdRequired);
          if (!!!memberFirstName) form.elmts.memberFirstName.setError(CommonString.FirstNameRequired);
          if (!!!memberLastName) form.elmts.memberLastName.setError(CommonString.LastNameRequired);
          if (!!!dob) form.elmts.dob.setError(CommonString.DOBRequired);
          if (!!!validZipCode) form.elmts.zipCode.setError(CommonString.ZipCode5);
          if (!!!gender) this.setState({ isGenderError: true });
          return false;
        }

        if (!insuranceUid && !(memberID.length > 1)) form.elmts.memberID.setError(CommonString.MIN2MAX15Validation);
        if (!insuranceUid && !(memberFirstName.length > 1)) form.elmts.memberFirstName.setError(CommonString.MIN2MAX50Validation);
        if (!insuranceUid && !(memberLastName.length > 1)) form.elmts.memberLastName.setError(CommonString.MIN2MAX50Validation);
        if (!insuranceUid && (!(memberID.length > 1) || !(memberFirstName.length > 1) || !(memberLastName.length > 1))) return false;

        this.setState({
          loaderVisible: true
        });

        if (!!poBoxNo && poBoxNo.trim() === "PO BOX") {
          poBoxNo = "";
        }

        if (!(this.state.selectedInsurance && this.state.selectedInsurance.isParticipant) && !this.state.addressConfirmed) {
          let isValidAddress = await this.props.app.dm.queryDomain.ValidateAddressDetails({
            address2: !!address1 ? address1 : poBoxNo,
            city: city !== null && city !== undefined ? city.label : null,
            state: states !== null && states !== undefined ? states.label : null,
            country: country !== null && country !== undefined ? country.label : null,
            zipCode: zipCode
          });

          if (isValidAddress && isValidAddress.success === false) {
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            this.props.app.dm.commonDomain.showToastr("error", isValidAddress.message);
            this.setState({ loaderVisible: false });
            return false;
          };

          /** Start of Handle address confirmation popup */
          const updateAddressKeys = !!isValidAddress ? Object.keys(isValidAddress.updatedAddress) : [];
          if (isValidAddress && updateAddressKeys.length) {
            let message = "";
            updateAddressKeys.forEach(key => {
              if (key === "address1") message = `Address: ${isValidAddress.updatedAddress["address1"]}`;
              if (key === "address2") message = (!!message ? `${message}\n` : "") + `Address2: ${isValidAddress.updatedAddress["address2"]}`;
              if (key === "city") message = (!!message ? `${message}\n` : "") + `City: ${isValidAddress.updatedAddress["city"]}`;
              if (key === "state") message = (!!message ? `${message}\n` : "") + `State: ${isValidAddress.updatedAddress["state"]}`;
              if (key === "zipCode") message = (!!message ? `${message}\n` : "") + `Zip Code: ${isValidAddress.updatedAddress["zipCode"]}`;
            });

            if (isValidAddress.alertMessage) message = (!!message ? `${message}\n\n` : "") + `Note* ${isValidAddress.alertMessage}`;
            this.setState({
              isAddressUpdation: "updateAddress",
              addressConfirmationMessage: message,
              addressConfirmed: true
            });
            return false;
          } else if (isValidAddress && !!isValidAddress.alertMessage) {
            const message = `Note* ${isValidAddress.alertMessage}`;
            this.setState({
              isAddressUpdation: "alertMessage",
              addressConfirmationMessage: message,
              addressConfirmed: true
            });
            return false;
          };
          /** End of handle address confirmation popup */
        }
        this.setState({ addressConfirmed: false });
        const reqBody = {
          isPrimary: this.state.isPrimary,
          name: name,
          // insuranceCompanyID: insuranceCompanyID,
          groupNumber: groupNumber,
          address1: address1,
          // address2: address2,
          city: city.label,
          state: states.label,
          country: country.label,
          zipCode: zipCode,
          memberID: memberID,
          memberFirstName: memberFirstName,
          memberLastName: memberLastName,
          userLoginDetailUid: userUID,
          poBoxNo: !!poBoxNo ? poBoxNo.trim().replace(/;$/, "") : "",
          updatedAt: new Date().toUTCString(),
          updatedBy: this.props.userLoginDetail.uid,
          countryObject: country !== null && country !== undefined ? country : null,
          stateObject: states !== null && states !== undefined ? states : null,
          cityObject: city !== null && city !== undefined ? city : null,
          dob: dob
        };

        if (this.state.isPrimary === "true") {
          // check if there is any primary insurance
          let filter = {
            "filter": {
              "isPrimary": { "eq": "true" },
              "isActive": { "eq": "true" },
              "userLoginDetailUid": {
                "eq": this.props.userLoginDetail.uid
              }
            },
            sort: { field: "createdAt", order: "DESC" },
            page: 1,
            resultsPerPage: 1
          };
          const primaryIns = await this.props.app.dm.addInsuranceDomain.pchInsuranceDetails(filter);
          if (primaryIns && primaryIns.length)
            await this.props.app.dm.addInsuranceDomain.updateInsuranceDetail({ isPrimary: "false", uid: primaryIns[0].uid });
        }

        if (!!selectedInsurance && !!selectedInsurance.isParticipant && selectedInsurance.serialNo > 0) {
          reqBody.isParticipant = true;
          // reqBody.uid = selectedInsurance.uid;
          reqBody.refInsuranceUid = selectedInsurance.uid;
          // reqBody.isActive = "true";
        }

        if (this.state.actionMode === "Add" || (!!selectedInsurance && !!selectedInsurance.isParticipant && selectedInsurance.serialNo > 0)) {
          let documentId = CommonUtility.generate20Character();
          let uploadDocs = attachments.map(async (doc, i) => {
            doc.documentId = documentId;
            doc.seqNo = i + 1;
            let fileData = { ...doc };
            delete fileData.content;
            let response = await this.props.app.dm.uploadBillDomain.uploadDocumentOnServer(fileData);
            if (!!response && !!response.content) {
              await this.uploadDocumentatS3(response.content, doc)
              doc.uid = response.uid;
            }
            return doc;
          });
          let docRes = await Promise.all(uploadDocs);
          if (docRes.filter(x => x.uid === null).length === 0) {
            docRes.map((item) => {
              delete item.contentUri;
              delete item.content;
              delete item.documentId;
              return item;
            });
          }

          reqBody.documents = !!docRes ? docRes : [];
          if (reqBody.documents.length > 0) {
            this.setState({
              isImageConfirmationModal: true,
              insuranceReqBody: reqBody,
              loaderVisible: false,
            })
            return false;
          } else {
            delete reqBody.dob;
            let InsuranceRes = await this.props.app.dm.addInsuranceDomain.insertInsuranceDetail(reqBody);
            if (!!InsuranceRes.uid) {
              // Add member details
              this.props.app.dm.addInsuranceDomain.insertMembereDetail({
                insuranceId: InsuranceRes.uid,
                memberId: memberID,
                firstName: memberFirstName,
                lastName: memberLastName,
                isPrimaryPolicyHolder: "yes",
                dob: dob,
                gender: gender,
                updatedAt: new Date().toUTCString(),
                updatedBy: this.props.userLoginDetail.uid,
              });
              const mixPanelAction = !!isSharedType ? CommonString.SHARED_INSURANCE_ADDED_ACTION : CommonString.INSURANCE_ADDED_ACTION;
              analyticsLogs(CommonString.MANAGE_INSURANCE, mixPanelAction, this.props.app);
              const { insuranceItems, totalBills, resultsPerPage } = this.props.app.dm.addInsuranceDomain.state;
              insuranceItems.unshift(InsuranceRes);
              let totalIns = totalBills + 1;
              if (resultsPerPage < totalIns)
                insuranceItems.pop();

              this.props.app.dm.addInsuranceDomain.setState({
                insuranceItems: insuranceItems,
                totalBills: totalIns,
                totalPages: Math.ceil(totalIns / resultsPerPage),
                isFetched: true
              });

              this.props.app.ui.progressIndicator.hide({ timeout: 750 });
              this.props.app.dm.commonDomain.showToastr("success", CommonString.MemberAdded);

              this.ResetFilterForm();
              if (!!isUberDocs) {
                // move to view insurance screen
                this.updateInsurance({ uid: InsuranceRes.uid });
                this.setState({
                  loaderVisible: false
                });
                return false;
              } else {
                this.setState({
                  loaderVisible: false
                });
                this.onCancel();
                return false;
              }
            } else {
              this.props.app.ui.progressIndicator.hide({ timeout: 750 });
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
          }
        } else {
          let documentId = CommonUtility.generate20Character();
          let uploadDocs = attachments.map(async (doc, i) => {
            if (!!doc.uid) return doc;
            else {
              doc.documentId = documentId;
              doc.seqNo = i + 1;
              let fileData = { ...doc };
              delete fileData.content;
              let response = await this.props.app.dm.uploadBillDomain.uploadDocumentOnServer(fileData);
              if (!!response && !!response.content) {
                await this.uploadDocumentatS3(response.content, doc)
                doc.uid = response.uid;
              }
              return doc;
            }
          });
          let docRes = await Promise.all(uploadDocs);
          if (docRes.filter(x => x.uid === null).length === 0) {
            docRes.map((item) => {
              delete item.contentUri;
              delete item.content;
              delete item.documentId;
              return item;
            });
          }

          reqBody.uid = insuranceUid;

          reqBody.documents = !!docRes ? docRes : [];
          if (reqBody.documents.length > 0) {
            this.setState({
              isImageConfirmationModal: true,
              insuranceReqBody: reqBody,
              loaderVisible: false,
            });
            return false;
          } else {
            delete reqBody.dob;
            this.props.app.dm.addInsuranceDomain.updateInsuranceDetail(reqBody);
            const { insuranceItems } = this.props.app.dm.addInsuranceDomain.state;
            if (!!insuranceItems.length) {
              let ind = insuranceItems.findIndex((el) => el.uid === insuranceUid);
              insuranceItems[ind] = { ...insuranceItems[ind], ...reqBody };


              let docForm = this.props.app.ui.form("insuranceAdd");
              docForm.elmts.name.setValue(insuranceItems[ind].name);
              docForm.elmts.groupNumber.setValue(insuranceItems[ind].groupNumber);
              docForm.elmts.address1.setValue(insuranceItems[ind].address1);
              docForm.elmts.zipCode.setValue(insuranceItems[ind].zipCode);
              docForm.elmts.country.setValue('countryObject' in insuranceItems[ind] && !!insuranceItems[ind].countryObject ? insuranceItems[ind].countryObject : selectedCountry);
              docForm.elmts.country.setItems('countryObject' in insuranceItems[ind] && !!insuranceItems[ind].countryObject ? [insuranceItems[ind].countryObject] : [selectedCountry]);
              docForm.elmts.states.setItems('stateObject' in insuranceItems[ind] && !!insuranceItems[ind].stateObject ? [insuranceItems[ind].stateObject] : null);
              docForm.elmts.states.setValue('stateObject' in insuranceItems[ind] && !!insuranceItems[ind].stateObject ? insuranceItems[ind].stateObject : null);
              docForm.elmts.city.setValue('cityObject' in insuranceItems[ind] ? insuranceItems[ind].cityObject : null);
              docForm.elmts.city.setItems('cityObject' in insuranceItems[ind] && insuranceItems[ind].cityObject ? [insuranceItems[ind].cityObject] : null);

            }
            this.props.app.dm.addInsuranceDomain.setState({
              insuranceItems: insuranceItems
            });

            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            this.props.app.dm.commonDomain.showToastr("success", CommonString.InsuranceUpdated);
            this.ResetFilterForm();
            if (!!isUberDocs) {
              // move to view insurance screen
              this.updateInsurance({ uid: insuranceUid });
              this.setState({
                loaderVisible: false
              });
            } else {
              this.setState({
                loaderVisible: false
              });
              this.onCancel();
              return false;
            }
          }
        }
        return false;
      } catch (error) {
        this.setState({
          loaderVisible: false
        });
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        return false;
      }
    };

    onUpdateAddress = async (updateNewAddress) => {
      // update required fields
      const form = this.props.app.ui.form("insuranceAdd");
      if (!!updateNewAddress) {
        const splitMessage = this.state.addressConfirmationMessage.split("\n");

        splitMessage.forEach(msg => {
          if (!msg.startsWith("Note*")) {
            const splitMsg = msg.split(": ");
            if (splitMsg[0] === "State") {
              form.elmts.states.setValue({ label: splitMsg[1], value: splitMsg[1] });
              form.elmts.states.setItems([{ label: splitMsg[1], value: splitMsg[1] }]);
            } else if (splitMsg[0] === "City") {
              form.elmts.city.setValue({ label: splitMsg[1], value: splitMsg[1] });
              form.elmts.city.setItems([{ label: splitMsg[1], value: splitMsg[1] }]);
            } else if (splitMsg[0] === "Zip Code") form.elmts.zipCode.setValue(splitMsg[1]);
            else if (splitMsg[0] === "Address") form.elmts.address1.setValue(splitMsg[1]);
          }
        });
      }

      this.setState({ addressConfirmed: true, isAddressUpdation: "", addressConfirmationMessage: "" });
      await this.handleSubmit(form);
      return false;
    }

    // Delete Member
    deleteMember = async () => {
      try {
        const { memberUid, membersList } = this.state;
        const memberReqBody = {
          isActive: "false",
          uid: this.state.memberUid,
          deletedBy: this.props.userLoginDetail.uid,
        };

        this.props.app.dm.addInsuranceDomain.updateMemberDetail(memberReqBody);
        if (!!membersList) {
          let ind = membersList.findIndex((el) => el.uid === memberUid);
          membersList.splice(ind, 1);
        };

        this.setState({
          isConfirmationVisible: false,
          memberUid: "",
          membersList
        });
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        analyticsLogs(CommonString.MANAGE_INSURANCE, CommonString.MEMBER_DELETED_ACTION, this.props.app);
        this.props.app.dm.commonDomain.showToastr("error", CommonString.MemberDeleted);
        return false;
      } catch (error) {
        console.log(error)
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
    }

    // NOTE: Insurance Delete.
    deleteInsurance = async () => {
      try {
        let InsuranceDetail = await this.props.app.dm.addInsuranceDomain.checkExistinUploadBill(
          {
            insuranceUid: { eq: this.state.insuranceUid },
          }
        );

        if (InsuranceDetail.length > 0) {
          this.setState({ isConfirmationVisible: false, insuranceUid: "" });
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.InsuranceCanNotDelete);
        } else {
          const insuranceReqBody = {
            isActive: "false",
            uid: this.state.insuranceUid,
            deletedAt: new Date().toUTCString(),
            deletedBy: this.props.userLoginDetail.uid,
          };
          const { insuranceItems, totalBills, resultsPerPage } = this.props.app.dm.addInsuranceDomain.state;
          if (totalBills <= resultsPerPage) {
            this.props.app.dm.addInsuranceDomain.updateInsuranceDetail(insuranceReqBody);
            if (!!insuranceItems.length) {
              let ind = insuranceItems.findIndex((el) => el.uid === this.state.insuranceUid);
              insuranceItems.splice(ind, 1);
            }
            this.props.app.dm.addInsuranceDomain.setState({
              insuranceItems: insuranceItems,
              totalBills: totalBills - 1
            });
          }
          else {
            await this.props.app.dm.addInsuranceDomain.updateInsuranceDetail(insuranceReqBody);
            await this.getFirst();
          }
          this.setState({ isConfirmationVisible: false, insuranceUid: "" });
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          analyticsLogs(CommonString.MANAGE_INSURANCE, CommonString.INSURANCE_DELETED_ACTION, this.props.app);
          this.props.app.dm.commonDomain.showToastr("error", CommonString.InsuranceDeleted);
          return false;
        }
      } catch (error) {
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
    };

    openPopScreen = async (uid) => {
      if (this.state.isViewInsurance) {
        let memberDetails = this.state.membersList.find((el) => el.uid === uid);
        if (memberDetails && memberDetails.isPrimaryPolicyHolder === "yes") {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.MinOneMember);
          return;
        }
        this.setState({ memberUid: uid, isConfirmationVisible: true });
      } else {
        this.setState({ insuranceUid: uid, isConfirmationVisible: true });
      }
    };

    navigateToEligibility = async (uid, mode) => {
      this.setState({ loaderVisible: true });
      const eligibility = await this.props.app.dm.addInsuranceDomain.getEligibilityString(uid, mode);
      if (!!eligibility && !!eligibility.success)
        if (!!eligibility.message) {
          AsyncStorage.setItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS, RSAencryption.encryptDataByBTOA(`${eligibility.data}`));
          let payment = JSON.parse(eligibility.data);
          this.setState({ paymentConfirmation : true, uniqueId: uid, inquiryAmount : payment.transactiondetails.total_amount.toFixed(2), currency: payment.transactiondetails.currency });
          return false;
        }
        else {
          Linking.LinkingURL(`${eligibility.data}`, "_self");
          return false;
        }
      else
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      this.setState({ loaderVisible: false });
      return false;
    }

    paymentConfirmation = () => {
      this.props.app.nav.to("patientCheckout", { data: RSAencryption.encryptDataByBTOA(`${this.state.uniqueId}`) });
      this.setState({ paymentConfirmation: false });
      return false;
    }

    cancelPaymentConfirmation = () => {
      AsyncStorage.removeItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
      this.setState({ loaderVisible: false, paymentConfirmation: false, inquiryAmount: 0, currency: "" });
      return false;
    }

    closePopScreen = () => {
      this.setState({ isConfirmationVisible: false });
    };

    onUpdateMember = async (member) => {
      const docForm = this.props.app.ui.form("insuranceAdd");
      docForm.elmts.memberID.setValue(member.memberId || "");
      docForm.elmts.memberFirstName.setValue(member.firstName || "");
      docForm.elmts.memberLastName.setValue(member.lastName || "");
      docForm.elmts.dob.setValue(member.dob || "");

      this.setState({
        isViewInsurance: false,
        isEditInsurance: false,
        isAddMember: false,
        isEditMember: true,
        memberUid: member.uid,
        selectedRelationInsured: member.relationToInsured || this.state.selectedRelationInsured,
        selectedPrimaryPolicy: member.isPrimaryPolicyHolder || this.state.selectedPrimaryPolicy,
        selectedGender: member.gender || this.state.selectedGender,

      });
      return false;
    }

    memberList = async (insuranceId) => {
      if (!insuranceId) return false;
      if (!this.state.membersList.length) {
        const filter = {
          filter: {
            isActive: { eq: "true" },
            insuranceId: { eq: insuranceId }
          },
          sort: { field: "createdAt", order: "DESC" },
          // page: currentPage,
          // resultsPerPage: resultsPerPage
        };
        this.setState({
          loaderVisible: true
        });
        const members = await this.props.app.dm.addInsuranceDomain.getInsuranceMembersList(filter);

        this.setState({
          membersList: members,
          loaderVisible: false
        })
        return !!members && members.length ? members : []
      } else {
        return this.state.membersList
      }
    }

    // NOTE: Insurance Update.
    updateInsurance = async (ins) => {
      try {
        let docForm = this.props.app.ui.form("insuranceAdd");
        const { selectedInsurance, sharedType } = this.state;
        if (!!selectedInsurance && !selectedInsurance.isParticipant) {
          await this.child.current.wrappedInstance.pullErrorText("");
        }
        this.setState({ loaderVisible: true });
        //TODO: call insurance method.
        let item = {};
        if (sharedType.value === "Yes")
          item = await this.props.app.dm.addInsuranceDomain.getMstInsurance(ins.uid);
        else {
          item = await this.props.app.dm.addInsuranceDomain.getInsurance(ins.uid);
        }

        this.memberList(ins.uid);
        this.setState({
          isPrimary: item.isPrimary,
          attachments: !!item.documents
            ? item.documents.map((img) => {
              return { filename: img.filename, content: null, actualsize: img.actualsize, uid: img.uid };
            })
            : [],
          isAddedNewItems: false,
        });

        docForm.elmts.name.setValue(item.name);
        // docForm.elmts.insuranceCompanyID.setValue(item.insuranceCompanyID);
        // docForm.elmts.planName.setValue(item.planName);
        // docForm.elmts.groupName.setValue(item.groupName);
        docForm.elmts.groupNumber.setValue(item.groupNumber);
        // docForm.elmts.email.setValue(item.email);
        // docForm.elmts.phoneNumber.setValue(item.phoneNumber);
        docForm.elmts.address1.setValue(item.address1);
        // docForm.elmts.address2.setValue(item.address2);
        docForm.elmts.zipCode.setValue(item.zipCode);
        // docForm.elmts.employerName.setValue(item.employerName);

        // docForm.elmts.memberID.setValue(item.memberID);
        if (sharedType.value === "Yes") {
          if (this.props.userLoginDetail) {
            const userLoginDetail = this.props.userLoginDetail;
            let userType = !!userLoginDetail ? userLoginDetail.userType : null;
            if (userType === USER_TYPE.PCH_PATIENT) {
              docForm.elmts.memberFirstName.setValue(userLoginDetail.firstName);
              docForm.elmts.memberLastName.setValue(userLoginDetail.lastName);
            }
          }
        }
        docForm.elmts.poBoxNo.setValue(this.validatePOBOX(item.poBoxNo, sharedType.value === "Yes"));
        if (this.state.isViewInsurance) {
          this.setState({
            actionMode: "Update",
            insuranceUid: item.uid,
            selectedInsurance: item,
            isAddInsurance: false,
            isViewInsurance: false,
            isEditInsurance: true,
            isAddMember: false,
            isEditMember: false,
            loaderVisible: false
          })
        } else {
          this.setState({
            actionMode: "Update",
            insuranceUid: item.uid,
            selectedInsurance: item,
            isAddInsurance: false,
            isViewInsurance: true,
            isEditInsurance: false,
            isAddMember: false,
            isEditMember: false,
            loaderVisible: false
          });
        };

        docForm.elmts.country.setValue('countryObject' in item && !!item.countryObject ? item.countryObject : selectedCountry);
        docForm.elmts.country.setItems('countryObject' in item && !!item.countryObject ? [item.countryObject] : [selectedCountry]);
        if (!!!item.countryObject) {
          let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, "", selectedCountry.value, "", 10);
          let stateData = data.map((item) => ({
            label: item.state_name,
            value: item.uid,
          }));

          docForm.elmts.states.setValue(null);
          docForm.elmts.states.setItems(stateData);
        }
        else {
          if (sharedType.value === "Yes") {
            docForm.elmts.states.setValue('stateObject' in item && !!item.stateObject ? { label: item.state, value: item.stateObject.value } : null);
            docForm.elmts.states.setItems('stateObject' in item && !!item.stateObject ? [item.stateObject] : null);
          } else {
            docForm.elmts.states.setValue('stateObject' in item && !!item.stateObject ? item.stateObject : null);
            docForm.elmts.states.setItems('stateObject' in item && !!item.stateObject ? [item.stateObject] : null);
          }
        }
        if (sharedType.value === "Yes") {
          docForm.elmts.city.setValue('cityObject' in item ? { label: item.city, value: item.cityObject.value } : null);
          docForm.elmts.city.setItems('cityObject' in item && item.cityObject ? [item.cityObject] : null);
        } else {
          docForm.elmts.city.setValue('cityObject' in item ? item.cityObject : null);
          docForm.elmts.city.setItems('cityObject' in item && item.cityObject ? [item.cityObject] : null);
        }
        return false;
      } catch (error) {
        console.log(error)
      }
    };

    onBack = async () => {
      try {
        // remove error messages
        let insuranceAddForm = this.props.app.ui.form("insuranceAdd");

        const { selectedInsurance, sharedType } = this.state;
        insuranceAddForm.reset();

        insuranceAddForm.elmts.name.setValue(selectedInsurance.name);
        insuranceAddForm.elmts.groupNumber.setValue(selectedInsurance.groupNumber);
        insuranceAddForm.elmts.address1.setValue(selectedInsurance.address1);
        insuranceAddForm.elmts.zipCode.setValue(selectedInsurance.zipCode);
        insuranceAddForm.elmts.poBoxNo.setValue(this.validatePOBOX(selectedInsurance.poBoxNo, sharedType.value === "Yes"));

        insuranceAddForm.elmts.country.setValue('countryObject' in selectedInsurance && !!selectedInsurance.countryObject ? selectedInsurance.countryObject : selectedCountry);
        insuranceAddForm.elmts.country.setItems('countryObject' in selectedInsurance && !!selectedInsurance.countryObject ? [selectedInsurance.countryObject] : [selectedCountry]);
        if (!!!selectedInsurance.countryObject) {
          let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, "", selectedCountry.value, "", 10);
          let stateData = data.map((item) => ({
            label: item.state_name,
            value: item.uid,
          }));

          insuranceAddForm.elmts.states.setValue(null);
          insuranceAddForm.elmts.states.setItems(stateData);
        }
        else {
          insuranceAddForm.elmts.states.setValue('stateObject' in selectedInsurance && !!selectedInsurance.stateObject ? { label: selectedInsurance.state, value: selectedInsurance.stateObject.value } : null);
          insuranceAddForm.elmts.states.setItems('stateObject' in selectedInsurance && !!selectedInsurance.stateObject ? [selectedInsurance.stateObject] : null);
        }
        insuranceAddForm.elmts.city.setValue('cityObject' in selectedInsurance ? { label: selectedInsurance.city, value: selectedInsurance.cityObject.value } : null);
        insuranceAddForm.elmts.city.setItems('cityObject' in selectedInsurance && selectedInsurance.cityObject ? [selectedInsurance.cityObject] : null);

        insuranceAddForm.elmts.isChooseInsurance && insuranceAddForm.elmts.isChooseInsurance.setError("");
        insuranceAddForm.elmts.isPrimary && insuranceAddForm.elmts.isPrimary.setError("");
        insuranceAddForm.elmts.name.setError("");
        insuranceAddForm.elmts.groupNumber.setError("");
        insuranceAddForm.elmts.poBoxNo.setError("");
        insuranceAddForm.elmts.address1.setError("");
        insuranceAddForm.elmts.states.setError("");
        insuranceAddForm.elmts.country.setError("");
        insuranceAddForm.elmts.city.setError("");
        insuranceAddForm.elmts.zipCode.setError("");
        insuranceAddForm.elmts.memberID.setError("");
        insuranceAddForm.elmts.memberFirstName.setError("");
        insuranceAddForm.elmts.memberLastName.setError("");
        insuranceAddForm.elmts.dob.setError("");
        this.setState({
          actionMode: "Update",
          isAddInsurance: false,
          isViewInsurance: true,
          isEditInsurance: false,
          isAddMember: false,
          isEditMember: false,
          selectedRelationInsured: "",
          selectedPrimaryPolicy: "yes",
          selectedGender: "",
          isGenderError: false,
          relationInsuredError: false,
          isChooseInsurance: false,
          isPrimary: selectedInsurance.isPrimary,
          attachments: !!selectedInsurance.documents
            ? selectedInsurance.documents.map((img) => {
              return { filename: img.filename, content: null, actualsize: img.actualsize, uid: img.uid };
            })
            : [],
          isAddedNewItems: false,
          showDobDatePicker: false,
        });
      } catch (error) {
        console.log(error);
      }
    }

    onCancel = async () => {
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let isForAdd = this.props.app.route._location.pathname;
      const { selectedInsurance } = this.state;
      if (isForAdd.includes("/add")) {
        if (!!isUberDocs) {
          this.clearAllValidationMessages();
          await this.props.app.nav.back();
          return false;
        } else {
          await this.props.app.nav.back();
          return false;
        }
      }
      if (!!selectedInsurance && !selectedInsurance.isParticipant) {
        await this.child.current.wrappedInstance.pullErrorText("");
      }
      if (this.state.isEditMember) {

        let docForm = this.props.app.ui.form("insuranceAdd");
        const { selectedInsurance } = this.state;

        docForm.elmts.name.setValue(selectedInsurance.name);
        docForm.elmts.groupNumber.setValue(selectedInsurance.groupNumber);
        docForm.elmts.address1.setValue(selectedInsurance.address1);
        docForm.elmts.zipCode.setValue(selectedInsurance.zipCode);
        docForm.elmts.poBoxNo.setValue(this.validatePOBOX(selectedInsurance.poBoxNo));

        docForm.elmts.country.setValue('countryObject' in selectedInsurance && !!selectedInsurance.countryObject ? selectedInsurance.countryObject : selectedCountry);
        docForm.elmts.country.setItems('countryObject' in selectedInsurance && !!selectedInsurance.countryObject ? [selectedInsurance.countryObject] : [selectedCountry]);
        if (!!!selectedInsurance.countryObject) {
          let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, "", selectedCountry.value, "", 10);
          let stateData = data.map((item) => ({
            label: item.state_name,
            value: item.uid,
          }));

          docForm.elmts.states.setValue(null);
          docForm.elmts.states.setItems(stateData);
        }
        else {
          docForm.elmts.states.setValue('stateObject' in selectedInsurance && !!selectedInsurance.stateObject ? selectedInsurance.stateObject : null);
          docForm.elmts.states.setItems('stateObject' in selectedInsurance && !!selectedInsurance.stateObject ? [selectedInsurance.stateObject] : null);
        }
        docForm.elmts.city.setValue('cityObject' in selectedInsurance ? selectedInsurance.cityObject : null);
        docForm.elmts.city.setItems('cityObject' in selectedInsurance && selectedInsurance.cityObject ? [selectedInsurance.cityObject] : null);

        this.setState({
          actionMode: "Update",
          isAddInsurance: false,
          isViewInsurance: true,
          isEditInsurance: false,
          isAddMember: false,
          isEditMember: false,
          selectedRelationInsured: "",
          selectedPrimaryPolicy: "yes",
          selectedGender: "",
          isGenderError: false,
          relationInsuredError: false,
          isPrimary: selectedInsurance.isPrimary,
          attachments: !!selectedInsurance.documents
            ? selectedInsurance.documents.map((img) => {
              return { filename: img.filename, content: null, actualsize: img.actualsize, uid: img.uid };
            })
            : [],
          isAddedNewItems: false,
          showDobDatePicker: false,
        });
      } else {
        this.clearAllValidationMessages();
        this.setState({
          actionMode: "Listing",
          isAddInsurance: false,
          isViewInsurance: false,
          isEditInsurance: false,
          isAddMember: false,
          isEditMember: false,
          selectedRelationInsured: "",
          selectedPrimaryPolicy: "yes",
          selectedGender: "",
          membersList: [],
          isGenderError: false,
          relationInsuredError: false,
        });
      }
    };

    addInsurance = async () => {
      this.clearAllValidationMessages();
      this.ResetFilterForm();
      await this.child.current.wrappedInstance.pullErrorText("");
      this.countriesList = await this.onSearchCountryStateCity(
        SEARCH_TYPE.COUNTRY,
        true
      );
      this.setState({
        actionMode: "Add",
        isAddInsurance: true,
        isViewInsurance: false,
        isEditInsurance: false,
        isAddMember: false,
        isEditMember: false
      });
      this.form = this.props.app.ui.form("insuranceAdd");

      this.onCountryChange(selectedCountry);
      if (this.props.userLoginDetail) {
        analyticsLogs(CommonString.MANAGE_INSURANCE, CommonString.ADD_INSURANCE_ACTION, this.props.app);
        const userLoginDetail = this.props.userLoginDetail;
        let userType = !!userLoginDetail ? userLoginDetail.userType : null;
        if (userType === USER_TYPE.PCH_PATIENT) {
          this.form.elmts.memberFirstName.setValue(userLoginDetail.firstName);
          this.form.elmts.memberLastName.setValue(userLoginDetail.lastName);
        }
      }
    };
    getHeightOfElement = async (event) => {
      const { height } = event.nativeEvent.layout;
      this.setState({
        addedHeight: height,
      });
    };

    handleShowHideDatePicker = (val) => {
      if (val === "open" && this.state.showDobDatePicker) {
        return;
      } else if (val === "close") {
        this.setState({ showDobDatePicker: false });
        return;
      } else {
        this.setState({ showDobDatePicker: !this.state.showDobDatePicker })
      }
    }

    onClearDobDate = () => {
      const form = this.props.app.ui.form("insuranceAdd");
      form.elmts.dob.setValue(null);
      form.elmts.dob.setError(null);
      this.setState({ showDobDatePicker: false })
    }

    validateDate = async (testdate) => {
      try {
        let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
        return date_regex.test(testdate);
      } catch (error) {
        return false;
      }
    };

    onDateValueChange = async (val, autoformat = true) => {
      const form = this.props.app.ui.form("insuranceAdd");
      this.handleShowHideDatePicker();
      form.elmts.dob.setError(null);
      if (!!val) {
        if (!!autoformat) {
          let date = DateUtils.formatDate(val);
          if (!!date && new Date(date) > new Date()) {
            form.elmts.dob.setValue(null);
            form.elmts.dob.setError(CommonString.DOBCanNotFuture);
            return false;
          } else if (!!date && DateUtils.isOlderThanMaxLimit(val)) {
            form.elmts.dob.setValue(null);
            form.elmts.dob.setError(CommonString.DOBCanNotOlderThan125);
            return false;
          } else {
            form.elmts.dob.setValue(date);
          }
        } else {
          if (!!val && new Date(val) > new Date()) {
            form.elmts.dob.setValue(null);
            form.elmts.dob.setError(CommonString.DOBCanNotFuture);
            return false;
          } else {
            form.elmts.dob.setValue(val);
          }
        }
      }
    };

    onDateBlur = async () => {
      const form = this.props.app.ui.form("insuranceAdd");
      const { dob } = form.values;
      this.handleShowHideDatePicker();
      if (!!dob) {
        let dat = await this.validateDate(dob);
        if (!!dat) {
          let formatedDate = DateUtils.formatDateInMMDDYYYY(dob);
          dat = await this.validateDate(formatedDate);
          if (!!dat) {
            form.elmts.dob.setValue(formatedDate);
            form.elmts.dob.setError(null);
            this.onDateValueChange(dob, false);
          } else {
            form.elmts.dob.setValue(null);
            form.elmts.dob.setError(CommonString.DateFormatRequired);
          }
        } else {
          form.elmts.dob.setValue(null);
          this.onDateValueChange(null, false);
          form.elmts.dob.setError(CommonString.DateFormatRequired);
        }
      } else {
        form.elmts.dob.setError(null);
      }
    };

    //--------Country Dropdown.
    onCountryChange = async (val, IsReturn) => {
      const { selectedInsurance } = this.state;
      let isParticipant = !!selectedInsurance && !!selectedInsurance.isParticipant ? true : false
      this.form = this.props.app.ui.form("insuranceAdd");
      if (!!IsReturn || !!isParticipant) {
        this.form.elmts.country.setValue(selectedCountry);
        return false;
      }

      // let { label } = val;
      let label = val.label;
      const { oldCountry } = this.state;
      if (!!oldCountry && oldCountry.label === val.label) { }
      else {
        this.form.elmts.states.setValue(null);
        this.form.elmts.states.setValue(null);
        this.form.elmts.city.setValue(null);
        this.form.elmts.city.setItems(null);
      }
      if (
        label === "No Results Found" ||
        label.includes("Type in a search value")
      ) {
        this.statesList = [];
        this.citiesList = [];
        this.form.elmts.country.setValue(null);
        this.form.elmts.city.setValue(null);
        this.form.elmts.city.setItems(null);
        this.form.elmts.states.setValue(null);
        this.form.elmts.states.setItems(null);
        // this.form.elmts.states.setItems(this.statesList);
        // this.form.elmts.city.setItems(this.citiesList);
        return false;
      }
      this.form.elmts.country.setValue(val);
      this.setState({
        oldCountry: val
      });
      this.statesList = await this.onSearchCountryStateCity(
        SEARCH_TYPE.STATE,
        true
      );
    };

    onStateChange = async (val, IsReturn) => {
      const { selectedInsurance } = this.state;
      let isParticipant = !!selectedInsurance && !!selectedInsurance.isParticipant ? true : false
      this.form = this.props.app.ui.form("insuranceAdd");
      if (!!IsReturn || !!isParticipant) {
        this.form.elmts.states.setValue(selectedInsurance.stateObject);
        return false;
      }
      let { label } = val;
      const { oldState } = this.state;
      if (!!oldState && oldState.label === val.label) { }
      else {
        this.form.elmts.city.setValue(null);
        this.form.elmts.city.setItems(null);
      }
      if (
        label === "No Results Found" ||
        label.includes("Type in a search value")
      ) {
        this.citiesList = [];
        this.form.elmts.city.setValue(null);
        this.form.elmts.city.setItems(null);
        this.form.elmts.states.setValue(null);
        this.form.elmts.city.setItems(this.citiesList);
        return false;
      }
      this.form.elmts.states.setValue(val);
      this.setState({
        oldState: val
      });
      this.citiesList = await this.onSearchCountryStateCity(
        SEARCH_TYPE.CITY,
        true
      );
    };

    onCityName = async (val, IsReturn) => {
      const { selectedInsurance } = this.state;
      let isParticipant = !!selectedInsurance && !!selectedInsurance.isParticipant ? true : false
      this.form = this.props.app.ui.form("insuranceAdd");

      if (!!IsReturn || !!isParticipant) {
        this.form.elmts.city.setValue(selectedInsurance.cityObject);
        return false;
      }
      const { label } = val;
      if (
        label === "No Results Found" ||
        label.includes("Type in a search value")
      ) {
        this.form.elmts.city.setValue(null);
        return false;
      }
      this.form.elmts.city.setValue(val);
    };

    onSearchCountryStateCity = async (
      type,
      isBlankAllow = false,
      search = ""
    ) => {
      try {
        const { values, elmts } = this.props.app.ui.form("insuranceAdd");
        let items = [];
        if (search !== "" || !!isBlankAllow) {
          if (type === SEARCH_TYPE.STATE) {
            let country_name = !!values && !!values.country && !!values.country.value ? values.country.value : "";
            items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, search, country_name);

            items = items.map((element) => ({
              label: element.state_name,
              value: element.uid,
            }));
            items = [
              ...new Map(items.map((item) => [item["label"], item])).values(),
            ];
            elmts.states.setItems(items);
            return items;
          } else if (type === SEARCH_TYPE.CITY) {
            let state_name = !!values && !!values.states && !!values.states.value ? values.states.value : "";
            if (!state_name) return [];
            items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.CITY, search, "", state_name);
            items = items.map((element) => ({
              label: element.city_name,
              value: element.uid,
            }));
            items = [
              ...new Map(items.map((item) => [item["label"], item])).values(),
            ];
            elmts.city.setItems(items);
            return items;
          } else {
            items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.COUNTRY, search);
            items = items.map((element) => ({
              label: element.country_name,
              value: element.uid,
            }));
            items = [
              ...new Map(items.map((item) => [item["label"], item])).values(),
            ];
            elmts.country.setItems(items);
            return items;
          }
        } else {
          return type === SEARCH_TYPE.STATE
            ? this.statesList
            : type === SEARCH_TYPE.CITY
              ? this.citiesList
              : this.countriesList;
        }
      } catch (error) {

      }
    };

    onCamClose = async () => {
      this.setState({ isCamVisible: false });
    };

    openCamera = async () => {
      const { osName } = this.state;
      if (osName !== "web") {
        let resPermission = DeviceUtils.getPermission();
        resPermission.then(
          (status) => {
            if (status === "granted")
              this.setState({ isCamVisible: true, hasPermission: status === "granted" });
          })
      }
      else {
        let deviceRes = DeviceUtils.getDevice()
        try {
          deviceRes.then(
            (res) => {
              if (res.includes("granted"))
                this.setState({ isCamVisible: true });
              else if (osName === 'web' && (window.innerWidth <= 800) && (window.innerHeight <= 720)) {
                this.setState({ isCamVisible: true });
              } else
                this.props.app.dm.commonDomain.showToastr("error", res);
            }
          )
        } catch (err) {
          this.props.app.dm.commonDomain.showToastr("error", err);
        }
      }
    }

    renderCamViewer() {
      const { isCamVisible, hasPermission } = this.state;
      return (
        <InAppCameraComponent
          isCamVisible={isCamVisible}
          onValueChange={this.onFileInputChange}
          onCamClose={this.onCamClose}
          hasPermission={hasPermission}
        />
      );
    }

    onFileInputChange = async (val, status) => {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      if (!!!isMobileDevice)
        await this.child.current.wrappedInstance.pullErrorText("");

      if (status === "done") {
        const { attachments } = this.state;
        let existingFiles = attachments.filter((value) => value.filename === val.filename && value.actualsize === val.actualsize);
        if (existingFiles.length > 0) {
          let error = "File has been already added."
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: error
            })
          }
          return false;
        }

        if (!!attachments && attachments.length > 1) {
          let error = "Maximum 2 cards can be uploaded.";
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: error
            })
          }
          return false;
        }

        let filesSize = attachments.reduce((prevVal, val) => prevVal + val.actualsize, 0);
        filesSize = filesSize + val.actualsize;
        if (filesSize / 1024 / 1024 > 2) {
          let error = "Maximum 2MB file size allowed.";
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: error
            })
          }
          return false;
        }
        if (!!attachments && attachments.length > 1) {
          let error = "Maximum 2 cards can be uploaded.";
          if (!!!isMobileDevice)
            await this.child.current.wrappedInstance.pullErrorText(error);
          else {
            this.setState({
              isAlertVisible: true,
              alertMessage: error
            })
          }
          return false;
        }
        attachments.push(val);
        this.setState({
          isAddedNewItems: true,
          attachments: attachments,
        });
      }
    };

    uploadDocumentatS3 = async (signedUrl, file) => {
      var options = {
        headers: {
          "content-type": file.contentUri.replace("data:", "").replace(";base64", ""),
        }
      };
      return await this.props.app.dm.rest.putS3(signedUrl, Buffer.from(file.content, 'base64'), options);
    }

    trimValue = async (elt, val, isTrim) => {
      this.form = this.props.app.ui.form("insuranceAdd");
      this.form.elmts[elt].setValue(!!isTrim ? val.trimStart() : val);
    };

    onDeleteFile = async (ind) => {
      const { attachments } = this.state;
      // if(!!attachments[ind].uid)
      // {
      //     this.props.app.dm.uploadBillDomain.deleteDocument(attachments[ind].uid);
      // }
      this.setState({
        attachments: attachments.filter((value, index, arr) => index !== ind),
        isAddedNewItems: true,
      });
    };

    onDownloadFile = async (document) => {
      let item = { ...document }
      if (!!item.content) {
        item.content = `data:application/octet-stream;base64,${item.content}`;
        HtmlDeCode.downloadFile(item)
      } else {
        let doc = await this.props.app.dm.uploadBillDomain.readDocument(item.uid);
        if (!!doc) {
          let options = {
            responseType: "arraybuffer",
            headers: {
              "content-type": "application/octet-stream",
            }
          };
          let response = await this.props.app.dm.rest.getS3(doc, options);
          let billDocuments = Buffer.from(response.data, 'binary').toString('base64');
          item.content = `data:application/octet-stream;base64,${billDocuments}`;
          await HtmlDeCode.downloadFile(item);
        }
        else
          this.props.app.dm.commonDomain.showToastr("error", CommonString.FileNotFound);
      }
    };

    onViewFile = async (document) => {
      let item = { ...document }
      if (!!item.content) {
        item.content = `data:application/octet-stream;base64,${item.content}`;
        this.setState({
          isViewFileModal: true,
          cardContent: item.content,
        })
      } else {
        let doc = await this.props.app.dm.uploadBillDomain.readDocument(item.uid);
        if (!!doc) {
          item.content = doc;
          this.setState({
            isViewFileModal: true,
            cardContent: item.content,
          })
        }
        else
          this.props.app.dm.commonDomain.showToastr("error", CommonString.FileNotFound);
      }
    };

    onViewFileModalClose = async (item) => {
      this.setState({
        isViewFileModal: false,
        cardContent: null,
      })
    };

    trimExceededCharZipCode = (val) => {
      CommonUtility.zipCodeFormater(this.props.app.ui.form("insuranceAdd"), "zipCode", val);
    };

    showModal = () => {
      this.setState({
        isVisible: !this.state.isVisible
      })
    }
    onClose = () => {
      this.setState({
        isVisible: false
      })
    }

    getNext = async () => {
      this.setState({
        loaderVisible: true
      })

      let { currentPage, totalPages, resultsPerPage } = this.props.app.dm.addInsuranceDomain.state;
      const { sharedType, searchText } = this.state;
      if (currentPage <= totalPages) {
        currentPage = currentPage + 1;
        if (currentPage <= totalPages) {

          this.props.app.dm.addInsuranceDomain.setState({
            currentPage: currentPage,
            isFetched: false
          });

          let filter = {
            search: searchText,
            sort: { field: "createdAt", order: "DESC" },
            page: currentPage,
            resultsPerPage: resultsPerPage
          };
          if (!!sharedType && sharedType.value === "Yes") {
            await this.props.app.dm.addInsuranceDomain.getMstInsuranceDetails(filter);
          }
          else {
            const userLoginDetail = this.props.userLoginDetail;
            filter.userId = userLoginDetail.uid;
            await this.props.app.dm.addInsuranceDomain.getPchInsuranceDetails(filter);
          }
        }
      }
      this.setState({
        loaderVisible: false
      })
    }

    getPrevious = async () => {
      this.setState({
        loaderVisible: true
      })
      let { currentPage, resultsPerPage } = this.props.app.dm.addInsuranceDomain.state;
      const { sharedType, searchText } = this.state;
      if (currentPage >= 1) {
        currentPage = currentPage - 1;
        if (currentPage > 0) {

          this.props.app.dm.addInsuranceDomain.setState({
            currentPage: currentPage,
            isFetched: false
          })
          let filter = {
            search: searchText,
            sort: { field: "createdAt", order: "DESC" },
            page: currentPage,
            resultsPerPage: resultsPerPage
          };
          if (!!sharedType && sharedType.value === "Yes") {
            await this.props.app.dm.addInsuranceDomain.getMstInsuranceDetails(filter);
          }
          else {
            const userLoginDetail = this.props.userLoginDetail;
            filter.userId = userLoginDetail.uid;
            await this.props.app.dm.addInsuranceDomain.getPchInsuranceDetails(filter);
          }
        }
      }
      this.setState({
        loaderVisible: false
      })
    }


    getFirst = async () => {
      this.setState({
        loaderVisible: true
      })
      this.props.app.dm.addInsuranceDomain.setState({
        currentPage: 1,
        isFetched: false
      })
      const { resultsPerPage, currentPage } = this.props.app.dm.addInsuranceDomain.state;
      const { sharedType, searchText } = this.state;
      let filter = {
        search: searchText,
        sort: { field: "createdAt", order: "DESC" },
        page: currentPage,
        resultsPerPage: resultsPerPage
      };
      if (!!sharedType && sharedType.value === "Yes") {
        await this.props.app.dm.addInsuranceDomain.getMstInsuranceDetails(filter);
      }
      else {
        const userLoginDetail = this.props.userLoginDetail;
        filter.userId = userLoginDetail.uid;
        await this.props.app.dm.addInsuranceDomain.getPchInsuranceDetails(filter);
      }
      this.setState({
        loaderVisible: false
      })
    }

    getLast = async () => {
      this.setState({
        loaderVisible: true
      })
      let { totalPages, resultsPerPage } = this.props.app.dm.addInsuranceDomain.state;
      const { sharedType, searchText } = this.state;
      this.props.app.dm.addInsuranceDomain.setState({
        currentPage: totalPages,
        isFetched: false
      })

      let filter = {
        search: searchText,
        sort: { field: "createdAt", order: "DESC" },
        page: totalPages,
        resultsPerPage: resultsPerPage
      };
      if (!!sharedType && sharedType.value === "Yes") {
        await this.props.app.dm.addInsuranceDomain.getMstInsuranceDetails(filter);
      }
      else {
        const userLoginDetail = this.props.userLoginDetail;
        filter.userId = userLoginDetail.uid;
        await this.props.app.dm.addInsuranceDomain.getPchInsuranceDetails(filter);
      }
      this.setState({
        loaderVisible: false
      })
    }
    alertOk = async (msg) => {
      this.setState({
        isAlertVisible: false,
        alertMessage: ''
      })
    };

    renderAlertPopup(clientStyle, commonFontStyles) {
      const { isAlertVisible, alertMessage } = this.state;
      return (
        <PopupModalComponent
          visibility={!!isAlertVisible}
          bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
          messageType={MESSAGE_TYPE.info}
          headingIcon={true}
          heading={"Info"}
          modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
          bodyContent={alertMessage}
          okLabel={"Ok"}
          handleOk={() => this.alertOk(alertMessage)}
        />
      );
    }

    renderInsuranceForm(clientStyle, commonFontStyles, isUberDocs) {
      const { actionMode, attachments, isViewFileModal, cardContent, isImageConfirmationModal, isEditInsurance, isAddInsurance, isEditMember, selectedInsurance, memberUid, membersList } = this.state;
      const { leftMenuToggle } = this.props.app.dm.userSession.state;
      let userType = USER_TYPE.XBP;
      if (this.props.userLoginDetail) {
        const userLoginDetail = this.props.userLoginDetail;
        userType =
          !!userLoginDetail && userLoginDetail.userType
            ? userLoginDetail.userType
            : USER_TYPE.XBP;
      }
      let isParticipant = false;
      isParticipant = !!selectedInsurance && !!selectedInsurance.isParticipant ? true : false;
      const genderTypes = [{ label: "Male", value: "male" }, { label: "Female", value: "female" }];
      const primaryPolicyHolderTypes = [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }];
      const relationInsuredTypes = [{ label: "Parent", value: "parent" }, { label: "Spouse", value: "spouse" }, { label: "Child", value: "child" }, { label: "Other", value: "other" }];
      const isPrimaryInsured = membersList.find(member => member.isPrimaryPolicyHolder === "yes" && member.uid === memberUid);
      return (
        <View style={[actionMode === "Listing" ? commonStyles.displayNone : [styles.addInsuranceWrap, clientStyle.secondaryBgColor]]}>
          {userType === USER_TYPE.PCH_PATIENT ? this.renderCamViewer() : null}
          <Form
            name="insuranceAdd"
            style={[
              styles.profileEditForm,
            ]}
            onSubmit={this.handleSubmit}
            validateBeforeSubmit={true}
            validators={CommonValidators.getFormValidator()}
          >
            {!!this.state.sharedType && this.state.sharedType.value === "Yes" ?
              <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection, commonStyles.alignItemsStart, commonStyles.marginBottom5, !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,]}>
                <View style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  commonStyles.flexDirectionRow,
                  styles.customAlignItemsCenter,
                  commonStyles.justifyContentCenter,
                  styles.customFlexDirection,
                  styles.isPrimaryandUpload,
                  commonStyles.marginBottom10
                ]}
                >
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.primaryIncflexBasis48,
                      commonStyles.marginRight8,
                      commonStyles.itemWithTwoCol,
                      // commonStyles.marginBottom15,
                      styles.isPrimaryCheckBox
                    ]}
                  >
                    <Checkbox
                      name="isChooseInsurance"
                      label={CommonString.ChooseInsuranceLabel}
                      value={this.state.isChooseInsurance}
                      colorBlank="#585858"
                      colorMarked="#4dc5f2"
                      onPress={() => this.toggleChooseInsurance()}
                    />
                    <View>
                      <Text
                        style={[
                          styles.primaryInsuranceText,
                          commonFontStyles.workSans,
                          commonStyles.heading6,
                          styles.primaryInsuranceTopPadding,
                          commonStyles.fullWidth,
                        ]}
                      >
                        {CommonString.ChooseInsuranceLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              : null}
            <View style={[(!!this.state.sharedType && this.state.sharedType.value === "Yes" && this.state.isChooseInsurance) || this.state.sharedType.value === "No" ? null : commonStyles.displayNone]}>
              <View style={[commonStyles.flex1, styles.customFlexDirection, commonStyles.alignItemsStart, attachments && !!attachments.length ? null : commonStyles.marginBottom10]}>
                <View
                  style={[
                    commonStyles.flex1,
                    commonStyles.flexBasis48,
                    styles.rightInputHolder,
                    commonStyles.flexDirectionRow,
                    styles.customAlignItemsCenter,
                    commonStyles.justifyContentCenter,
                    styles.customFlexDirection,
                    styles.isPrimaryandUpload,
                    (this.state.isAddMember || isEditMember) && commonStyles.displayNone
                  ]}
                >
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.primaryIncflexBasis48,
                      commonStyles.marginRight8,
                      commonStyles.itemWithTwoCol,
                      commonStyles.marginBottom15,
                      styles.isPrimaryCheckBox,
                      !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                    ]}
                  >
                    <Checkbox
                      name="isPrimary"
                      label={CommonString.isPrimaryInsuranceLabel}
                      value={this.state.isPrimary === "true" ? true : false}
                      colorBlank="#585858"
                      colorMarked="#4dc5f2"
                      onPress={() => this.toggleIsPrimary()}
                      disabled={!isEditInsurance && !isAddInsurance}
                    />
                    <Text
                      style={[
                        styles.primaryInsuranceText,
                        commonFontStyles.workSans,
                        commonStyles.smallHeading,
                        styles.primaryInsuranceTopPadding,
                        commonStyles.fullWidth,
                      ]}
                    >
                      Primary Insurance
                    </Text>
                  </View>
                  <View
                    style={[
                      commonStyles.flex1,
                      commonStyles.flexBasis48,
                      styles.leftInputHolder,
                      commonStyles.itemWithTwoCol,
                      commonStyles.marginBottom15,
                      styles.fileUploadWrapper,
                      !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                      (this.state.isAddMember || isEditMember) && commonStyles.displayNone
                    ]}
                  >
                    <FileUploadComponent
                      ref={this.child}
                      maxFiles={3}
                      labelText={CommonString.UploadCardLabel}
                      fileTypes={CommonString.FileTypes}
                      fileTypeLabel={CommonString.FileTypeLabel}
                      module="cards"
                      onValueChange={this.onFileInputChange}
                      fileInputStyle={[commonStyles.margin0Auto]}
                      attachmentFieldOuterCustomHeight={
                        styles.attachmentFieldOuterCustomHeight
                      }
                    />
                  </View>
                </View>
                <View
                  style={[
                    commonStyles.dFlex,
                    commonStyles.flex1,
                    commonStyles.flexBasis48,
                    styles.leftInputHolder,
                    commonStyles.itemWithTwoCol,
                    customCommonStyles.customAddIncpageMarginBottom15,
                    styles.customFlexDirection,
                    styles.isPrimaryandUpload,
                  ]}
                >
                  <View
                    style={[
                      commonStyles.flexGrow1,
                      styles.attachmentsHolder,
                      // attachments && !!attachments.length ? null : [commonStyles.dFlex, commonStyles.alignItemsCenter],
                      commonStyles.dFlex,
                      commonStyles.alignItemsCenter,
                    ]}
                  >
                    <View
                      style={[
                        commonStyles.flex1,
                        commonStyles.flexDirectionRow,
                        styles.uploadCardWrap,
                        customCommonStyles.customUploadCardWrap,
                        !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                        (this.state.isAddMember || isEditMember) && commonStyles.displayNone
                      ]}
                    >
                      <Touchable
                        style={[
                          commonStyles.flex1,
                          commonStyles.flexBasis48,
                          styles.uploadCard,
                          styles.uploadCardLeft,
                          commonStyles.justifyContentCenter,
                          commonStyles.alignItemsCenter,
                        ]}
                        onPress={() => this.openCamera()}
                      >
                        <View
                          style={[
                            [
                              styles.cameraOpt,
                              commonStyles.flex1,
                              commonStyles.flexDirectionRow,
                              commonStyles.justifyContentCenter,
                              commonStyles.alignItemsCenter,
                            ],
                          ]}
                          onPress={() => this.openCamera()}
                        >
                          <View>
                            <Image
                              source={cameraIcon}
                              style={[commonStyles.icon20]}
                            />
                          </View>
                          <View>
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.heading6,
                                commonStyles.fontWeight500,
                              ]}
                            >
                              Front of Card
                            </Text>
                          </View>
                        </View>
                      </Touchable>
                      <Touchable
                        style={[
                          commonStyles.flex1,
                          commonStyles.flexBasis48,
                          styles.leftInputHolder,
                          styles.uploadCard,
                          styles.uploadCardRight,
                          commonStyles.alignItemsCenter,
                          commonStyles.justifyContentCenter,
                        ]}
                        onPress={() => this.openCamera()}
                      >
                        <View
                          style={[
                            [
                              styles.cameraOpt,
                              commonStyles.flex1,
                              commonStyles.flexDirectionRow,
                              commonStyles.justifyContentFlexEnd,
                              commonStyles.alignItemsCenter,
                            ],
                          ]}
                          onPress={() => this.openCamera()}
                        >
                          <View>
                            <Image
                              source={cameraIcon}
                              style={[commonStyles.icon20]}
                            />
                          </View>
                          <View>
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.heading6,
                                commonStyles.fontWeight500,
                              ]}
                            >
                              Back of Card
                            </Text>
                          </View>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={[commonStyles.dFlex, styles.customFlexDirection]}
              ></View>
              {attachments && !!attachments.length ?
                <View
                  style={[
                    styles.attachmentsWrapper,
                    commonStyles.flexGrow1,
                    customCommonStyles.customAttachmentsHolder,
                    // attachments && !!attachments.length ? null : [commonStyles.dFlex, commonStyles.alignItemsCenter],
                    commonStyles.flex1,
                    styles.customFlexDirection,
                    !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                    (this.state.isAddMember || isEditMember) && commonStyles.displayNone
                  ]}
                >
                  {attachments && !!attachments.length ? (
                    attachments.map((el, i) => (
                      <View style={[styles.attachments]} key={`atta_${i}`}>
                        <View
                          style={[
                            commonStyles.flex1,
                            commonStyles.justifyContentSpaceBetween,
                            commonStyles.flexDirectionRow,
                            styles.borderBottom1,
                            commonStyles.alignItemsCenter,
                          ]}
                        >
                          <View style={[styles.filenameSection]}>
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.heading6,
                                commonStyles.fontWeight500,
                                styles.filename
                              ]}
                            >
                              {el.filename}
                            </Text>
                          </View>
                          <View
                            style={[
                              commonStyles.flex1,
                              commonStyles.alignItemsCenter,
                              commonStyles.flexDirectionRow,
                              commonStyles.justifyContentSpaceBetween,
                              commonStyles.flexGrow0,
                              styles.downloadDeleteAction
                            ]}
                          >
                            <Touchable
                              style={[
                                styles.iconAction
                              ]}
                              onPress={() => this.onViewFile(el)}
                            >
                              <View>
                                <Image
                                  source={eye}
                                  style={commonStyles.icon20}
                                />
                              </View>
                            </Touchable>
                            <Touchable
                              style={[
                                styles.iconAction
                              ]}
                              onPress={() => this.onDownloadFile(el)}
                            >
                              <View>
                                <Image
                                  source={attachedFileIcon}
                                  style={commonStyles.icon20}
                                />
                              </View>
                            </Touchable>

                            <Touchable
                              style={[customCommonStyles.deleteIconMargin, styles.iconAction]}
                              onPress={() => this.onDeleteFile(i)}>
                              <View>
                                <Image
                                  source={deleteIcon}
                                  style={commonStyles.icon20}
                                />
                              </View>
                            </Touchable>
                          </View>
                        </View>
                      </View>
                    ))
                  ) : null}
                </View>
                : null}
            </View>
            <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection, (this.state.isAddMember || isEditMember) && commonStyles.flexGrow0]}>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  styles.inputFields,
                  commonStyles.dFlex,
                  commonStyles.flexDirectionRow,
                  styles.formFieldsHolder,
                  // !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                  !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput
                ]}
              >
                <TextInputField
                  name="name"
                  label={`${CommonString.InsuranceCompanyLabel}* `}
                  tooltip={CommonString.MIN2MAX50Validation}
                  onChangeText={async (value) => {
                    this.trimValue("name", value, true);
                  }}
                  required={CommonString.EnterInsuranceCompanyRequired}
                  readOnly={!!isParticipant || (!isEditInsurance && !isAddInsurance) ? true : false}
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields, customCommonStyles.inputHeight, !!isParticipant ? styles.isParticipant : null]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.leftInputHolder,
                  styles.inputFields,
                  styles.formFieldsHolder,
                  !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput
                ]}
              >
                <TextInputField
                  name="groupNumber"
                  label={CommonString.GroupNumberLabel}
                  // required="Enter Group Number"
                  validator="min0Max15"
                  readOnly={!!this.state.sharedType && this.state.sharedType.value === "Yes" ?
                    this.state.isChooseInsurance ? false : true
                    : isEditInsurance || isAddInsurance ? false : true}
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields, customCommonStyles.inputHeight,
                  (!!this.state.sharedType && this.state.sharedType.value === "Yes" && this.state.isChooseInsurance) || this.state.sharedType.value === "No" ? null : styles.isParticipant]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                />
              </View>

              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  styles.inputFields,
                  commonStyles.flexDirectionRow,
                  styles.formFieldsHolder,
                  !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                  (this.state.isAddMember || isEditMember) && commonStyles.displayNone
                ]}
              >
                <TextInputField
                  name="poBoxNo"
                  label={`${CommonString.POBoxLabel} `}
                  // required= CommonString.POBoxRequired
                  // {...poBoxAttribute}
                  onChangeText={(val) => this.onChangePOBox(val)}
                  onFocus={() => this.onFocusPOBox()}
                  readOnly={!!isParticipant || (!isEditInsurance && !isAddInsurance) ? true : false}
                  validator="poBoxNoWihSemiCol"//"poBoxNoWihSemiCol"
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields, customCommonStyles.inputHeight, !!isParticipant ? styles.isParticipant : null]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                />
                <TooltipComponent
                  toolTipMessage={CommonString.POBoxInsurance}
                  toolTipMessageCss={styles.customtoolTipMessage}
                  justifyContentEnd={commonStyles.justifyContentFlexEnd}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.leftInputHolder,
                  styles.inputFields,
                  styles.formFieldsHolder,
                  !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                  (this.state.isAddMember || isEditMember) && commonStyles.displayNone
                ]}
              >
                <TextInputField
                  name="address1"
                  label={`${CommonString.InsuranceCompanyAddressLabel} `}
                  readOnly={!!isParticipant || (!isEditInsurance && !isAddInsurance) ? true : false}
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields, customCommonStyles.inputHeight, !!isParticipant ? styles.isParticipant : null]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  validator="max75"
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                />
              </View>
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexWrap,
                styles.customFlexDirection,
                (this.state.isAddMember || isEditMember) && commonStyles.displayNone
              ]}
            >
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  styles.inputFields,
                  !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                ]}
              >
                <SelectMenuField
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields,]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  name="country"
                  label={`${CommonString.CountryLabel}*`}
                  required={CommonString.SelectCountryRequired}
                  items={this.countriesList}
                  // readOnly={!isEditInsurance && !isAddInsurance}
                  readOnly={true}
                  onValueChange={async (val) => this.onCountryChange(val, true)}
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                  searchable
                  itemLoader={async ({ search }) => {
                    return await this.onSearchCountryStateCity(
                      SEARCH_TYPE.COUNTRY,
                      false,
                      search
                    );
                  }}
                  onFocus={this.showModal}
                  disabled={true}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.leftInputHolder,
                  styles.inputFields,
                  styles.formFieldsHolder,
                  styles.customMarTop30,
                  commonStyles.marginBottom30,
                  !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                ]}
              >
                <SelectMenuField
                  style={[
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonFontStyles.workSans,
                    styles.formFields, !!isParticipant ? styles.isParticipant : null
                  ]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                  name="states"
                  label={`${CommonString.StatesLabel}*`}
                  required={CommonString.SelectStateRequired}
                  items={this.statesList}
                  readOnly={!isEditInsurance && !isAddInsurance}
                  onValueChange={async (val) => this.onStateChange(val, !isEditInsurance && !isAddInsurance)}
                  searchable
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                  itemLoader={async ({ search }) => {
                    return await this.onSearchCountryStateCity(
                      SEARCH_TYPE.STATE,
                      false,
                      search
                    );
                  }}
                  disabled={!!isParticipant ? true : false}
                />
              </View>
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexWrap,
                styles.customFlexDirection,
                (this.state.isAddMember || isEditMember) && commonStyles.displayNone
              ]}
            >
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  styles.inputFields,
                  styles.formFieldsHolder,
                  !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                ]}
              >
                <SelectMenuField
                  style={[
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonFontStyles.workSans,
                    styles.formFields, !!isParticipant ? styles.isParticipant : null
                  ]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                  name="city"
                  label={`${CommonString.CityLabel}*`}
                  required={CommonString.SelectCityRequired}
                  items={this.citiesList}
                  readOnly={!isEditInsurance && !isAddInsurance}
                  onValueChange={async (val) => this.onCityName(val, !isEditInsurance && !isAddInsurance)}
                  searchable
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                  itemLoader={async ({ search }) => {
                    return await this.onSearchCountryStateCity(
                      SEARCH_TYPE.CITY,
                      false,
                      search
                    );
                  }}
                  disabled={!!isParticipant ? true : false}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.leftInputHolder,
                  styles.inputFields,
                  styles.formFieldsHolder,
                  !isEditInsurance && !isAddInsurance && customCommonStyles.disabledInput,
                ]}
              >
                <TextInputField
                  name="zipCode"
                  label={`${CommonString.ZipCodeLabel}*`}
                  required={CommonString.EnterZipCodeRequired}
                  // validator="min5Max10"
                  onChangeText={this.trimExceededCharZipCode}
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields, customCommonStyles.inputHeight, !!isParticipant ? styles.isParticipant : null]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                  readOnly={!!isParticipant || (!isEditInsurance && !isAddInsurance) ? true : false}
                />
              </View>
            </View>
            {/* Add/Edit Member */}
            <View style={[
              commonStyles.marginBottom15,
              // (!this.state.isAddMember && !isEditMember) && commonStyles.displayNone,
              !!this.state.isViewInsurance ? commonStyles.displayNone : null,
              !!this.state.isViewInsurance && !!isEditInsurance ? commonStyles.displayNone : null,
              !!this.state.sharedType && this.state.sharedType.value === "Yes" && !isEditInsurance ? commonStyles.displayNone : null,
              !!this.state.sharedType && this.state.sharedType.value === "No" && !!isEditInsurance ? commonStyles.displayNone : null
            ]}>
              <Text style={[
                commonStyles.blueButtonColor,
                commonStyles.fontWeight500,
                commonFontStyles.workSans,
                commonStyles.heading5,
                commonStyles.darkGrayColor
              ]}>{isEditMember ? 'Edit Member' : isEditInsurance || isAddInsurance ? 'Primary Member Details' : 'Add Member'}</Text>
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexWrap,
                styles.customFlexDirection,
                commonStyles.flexGrow0,
                // (!this.state.isAddMember && !isEditMember) && commonStyles.displayNone,
                // isAddInsurance && commonStyles.displayNone
                !!this.state.isViewInsurance ? commonStyles.displayNone : null,
                !!this.state.isViewInsurance && !!isEditInsurance ? commonStyles.displayNone : null,
                !!this.state.sharedType && this.state.sharedType.value === "Yes" && !isEditInsurance ? commonStyles.displayNone : null,
                !!this.state.sharedType && this.state.sharedType.value === "No" && !!isEditInsurance ? commonStyles.displayNone : null
              ]}
            >
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis49,
                  styles.rightInputHolder,
                  styles.inputFields,
                  styles.formFieldsHolder,
                  commonStyles.flexGrow0
                ]}
              >
                <TextInputField
                  name="memberID"
                  label={`${CommonString.MemberIdLabel}* `}
                  validator={"min2Max15AlphaNumeric"}
                  readOnly={!!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? false : true : false}
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields, customCommonStyles.inputHeight, !!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? null : customCommonStyles.disabledInput : null]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                />
              </View>
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexWrap,
                styles.customFlexDirection,
                commonStyles.flexGrow0,
                // ((!this.state.isAddMember && !isEditMember) || isAddInsurance ) && commonStyles.displayNone
                !!this.state.isViewInsurance ? commonStyles.displayNone : null,
                !!this.state.isViewInsurance && !!isEditInsurance ? commonStyles.displayNone : null,
                !!this.state.sharedType && this.state.sharedType.value === "Yes" && !isEditInsurance ? commonStyles.displayNone : null,
                !!this.state.sharedType && this.state.sharedType.value === "No" && !!isEditInsurance ? commonStyles.displayNone : null
              ]}
            >
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  styles.inputFields,
                  styles.formFieldsHolder,
                ]}
              >
                <TextInputField
                  name="memberFirstName"
                  label={`${CommonString.MemberFirstNameLabel}* `}
                  //   required=CommonString.FirstNameRequired
                  readOnly={!!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? false : true : false}
                  validator="min0Max50"
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields, customCommonStyles.inputHeight, commonStyles.wordBreakWord, !!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? null : customCommonStyles.disabledInput : null]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.leftInputHolder,
                  styles.inputFields,
                  styles.formFieldsHolder,
                ]}
              >
                <TextInputField
                  name="memberLastName"
                  label={`${CommonString.MemberLastNameLabel}* `}
                  //   required=CommonString.LastNameRequired
                  validator="min0Max50"
                  readOnly={!!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? false : true : false}
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.formFields, customCommonStyles.inputHeight, !!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? null : customCommonStyles.disabledInput : null]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.helperTextFontStyle,
                    commonStyles.left0,
                    commonFontStyles.workSans,
                  ]}
                />
              </View>
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexWrap,
                styles.customFlexDirection,
                commonStyles.flexGrow0,
                // ((!this.state.isAddMember && !isEditMember) || isAddInsurance ) && commonStyles.displayNone
                // !!this.state.isViewInsurance || !!isEditInsurance ? commonStyles.displayNone : null,
                !!this.state.isViewInsurance ? commonStyles.displayNone : null,
                !!this.state.isViewInsurance && !!isEditInsurance ? commonStyles.displayNone : null,
                !!this.state.sharedType && this.state.sharedType.value === "Yes" && !isEditInsurance ? commonStyles.displayNone : null,
                !!this.state.sharedType && this.state.sharedType.value === "No" && !!isEditInsurance ? commonStyles.displayNone : null
              ]}
            >
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  styles.inputFields,
                  // styles.formFieldsHolder,
                ]}
              >
                {this.state.showDobDatePicker ? <ReactDatePicker
                  name={"dob"}
                  form="insuranceAdd"
                  value={this.props.app.ui.form("insuranceAdd").values.dob}
                  mode={"modal"}
                  onValueChange={this.onDateValueChange}
                  onBlur={this.onDateBlur}
                  visible={this.state.showDobDatePicker}
                  closeDatePicker={this.handleShowHideDatePicker}
                  isUberDocs={isUberDocs}
                /> : null}
                <TextInputField
                  name={"dob"}
                  label={`${CommonString.DOBLabel}* `}
                  secure={false}
                  readOnly={true}
                  onFocus={() => this.handleShowHideDatePicker('open')}
                  icon="calendar"
                  onIconPress={() => this.handleShowHideDatePicker()}
                  iconRight={this.props.app.ui.form("insuranceAdd") && this.props.app.ui.form("insuranceAdd").values.dob ? "close" : ""}
                  onIconRightPress={this.onClearDobDate}
                  style={[styles.formFields, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, commonStyles.marB0, !!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? null : customCommonStyles.disabledInput : null, commonStyles.marginLeft20]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.leftInputHolder,
                  styles.inputFields,

                ]}
              >
                <View style={[styles.leftInputField, commonStyles.flexDirectionColumn, styles.customMarTop30,]}>
                  <View style={[styles.accountTypeContainer, customCommonStyles.accountTypeContainerHeight]}>
                    <View style={[styles.accountTypeLabel, clientStyle.secondaryBgColor]}>
                      <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans]} >Gender*</Text>
                    </View>
                    {genderTypes && genderTypes.map((el, key) => (
                      <View style={[commonStyles.flex1, !!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? null : customCommonStyles.disabledInput : null]}>
                        <RadioButtons
                          radioButtonsClass={styles.radioButtonsOuter}
                          touchableHolder={[commonStyles.flexBasis50]}
                          key={"genderType_" + key}
                          options={el}
                          onValueChange={this.onGenderChange}
                          isSelected={this.state.selectedGender}
                        />
                      </View>
                    ))}
                  </View>
                </View>
                {this.state.isGenderError ? (
                  <View style={[styles.accountTypeErrorLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >Select Gender</Text>
                  </View>
                ) : null}
              </View>
            </View>
            <View
              style={[
                commonStyles.flex1,
                // commonStyles.flexWrap,
                styles.customFlexDirection,
                commonStyles.flexGrow0,
                ((!this.state.isAddMember && !isEditMember) || isAddInsurance) && commonStyles.displayNone
              ]}
            >
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  styles.inputFields,
                  //   isAlreadyPrimaryPolicyHolder && customCommonStyles.disabledInput
                  // styles.formFieldsHolder,
                ]}
              >
                <View style={[styles.leftInputField, commonStyles.flexDirectionColumn]}>
                  <View style={[styles.accountTypeContainer, customCommonStyles.accountTypeContainerHeight, isPrimaryInsured && customCommonStyles.disabledInput]}>
                    <View style={[styles.accountTypeLabel, clientStyle.secondaryBgColor]}>
                      <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans]} >Is Primary Policy Holder*</Text>
                    </View>
                    {primaryPolicyHolderTypes && primaryPolicyHolderTypes.map((el, key) => (
                      <View style={[commonStyles.flex1]}>
                        <RadioButtons
                          radioButtonsClass={styles.radioButtonsOuter}
                          touchableHolder={[]}
                          key={"isPrimaryPolicyHolder_" + key}
                          options={el}
                          onValueChange={(val) => this.onPrimaryPolicyChange(val)}
                          isSelected={this.state.selectedPrimaryPolicy}
                        />
                      </View>
                    ))}
                  </View>
                </View>
                {this.state.accountHolderError ? (
                  <View style={[styles.accountTypeErrorLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >Select Is Primary Policy Holder Type</Text>
                  </View>
                ) : null}
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.leftInputHolder,
                  styles.inputFields,
                  // styles.formFieldsHolder,
                ]}
              >
                <View style={[styles.leftInputField, commonStyles.flexDirectionColumn,
                this.state.selectedPrimaryPolicy === "yes" ? commonStyles.disabledInput : null
                ]}>
                  <View style={[styles.accountTypeContainer, customCommonStyles.accountTypeContainerHeight]}>
                    <View style={[styles.accountTypeLabel, clientStyle.secondaryBgColor]}>
                      <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans]} >Relation To Primary*</Text>
                    </View>
                    {relationInsuredTypes && relationInsuredTypes.map((el, key) => (
                      <View style={[commonStyles.flex1]}>
                        <RadioButtons
                          radioButtonsClass={styles.radioButtonsOuter}
                          touchableHolder={[commonStyles.flexBasis50, this.state.selectedPrimaryPolicy === "yes" ? commonStyles.disabledInput : null]}
                          key={"relationInsured_" + key}
                          options={el}
                          onValueChange={this.onRelationInsuredChange}
                          isSelected={this.state.selectedRelationInsured}
                        />
                      </View>
                    ))}
                  </View>
                </View>
                {this.state.relationInsuredError && this.state.selectedPrimaryPolicy === "no" ? (
                  <View style={[styles.accountTypeErrorLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >Select Relation to Primary</Text>
                  </View>
                ) : null}
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.customFlexDirection, (this.state.isAddMember || isEditMember) && commonStyles.displayNone]}>

              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.leftInputHolder,
                  styles.inputFields,
                  styles.customFlexDirection,
                  commonStyles.marRT0,
                ]}
              ></View>
            </View>
            <View
              style={[
                commonStyles.marTAuto,
                commonStyles.flex1,
                commonStyles.flexDirectionRow,
                styles.buttonWrapper,
              ]}
            >
              <View style={[commonStyles.marginRight10, styles.formBtnsHolder]}>
                <Button
                  key="cancel"
                  title={this.state.isViewInsurance || isAddInsurance ? "Cancel" : "Back"}
                  onPress={() => this.state.isViewInsurance || isAddInsurance ? this.onCancel() : this.onBack()}
                  style={[
                    commonStyles.blueBorderButton,
                    commonStyles.fullWidth,
                    styles.addBtn,
                    clientStyle.backAndCancelButton
                  ]}
                  titleTextStyle={[
                    commonStyles.blueBorderButtonColor,
                    commonStyles.fontWeight500,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    clientStyle.backAndCancelButtonText
                  ]}
                />
              </View>
              {this.state.isViewInsurance ? (
                <View style={[styles.formBtnsHolder]}>
                  <Button
                    key="edit"
                    title={this.state.isViewInsurance ? "EDIT" : isEditInsurance || isEditMember ? "UPDATE" : "ADD"}
                    onPress={() => this.state.isViewInsurance ? this.updateInsurance({ uid: this.state.insuranceUid }) : this.handleSubmit(this.props.app.ui.form("insuranceAdd"))}
                    style={[commonStyles.blueButton, styles.addBtn, customCommonStyles.noBoxShadow, clientStyle.submitButton]}
                    titleTextStyle={[
                      commonStyles.whiteColor,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      clientStyle.submitButtonText
                    ]}
                  />
                </View>
              ) : (
                <View style={[styles.formBtnsHolder, !!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? null : customCommonStyles.disabledBtn : null]}>
                  <SubmitButton
                    key="Add"
                    title={this.state.isViewInsurance ? "EDIT" : isEditInsurance || isEditMember ? 'UPDATE' : 'ADD'}
                    disabled={!!this.state.sharedType && this.state.sharedType.value === "Yes" ? this.state.isChooseInsurance ? false : true : false}
                    style={[commonStyles.blueButton, styles.addBtn, customCommonStyles.noBoxShadow, clientStyle.submitButton]}
                    titleTextStyle={[
                      commonStyles.whiteColor,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      clientStyle.submitButtonText
                    ]}
                  />
                </View>
              )}
            </View>
            <View style={[
              commonStyles.flex1,
              commonStyles.flexDirectionRow,
              commonStyles.justifyContentSpaceBetween,
              commonStyles.marginTop15,
              commonStyles.marginBottom15,
              (this.state.isAddMember || isEditMember || isAddInsurance || isEditInsurance || (!!this.state.sharedType && this.state.sharedType.value === "Yes")) && commonStyles.displayNone
            ]}>
              <View>
                <Text style={[
                  commonStyles.blueButtonColor,
                  commonStyles.fontWeight500,
                  commonFontStyles.workSans,
                  commonStyles.heading5,
                  commonStyles.darkGrayColor
                ]}>{'Members'}</Text>
              </View>
              <View>
                <Touchable
                  style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd, commonStyles.alignItemCenter]}
                  onPress={() => this.onAddMember()}
                >
                  <Image source={plusSolidIcon} style={commonStyles.icon20} />
                  <Text style={[
                    commonStyles.blueButtonColor,
                    commonStyles.fontWeight500,
                    commonFontStyles.workSans,
                    commonStyles.heading5,
                    commonStyles.darkGrayColor,
                    commonStyles.marginLeft8
                  ]}>Add Member</Text>
                </Touchable>
              </View>
            </View>
            {membersList.length > 0 && !this.state.isAddMember && !isEditMember && !isAddInsurance && !isEditInsurance && !(!!this.state.sharedType && this.state.sharedType.value === "Yes") ? (
              <View style={[styles.table, commonStyles.margin0, commonStyles.padding0, styles.borderWidth0]}>
                <View style={styles.theadOuter}>
                  <View
                    style={[
                      styles.thead,
                      commonStyles.flex1,
                      commonStyles.justifyContentSpaceBetween,
                      commonStyles.flexDirectionRow,
                    ]}
                  >
                    <View style={[styles.th, styles.incNameTh]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                        ]}
                      >
                        Member ID
                      </Text>
                    </View>
                    <View style={[styles.th, commonStyles.paddingRight16]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                        ]}
                      >
                        First Name
                      </Text>
                    </View>
                    <View style={styles.th}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                        ]}
                      >
                        Last Name
                      </Text>
                    </View>
                    <View style={[styles.th]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                        ]}
                      >
                        DOB
                      </Text>
                    </View>
                    <View style={[styles.th]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                        ]}
                      >
                        Gender
                      </Text>
                    </View>
                    <View style={[styles.th]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                        ]}
                      >
                        Primary Holder
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.th,
                        styles.action,
                        styles.thAction,
                        leftMenuToggle ? null : styles.thActionLeftMenuToggle,
                        // !!dashboarScroll ? styles.customMarginRight16 : null
                        // !!this.state.sharedType && this.state.sharedType.value === "Yes" ? styles.thActionWithEeyIcon : null
                      ]}
                    >
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                        ]}
                      >
                        Action
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            ) : null}
            <FlatList
              style={[
                styles.flatlistEle,
                membersList.length > 0
                  ? styles.memberListComponent
                  : styles.noItemsComponent,
                // this.props.app.dm.addInsuranceDomain.state.totalPages > 1 ? null : styles.insuranceListComponentWithNoPagi, styles.borderWidth0, commonStyles.marT0, commonStyles.marRT0, commonStyles.marLT0,  commonStyles.positionRelative, this.state.isAddMember || isEditMember || isAddInsurance || (!!this.state.sharedType && this.state.sharedType.value === "Yes") && commonStyles.displayNone,
                this.props.app.dm.addInsuranceDomain.state.totalPages > 1 ? null : styles.insuranceListComponentWithNoPagi, styles.borderWidth0, commonStyles.marT0, commonStyles.marRT0, commonStyles.marLT0, commonStyles.positionRelative, (!!this.state.sharedType && this.state.sharedType.value === "Yes") && commonStyles.displayNone,
                !!isEditInsurance && commonStyles.displayNone,
                !!isAddInsurance && commonStyles.displayNone,
                !!this.state.isAddMember && commonStyles.displayNone,
                !!isEditMember && commonStyles.displayNone,
                clientStyle.secondaryBgColor
              ]}
              data={membersList || []}
              // onLayout={(event) => this.getHeightOfElement(event)}
              ListEmptyComponent={
                <View style={[styles.padd10, commonStyles.flex1]}>
                  <NoRecordsFound
                    isUberDocs={isUberDocs}
                    noRecords={[commonStyles.marginTop15, styles.noIncRecords, commonStyles.marT0, commonStyles.marRT0, commonStyles.marLT0, clientStyle.primaryBgColor]}
                    message={CommonString.NoMemberFound}
                  />
                </View>
              }
              keyExtractor={(el, i) => i}
              renderItem={({ item, index }) => (
                <View style={styles.rowInner} key={"insuranceRow_" + index}>
                  <View
                    style={[
                      styles.tr,
                      commonStyles.flex1,
                      commonStyles.justifyContentSpaceBetween,
                      styles.customFlexDirection,
                      commonStyles.marLT0,
                      commonStyles.marRT0
                    ]}
                  >
                    <View style={[styles.td, styles.incNameTh, styles.paddingCustom60]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.heading6,
                          styles.customFontWeight600,
                          commonStyles.wordBreakWord
                        ]}
                      >
                        {item.memberId}
                      </Text>
                    </View>
                    <View style={[styles.td, commonStyles.paddingRight16]}>
                      <View
                        style={[
                          commonStyles.mView,
                          commonStyles.marginBottom5,
                          styles.mobileLabels,
                        ]}
                      >
                        <Text
                          style={[
                            commonFontStyles.workSans,
                            commonStyles.fontWeight700,
                            commonStyles.heading6,
                          ]}
                        >
                          First Name:
                        </Text>
                      </View>
                      <View style={[customCommonStyles.valueInMbileBreakWord]}>
                        <Text
                          style={[commonFontStyles.workSans, commonStyles.heading6]}
                        >
                          {item.firstName}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.td, styles.fullMaxWidthTd]}>
                      <View
                        style={[
                          commonStyles.mView,
                          commonStyles.marginBottom5,
                          styles.mobileLabels,
                        ]}
                      >
                        <Text
                          style={[
                            commonFontStyles.workSans,
                            commonStyles.fontWeight700,
                            commonStyles.heading6,
                          ]}
                        >
                          Last Name:
                        </Text>
                      </View>
                      <View style={[customCommonStyles.valueInMbileBreakWord, styles.fullMaxWidthTd]}>
                        <Text
                          style={[commonFontStyles.workSans, commonStyles.heading6]}
                        >
                          {item.lastName}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.td, styles.memberName]}>
                      <View
                        style={[
                          commonStyles.mView,
                          commonStyles.marginBottom5,
                          styles.mobileLabels,
                        ]}
                      >
                        <Text
                          style={[
                            commonFontStyles.workSans,
                            commonStyles.fontWeight700,
                            commonStyles.heading6,
                          ]}
                        >
                          DOB:
                        </Text>
                      </View>
                      <View style={[customCommonStyles.valueInMbileMemberName]}>
                        <Text
                          style={[commonFontStyles.workSans, commonStyles.heading6]}
                        >
                          {item.dob}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.td, styles.memberName]}>
                      <View
                        style={[
                          commonStyles.mView,
                          commonStyles.marginBottom5,
                          styles.mobileLabels,
                        ]}
                      >
                        <Text
                          style={[
                            commonFontStyles.workSans,
                            commonStyles.fontWeight700,
                            commonStyles.heading6,
                          ]}
                        >
                          Gender:
                        </Text>
                      </View>
                      <View style={[customCommonStyles.valueInMbileMemberName]}>
                        <Text
                          style={[commonFontStyles.workSans, commonStyles.heading6]}
                        >
                          {!!item.gender ? item.gender.substring(0, 1).toUpperCase() + item.gender.substring(1) : ""}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.td, styles.memberName]}>
                      <View
                        style={[
                          commonStyles.mView,
                          commonStyles.marginBottom5,
                          styles.mobileLabels,
                        ]}
                      >
                        <Text
                          style={[
                            commonFontStyles.workSans,
                            commonStyles.fontWeight700,
                            commonStyles.heading6,
                          ]}
                        >
                          Primary Holder:
                        </Text>
                      </View>
                      <View style={[customCommonStyles.valueInMbileMemberName]}>
                        <Text
                          style={[commonFontStyles.workSans, commonStyles.heading6]}
                        >
                          {!!item.isPrimaryPolicyHolder ? item.isPrimaryPolicyHolder.substring(0, 1).toUpperCase() + item.isPrimaryPolicyHolder.substring(1) : ""}
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.td, styles.action, customCommonStyles.customActionColumn,]}>
                      <View
                        style={[
                          commonStyles.flex1,
                          commonStyles.alignItemsCenter,
                          commonStyles.flexDirectionRow
                        ]}
                      >
                        <View style={styles.marginRight10}>
                          <Touchable onPress={async () => this.onUpdateMember(item)}
                          >
                            <View style={[styles.editAction, customCommonStyles.customActionBtn]}>
                              <Image
                                source={editIcon}
                                style={commonStyles.icon18}
                              />
                            </View>
                          </Touchable>
                        </View>

                        <View style={styles.marginLeft10}>
                          <Touchable
                            onPress={async () => this.openPopScreen(item.uid)}
                          >
                            <View style={customCommonStyles.customActionBtn}>
                              <Image
                                source={deleteIcon}
                                style={commonStyles.icon20}
                              />
                            </View>
                          </Touchable>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              )}
            />
            <PopupModalComponent
              visibility={!!isImageConfirmationModal}
              bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
              messageType={MESSAGE_TYPE.info}
              headingIcon={true}
              heading={"Confirm Uploaded Card"}
              modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
              bodyContent={"Please confirm if the card images are correctly uploaded."}
              okLabel={"Yes"}
              handleOk={() => this.imageConfirm()}
              closeLabel={"No"}
              handleClose={() => this.imageNotConfirm()}
            />
          </Form>
          <ModalPopUp
            visibility={isViewFileModal}
            isFooter={false}
            bodyWrapper={[commonStyles.exLargModalBodyWrapper, clientStyle.secondaryBgColor]}
            heading={"View Image"}
            handleCancel={this.onViewFileModalClose}
            // closeButton={[commonStyles.displayNone]}
            modalContent={styles.modalContent}
          >
            <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
              <View style={[styles.attachments]} >
                <Image
                  source={{ uri: cardContent }}
                  style={styles.attachmedImage}
                />
              </View>
            </View>
          </ModalPopUp>
        </View>
      );
    }
    renderInsuranceListing(clientStyle, commonFontStyles) {
      const { actionMode } = this.state;
      const { insuranceItems, currentPage, resultsPerPage, totalBills } = this.props.app.dm.addInsuranceDomain.state;
      let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
      let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
      let rowItems = endIndex - startIndex + 1;
      const { leftMenuToggle } = this.props.app.dm.userSession.state;
      let label = "Insurance Directory"
      return (
        <View style={actionMode === "Listing" ? commonStyles.flex1 : commonStyles.displayNone}>
          <View style={[styles.newFormWrap]}>
            <Form
              name="frmInsFilter"
              validateBeforeSubmit={false}
              style={[styles.optFormStyles]}>
              <View style={[styles.statusFieldOuter]}>
                <View style={[commonStyles.flexBasis50, commonStyles.marginRight8, styles.statusFormElementOuter, styles.statusSelectMenuOuter]}>
                  <SelectMenuField
                    name="ddlSharedType"
                    label={`${label}*`}
                    required={`${label}`}
                    // value={this.state.sharedType}
                    style={[styles.statusField, styles.statusFormElementSelectMenu]}
                    items={[{ value: "Yes", label: "Search Insurance" }, { value: "No", label: "Selected Insurance" }]}
                    onValueChange={(val) => this.onSearchSharedInsurance(val, "")}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle,]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
                <View style={[commonStyles.flexBasis50, commonStyles.marginLeft8, styles.statusFormElementOuter]}>
                  <TextInputField
                    name="searchInsName"
                    label={CommonString.SearchInsuranceLabel}
                    required={CommonString.EnterSearchTextRequired}
                    onChangeText={(val) => this.onChangeSearchText(val)}
                    style={[commonStyles.heading6, commonFontStyles.workSans, styles.formFields, customCommonStyles.inputHeight, styles.statusFormElementInput]}
                    labelTextStyle={[
                      commonStyles.labelText,
                      commonFontStyles.workSans,
                      commonStyles.heading6,
                    ]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    validator="min2Max50"
                    helperTextStyle={[
                      commonStyles.helperTextFontStyle,
                      commonStyles.left0,
                      commonFontStyles.workSans,
                    ]} />
                </View>
              </View>
              <View style={[styles.btnWrapper, commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, commonStyles.marT0, styles.frmInsFilterBtnWrapper]}>
                <Button
                  title={"Reset"}
                  style={[commonStyles.blueBorderButton, commonStyles.marginRight15, styles.buttons, styles.frmInsFilterBtn]}
                  onPress={async () => this.ResetFilterForm()}
                  titleTextStyle={[commonStyles.blueBorderButtonColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                />
                <Button
                  title={"Search"}
                  type="submit"
                  onPress={this.filterInsuranceDetail}
                  style={[commonStyles.blueButton, styles.buttons, customCommonStyles.noBoxShadow, styles.frmInsFilterBtn]}
                  titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                />
              </View>
            </Form>
          </View>
          {insuranceItems.length > 0 ? (
            <View style={styles.table}>
              <View style={styles.theadOuter}>
                <View
                  style={[
                    styles.thead,
                    commonStyles.flex1,
                    commonStyles.justifyContentSpaceBetween,
                    commonStyles.flexDirectionRow,
                  ]}
                >
                  <View style={[styles.th, styles.incNameTh]}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                      ]}
                    >
                      Insurance Name
                    </Text>
                  </View>
                  <View style={styles.th}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                      ]}
                    >
                      PO Box#/Address
                    </Text>
                  </View>
                  <View style={styles.th}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                      ]}
                    >
                      City, State, Zip
                    </Text>
                  </View>
                  {/* <View style={[styles.th]}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                      ]}
                    >
                      Zip Code
                    </Text>
                  </View> */}
                  <View
                    style={[
                      styles.th,
                      styles.action,
                      styles.thAction,
                      leftMenuToggle ? null : styles.thActionLeftMenuToggle,
                      // !!dashboarScroll ? styles.customMarginRight16 : null
                      !!this.state.sharedType && this.state.sharedType.value === "Yes" ? styles.thActionWithEeyIcon : null
                    ]}
                  >
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                      ]}
                    >
                      Action
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          ) : null}
          <View style={[insuranceItems.length > 0
                ? styles.insuranceListComponent
                : styles.noItemsComponent]}>
          <FlatList
            style={[
              styles.flatlistEle, 
              this.props.app.dm.addInsuranceDomain.state.totalPages > 1 ? null : styles.insuranceListComponentWithNoPagi,
            ]}
            data={insuranceItems}
            // onLayout={(event) => this.getHeightOfElement(event)}
            ListEmptyComponent={
              <View style={[styles.padd10, commonStyles.flex1]}>
                <NoRecordsFound
                  noRecords={[commonStyles.marginTop15, styles.noIncRecords]}
                  message={CommonString.NoInsuranceCompanyFound}
                />
              </View>
            }
            keyExtractor={(el, i) => i}
            renderItem={({ item, index }) => (
              <View style={styles.rowInner} key={"insuranceRow_" + index}>
                <View
                  style={[
                    styles.tr,
                    commonStyles.flex1,
                    commonStyles.justifyContentSpaceBetween,
                    styles.customFlexDirection,
                  ]}
                >
                  <View style={[styles.td, styles.incNameTh, styles.paddingCustom60]}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        styles.customFontWeight600,
                        commonStyles.wordBreakWord
                      ]}
                    >
                      {item.name}
                    </Text>
                  </View>
                  <View style={[styles.td]}>
                    <View
                      style={[
                        commonStyles.mView,
                        commonStyles.marginBottom5,
                        styles.mobileLabels,
                      ]}
                    >
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight700,
                          commonStyles.heading6,
                        ]}
                      >
                        PO Box#/Address:
                      </Text>
                    </View>
                    <View style={[customCommonStyles.valueInMbileBreakWord]}>
                      <Text
                        style={[commonFontStyles.workSans, commonStyles.heading6]}
                      >
                        {
                          !!item.poBoxNo && !!item.address1 ? `${item.poBoxNo} ${item.address1}` :
                            !!item.poBoxNo ? item.poBoxNo :
                              !!item.address1 ? item.address1 :
                                ""
                        }
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.td, styles.fullMaxWidthTd]}>
                    <View
                      style={[
                        commonStyles.mView,
                        commonStyles.marginBottom5,
                        styles.mobileLabels,
                      ]}
                    >
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight700,
                          commonStyles.heading6,
                        ]}
                      >
                        City, State, Zip:
                      </Text>
                    </View>
                    <View style={[customCommonStyles.valueInMbileBreakWord, styles.fullMaxWidthTd]}>
                      <Text
                        style={[commonFontStyles.workSans, commonStyles.heading6]}
                      >
                        {item.city}{!!item.state ? `, ${item.state.split('-').pop()}` : ""}{!!item.zipCode ? `, ${item.zipCode}` : ""}
                      </Text>
                    </View>
                  </View>
                  {/* <View style={[styles.td, styles.memberName]}>
                    <View
                      style={[
                        commonStyles.mView,
                        commonStyles.marginBottom5,
                        styles.mobileLabels,
                      ]}
                    >
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight700,
                          commonStyles.heading6,
                        ]}
                      >
                        Zip Code:
                      </Text>
                    </View>
                    <View style={[customCommonStyles.valueInMbileMemberName]}>
                      <Text
                        style={[commonFontStyles.workSans, commonStyles.heading6]}
                      >
                        {item.zipCode}
                      </Text>
                    </View>
                  </View> */}

                  <View style={[styles.td, !!this.state.sharedType && this.state.sharedType.value === "Yes" ? styles.tdActionWithEeyIcon : null]}>
                    {!!this.state.sharedType && this.state.sharedType.value === "Yes" && !!item.isParticipant ?
                      <View style={[
                        commonStyles.flex1,
                        commonStyles.alignItemsCenter,
                        commonStyles.flexDirectionRow,
                        commonStyles.justifyContentFlexEnd
                      ]}> <Touchable onPress={async () => this.updateInsurance(item)}>
                          <View style={[styles.editAction, customCommonStyles.customActionBtn]}>
                            <Image source={eye} style={commonStyles.icon18} />
                          </View>
                        </Touchable>
                      </View> :
                      <View style={[
                        commonStyles.flex1,
                        commonStyles.alignItemsCenter,
                        commonStyles.flexDirectionRow,
                        commonStyles.justifyContentFlexEnd
                      ]}>
                        <Touchable tooltip= "Update" onPress={async () => this.updateInsurance(item)}>
                          <View style={[styles.editAction, customCommonStyles.customActionBtn]}>
                            <Image source={editIcon} style={commonStyles.icon18} />
                          </View>
                        </Touchable>
                        <Touchable tooltip= "Delete" onPress={async () => this.openPopScreen(item.uid)}>
                          <View style={[customCommonStyles.customActionBtn]}>
                            <Image source={deleteIcon} style={commonStyles.icon18}
                            />
                          </View>
                        </Touchable>
                        <Touchable tooltip= "Eligibility Inquiry" onPress={async () => this.navigateToEligibility(item.uid, "EligibilityCheck")}>
                          <View style={[customCommonStyles.customActionBtn]}>
                            <Image source={inquiryIcon} style={commonStyles.icon18} />
                          </View>
                        </Touchable>
                        <Touchable tooltip= "Eligibility Search" onPress={async () => this.navigateToEligibility(item.uid, "EligibilitySearch")}>
                          <View style={[customCommonStyles.customActionBtn]}>
                            <Image source={searchIcon} style={commonStyles.icon18} />
                          </View>
                        </Touchable>
                      </View>
                    }
                  </View>
                </View>
              </View>
            )}
          />
          {this.props.app.dm.addInsuranceDomain.state.totalPages > 1 ?
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.paginationOuterWrap, commonStyles.paginationWrap, commonStyles.flexGrow0, customCommonStyles.customPaginationWrap, styles.customMargin]}>
              <View style={[commonStyles.rowPerPage]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}>Rows per page: {rowItems}</Text>
              </View>
              <View style={[commonStyles.displayingItem]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}>Displaying item {startIndex}-{endIndex} of {totalBills}</Text>
              </View>
              <View style={[commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, commonStyles.paginationInnerWrap, commonStyles.flexGrow0]}>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.addInsuranceDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.addInsuranceDomain.state.currentPage === 1 ? null : this.getFirst()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.addInsuranceDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowFirst} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.addInsuranceDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.addInsuranceDomain.state.currentPage === 1 ? null : this.getPrevious()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.addInsuranceDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowLeft} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.addInsuranceDomain.state.currentPage === this.props.app.dm.addInsuranceDomain.state.totalPages ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.addInsuranceDomain.state.currentPage === this.props.app.dm.addInsuranceDomain.state.totalPages ? null : this.getNext()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.addInsuranceDomain.state.currentPage === this.props.app.dm.addInsuranceDomain.state.totalPages ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowRight} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.addInsuranceDomain.state.currentPage === this.props.app.dm.addInsuranceDomain.state.totalPages ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.addInsuranceDomain.state.currentPage === this.props.app.dm.addInsuranceDomain.state.totalPages ? null : this.getLast()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.addInsuranceDomain.state.currentPage === this.props.app.dm.addInsuranceDomain.state.totalPages ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowLast} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
            : null}
            </View>
          <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} clientStyle={clientStyle} />
        </View>
      );
    }

    backToInsuranceListing = () => {
      const { defaultMode } = this.props;
      if (defaultMode === "add") this.props.app.nav.to("insuranceListRoute");
      else this.setState({ actionMode: "Listing" });
    };

    render() {
      this.enlistedCountryCode = [
        {
          label: "+1",
          value: "+1",
        },
      ];
      const { actionMode, selectedInsurance, isAddressUpdation, addressConfirmationMessage, paymentConfirmation, inquiryAmount, currency } = this.state;
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <>
          <View
            style={[
              styles.pageTitleHolder,
              commonStyles.flex1,
              commonStyles.alignItemsCenter,
              commonStyles.justifyContentSpaceBetween,
              commonStyles.flexDirectionRow,
            ]}
          >
            {this.props.r14.navigation.currRoute._config.label ?
              (<View>
                {actionMode === "Listing" ? (<Text
                  style={[
                    commonStyles.heading3,
                    commonFontStyles.workSans,
                    commonStyles.fontWeight500,
                  ]}
                >
                  {this.props.r14.navigation.currRoute._config.label}
                </Text>) : actionMode === "Update" ? (<Text
                  style={[
                    commonStyles.heading3,
                    commonFontStyles.workSans,
                    commonStyles.fontWeight500,
                  ]}
                >
                  {!!selectedInsurance && !!selectedInsurance.isParticipant ? "Insurance Co. (Shared)" : "Edit Insurance Co."}
                </Text>) :
                  (<Text
                    style={[
                      commonStyles.heading3,
                      commonFontStyles.workSans,
                      commonStyles.fontWeight500,
                    ]}
                  >
                    Add Insurance Co.
                  </Text>)}
              </View>)
              : (
                <View style={styles.emptyElement}></View>
              )
            }
            {actionMode === "Listing" ? (
              <View style={commonStyles.marLAuto}>
                <Touchable
                  onPress={() => this.addInsurance()}
                  style={[commonStyles.primaryButton, commonStyles.btnHeight24, commonStyles.paddingBottom0, styles.paddingTop2]}
                >
                  <View
                    style={[
                      commonStyles.flex1,
                      commonStyles.flexDirectionRow,
                      commonStyles.alignItemsCenter,
                      commonStyles.flexGrow0,
                      commonStyles.justifyContentFlexEnd,
                      styles.height15,
                    ]}
                  >
                    <View>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight400,
                          commonStyles.exSmallHeading,
                          styles.paddingLeftRight10,
                          commonStyles.whiteColor
                        ]}
                      >
                        Add Insurance
                      </Text>
                    </View>
                  </View>
                </Touchable>
              </View>
            ) : null}
          </View>
          {this.renderInsuranceListing(clientStyle, commonFontStyles)}
          {this.renderInsuranceForm(clientStyle, commonFontStyles, isUberDocs)}
          {this.renderAlertPopup(clientStyle, commonFontStyles)}
          <PopupModalComponent
            visibility={!!this.state.isConfirmationVisible || !!isAddressUpdation || !!paymentConfirmation}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
            messageType={!!paymentConfirmation ? MESSAGE_TYPE.info : !!isAddressUpdation ? MESSAGE_TYPE.info : !this.state.isEditMember && !this.state.isAddMember ? MESSAGE_TYPE.delete : MESSAGE_TYPE.info}
            headingIcon={true}
            heading={!!paymentConfirmation ? "Payment Confirmation" :
              this.state.isViewInsurance ? "Delete Member"
                : !!this.state.isEditMember || !!this.state.isAddMember ? "Primary Member"
                  : isAddressUpdation === "updateAddress" ? "Update Suggested Address"
                    : !!isAddressUpdation ? "Update Address"
                      : "Delete Insurance"
            }
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={!!this.state.isEditMember || !!this.state.isAddMember ? "This member will be considered as Primary Member. Do you want to proceed?" : !!isAddressUpdation ? addressConfirmationMessage : "Are you sure you want to delete ?"}
            childContent ={!!paymentConfirmation ?
              <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, commonStyles.textAlignCenter]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>
                  Are you sure you want to submit the inquiry?
                </Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>
                  You will be charged {CURRENCY[currency]}{inquiryAmount}.
                </Text>
              </View> : null}
            okLabel={!!paymentConfirmation ? "Yes" : (isAddressUpdation === "alertMessage") ? "Ok" : "No"}
            handleOk={() => !!paymentConfirmation ?  this.paymentConfirmation() :  !!isAddressUpdation ? this.onUpdateAddress(false) : this.closePopScreen()}
            closeLabel={!!paymentConfirmation ? "No": "Yes"}
            handleClose={ isAddressUpdation === "alertMessage" ? null : () => !!paymentConfirmation ? this.cancelPaymentConfirmation() : this.state.isViewInsurance ? this.deleteMember() : !!this.state.isEditMember || !!this.state.isAddMember ? this.handleAddMemberSubmit(this.props.app.ui.form("insuranceAdd")) : !!isAddressUpdation ? this.onUpdateAddress(true) : this.deleteInsurance()}
          />
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  table: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    backgroundColor: "#fff",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    borderBottomWidth: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 2,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  }, 
  pageTitleHolder: {
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    maxHeight: 50,
    minHeight: 50
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customFlexGrow: {
    flexGrow: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexGrow: 0,
        };
      }
    },
  },
  buttonWrapper: {
    justifyContent: "flex-end",
    marginTop: -10,
    marginRight: -16,
    marginBottom: 0,
    marginLeft: -16,
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          justifyContent: "space-between",
          marginTop: 0,
          marginBottom: 10,
        };
      }
    },
  },
  addBtn: {
    minWidth: 108,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          width: "100%",
        };
      }
    },
  },
  rightInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          flexBasis: "100%",
        };
      }
    },
  },
  leftInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginLeft: 0,
        };
      }
    },
  },
  updatebtnHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 16,
        };
      }
    },
  },
  thead: {
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    zIndex: 999,
    backgroundColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    flexDirection: "row",
    height: 25,
    alignItems: 'center',
  },
  theadOuter: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  tr: {
    flexDirection: "row",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    minHeight: 30,
    height: "auto",
    alignItems: 'center',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 10,
          paddingRight: 16,
          paddingBottom: 10,
          paddingLeft: 16,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          alignItems: 'flex-start',
          flexDirection: "column",
        };
    },
  },
  th: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
  },
  incNameTh: {
    flex: 1,
    flexBasis: "25.5%",
    maxWidth: "25.5%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "75%",
          flexDirection: "row",
        };
    },
  },
  paddingCustom60: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
          maxWidth: "100%",
          paddingRight: 60,
        };
    },
  },
  td: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "100%",
          width: "100%",
          flexDirection: "row",
        };
    },
  },
  thAction: {
    maxWidth: "10%",
    flexBasis: "10%",
    paddingLeft: 8,
    minWidth: 103,
  },
  thActionWithEeyIcon: {
    maxWidth: "8%",
    flexBasis: "8%",
    paddingLeft: 8,
    minWidth: 75,
  },
  tdActionWithEeyIcon: {
    maxWidth: "7%",
    flexBasis: "7%",
    paddingLeft: 8,
    minWidth: 72,
  },
  thActionLeftMenuToggle: {
    minWidth: 113,
  },
  action: {
    maxWidth: "10%",
    flexBasis: "10%",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          minWidth: 100,
        };
      }
      if (width <= 991) {
        return {
          position: "absolute",
          zIndex: 999999,
          top: 10,
          justifyContent: "space-between",
        };
      }
    },
  },
  plusIcon: {},
  noItemsComponent: {
    marginTop: 0,
    marginBottom: 50,
    marginLeft: 16,
    marginRight: 16,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    position: 'relative',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginLeft: 0,
          marginTop: 145,
          marginBottom: 0,
          marginRight: 0,
        };
    },
  },
  insuranceListComponentWithNoPagi: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 75,
        };
    },
  },
  insuranceListComponent: {
    marginTop: 0,
    marginRight: 16,
    // marginBottom: 70,
    marginLeft: 16,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTop: 0,
    position: 'relative',
    height: "100%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderTopWidth: 1,
          marginRight: 0,
          marginLeft: 0,
          paddingBottom: 0,
        };
    },
  },
  loaderimg: {
    width: 300,
    height: 190,
    display: "block",
    backgroundSize: "contain",
  },
  addInsuranceWrap: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    paddingTop: 16,
    paddingBottom: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    backgroundColor: "#fff",
    display: "block !important",
    // overflowY: "scroll",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          //   flex: 1,
          display: "block !important",
        };
      }
      if (width <= 991) {
        return {
          paddingTop: 16,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
      }
    },
  },
  profileEditForm: {
    flexGrow: 0,
    minHeight: 543,
    paddingTop: 0,
    display: "block !important",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  btnWrapper: {
    flex: 1,
    justifyContent: "center",
    marginTop: 24,
    // marginBottom: 15,
  },
  bodyWrapper: {
    width: 300,
    borderRadius: 4,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 600,
        };
    },
  },
  viewFilebodyWrapper: {
    width: "98vw",
    borderRadius: 6,
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: 300,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
        };
      }
    },
  },
  noButton: {
    minWidth: 79,
  },
  customPaddingRight16: {
    paddingRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingRight: 0,
        };
      }
    },
  },
  customMarginRight16: {
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  mobileLabels: {
    marginRight: 10,
    minWidth: 155,
  },
  memberName: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          flexBasis: "100%",
          maxWidth: "100%",
        };
      }
    },
  },
  memberSection: {
    marginTop: 0,
    marginRight: -16,
    marginBottom: 20,
    marginLeft: -16,
    backgroundColor: "#f4f4f4",
    paddingTop: 8,
    paddingBottom: 8,
  },
  memberSectionlabel: {
    paddingTop: 5,
    paddingRight: 16,
    paddingBottom: 5,
    paddingLeft: 16,
  },
  isPrimaryText: {
    marginLeft: -7,
  },
  cameraOpt: {
    height: 30,
  },
  attachmentsHolder: {
    maxHeight: 120,
    overflowY: "auto",
    // marginBottom: -30,
    flexBasis: null,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  uploadCard: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#a1a4a8",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    paddingTop: 5,
    paddingBottom: 5,
    flexBasis: "15%",
    minHeight: 30,
    maxHeight: 30,
  },
  uploadCardRight: {
    marginLeft: 8,
  },
  uploadCardLeft: {
    marginRight: 8
  },
  fileUploadWrapper: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#a1a4a8",
    borderRadius: 5,
    flexDirection: "column",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "46%",
          marginLeft: 0,
          width: "100%",
        };
      }
    },
  },
  attachmentsWrapper: {
    justifyContent: "space-between",
    // marginBottom: 10,
  },
  attachments: {
    flexBasis: "49%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
        };
      }
    },
  },
  buttons: {
    height: 24,
  },
  attachmentFieldOuterCustomHeight: {
    position: "relative",
    height: 30,
    minHeight: 30,
  },
  primaryInsuranceText: {
    marginLeft: -7,
    minWidth: 150,
  },
  isPrimaryCheckBox: {
    marginLeft: -6,
  },
  uploadCardWrap: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 10,
        };
      }
    },
  },
  inputFields: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
        };
      }
    },
  },
  formBtnsHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  editAction: {
    // screen: ({ width }) => {
    //   if (width <= 991) {
    //     return {
    //       marginRight: 16,
    //     };
    //   }
    // },
  },
  emptyElement: {
    minWidth: 10,
    flex: 1,
  },
  marginLeft10: {
    marginLeft: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customAlignItemsCenter: {
    alignItems: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: "flex-start",
        };
      }
    },
  },
  primaryInsuranceTopPadding: {
    paddingTop: 4,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 3,
        };
      }
    },
  },
  downloadDeleteAction: {
    minWidth: 102,
    marginLeft: 16,
  },
  filenameSection: {
    maxWidth: "60%",
    wordBreak: "breal-all",
    // overflowY: "scroll"
  },
  filename: {
    wordBreak: "break-all",
  },
  iconAction: {
    minWidth: 34,
    minHeight: 34,
    flex: 1,
    flexGrow: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  customFontWeight600: {
    fontWeight: 400,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontWeight: "bold",
        };
      }
    },
  },
  noIncRecords: {
    // marginBottom: 350,
  },
  copyRightWrap: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  paginationWrap: {
    marginTop: "auto",
    marginRight: 50,
    marginBottom: 30,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 17,
          // paddingTop: 20,
          marginBottom: 70,
        }
      }
    }
  },
  paginationBtn: {
    borderWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  paginationBtnTextSize: {
    fontSize: 14,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontSize: 12,
        }
      }
    },
  },
  attachmedImage: {
    width: "90vw",
    height: "80vh",
    flex: 1,
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  borderBottom1: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    marginBottom: 16,
    minHeight: 40,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 10
        }
      }
    }
  },
  formFields: {
    marginBottom: 0,
  },
  formFieldsHolder: {
    marginBottom: 36,
  },
  imageConfirmationModal: {
    width: "100%",
    textAlign: "center",
  },
  areYou: {
    // marginTop: -7,
  },
  primaryIncflexBasis48: {
    flexBasis: "20%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
        }
      }
    },
  },
  isPrimaryandUpload: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        }
      }
    },
  },
  paddingLeftRight10: {
    paddingLeft: 10,
    paddingRight: 10,
  },

  isParticipant: {
    opacity: 0.9,
    pointerEvents: "none",
    cursor: "default",
  },
  flatlistEle: {
    position:"relative",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "block",
        }
      }
    },
  },
  optFormStyles: {
    paddingBottom: 0,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flexGrow: 0
        }
      }
      if (width <= 991) {
        return {
          flexDirection: "column",
          paddingRight: 0,
          paddingLeft: 0,
        }
      }
    },
  },
  statusFieldOuter: {
    flex: 1,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        }
      }
    },
  },
  newFormWrap: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginLeft: 16,
    paddingBottom: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          marginLeft: 0,
          paddingRight: 16,
          paddingLeft: 16,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "#ccc",
        }
      }
    },
  },
  frmInsFilterBtn: {
    minWidth: 100,
    height: 30
  },
  statusFormElementOuter: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
        }
      }
    },
  },
  statusFormElementSelectMenu: {
    marginBottom: 0,
  },
  statusFormElementInput: {
    height: 44,
  },
  statusSelectMenuOuter: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 16,
        }
      }
    },
  },
  fullMaxWidthTd: {
    wordBreak: "break-word",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
        }
      }
    },
  },
  frmInsFilterBtnWrapper: {
    marginLeft: 16,
    paddingTop: 5,
    flexGrow: 0,
  },
  memberListComponent: {
    marginTop: 0,
    marginRight: -16,
    marginBottom: 70,
    marginLeft: -16,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    position: 'relative',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderTopWidth: 1,
          marginTop: 150,
          marginRight: 0,
          marginBottom: 100,
          marginLeft: 0,
          paddingBottom: 0,
        };
    },
  },
  borderWidth0: {
    borderWidth: 0
  },
  leftInputField: {
    maxWidth: "100%",
    // marginRight: 20,
    maxHeight: 60,
    minHeight: 60,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // maxWidth: "100%",
          // flexBasis: "100%",
          marginRight: 0,
          marginBottom: 0,
        };
    },
  },
  accountTypeContainer: {
    flex: 1,
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'rgba(21, 27, 38, 0.4)',
    borderStyle: 'solid',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 16,
    paddingRight: 16,
    position: 'relative',
    maxHeight: 36,
    minHeight: 30,
  },
  accountTypeLabel: {
    position: 'absolute',
    top: -11,
    left: 8,
    backgroundColor: 'white',
    paddingLeft: 5,
    paddingRight: 5
  },
  radioButtonsOuter: {
    marginRight: 5
  },
  accountTypeErrorLabel: {
    marginTop: -25,
    marginBottom: 8
  },
  left0: {
    left: 0
  },
  customMarTop30: {
    marginTop: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 30,
        };
    },
  }, 
  paddingTop2: {
    paddingTop: 2
  },
  customtoolTipMessage: {
    top: 10,
    marginLeft: 0
  },
  height15: {
    height: 15
  },
  customMargin:{
    marginLeft:16,
    marginRight:16,
    marginTop:10,
    borderWidth: 0
  } 
});
