import React from "react";
import {
  View,
  StyleSheet,
  Button,
} from "../../core";
import ReactDatePickerField from "react-datepicker";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import "react-datepicker/dist/react-datepicker.css";

export default class ReactDatePicker extends React.Component {
    render() {
        const {isUberDocs, value, onValueChange, form, name, onBlur, closeDatePicker } = this.props;
        let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
        let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

        return <>
          <ReactDatePickerField
            selected={!!value ? new Date(value) : new Date()}
            onChange={onValueChange}
            showMonthDropdown
            isClearable
            showIcon
            inline
            form={form}
            name={name}
            showYearDropdown
            dropdownMode="select"
            onBlur={() => onBlur()}
            closeOnScroll={true}
          >
          <View>
            <Button
                onPress={() => closeDatePicker ? closeDatePicker("close") : null}
                title={'Close'}
                style={[styles.closeBtn, commonStyles.blueButton, commonStyles.flexBasis48, customCommonStyles.noBoxShadow, styles.buttons, clientStyle.submitButton]}
                titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6, clientStyle.submitButtonText]}
            />
            </View>
          </ReactDatePickerField>
        </>
    }
}

const styles = StyleSheet.create({
  closeBtn: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  buttons: {
    width: 100,
    minWidth: 100,
  }
});

