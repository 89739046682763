import React, { PureComponent } from "react";
import R14, { StyleSheet, Button, View, Text, Form, SelectMenuField, TextInputField, Platform, ScrollView } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import ModalPopUp from "./ModalPopUp";
import RadioButtons from "./RadioButtons"
import StatusComponent from "./StatusComponent";
import ToggleSwitch from "./ToggleSwitch";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import DatePicker from "./PCH/DatePicker";
import ReactDatePicker from "./PCH/ReactDatePicker";
import DateUtils from "../utils/DateUtils";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";

export default R14.connect(
  class FilterComponent extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        showFromDatePicker: false,
        showToDatePicker: false,
      };
    }
  onDateChange = (selectedChk) => {
    this.props.onCheckboxChange(selectedChk);
  };

  onDateChkChange = (selectedDateChk) => {
    this.props.onDateChkboxChange(selectedDateChk);
  };

  onSwitch = (sortOrder) => {
    this.props.onSwitchChange(sortOrder);
  };

  onStatus = async (ind) => {
    const { statusType } = this.props.app.dm.filterDomain.state;
    statusType[ind].isSelected = !statusType[ind].isSelected;
    this.props.app.dm.filterDomain.setState({ statusType });
    return false;
  };

  onFilterCall = () => {
    const form = this.props.app.ui.form("sortFilter");
    form.elmts.fromDate.setError(null);
    form.elmts.toDate.setError(null);
    if (!!form.values.fromDate) {
      let date = DateUtils.formatDateInMMDDYYYY(form.values.fromDate);
      form.elmts.fromDate.setValue(date);
      this.props.app.dm.filterDomain.setState({ fromDate: date });
    } else {
      form.elmts.fromDate.setValue("");
      this.props.app.dm.filterDomain.setState({ fromDate: "" });
    }

    if (!!form.values.toDate) {
      let date = DateUtils.formatDateInMMDDYYYY(form.values.toDate);
      form.elmts.toDate.setValue(date);
      this.props.app.dm.filterDomain.setState({ toDate: date });
    } else {
      form.elmts.toDate.setValue("");
      this.props.app.dm.filterDomain.setState({ toDate: "" });
    }
    this.setState({
      showFromDatePicker: false,
      showToDatePicker: false,
    })
    analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.APPLIED_FILTER_ACTION, this.props.app);

    this.props.onFilter();
  };

  onBillerName = async (bu) => {
    let docForm = this.props.app.ui.form("sortFilter");
    if(!bu || bu.label === "No Results Found" || bu.label === "Type in a search value..." ) {
      docForm.elmts.billerName.setValue(null);
    } else {
      docForm.elmts.billerName.setValue(bu);
      this.props.app.dm.filterDomain.setState({ selectedBU: bu });
    }
  };

  handleFromShowHideDatePicker = (val) => {
    if(val === "open" && this.state.showFromDatePicker) {
      return;
    } else if (val === "close") {
      this.setState({showFromDatePicker: false});
      return;
    } else {
      this.setState({showFromDatePicker: !this.state.showFromDatePicker})
    }
  };

  onClearFromDate = () => {
    const form = this.props.app.ui.form("sortFilter");
    form.elmts.fromDate.setValue(null);
    form.elmts.fromDate.setError(null);
    this.props.app.dm.filterDomain.setState({ fromDate: null })
    this.setState({
      showFromDatePicker: false,
    })
  }

  onFromDateChange = async (val, autoformat = true) => {
    const form = this.props.app.ui.form("sortFilter");
    this.handleFromShowHideDatePicker();
    form.elmts.fromDate.setError(null);
    if (!!val) {
      if (!!autoformat) {
        let date = DateUtils.formatDateInMMDDYYYY(val);
        if (date){
          form.elmts.fromDate.setValue(date);
          form.elmts.fromDate.setError("");
          this.props.app.dm.filterDomain.setState({ fromDate: date });
        } else form.elmts.fromDate.setValue(null);
      } else {
        if (val) {
          form.elmts.fromDate.setValue(val);
          form.elmts.fromDate.setError("");
          this.props.app.dm.filterDomain.setState({ fromDate: val });
        } else form.elmts.fromDate.setValue(null);
      }
    }
  };
    
  onFromDateBlur = async () => {
    const form = this.props.app.ui.form("sortFilter");
    const { fromDate } = form.values;
    this.handleFromShowHideDatePicker();
    if (!!fromDate) {
      let dat = await this.validateDate(fromDate);
      if (!!dat) {
        let formatedDate = DateUtils.formatDateInMMDDYYYY(fromDate);
        dat = await this.validateDate(formatedDate);
        if (!!dat) {
          form.elmts.fromDate.setValue(formatedDate);
          form.elmts.fromDate.setError(null);
          this.onFromDateChange(fromDate, false);
        } else {
          form.elmts.fromDate.setValue(fromDate);
          form.elmts.fromDate.setError(CommonString.DateFormatRequired);
        }
      } else {
        form.elmts.fromDate.setValue(null);
        form.elmts.fromDate.setError(CommonString.DateFormatRequired);
      }
    } else {
      form.elmts.fromDate.setError(null);
    }
  };
    
  validateDate = async (testdate) => {
    let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return date_regex.test(testdate);
  };

  handleToShowHideDatePicker = (val) => {
    if(val === "open" && this.state.showToDatePicker) {
      return;
    } else if (val === "close") {
      this.setState({showToDatePicker: false});
      return;
    } else {
      this.setState({showToDatePicker: !this.state.showToDatePicker})
    }
  };

  onClearToDate = () => {
    const form = this.props.app.ui.form("sortFilter");
    form.elmts.toDate.setValue(null);
    form.elmts.toDate.setError(null);
    this.props.app.dm.filterDomain.setState({ toDate: null })
    this.setState({
      showToDatePicker: false,
    })
  }

  onToDateChange = async (val, autoformat = true) => {
    const form = this.props.app.ui.form("sortFilter");
    form.elmts.toDate.setError(null);
    this.handleToShowHideDatePicker();
    if (!!val) {
      if (!!autoformat) {
        let date = DateUtils.formatDateInMMDDYYYY(val);
        if (date){
          form.elmts.toDate.setValue(date);
          form.elmts.toDate.setError("");
          this.props.app.dm.filterDomain.setState({ toDate: date });
        } else form.elmts.toDate.setValue(null);
      } else {
        if (val) {
          form.elmts.toDate.setValue(val);
          form.elmts.toDate.setError("");
          this.props.app.dm.filterDomain.setState({ toDate: val });
        } else form.elmts.toDate.setValue(null);
      }
    }
  };
    
  onToDateBlur = async () => {
    const form = this.props.app.ui.form("sortFilter");
    const { toDate } = form.values;
    this.handleToShowHideDatePicker();
    if (!!toDate) {
      let dat = await this.validateDate(toDate);
      if (!!dat) {
        let formatedDate = DateUtils.formatDateInMMDDYYYY(toDate);
        dat = await this.validateDate(formatedDate);
        if (!!dat) {
          form.elmts.toDate.setValue(formatedDate);
          form.elmts.toDate.setError(null);
          this.onToDateChange(toDate, false);
        } else {
          form.elmts.toDate.setValue(toDate);
          form.elmts.toDate.setError(CommonString.DateFormatRequired);
        }
      } else {
        form.elmts.toDate.setValue(null);
        form.elmts.toDate.setError(CommonString.DateFormatRequired);
      }
    } else {
      form.elmts.toDate.setError(null);
    }
  };

  handleRender = () => {
    setTimeout(() => {
      const { selectedBU, selectedDateChk, fromDate, toDate } = this.props.app.dm.filterDomain.state;
      let docForm = this.props.app.ui.form("sortFilter");
      
      if (docForm && selectedBU && selectedBU.label) {
        docForm.elmts.billerName.setValue(selectedBU);
      }
      if (docForm && selectedDateChk && fromDate) {
        docForm.elmts.fromDate.setValue(fromDate);
      }
      if (docForm && selectedDateChk && toDate) {
        docForm.elmts.toDate.setValue(toDate);
      }
    }, 0)
  }

  onCancelFilter = () => {
    const form = this.props.app.ui.form("sortFilter");
    form.elmts.billerName.setValue(null);
    form.elmts.toDate.setValue(null);
    form.elmts.toDate.setError(null);
    form.elmts.fromDate.setValue(null);
    form.elmts.fromDate.setError(null);
    this.props.app.dm.filterDomain.setState({ toDate: null, fromDate: null });
    this.setState({
      showFromDatePicker: false,
      showToDatePicker: false,
    })
    this.props.onCancelFilter()
  }

  render() {
    const { isVisible, defaultFilter } = this.props;
    const { selectedChk, sortOrder, statusType, selectedStatus, radioOpt, selectedDateChk } = defaultFilter;
    const statusGrp = [...new Set(statusType.map(({ groupLabel }) => groupLabel))];
    this.handleRender();
    
    let { billerNameList } = this.props;
    let seen = Object.create(null);
    billerNameList = billerNameList.filter(o => {
        var key = ['buId', 'billerRef'].map(k => o[k]).join('|');
        if (!seen[key]) {
            seen[key] = true;
            return true;
        } return false;
    });

    return (
        
      <ModalPopUp
        visibility={isVisible}
        bodyWrapper={[commonStyles.mediumModalBodyWrapper, customCommonStyles.filterModalWrapper, styles.widhtHeight]}
        isFooter={true}
        modalContent={commonStyles.marT0}>
        <ScrollView style={[commonStyles.padding16, styles.bottom10]}>
        <View style={styles.sortByHeading}>
          <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans]}>Sort by</Text>
        </View>
          <Form
            title="SortFilter"
            name="sortFilter"
            style={[commonStyles.padding0, styles.filterForm, commonStyles.dBlock ]}
            controlsBottomRight={[
                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd, commonStyles.paddingTop15]}>
                    <Button
                      key="cancel"
                      title="Cancel"
                      style={[commonStyles.blueBorderButton, commonStyles.marginRight15, styles.filterButton]}
                      titleTextStyle={[commonStyles.fontWeight500, commonStyles.blueBorderButtonColor, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                      onPress={this.onCancelFilter}
                    />
                    <Button
                      title="Apply"
                      key="apply"
                      style={[commonStyles.blueButton, customCommonStyles.noBoxShadow, styles.filterButton]}
                      titleTextStyle={[commonStyles.fontWeight500, commonStyles.whiteColor, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                      onPress={this.onFilterCall}
                      disabled={(sortOrder === "NA" || !selectedChk) && !selectedStatus.length}
                    />
                </View>
            ]}>
            
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
            {radioOpt.map((el, key) => (
              <View style={[styles.width50]}>
                <RadioButtons key={key} options={el} onValueChange={this.onDateChange} isSelected={selectedChk} />
              </View>  
            ))}
            </View>
            <View style={[commonStyles.marRT0, styles.toggleSwitch]}>
              <ToggleSwitch
                onSwiwtch={this.onSwitch}
                seletedOpt={sortOrder}
                toggleSwitchBtnouterStyle={[customCommonStyles.toggleSwitchBtnouterStyle]} />
            </View>
            <View style={[]}>
              <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans,commonStyles.contentColor]}>Filter by</Text>
            </View>
            <View style={[styles.leftInputField]}>
              <SelectMenuField
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, commonStyles.marB0]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                  name="billerName"
                  label={CommonString.BillerBULabel}
                  // label={"Biller Name*"}
                  // required="Select Biller (Business unit)"
                  items={billerNameList}
                  onValueChange={this.onBillerName}
                  searchable
                  // value
                  itemLoader={async ({ search }) => {
                      let items = billerNameList;
                      return items
                          .filter((item) => {
                              return item.label.toLowerCase().includes(search.toLowerCase());
                          })
                          .sort((a, b) => b.label - a.label);
                  }}
                  disabled={false}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, this.props.billerNameList.length === 1 ? customCommonStyles.textOverflowEllipsis : null]}
                  helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans,commonStyles.heading6]}
              />
            </View>
            <View style={[styles.filterByHeading]}>
              <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans,commonStyles.contentColor ]}>Date by</Text>
            </View>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.customDisplayBlock]}>
              {radioOpt.map((el, key) => (
                <View style={[styles.width50]}>
                  <RadioButtons key={key} options={el} onValueChange={this.onDateChkChange} isSelected={selectedDateChk} />
                </View>  
              ))}
            </View>
            <View style={[styles.customDatePickerStyles]}>
              <View style={[styles.customDatePickerWidth, styles.customBottom24]}>
                {Platform.OS === 'web' ? <>
                  {this.state.showFromDatePicker ? <ReactDatePicker
                    name={"fromDate"}
                    form="sortFilter"
                    value={this.props.app.ui.form("sortFilter").values.fromDate}
                    mode={"modal"}
                    onValueChange={this.onFromDateChange}
                    onBlur={this.onFromDateBlur}
                    visible={this.state.showFromDatePicker}
                    closeDatePicker={this.handleFromShowHideDatePicker}
                  /> : null }
                  <TextInputField
                    name={"fromDate"}
                    label={"From"}
                    secure={false}
                    readOnly={true}
                    onFocus={() => this.handleFromShowHideDatePicker('open')}
                    icon="calendar"
                    onIconPress={() => this.handleFromShowHideDatePicker()}
                    iconRight={this.props.app.ui.form("sortFilter") && this.props.app.ui.form("sortFilter").values.fromDate ? "close" : ""}
                    onIconRightPress={this.onClearFromDate}
                    // onChangeText={this.onChangeSSIDText}
                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                  />
                </> : <>
                <DatePicker
                  style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.marB0, customCommonStyles.customBobInputField, customCommonStyles.inputHeight]}
                  name={"fromDate"}
                  label={"From"}
                  // mode={!!isEnterSSID ? "dropdown" : "modal"}
                  // textInputEditable={!!isEnterSSID}
                  readOnly={true}
                  mode={"dropdown"}
                  textInputEditable={false}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                  helperTextStyle={[styles.labelStyle, commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  onValueChange={this.onFromDateChange}
                  onBlur={this.onFromDateBlur}
                />
                {Platform.OS !== "web" &&
                  <TextInputField
                    name={"fromDate"}
                    label={"From"}
                    secure={false}
                    disabled={true}
                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                    helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                  />
                }
                </>}
              </View>

              <View style={[styles.customDatePickerWidth]}>
                {Platform.OS === 'web' ? <>
                  {this.state.showToDatePicker ? <ReactDatePicker
                    name={"toDate"}
                    form="sortFilter"
                    value={this.props.app.ui.form("sortFilter").values.toDate}
                    mode={"modal"}
                    onValueChange={this.onToDateChange}
                    onBlur={this.onToDateBlur}
                    visible={this.state.showToDatePicker}
                    closeDatePicker={this.handleToShowHideDatePicker}
                  /> : null }
                  <TextInputField
                    name={"toDate"}
                    label={"To"}
                    secure={false}
                    readOnly={true}
                    onFocus={() => this.handleToShowHideDatePicker('open')}
                    icon="calendar"
                    onIconPress={() => this.handleToShowHideDatePicker()}
                    iconRight={this.props.app.ui.form("sortFilter") && this.props.app.ui.form("sortFilter").values.toDate ? "close" : ""}
                    onIconRightPress={this.onClearToDate}
                    // onChangeText={this.onChangeSSIDText}
                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                  />
                </> : <>
                <DatePicker
                  style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.marB0, customCommonStyles.customBobInputField, customCommonStyles.inputHeight]}
                  name={"toDate"}
                  label={"To"}
                  // mode={!!isEnterSSID ? "dropdown" : "modal"}
                  // textInputEditable={!!isEnterSSID}
                  readOnly={true}
                  mode={"dropdown"}
                  textInputEditable={false}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                  helperTextStyle={[styles.labelStyle, commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  onValueChange={this.onToDateChange}
                  onBlur={this.onToDateBlur}
                />
                {Platform.OS !== "web" &&
                  <TextInputField
                    name={"toDate"}
                    label={"To"}
                    secure={false}
                    disabled={true}
                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                    helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                  />
                }
                </>}
              </View>
            </View>
            {statusGrp.map((gl, i) => {
              return (
                <View key={i} style={[
                  commonStyles.marginRight15, 
                  customCommonStyles.chipsHolder,
                  gl === "Payment" ? customCommonStyles.paymentChipsHolder : null,
                  gl === "Extensions" ? customCommonStyles.extensionsChipsHolder : null
                ]}
                  >
                  <View style={[styles.billsPaymentsExt]}>
                    <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, ]}>{gl}</Text>
                  </View>
                  <View style={[styles.statusWrapper, commonStyles.dFlex, commonStyles.flexWrap, commonStyles.flexDirectionRow]}>
                    {statusType
                      .filter((x) => x.groupLabel === gl)
                      .map((el, ind) => (
                        <StatusComponent
                          key={el.id}
                          label={el.label}
                          isSelected={el.isSelected}
                          toggleStatus={() => this.onStatus(el.id)}
                          paymentStatusLabel={el.label}
                          isStatusColor={true}
                          circleNotAllowed={false}
                        />
                      ))}
                  </View>
                </View>
              );
            })}
            
          </Form>
      </ScrollView>
      </ModalPopUp>
    );
  }
});

const styles = StyleSheet.create({
  isSortModal: {
    width: "100vw",
    borderRadius: "30px 30px 0 0",
    padding: 16,
    marginTop: "auto",
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: 520,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          marginTop: "initial",
        };
      }
    },
  },
  filterButton: {
    minWidth: "30%",
    height:24,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: 38
        };
      }
    },
  },
  statusWrapper: {
    maxWidth: "100%",
    minWidth: "100%"
  },
  sortByHeading: {
    // paddingTop: 12,
    paddingBottom: 12,
    // marginLeft: 10,
  },
  filterByHeading: {
    paddingTop: 5,
    paddingBottom: 0,
    // marginLeft: 10,
  },
  toggleSwitch: {
    alignItems: "center",
    // paddingTop: 6,
    // paddingBottom: 6,
    // marginTop: 18,
    // marginRight: 39,
    marginBottom: 16,
    // marginLeft: 39,
    // minHeight: 26,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginTop: 8,
          marginBottom: 8,
        };
      }
    },
  },
  filterForm: {
    minWidth: "100%",
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    bottom: 0,
    justifyContent: "flex-start",
    // margin: 0,
    // padding: 0,
  },
  billsPaymentsExt: {
    // minHeight: 20,
    paddingTop: 14,
    paddingBottom: 5,
  },
  leftInputField: {
    maxWidth: "100%",
    flexBasis: "100%",
    marginRight: 0,
    marginBottom: 0,
    marginTop: 8,
  },
  width50: {
    width: "50%"
  },
  marginTopBottom8: {
    marginTop: 8,
    marginBottom: 8
  },
  customDatePickerStyles: {
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between'
        };
      }
    },
  },
  customDatePickerWidth: {
    marginBottom: 16,
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          marginBottom: 0,
          width: '49%'
        };
      }
    },
  },
  customBottom24: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 24,
        };
      }
    },
  },
  widhtHeight: {
    height: "76vh",
    width: 520,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "90vw"
        };
      }
    },
  },
  customDisplayBlock: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: 'block',
          width: '100%'
        };
      }
    },
  },
  bottom10: {
    bottom: 10,
    // overflow: "auto",
  }
});
