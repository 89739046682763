import React from "react";
import R14, { AsyncStorage, Button, StyleSheet, Form, SubmitButton, TextInputField, Colors, Text, View, ScrollView, Platform } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import PamMessasgeBottomSection from "../components/PamMessasgeBottomSection";
import CommonValidators from "../utils/CommonValidators";
import CommonUtility from "../utils/CommonUtility";
import { BILLER_STATUS } from "../constant/app.data.constant";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import PAMPerferencesComponent from "../components/PAMPerferencesComponent";
import { CommonString } from "../constant/appCommonText";
import RSAencryption from "../utils/RSAencryption";
import { analyticsLogs } from "../utils/AnalyticLogs";

export default R14.connect(
  class PamMessageScreen extends React.Component {
    constructor(props) {
      super(props);
      this.scrollRef = React.createRef();
      const { moreInfo } = this.props;
      const chat = [];
      if (moreInfo && moreInfo.moreInfos) {
        const { moreInfos } = moreInfo;
        moreInfos.forEach((ele) => {
          if (ele.moreInfoMessage) chat.push({ payer: ele.moreInfoMessage });
          if (ele.moreInfoReply) chat.push({ biller: ele.moreInfoReply });
        });
      }

      this.state = {
        messageContentHeight: 0,
        scrollCount: 0,
        chatMessage: chat,
        biller: props.pam.biller,
        isChatAllow: this.props.messageFlag,
        isPreferencePopup: false,
        isEmailNotification: props.pam.textToPayPreference && (props.pam.textToPayPreference === "SMS" || props.pam.textToPayPreference === "None") ? false : true,
        isSMSNotification: props.pam.textToPayPreference && (props.pam.textToPayPreference === "Email" || props.pam.textToPayPreference === "None") ? false : true
      };

      this.timerInstance = null;
    }

    async componentDidMount() {
      this.form = this.props.app.ui.form("chatBox");
      const { pam, moreInfo } = this.props;
      if (moreInfo && moreInfo.status === "requested") {
        const moreInfoReply = moreInfo && moreInfo.moreInfos ? moreInfo.moreInfos.filter((el) => !!el.moreInfoReply).length + 1 : 1;
        await this.props.app.dm.billerDirectoryDomain.updatePamMessages({ readMessages: moreInfoReply, uid: pam.uid });
      }
      this.updatePam();
    }

    updatePam = async () => {
      const { pam } = this.props;
      const { pamList } = this.props.app.dm.billerDirectoryDomain.state;
      const { biller } = this.state;

      const pamRes = await this.props.app.dm.billerDirectoryDomain.moreInfoMessages(pam.pamId, pam.payerId); // api call....
      if (!!pamRes && !!pamRes.moreInfos && !!pamRes.moreInfos.length && pamRes.status === "requested") {
        const { moreInfos } = pamRes;
        const moreInfoReply = moreInfos.filter((el) => !!el.moreInfoReply);
        this.props.app.dm.billerDirectoryDomain.updatePamMessages({ readMessages: moreInfoReply.length + 1, uid: pam.uid });

        if (!!pamList.length) {
          let ind = pamList.findIndex((el) => el.uid === pam.uid);
          pamList[ind].readMessages = moreInfoReply.length;
          this.props.app.dm.billerDirectoryDomain.setState({ pamList });
        }
        if (moreInfos) {
          const chat = [];
          moreInfos.forEach((ele) => {
            if (ele.moreInfoMessage) chat.push({ payer: ele.moreInfoMessage });
            if (ele.moreInfoReply) chat.push({ biller: ele.moreInfoReply });
          });
          this.setState({
            chatMessage: chat,
            biller: biller,
          });
        }
      }
    };

    componentWillUnmount() {
      if (this.timerInstance) {
        clearInterval(this.timerInstance);
      }
    }

    onSubmit = async (form) => {
      const { chatMessage, messageContentHeight, biller } = this.state;
      let res = await this.props.app.dm.billerDirectoryDomain.createPamMesagePayload(
        form.values.enterMessage,
        this.props.pam.pamId,
        this.props.pam.payerId
      );
      form.reset();
      if (!!res && !!res.mimId) {
        analyticsLogs(CommonString.PAM_DETAILS, `${CommonString.MESSAGE_SENT}`, this.props.app);
        this.setState({
          chatMessage: chatMessage.concat({ payer: res.moreInfoMessage }),
          biller: biller,
        });
        this.props.app.dm.queryDomain.updateBiller(biller);
        this.scrollToEnd({ y: messageContentHeight });
      }
      else {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
      return false;
    };

    layoutChange = (event) => {
      let evtobj = event.nativeEvent.layout;
      this.setState({
        messageContentHeight: evtobj.height,
      });

      this.scrollToEnd({ y: evtobj.height });
    };

    scrollToEnd(options = { animated: true }) {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollToEnd(options);
      }
    }

    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.enterMessage.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.enterMessage.setError("Character Limit Exceeds 140 Characters.");
        this.props.app.dm.commonDomain.showToastr("error", CommonString.ContentExceed140);
      }
    };

    handleCloseModel = () => {
      this.setState({
        isPreferencePopup: false
      })
    };

    reSyncBiller = async () => {
      analyticsLogs(CommonString.PAM_DETAILS, `${CommonString.RESYNC_PAYMENT_PREFERENCE}`, this.props.app);
      const { biller } = this.state;
      const activeBillers = await this.props.app.dm.billRequest.getBillerCSEPaymentToggle(
        {
          isMessagingConfig: true,
          billerPIDList: [{ pid: biller.billerId, buId: biller.buId }],
          from: 1,
          to: parseInt((new Date().getTime() / 1000).toFixed())
        });

      let activeBiller = null;
      if (!!activeBillers && activeBillers.length > 0) {
        activeBiller = activeBillers.pop();
      }

      // if different status then
      if (!!activeBiller && (
        biller.messagingToggleValue !== activeBiller.messagingToggleValue
        || biller.isPaymentDecline !== activeBiller.isPaymentDecline
        || biller.isPaymentDueDateExtensions !== activeBiller.isPaymentDueDateExtensions
        || biller.isPaymentsFull !== activeBiller.isPaymentsFull
        || biller.isPaymentsPartial !== activeBiller.isPaymentsPartial
        || biller.billerName !== activeBiller.billerName)
      ) {
        const updatedOptions = {
          messagingToggleValue: activeBiller.messagingToggleValue,
          isPaymentDecline: activeBiller.isPaymentDecline,
          isPaymentDueDateExtensions: activeBiller.isPaymentDueDateExtensions,
          isPaymentsFull: activeBiller.isPaymentsFull,
          isPaymentsPartial: activeBiller.isPaymentsPartial,
          billerName: activeBiller.billerName
        };

        // update asyncStorage (billerList)
        const allBillers = await this.props.app.dm.rest.getBillers();
        let ind = allBillers.findIndex((el) => el.billerRef === activeBiller.billerRef
          && el.buId === activeBiller.buId
        );
        allBillers[ind] = {
          ...allBillers[ind],
          ...updatedOptions
        };
        this.props.app.dm.userSession.setState({
          billers: allBillers
        })
        await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_BILLER, RSAencryption.EnctyptString(JSON.stringify(allBillers)));

        // call update biller
        await this.props.app.dm.queryDomain.updateBiller({
          ...updatedOptions,
          uid: biller.uid
        });
        // update biller state
        this.setState({
          biller: {
            ...biller,
            messagingToggleValue: activeBiller.messagingToggleValue,
            isPaymentDecline: activeBiller.isPaymentDecline,
            isPaymentDueDateExtensions: activeBiller.isPaymentDueDateExtensions,
            isPaymentsFull: activeBiller.isPaymentsFull,
            isPaymentsPartial: activeBiller.isPaymentsPartial,
            billerName: activeBiller.billerName
          }
        });
      }
      this.handleCloseModel();
      this.props.app.dm.commonDomain.showToastr("success", CommonString.BillerPreferences);
    }

    renderModelPopup(isPreferencePopup) {
      return (
        <PAMPerferencesComponent
          pam={this.props.pam}
          biller={this.state.biller}
          handleCloseModel={this.handleCloseModel}
          visibility={!!isPreferencePopup}
          reSyncBiller={this.reSyncBiller}
          moreInfo={this.props.moreInfo}
          isBlocked={this.props.pam.status === "blocked"}
          emailStatus={this.state.isEmailNotification}
          SMSstatus={this.state.isSMSNotification}
        />
      )
    }

    render() {
      const { pam, moreInfo } = this.props;
      const { chatMessage, biller, isChatAllow } = this.state;
      const additionalUserInfo = this.props.userDetails;
      let userShortName = additionalUserInfo.firstName + (additionalUserInfo.lastName ? " " + additionalUserInfo.lastName : "");
      return (
        <View style={[styles.pamMessageScreen, commonStyles.flex1]}>
          {this.renderModelPopup(this.state.isPreferencePopup)}
          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.cardBox]}>
            <View style={[styles.userIconDiv, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentCenter]}>
              <Text style={[commonStyles.heading6, commonStyles.fontWeight700, commonStyles.userInitial, commonFontStyles.workSans]}>
                {CommonUtility.shortName(biller.billerName)}
              </Text>
            </View>
            <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.infoAndStatus]}>
              <View style={[styles.cardRightDiv, customCommonStyles.customCardRightDiv]}>
                <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                  <View>
                    <Text
                      style={[
                        styles.cardHeadingText,
                        commonStyles.heading5,
                        commonStyles.fontWeight500,
                        commonFontStyles.workSans,
                        commonStyles.wordBreakWord,
                      ]}>
                      {biller.billerName}
                    </Text>
                  </View>
                </View>
                <View style={[styles.statusAndPreference, commonStyles.flex1, commonStyles.alignItemsFlexEnd]}>
                  <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonStyles.heading6,
                        commonStyles.fontWeight700,
                        commonFontStyles.workSans,
                        commonStyles.margin0,
                      ]}>
                      Business unit:{" "}
                    </Text>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.margin0,
                      ]}>
                      {!!biller.buName ? biller.buName : biller.buId}
                    </Text>
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.justifyContentSpaceBetween]}>
                  <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonStyles.heading6,
                        commonStyles.fontWeight700,
                        commonFontStyles.workSans,
                        commonStyles.margin0,
                      ]}>
                      Account Number:{" "}
                    </Text>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.margin0,
                      ]}>
                      {!!pam.accountNumber ? pam.accountNumber : "N/A"}
                    </Text>
                  </View>
                  <View style={[styles.statusSection, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexGrow0, styles.customJustifyContentFlexEnd]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonStyles.heading6,
                        commonStyles.fontWeight700,
                        commonFontStyles.workSans,
                        commonStyles.margin0,
                      ]}>
                      Status:{" "}
                    </Text>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        styles[`${BILLER_STATUS[moreInfo.status]}`],
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.margin0,
                        commonStyles.transformUpperCase,
                      ]}>
                      {BILLER_STATUS[moreInfo.status]}
                    </Text>
                  </View>

                </View>

                <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                  <Text
                    style={[
                      styles.cardSubHeadingText,
                      commonStyles.heading6,
                      commonStyles.fontWeight700,
                      commonFontStyles.workSans,
                      commonStyles.margin0,
                    ]}>
                    Request Type:{" "}
                  </Text>
                  <Text style={[styles.cardSubHeadingText, commonStyles.heading6, commonFontStyles.workSans, commonStyles.margin0]}>
                    Pre Authorization Request
                  </Text>

                </View>
                {moreInfo.status !== "blocked" ? (
                  <View style={[styles.statusSection, commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.flexGrow0, styles.billerStatusContainer]}>
                    <View style={[commonStyles.marLAuto]}>
                      <Button
                        title={"Preferences"}
                        key="preference-model"
                        style={[commonStyles.blueBorderButton, commonStyles.blueBorderButtonColor, styles.preferenceButton]}
                        onPress={() => {
                          analyticsLogs(CommonString.PAM_DETAILS, `${CommonString.OPEN_PREFERENCE_POP_UP}`, this.props.app);
                          this.setState({ isPreferencePopup: true })
                        }}
                        titleTextStyle={[
                          commonStyles.blueBorderButtonColor,
                          commonStyles.fontWeight500,
                          commonFontStyles.workSans,
                          commonStyles.exSmallHeading,
                        ]}
                      />
                    </View>
                  </View>
                ) : null}
              </View>

            </View>
          </View>
          <ScrollView
            key={this.state.scrollCount}
            ref={this.scrollRef}
            onLayout={this.layoutChange}
            height={Platform.OS !== "web" ? null : this.state.messageContentHeight}
            style={[styles.centerScrollable, customCommonStyles.customPamMessageCenterScrollable,
            !["accepted", "denied", "blocked", "payerblocked"].includes(moreInfo.status) ?
              customCommonStyles.pamMessageWithPreference : customCommonStyles.pamMessageCenterScrollableWithoutBottom,
              // !["accepted", "denied", "blocked", "payerblocked"].includes(moreInfo.status) && isChatAllow && customCommonStyles.pamMessageWithPreferenceChatInput , 
            ]}>
            <View style={[commonStyles.flex1, commonStyles.padding5]}>
              <View style={[commonStyles.textAlignCenter, commonStyles.marginBottom10]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    // commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}></Text>
              </View>
              <View style={[styles.chatBoxWidth]}>
                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.userInitialDiv]}>
                  <View style={[commonStyles.userInitialOuter, styles.billerInitial]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fileSize, commonStyles.fontWeight500]}>
                      {CommonUtility.shortName(biller.billerName)}
                    </Text>
                  </View>
                  <View>
                    <Text style={[commonStyles.guestName, commonFontStyles.workSans]}>{biller.billerName}</Text>
                  </View>
                </View>
                <View>
                  <View style={[commonStyles.minWidth150, commonStyles.guestText, commonStyles.dInlineFlex, commonStyles.flexDirectionColumn]}>
                    <View>
                      <Text style={[commonStyles.smallHeading, commonStyles.fontWeight500, commonFontStyles.workSans]}>
                        Request For Authorisation
                      </Text>
                    </View>
                    <View>
                      {moreInfo ? <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>{moreInfo.requestMessage}</Text> : null}
                    </View>
                    <View style={[commonStyles.guestTextBorder]}></View>
                  </View>
                </View>
                <View style={commonStyles.marLAuto}>
                  <Text
                    style={[
                      commonStyles.exSmallHeading,
                      commonStyles.chatTiming,
                      commonStyles.transperentBtnColor,
                      commonStyles.marLAuto,
                      commonFontStyles.workSans,
                    ]}></Text>
                </View>
              </View>
              <View>
                {chatMessage.map((item, indx) => (
                  <View key={indx}>
                    {item.payer ? (
                      <View style={[commonStyles.marginBottom15, commonStyles.dFlex, commonStyles.flexDirectionColumn]}>
                        <View style={[commonStyles.minWidth150, commonStyles.marLAuto]}>
                          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                            <View style={[styles.userInitialOuter, styles.payerInital]}>
                              <Text
                                style={[
                                  commonFontStyles.workSans,
                                  commonStyles.fileSize,
                                  commonStyles.blackTextColor,
                                  commonStyles.fontWeight500,
                                ]}>
                                {CommonUtility.shortName(userShortName)}
                              </Text>
                            </View>
                            <View>
                              <Text
                                style={[
                                  commonStyles.guestName,
                                  commonFontStyles.workSans,
                                  commonStyles.blackTextColor,
                                ]}>
                                {userShortName}
                              </Text>
                            </View>
                          </View>
                          <View style={[styles.responceMsg, commonStyles.hostText]}>
                            <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>{item.payer}</Text>
                            <Text style={[commonStyles.hostTextBorder]}></Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <View style={[commonStyles.marginBottom15, styles.chatBoxWidth, commonStyles.dFlex, commonStyles.flexDirectionColumn]}>
                        <View style={[commonStyles.minWidth150, commonStyles.marRAuto]}>
                          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                            <View style={[styles.userInitialOuter, styles.billerInitial]}>
                              <Text style={[commonFontStyles.workSans, commonStyles.fileSize, commonStyles.fontWeight500]}>
                                {CommonUtility.shortName(pam.biller.billerName)}
                              </Text>
                            </View>
                            <View>
                              <Text style={[commonStyles.guestName, commonFontStyles.workSans]}>
                                {pam.biller.billerName}
                              </Text>
                            </View>
                          </View>
                          <View style={[styles.responceMsg, styles.billerText]}>
                            <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>{item.biller}</Text>
                            <Text style={[styles.billerBorder]}></Text>
                          </View>
                        </View>
                      </View>
                    )}
                    <View style={commonStyles.marLAuto}>
                      <Text
                        style={[
                          commonStyles.exSmallHeading,
                          commonStyles.chatTiming,
                          commonStyles.transperentBtnColor,
                          commonStyles.marLAuto,
                          commonFontStyles.workSans,
                        ]}></Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </ScrollView>
          {!["accepted", "denied", "blocked", "payerblocked"].includes(moreInfo.status) ? (
            <View style={[styles.bottomSectionOuter]}>
              {isChatAllow ? (
                <View style={[styles.chatInputField, commonStyles.marginBottom20]}>
                  <View style={[commonStyles.enterMessage, commonStyles.marTAuto]}>
                    <Form
                      name="chatBox"
                      style={[commonStyles.padding0]}
                      validators={CommonValidators.getFormValidator()}
                      validateBeforeSubmit={true}
                      onSubmit={this.onSubmit}>
                      <View style={[Platform.OS === 'ios' ? commonStyles.dFlex : commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                        <View style={[styles.fieldsHolder, commonStyles.marginRight15, styles.messageTextInput]}>
                          <TextInputField
                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                            name="enterMessage"
                            label={CommonString.ChatInputLabel}
                            validator="cmtsVali"
                            required={CommonString.MessageRequired}
                            multiline
                            onChangeText={this.readText}
                            style={[commonStyles.margin0, styles.messageTextField, commonStyles.resizeNone, customCommonStyles.inputHeight, commonFontStyles.workSans, commonStyles.heading6,]}
                            helperTextStyle={[commonStyles.nowrap, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                          />
                        </View>
                        <SubmitButton
                          type="button"
                          title="Send"
                          name="sendBtn"
                          style={[commonStyles.blueButton, customCommonStyles.noBoxShadow, customCommonStyles.customSendBtnStyle, customCommonStyles.customPamSendBtnStyle,]}
                          titleTextStyle={[
                            commonStyles.fontWeight500,
                            commonFontStyles.workSans,
                            commonStyles.exSmallHeading,
                            commonStyles.whiteColor,
                          ]}
                        />
                      </View>
                    </Form>
                  </View>
                </View>
              ) : null}
              <PamMessasgeBottomSection
                pam={this.props.pam}
                emailStatus={this.state.isEmailNotification}
                SMSstatus={this.state.isSMSNotification}
              />
            </View>
          ) : null}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  pamMessageScreen: {
    backgroundColor: "#fff",
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
    marginTop: 16,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    // position: "relative",
    // height: "calc(100vh - 375px)",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 70,
          borderColor: "#fff",
        };
    },
  },

  logoView: {
    ...StyleSheet.margin(32, 0, 48, 0),
  },
  textWrapperView: {
    paddingBottom: 16,
  },
  text: {
    color: Colors.onBackground,
  },
  userIconDiv: {
    width: 32,
    minWidth: 32,
    height: 32,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    backgroundColor: "#e7e7e7",
    marginRight: 16,
  },
  userIcon: {
    height: 40,
    width: 40,
    marginRight: 10,
    display: "block",
  },
  cardRightDiv: {
    // width: "calc(100% - 50px)",
  },
  titleDiv: {
    marginBottom: 15,
  },
  cardBox: {
    paddingTop: 15,
    paddingRight: 0,
    paddingBottom: 15,
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
  },
  cardHeadingText: {
    // color: "#0C7DA6",
    display: "block",
    fontSize: 16,
  },
  cardSubHeadingText: {
    color: "#444444",
    display: "block",
    fontSize: 14,
    // marginTop: 5,
    marginRight: 0,
    // marginBottom: 5,
    marginLeft: 0,
  },
  cardSmlHeadingText: {
    color: "#888888",
    display: "block",
    fontSize: 12,
    margin: 0,
  },
  statusDiv: {
    backgroundColor: "#F1F1F1",
    border: "0.5px solid #D6D6D6",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    padding: 5,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
  },
  statusColorDiv: {
    backgroundColor: "#7accf6",
    borderRadius: "50%",
    padding: 4,
    height: 8,
    width: 8,
    marginTop: 0,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 2,
  },
  statusPaid: {
    backgroundColor: "#218666",
  },
  statusOutstanding: {
    backgroundColor: "#FFA500",
  },
  statusOverdue: {
    backgroundColor: "#EE82EE",
  },
  BorderTop: {
    borderTop: "1px solid #D6D6D6",
  },
  smallChatICon: {
    width: 15,
    height: 15,
    display: "inline-block",
    backgroundSize: "contain",
  },
  meadiumChatICon: {
    width: 30,
    height: 27,
    display: "inline-block",
    backgroundSize: "contain",
  },
  blueButton: {
    backgroundColor: "#37c2ef",
    color: "#333",
    height: 52,
    width: 75,
    border: 0,
  },
  bottomSectionOuter: {
    // position: "absolute",
    marginTop: "auto",
    marginRight: -15,
    marginBottom: 0,
    marginLeft: -15,
    backgroundColor: "#fff",
    // flex: 1,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          // paddingBottom: 75,
        };
      }
    },
  },
  chatInputField: {
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
    border: 0,
  },
  bottomPaySection: {
    border: 0,
  },
  decline: {
    height: 13,
    width: 15,
    marginTop: 0,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
  },
  statusSection: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          marginLeft: "auto",
          textAlign: "right",
          alignItems: "flex-end"
        };
      }
    },
  },
  preferenceLink: {
    marginTop: 10,
    marginRight: 16,
  },
  requestDate: {
    color: "red",
  },
  chatBoxWidth: {
    maxWidth: "85%",
    marginBottom: 15,
    display: "inline-flex",
    flexDirection: "column",
  },
  acceptDeclineIcon: {
    width: 16,
    height: 16,
    display: "inline-block",
    backgroundSize: "contain",
    marginRight: 5,
  },
  responceMsg: {
    marginBottom: 3,
  },
  active: {
    color: "#218666",
  },
  requested: {
    color: "#800080",
  },
  new: {
    color: "#800080",
  },
  inprogress: {
    color: "#EE781E",
  },
  accepted: {
    color: "#218666",
  },
  denied: {
    color: "#B40220",
  },
  blocked: {
    color: "#B40220",
  },
  fieldsHolder: {
    position: "relative",
    flexGrow: 1,
  },
  messageTextField: {
    position: "relative",
    zIndex: 1,
    // padding: 0,
    // paddingTop: 5,
    // paddingLeft: 5,
    resize: "none",
    lineHeight: 12,
  },
  attachmentCls: {
    height: 52,
    width: 56,
    paddingTop: 5,
  },
  attachmentCover: {
    backgroundColor: "#fff",
  },
  messageTextInput: {
    // color: "red",
    maxHeight: 48,
    flexBasis: "75%",
  },
  attachmentField: {
    position: "absolute",
    right: 0,
    zIndex: 99,
    backgroundColor: "#fff",
  },
  fileIcon: {
    color: "red",
  },
  billerBorder: {
    height: 0,
    width: 0,
    position: "absolute",
    bottom: 0,
    left: -9,
    borderTop: "5px solid rgba(255,255,255, 0.0)",
    borderRight: "5px solid #efefef",
    borderBottom: "5px solid rgba(255,255,255, 0.0)",
    borderLeft: "5px solid rgba(255,255,255, 0.0)",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  billerText: {
    display: "block",
    backgroundColor: "#e8e5e5",
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
    position: "relative",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 2,
    marginTop: 2,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  userInitialOuter: {
    height: 20,
    width: 20,
    maxWidth: 20,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  payerInital: {
    backgroundColor: "#cff2fe",
  },
  billerInitial: {
    // backgroundColor: "#e8e5e5",
    backgroundColor: "#ecf3fe",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  centerScrollable: {
    // top: 140,
    paddingTop: 16,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    // bottom: 97,
    // overflowY: "auto",
  },
  pageTitleHolder: {
    // backgroundColor: "#fff",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#efefef",
          paddingBottom: 10,
        }
      }
    }
  },
  pamMessageScreenOuter: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
          borderColor: "#fff",
          marginTop: 0,
        }
      }
    }
  },
  mediumModalWrapper: {
    width: '80vw',
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          width: '50vw',
        }
      }
    }
  },
  buttonContainers: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: "flex-end",
  },
  modelPopupButton: {
    minWidth: 101,
    // maxWidth: 101,
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flexBasis: "20%",
        }
      }
    }
  },
  primeryBtn: {
    minWidth: 101,
  },
  billerStatusContainer: {
    marginTop: -16,
    alignItems: "flex-end",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          justifyContent: "space-between",
        }
      }
      if (width <= 991) {
        return {
          marginTop: 0,
        }
      }
    }
  },
  infoAndStatus: {
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          justifyContent: "space-between",
        }
      }
    }
  },
  statusAndPreference: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "row",
          marginLeft: 0,
        }
      }
    }
  },
  customJustifyContentFlexEnd: {
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          justifyContent: "flex-end"
        }
      }
    }
  },
});
