import React, { Component } from "react";
import R14, {
  StyleSheet,
  View,
  Text,
  Touchable,
  Image,
  FlatList,
  Form,
  TextInputField,
  Button,
  SubmitButton,
  SelectMenuField,
  Platform
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import CommonUtility from "../../utils/CommonUtility";
import CommonValidators from "../../utils/CommonValidators";
import NoRecordsFound from "../NoRecordsFound";
import DateUtils from "../../utils/DateUtils";
import { BILL_STATUS, USER_TYPE } from "../../constant/app.data.constant";
import PDFViewerComponent from "./PDFViewerComponent";
import ModalPopUp from "../ModalPopUp";
import PCHLoaderComponent from "./PCHLoaderComponent";
import DatePicker from "../../components/PCH/DatePicker"
import ReactDatePicker from "../../components/PCH/ReactDatePicker"
import HtmlDecode from '../../utils/HtmlDecode';
import ChannelInfoComponent from "./ChannelInfoComponent";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";
import JsonToCSV from "../../utils/JsonToCSV";
import { responsiveTableStyle } from "../../assets/css/responsiveTableStyle";
import { analyticsLogs } from "../../utils/AnalyticLogs";

const attachedFileIcon = require("../../assets/images/attachedFileIcon.png");
const viewBlueIcon = require("../../assets/images/viewBlue.png");
const arrowLeft = require("../../assets/images/icPrevious.png");
const arrowRight = require("../../assets/images/icNext.png");
const arrowFirst = require("../../assets/images/icFirst.png");
const arrowLast = require("../../assets/images/icLast.png");

export default R14.connect(
  class BillItemsComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        addedHeight: 0,
        isVisible: false,
        password: "",
        uploadBill: {},
        docAction: "",
        isDocVisible: false,
        fileContent: "",
        fileContentUnsecure: "",
        signedUrl: "",
        selectedBlock: this.props.selectedBlock,
        loaderVisible: false,
        items: this.props.filterBills,
        showDobDatePicker: false,
        isPdfConfirmation: false,
        selectedItem: {},
        selectedItemType: "",
        pdfs: [],
        patientAddress: {},
      };
      this.ssid = "";
      this.statusItems = !! this.props.isUberDoc ?
        [
          { label: CommonString.AllLabel, value: CommonString.AllLabel },
          { label: CommonString.DraftLabel, value: CommonString.DraftLabel },
          { label: CommonString.ReceivedLabel, value: CommonString.ReceivedLabel },
          { label: CommonString.SubmittedLabel, value: CommonString.SubmittedLabel },
          { label: CommonString.AcceptedLabel, value: CommonString.AcceptedLabel },
          { label: CommonString.MailedLabel, value: CommonString.MailedLabel },
          { label: CommonString.RejectedLabel, value: CommonString.RejectedLabel },
        ] : [
          { label: CommonString.AllLabel, value: CommonString.AllLabel },
          { label: CommonString.NewLabel, value: CommonString.NewLabel },
          { label: CommonString.DraftLabel, value: CommonString.DraftLabel },
          { label: CommonString.ReceivedLabel, value: CommonString.ReceivedLabel },
          { label: CommonString.SubmittedLabel, value: CommonString.SubmittedLabel },
          { label: CommonString.AcceptedLabel, value: CommonString.AcceptedLabel },
          { label: CommonString.MailedLabel, value: CommonString.MailedLabel },
          { label: CommonString.RejectedLabel, value: CommonString.RejectedLabel },
        ]
        ;
    }

    componentDidMount = async () => {
      try {
        this.form = this.props.app.ui.form("frmDashboard");
        if (!!this.form.elmts && !!this.form.elmts.selectStatus) {
          this.form.elmts.selectStatus.setValue(this.statusItems.filter(x => x.value === this.props.selectedBlock)[0]);
          this.form.elmts.selectStatus.setItems(this.statusItems);
        }
        const patientAddress = await this.props.app.dm.rest.getuserBUDetails();
        this.setState({patientAddress: patientAddress && patientAddress.length ? patientAddress[0] : {}})
      } catch (error) {

      }
    }

    onSearch = async (val) => {
      this.onBlockNavigatation(val.value);
    }

    onSubmitBill = (item) => {
      if (!!this.props.app.dm.userSession.state.isUberDocs) {
        this.getparams(true, item);
        return false;
      }
      else {
        this.props.app.dm.uploadBillDomain.setState({
          isSubmitted: true,
        });
        analyticsLogs(CommonString.CLAIM_DASHBOARD, CommonString.CLICKED_SUBMIT_BUTTON, this.props.app);
        this.props.app.nav.to("uploadedBillsRoute", { uid: item.uid });
      }
    };

    getparams = (isSubmitted = true, bill) => {
      let params = this.props.app.route._data.params
      if (!!isSubmitted || bill.billStatus === "Draft")
        this.props.app.nav.to("externalClientUploadBillRoute", { appointment: bill.uid, redirect_url: params.redirect_url, access_token: params.access_token });
      else
        this.props.app.nav.to("externalClientbillViewRoute", { appointment: bill.uid, redirect_url: params.redirect_url, access_token: params.access_token });
    }

    onNavigate = (item) => {
      if (!!this.props.app.dm.userSession.state.isUberDocs) {
        this.getparams(false, item);
        return false;
      }
      else {
        this.props.app.dm.uploadBillDomain.setState({
          InSideNavigation: true,
        });
        this.props.app.nav.to("uploadedBillsRoute", { uid: item.uid });
      }
    };

    onBlockNavigatation = async (type) => {
      this.setState({
        loaderVisible: true
      })
      let items = this.props.filterBills;
      this.props.app.dm.uploadBillDomain.setState({
        currentPage: 1,
        selectedBlock: type,
      })
      items = await this.props.app.dm.uploadBillDomain.getUploadedBillsWithPage(type);
      this.setState({
        selectedBlock: type,
        items: items,
        loaderVisible: false
      })
    }

    getHeightOfElement = (event) => {
      const { height } = event.nativeEvent.layout;
      this.setState({
        addedHeight: height,
      });
    };

    onPasswordDateBlur = () => {
      const { dOBPassword } = this.props.app.ui.form("billPassword").values;
      this.handleDobShowHideDatePicker();
      if (!!dOBPassword) {
        let dat = this.validateDate(dOBPassword);
        if (!!dat) {
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setValue(dOBPassword);
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setError(null);
          this.onPassDateValueChange(dOBPassword, false);
        } else {
          this.ssid = "";
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setValue("");
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setError(CommonString.DateFormatRequired);
          return false;
        }
      } else {
        this.ssid = "";
        this.props.app.ui.form("billPassword").elmts.dOBPassword.setError(null);
      }
    };

    validateDate = (testdate) => {
      let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
      return date_regex.test(testdate);
    };

    onPassDateValueChange = (val, autoformat = true) => {
      // this.onPasswordDateBlur()
      this.handleDobShowHideDatePicker();
      if (!!autoformat) {
        let date = DateUtils.formatDateInMMDDYYYY(val);
        if (!!date && new Date(date) > new Date()) {
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setValue(null);
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotFuture);;
          return false;
        }  else if (!!date && DateUtils.isOlderThanMaxLimit(val)) {
            this.props.app.ui.form("billPassword").elmts.dOBPassword.setValue(null);
            this.props.app.ui.form("billPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotOlderThan125);
            return false;
          }
        else
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setValue(date);
      }
      else {
        if (!!val && new Date(val) > new Date()) {
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setValue(null);
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotFuture);;
          return false;
        }
        else
          this.props.app.ui.form("billPassword").elmts.dOBPassword.setValue(val);
      }
      this.setState({
        password: val,
        showDobDatePicker: false,
      });
    };

    onOk = async (form) => {
      const { dOBPassword } = form.values;
      const filePassword = this.ssid;
      const { uploadBill } = this.state;
      if (!!uploadBill.ssId ? filePassword === uploadBill.ssId : new Date(dOBPassword).toString() === new Date(uploadBill.dOB).toString()) {
        await this.downloadOrViewBillDocuments();
      } else {
        this.props.app.dm.commonDomain.showToastr("error", !!uploadBill.ssId ? CommonString.IncorrectSSID : CommonString.IncorrectDOB);
        if (form.elmts.filePassword) {
          form.elmts.filePassword.setValue(null);
          this.ssid = "";
        }
      }
      return false;
    };

    downloadOrViewBillDocuments = async () => {
      try {
        this.setState({ loaderVisible: true });
        const { uploadBill } = this.state;
        if (!!uploadBill.isProcessed) {
          let options = {
            responseType: "arraybuffer",
            headers: {
              "content-type": "application/pdf",
            }
          };
          let signedUrl = await this.props.app.dm.uploadBillDomain.getObjectSignedUrl(uploadBill.mergedFileName);
          let response = await this.props.app.dm.rest.getS3(signedUrl, options);
          let billDocuments = Buffer.from(response.data, 'binary').toString('base64');
          
          let signedUnsecuredUrl = !!uploadBill.mergedUnsecuredFileName && !!uploadBill.isSuperBill ? await this.props.app.dm.uploadBillDomain.getObjectSignedUrl(uploadBill.mergedUnsecuredFileName) : signedUrl;
          let responseUnsecure = !!signedUnsecuredUrl && !!uploadBill.isSuperBill ? await this.props.app.dm.rest.getS3(signedUnsecuredUrl, options) : signedUrl;
          let billDocumentsUnsecure = !!responseUnsecure && !!uploadBill.isSuperBill ? Buffer.from(responseUnsecure.data, 'binary').toString('base64') : billDocuments;
          // let billDocuments = await this.props.app.dm.uploadBillDomain.readPdfServer(uploadBill.mergedFileName);
          if (!!billDocuments) {
            if (this.state.docAction === "View") {
              this.setState({ fileContent: billDocuments, signedUrl: signedUnsecuredUrl, isDocVisible: true, fileContentUnsecure: billDocumentsUnsecure, });
              this.onCancel();
            } else {
              const item = {
                content: `data:application/octet-stream;base64,${billDocuments}`,
                contentType: 'PDF',
                filename: uploadBill.mergedFileName
              };
              HtmlDecode.downloadFile(item);
              this.onCancel();
            }
          }
          else
            this.props.app.dm.commonDomain.showToastr("error", CommonString.FileNotFound);
        }
        else
          this.props.app.dm.commonDomain.showToastr("error", CommonString.FileInProcess);

        this.setState({ loaderVisible: false })
      } catch (error) {
        console.log(error)
      }

    };

    onChangeSSIDText = (val) => {
      let ssidText = val;
      if (!!val) {
        let cleaned = ("" + ssidText).replace(/\D/g, "").substring(0, 4);
        this.props.app.ui.form("billPassword").elmts.filePassword.setValue(cleaned);
        this.ssid = cleaned;
      };
    };

    onDownloadFile = async (billItem, action) => {
      this.ssid = "";
      let item = { ...billItem };
      // let userType = this.props.userType;
      // if (item.isSuperBill)
      //   item.isPatientRequest = true;
      this.setState(
        {
          isVisible: true,
          uploadBill: item,
          docAction: action,
          // password: "",
        },
        // async () => {
        //   if (!!item.isSuperBill) await this.downloadOrViewBillDocuments();
        // }
      );
    };

    onCancel = () => {
      this.setState({
        isVisible: false,
        loaderVisible: false,
      });
    };

    handleDobShowHideDatePicker = (val) => {
      if(val === "open" && this.state.showDobDatePicker) {
        return;
      } else if (val === "close") {
        this.setState({showDobDatePicker: false});
        return;
      } else {
        this.setState({showDobDatePicker: !this.state.showDobDatePicker})
      }
    }

    onClearDobDate = () => {
        const form = this.props.app.ui.form("billPassword");
        form.elmts.dOBPassword.setValue(null);
        form.elmts.dOBPassword.setError(null);
        this.setState({
            showDobDatePicker: false,
        })
    }

    onViewClick = async (item, type) => {
        const { mergedFileName, documentId } = item;
        const filter = {
        filter: {
            documentId:{
            eq: documentId
            }
        },
        sort: {
            field: "seqNo",
            order: "ASC"
        }
          }
        let documentLogs =  await this.props.app.dm.uploadBillDomain.documentLogs(filter);
        // if(item && isSuperBill) {   // merged file show only when super bill exists 
        if(item) {      // merged file show (superBill or not)
          const isDocumentAlreadyExists = documentLogs.findIndex(documentFile => documentFile.filename === mergedFileName);
          if(isDocumentAlreadyExists === -1 && !!mergedFileName) documentLogs.unshift({uid: "", filename: mergedFileName, contentType: "pdf", seqNo: 1, isSuperBill: true});
          documentLogs = documentLogs.map((doc, idx) => {
            return {
                ...doc,
                seqNo: idx + 1
            }
          })
        }
        this.setState({isPdfConfirmation: true, selectedItem: item, selectedItemType: type, pdfs: documentLogs})
    }

    convertToCSV = async(items, screenName = "data") => {
      let {userType} = this.props;
      if (items.length){
        items = items.map(item => {
          const patientAddress = this.getPatientNameAndAddress(item)
          const insuranceAddress = this.getInsuranceNameAndAddress(item)
          if (userType === USER_TYPE.PCH_PATIENT) {
            return {
              "ICN#": item.processFileName,
              "Practice Group": !!item.parentName ? item.parentName : "N/A",
              "Provider": !!item.providerName ? item.providerName : "",
              // "Patient ID": !!item.insuranceMember ? item.insuranceMember.memberId : "",
              "Patient Name": patientAddress || "",
              "Insurance/Address": insuranceAddress || "",
              "Uploaded On": item.uploadedDateTime ? item.uploadedDateTime : DateUtils.dateAndTimeWithAmPm(item.createdAt, "PCH"),
              "Status": item.pchSubmittedStatus,
            }
          }
          else {
            return {
              "ICN#": item.processFileName,
              "Patient Mobile No": CommonUtility.mobileNumberFormatting(item.mobileNumber),
              "Provider": !!item.providerName ? item.providerName : "",
              // "Patient ID": !!item.insuranceMember ? item.insuranceMember.memberId : "",
              "Patient Name": patientAddress || "",
              "Insurance/Address": insuranceAddress || "",
              "Uploaded On": item.uploadedDateTime ? item.uploadedDateTime : DateUtils.dateAndTimeWithAmPm(item.createdAt, "PCH"),
              "Status": item.pchSubmittedStatus,
            }
          }
        })
        const csvContent = JsonToCSV.convertJsonToCSV(items);
        HtmlDecode.downloadUTF8file({
          content: csvContent,
          filename: `${screenName}_${DateUtils.formatYYYYMMDDHHMMSS()}.csv`
        });
        analyticsLogs(CommonString.CLAIM_DASHBOARD, CommonString.DOWNLOAD_CSV, this.props.app);
      }
      return false;
    }

    renderModelPopup(clientStyle, commonFontStyles, isUberDocs) {
      const { isVisible, uploadBill } = this.state;
      return (
        <ModalPopUp
          visibility={isVisible}
          isFooter={true}
          heading={!!uploadBill.ssId ? CommonString.SSIDRequired : CommonString.DOBRequired}
          bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
          handleCancel={this.onCancel}
          closeButton={customCommonStyles.customDisplayNone}
          modalOuterWrapper={styles.overFlowAuto}
          modalContent = {[commonStyles.marB0]}>
          <View style={[commonStyles.flex1, commonStyles.flexGrow0, customCommonStyles.customFilePassowrdModalBodyWrapper]}>
            <Form
              name="billPassword"
              style={[commonStyles.padding0]}
              onSubmit={this.onOk}
              validateBeforeSubmit={true}
              validators={CommonValidators.getFormValidator()}>
              <View>
                {!!uploadBill.ssId ? (
                  <View style={[commonStyles.marginTop10, commonStyles.marginBottom10, styles.minHeight52]}>
                    <TextInputField
                      name="filePassword"
                      label={`${CommonString.SSIDRequiredLabel}`}
                      required={CommonString.SSIDRequired}
                      secure={true}
                      onChangeText={this.onChangeSSIDText}
                      style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle, commonStyles.helperTextFontStyle]}
                    />
                  </View>
                ) : (
                  <View style={[commonStyles.marginTop10, commonStyles.dFlex, commonStyles.flexDirectionRow, commonStyles.marginBottom20, styles.minHeight52]}>
                    {Platform.OS === 'web' ? <>
                      {this.state.showDobDatePicker ? <ReactDatePicker
                        name={"dOBPassword"}
                        form="billPassword"
                        value={this.props.app.ui.form("billPassword").values.dOBPassword}
                        mode={"modal"}
                        onValueChange={this.onPassDateValueChange}
                        onBlur={this.onPasswordDateBlur}
                        visible={this.state.showDobDatePicker}
                        closeDatePicker={this.handleDobShowHideDatePicker}
                        isUberDocs={isUberDocs}
                      /> : null }
                      <TextInputField
                        name={"dOBPassword"}
                        label={`${CommonString.DOBRequired}*`}
                        required={CommonString.DOBRequired}
                        secure={false}
                        readOnly={true}
                        onFocus={() => this.handleDobShowHideDatePicker('open')}
                        icon="calendar"
                        onIconPress={() => this.handleDobShowHideDatePicker()}
                        iconRight={this.props.app.ui.form("billPassword") && this.props.app.ui.form("billPassword").values.dOBPassword ? "close" : ""}
                        onIconRightPress={this.onClearDobDate}
                        // onChangeText={this.onChangeSSIDText}
                        style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                        helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                      />
                    </> : <>
                    <DatePicker
                      style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.marB0, customCommonStyles.inputHeight]}
                      name={"dOBPassword"}
                      label={`${CommonString.DOBRequired}*`}
                      // mode={!!isEnterSSID ? "dropdown" : "modal"}
                      // textInputEditable={!!isEnterSSID}
                      value={this.state.dOBPassword}
                      mode={"dropdown"}
                      textInputEditable={true}
                      required={CommonString.DOBRequired}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                      helperTextStyle={[styles.dobLabelStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      onValueChange={this.onPassDateValueChange}
                      onBlur={this.onPasswordDateBlur}
                      onFocus={this.onPasswordDateBlur}
                    />
                    {Platform.OS !== "web" && <TextInputField
                      name="dOBPassword"
                      label={`${CommonString.DOBRequired}*`}
                      required={CommonString.DOBRequired}
                      secure={false}
                      disabled={true}
                      style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                    />}
                    </>}
                  </View>
                )}
              </View>
              <View style={[styles.btnHolder, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow, customCommonStyles.customBtnHolder, commonStyles.flexGrow1]}>
                <Button
                  title={"Cancel"}
                  style={[
                    commonStyles.blueBorderButton,
                    commonStyles.marginRight8,
                    styles.buttons,
                    clientStyle.backAndCancelButton
                  ]}
                  onPress={this.onCancel}
                  key="billCancel"
                  titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonStyles.transperentBtnColor, clientStyle.backAndCancelButtonText]}
                />
                <SubmitButton
                  title="Ok"
                  key="billOk"
                  style={[
                    commonStyles.blueButton,
                    commonStyles.marginLeft8,
                    styles.buttons,
                    customCommonStyles.noBoxShadow,
                    clientStyle.submitButton
                  ]}
                  titleTextStyle={[commonStyles.fontWeight500, commonStyles.black,commonFontStyles.workSans,commonStyles.exSmallHeading, commonStyles.whiteColor, clientStyle.submitButtonText]}
                // disabled={!this.ssid}
                />
              </View>
            </Form>
          </View>
        </ModalPopUp>
      );
    }

    onPDFClose = async () => {
      this.setState({ isDocVisible: false });
    };

    renderPDFViewer(clientStyle, isMobileDevice, isUberDocs) {
      const { isDocVisible, fileContent, signedUrl, uploadBill, fileContentUnsecure } = this.state;
      return (
        <ModalPopUp
          visibility={isDocVisible}
          isFooter={true}
          bodyWrapper={[commonStyles.largModalBodyWrapper, clientStyle.secondaryBgColor]}
          heading={"View Document"}
          handleCancel={this.onPDFClose}
          animationType="fade"
          closeButton={ customCommonStyles.viewPDFCloseBtn}
        >
          <View>
            <PDFViewerComponent closePDF={this.onPDFClose} isMobileDevice = {isMobileDevice} bills={uploadBill} signedUrl={signedUrl} dataSource={fileContent} dataSourceUnsecured={fileContentUnsecure} dataSourceType={uploadBill.dataSourceType || "pdf"} disableFileName={true} isUberDocs={isUberDocs} />
          </View>
        </ModalPopUp>
      );
    }

    renderAlertModelPopup(clientStyle, commonFontStyles) {
      const { isPdfConfirmation, selectedItem, selectedItemType, pdfs } = this.state;
      return (
        <ModalPopUp
            visibility={isPdfConfirmation}
            isFooter={true}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.confirmationModal, clientStyle.secondaryBgColor, pdfs.length ? null : styles.width300,]}
            modalContent = {[commonStyles.marB0]}>
            <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter,]}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight500, commonStyles.textAlignCenter]}>{`Document password is Patient's ${!selectedItem.dOB ? "4 digits SSID" : "DOB(MM/DD/YYYY)"}`}</Text>
            </View>
            
            <FlatList
                style={[
                  styles.messageComponent,
                  styles.dashboardMessageComponent,
                  customCommonStyles.customDashboardMessageComponent,
                  styles.tboday,
                  styles.noBorder,
                  pdfs.length ? null : commonStyles.displayNone,
                  clientStyle.secondaryBgColor,
                ]}
                data={pdfs}
                keyExtractor={(el, i) => `${el}_${i}`}
                renderItem={({ item, index }) => (
                  <View key={"row_" + index}
                    style={[
                      styles.tr,
                      commonStyles.flex1,
                      commonStyles.alignItemsStart,
                      commonStyles.flexDirectionRow,
                      styles.attachmentCard
                    ]}>
                      <View style={[commonStyles.marginRight16, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.tableValue, commonStyles.fontWeight600, commonStyles.paddingTop0]}>
                          {item.seqNo + ")  "}
                        </Text>
                        <View style={[commonStyles.marginRight16]}>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, customCommonStyles.wordBreakWord]}>
                            {item.filename}
                          </Text>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight300, customCommonStyles.wordBreakWord, commonStyles.tableValue]}>
                            {item.size}
                          </Text>
                        </View>

                      </View>
                      <Touchable
                          style={[styles.customMarLAuto, ]}
                          onPress={() => {              
                            selectedItem.mergedFileName = item.filename;  
                            selectedItem.dataSourceType = item.contentType ? item.contentType.toLowerCase() : ""
                            selectedItem.isSuperBill = item.isSuperBill;
                            this.onDownloadFile(selectedItem, selectedItemType)
                          }}>
                           <Image source={viewBlueIcon} style={[styles.viewPdfIconSize]} />
                        </Touchable>
                  </View>
                )}
              />
            <View style={[commonStyles.smallModalBtnWrapper, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow]}>
              <Button
                title={"OK"}
                style={[
                    commonStyles.blueButton,
                    styles.buttons,
                    customCommonStyles.noBoxShadow,
                    clientStyle.submitButton
                    ]}
                onPress={() =>  {
                    this.setState({isPdfConfirmation: false, selectedItem: {}, selectedItemType: ""})
                } }
                titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonFontStyles.workSans, commonStyles.whiteColor, clientStyle.submitButtonText, commonStyles.exSmallHeading]}
              />
            </View>
          </ModalPopUp>
      );
    }


    getFirstBill = async () => {
      try {
        this.setState({
          loaderVisible: true
        })
        let items = this.props.filterBills;
        this.props.app.dm.uploadBillDomain.setState({
          currentPage: 1
        })
        items = await this.props.app.dm.uploadBillDomain.getUploadedBillsWithPage(this.state.selectedBlock);
        this.setState({
          items: items,
          loaderVisible: false
        })
      } catch (e) {
        console.log("error", e)
        this.setState({
          loaderVisible: false
        })
      }
    }

    getLastBill = async () => {
      try {
        this.setState({
          loaderVisible: true
        });
        let items = this.props.filterBills;
        let { totalPages } = this.props.app.dm.uploadBillDomain.state;
        this.props.app.dm.uploadBillDomain.setState({
          currentPage: totalPages
        })
        items = await this.props.app.dm.uploadBillDomain.getUploadedBillsWithPage(this.state.selectedBlock);
        this.setState({
          items: items,
          loaderVisible: false
        })
      } catch (e) {
        console.log("error", e)
        this.setState({
          loaderVisible: false
        })
      }
    }

    getPreviousBill = async () => {
      try {
        this.setState({
          loaderVisible: true
        })
        let { currentPage } = this.props.app.dm.uploadBillDomain.state;
        let items = this.props.filterBills;
        if (currentPage >= 1) {
          currentPage = currentPage - 1;
          if (currentPage > 0) {
            this.props.app.dm.uploadBillDomain.setState({
              currentPage: currentPage
            })
            items = await this.props.app.dm.uploadBillDomain.getUploadedBillsWithPage(this.state.selectedBlock);
          }
        }
        this.setState({
          items: items,
          loaderVisible: false
        })
      } catch (e) {
        console.log("error", e)
        this.setState({
          loaderVisible: false
        })
      }
    }

    getNextBill = async () => {
      try {
        this.setState({
          loaderVisible: true
        })
        let { currentPage, totalPages } = this.props.app.dm.uploadBillDomain.state;
        let items = this.props.filterBills;
        if (currentPage <= totalPages) {
          currentPage = currentPage + 1;
          if (currentPage <= totalPages) {
            this.props.app.dm.uploadBillDomain.setState({
              currentPage: currentPage
            });
            items = await this.props.app.dm.uploadBillDomain.getUploadedBillsWithPage(this.state.selectedBlock);
          }
        }
        this.setState({
          items: items,
          loaderVisible: false
        })
      } catch (e) {
        console.log("error", e)
        this.setState({
          loaderVisible: false
        })
      }
    }

    getPatientNameAndAddress = (item) => {
      if(item.insuranceMember && item.insuranceMember.firstName){
        return item.insuranceMember.firstName + " " + item.insuranceMember.lastName;
      }
      return "";
    }

    getInsuranceNameAndAddress = (item) => {
        if(item.pchInsuranceDetail && item.pchInsuranceDetail.name){
            return item.pchInsuranceDetail.name + "/" + (item.pchInsuranceDetail.address1 ? item.pchInsuranceDetail.address1 : "") + (item.pchInsuranceDetail.address2 ? (", " + item.pchInsuranceDetail.address2) : "") + (item.pchInsuranceDetail.city ? (", " + item.pchInsuranceDetail.city) : "") + (item.pchInsuranceDetail.state ? (", " + item.pchInsuranceDetail.state) : "") + (item.pchInsuranceDetail.zipCode ? (", " + item.pchInsuranceDetail.zipCode) : "");
        }
        return ""
    }

    render() {
      const { leftMenuToggle, isUberDocs } = this.props.app.dm.userSession.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const { items, selectedBlock } = this.state;
      const { currentPage, resultsPerPage, totalBills } = this.props.app.dm.uploadBillDomain.state;
      let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
      let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
      let rowItems = endIndex - startIndex + 1;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles; 
      let userType = this.props.userType;
      let IsAllSubmmitedBill = !!items && items.filter((x) => x.billStatus === "New").length > 0 ? false : true;
      return (
        <>
          <View style={[responsiveTableStyle.pageTitle, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexGrow0, commonStyles.justifyContentSpaceBetween, commonStyles.alignItemsCenter]}>
            <View style={[]}>
                <Text style={[styles.text, commonStyles.heading3, commonStyles.fontWeight500, commonFontStyles.workSans]}>
                {userType === USER_TYPE.PCH_PATIENT && !!!isUberDocs ? "Dashboard" : "Bill History"}
                </Text>
            </View>
            <View style={[]}>
              {(!!items && items.length > 0) ?
                <Touchable onPress={() => this.convertToCSV(items, userType === USER_TYPE.PCH_PATIENT && !!!isUberDocs ? "Claims Dashboard" : "Bill History")}>
                  <Image source={attachedFileIcon} style={commonStyles.icon18} />
                </Touchable>
                : <View style={[]}><Image source={attachedFileIcon} style={commonStyles.icon14} /></View>}
            </View>
          </View>
          <View style={[styles.table, clientStyle.secondaryBgColor]}>
            {this.renderModelPopup(clientStyle, commonFontStyles, isUberDocs)}
            {this.renderAlertModelPopup(clientStyle, commonFontStyles)}
            {this.renderPDFViewer(clientStyle, isMobileDevice, isUberDocs)}
            <View style={[styles.dashboardHeader, clientStyle.secondaryBgColor]}>
              <View style={[commonStyles.flex1, styles.countBoxHolder, commonStyles.alignItemsCenter, commonStyles.flexDirectionRow]}>
                <Form
                  name="frmDashboard"
                  validateBeforeSubmit={false}
                  style={[styles.optFormStyles]}
                >
                  <View style={[styles.statusFieldOuter, commonStyles.flex1, commonStyles.customFlexDirection]}>
                    <View style={[commonStyles.flexBasis48]}>
                      <SelectMenuField
                        name="selectStatus"
                        label={"Select Status*"}
                        required={"Select Status"}
                        style={[styles.statusField,]}
                        items={this.statusItems}
                        onValueChange={this.onSearch}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                      // helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      />
                    </View>
                    <View><Text>&nbsp;</Text></View>
                  </View>
                </Form>
              </View>
            </View>
            {!!items.length > 0 ? (
              <View
                style={[
                  styles.thead,
                  commonStyles.flex1,
                  commonStyles.flexGrow0,
                  commonStyles.flexDirectionRow,
                  commonStyles.justifyContentSpaceBetween,
                  commonStyles.displayNoneInMobView,
                ]}>
                {/* !!dashboarScroll ? styles.customPaddingRight16 : null */}
                <View style={[commonStyles.dFlex, commonStyles.flexGrow1, styles.tdLeftSection, styles.thLeftSection, userType === USER_TYPE.PCH_PATIENT ? null : styles.customWidth50]}>
                  <View
                    style={[
                      styles.billerName,
                      commonStyles.flex1,
                      styles.makePaymentBillName,
                      leftMenuToggle ? styles.leftMenuToggleMakePaymentBillname : null,
                      userType === USER_TYPE.PCH_PATIENT ? styles.practitionerBillerName : styles.patientBillerName,
                      styles.leftMenuTogglePatientBillerName,
                    ]}>
                    <View style={[styles.th]}>
                      <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>
                        ICN#
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.billerDetails, commonStyles.dFlex, commonStyles.flexGrow1]}>
                    <View style={[styles.th, userType === USER_TYPE.PCH_PATIENT ? styles.billerSubj : styles.pracBillerSubj, commonStyles.flexGrow1, leftMenuToggle ? null : userType === USER_TYPE.PCH_PATIENT ? styles.billerSubjLeftMenuToggle : styles.pracBillerSubjLeftMenuToggle, commonStyles.justifyContentCenter]}>
                      <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>{userType === USER_TYPE.PCH_PATIENT ? "Practice Group" : "Patient Mobile No"}</Text>
                    </View>
                    <View style={[
                      styles.th,
                      styles.th,
                      styles.dateAndTime,
                      commonStyles.flexGrow1,
                      leftMenuToggle ? styles.leftMenuToggleDateAndTime : null,
                      userType === USER_TYPE.PCH_PROVIDER && !leftMenuToggle ? styles.practLeftMenuToggleDateAndTime : null
                    ]}>
                      <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Provider</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    commonStyles.dFlex,
                    styles.makePaymentTheadRightSection,
                    styles.tdRightSection,
                    styles.leftMenuTogglemakePaymentTheadRightSection,
                    userType === USER_TYPE.PCH_PATIENT ? null : styles.customWidth50,
                    // IsAllSubmmitedBill ? styles.tdRightSectionWithoutSubmitBtn : null,
                  ]}>
                  <View style={[styles.th, styles.billStatus, styles.thBillStatus, styles.dashboardThBillStatus, styles.makePaymentThBillStatus, styles.customWidth35]}>
                    <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Patient Name</Text>
                  </View>
                  <View style={[styles.th, styles.billStatus, styles.thBillStatus, styles.dashboardThBillStatus, styles.makePaymentThBillStatus, styles.customWidth35]}>
                    <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Insurance/Address</Text>
                  </View>
                  <View style={[styles.th, styles.billStatus, styles.thBillStatus, styles.dashboardThBillStatus, styles.makePaymentThBillStatus]}>
                    <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Uploaded On</Text>
                  </View>
                  <View style={[styles.th, styles.billStatus, styles.thBillStatus, styles.dashboardThBillStatus, styles.makePaymentThBillStatus]}>
                    <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Status</Text>
                  </View>
                  {/* {userType == USER_TYPE.PCH_PROVIDER ? null : ( */}
                  <View
                    style={[
                      styles.th,
                      styles.thBillAction,
                      styles.billAction,
                      styles.thAction,
                      userType === USER_TYPE.PCH_PATIENT ? styles.thPatientBillAction : styles.providerBillActionTh,
                      userType === USER_TYPE.PCH_PROVIDER ? null : leftMenuToggle ? styles.leftMenuToggleThAction : styles.leftMenuToggleOffThAction,
                      // IsAllSubmmitedBill ? styles.actionWithoutSubmitBtn : null,
                      IsAllSubmmitedBill ? USER_TYPE.PCH_PROVIDER ? styles.providerActionWithoutSubmitBtn : styles.patientActionWithoutSubmitBtn : null,
                      clientStyle.submitClaimButtonWidth,
                    ]}>
                    <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Action</Text>
                  </View>
                  {/* )} */}
                </View>
              </View>
            ) : null}
            <View>
              <FlatList
                style={[
                  styles.messageComponent,
                  !!items.length === 0 ? styles.dashboardNoListView : null,
                  styles.tboday,
                  clientStyle.secondaryBgColor,
                ]}
                contentContainerStyle={styles.contentContainerStyle}
                ListEmptyComponent={
                  <View style={styles.padd10}>
                    <NoRecordsFound isUberDocs={isUberDocs} noRecords={[styles.noRecords, clientStyle.primaryBgColor,]}
                      message=
                      {
                        userType === USER_TYPE.PCH_PATIENT ?
                          selectedBlock === "Submitted" ? `No${items.length > 0 ? 'submitted' : ''} claims found.` :
                            selectedBlock === "New" ? "No claims found." :
                              "No claims found."
                          : selectedBlock === "Submitted" ? `No${items.length > 0 ? 'submitted' : ''} bills found.` :
                            selectedBlock === "New" ? "No bills found." :
                              "No bills found."
                      } />
                  </View>
                }
                data={items}
                keyExtractor={(el, i) => i}
                renderItem={({ item, index }) => (
                  <View
                    style={[
                      styles.tr,
                      commonStyles.flex1,
                      commonStyles.alignItemsStart,
                      commonStyles.flexDirectionRow,
                      styles.customTableSize,
                      // items.length - 1 === index ? customCommonStyles.borderBottom1 : null,
                    ]}>
                    <View
                      style={[
                        commonStyles.flex1,
                        commonStyles.flexGrow1,
                        styles.tdLeftSection,
                        userType === USER_TYPE.PCH_PROVIDER ? [commonStyles.pointerEventsNone] : null,
                        !!item.isPatientRequest ? customCommonStyles.emptyIconTextWrap : null,
                        userType === USER_TYPE.PCH_PATIENT ? null : styles.customWidth50,
                        styles.customFlexDirection
                      ]}
                      onPress={() => userType === USER_TYPE.PCH_PROVIDER ? null : this.onNavigate(item)}>
                      <View
                        style={[
                          styles.billerName,
                          styles.dashboardBillerName,
                          styles.makePaymentBillerName,
                        ]}>
                        <Touchable onPress={() => userType === USER_TYPE.PCH_PROVIDER ? null : this.onNavigate(item)}>
                          <View style={[styles.customContainer]}>
                            <View style={[styles.customTextContainer]}>
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                styles.billStatusChip,
                                customCommonStyles.wordBreakWord,
                                commonStyles.tableValue,
                                commonStyles.fontWeight600,
                                styles.customDisplayNone
                              ]}>
                              {"ICN#: "}
                            </Text>
                            </View>
                            <View style={[styles.customValueContainer]}>
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                styles.billStatusChip,
                                customCommonStyles.wordBreakWord,
                                commonStyles.tableValue
                              ]}>
                              {item.processFileName }
                            </Text>
                            </View>
                          </View>
                        </Touchable>
                      </View>
                      <View
                        style={[
                          styles.billerDetails,
                          customCommonStyles.customBillerDetails,
                          styles.dashboardAlignItemsStart,
                          //  userType == USER_TYPE.PCH_PROVIDER ? styles.providerRightSection :styles.patientRightSection
                          styles.patientRightSection,
                        ]}>
                        {!!isMobileDevice ? (
                          <View
                            style={[
                              styles.td,
                              styles.tdType,
                              styles.billerSubj,
                              styles.tdBillerSubj,
                            ]}>
                            <Touchable onPress={() => userType === USER_TYPE.PCH_PROVIDER ? null : this.onNavigate(item)}>
                              <View style={[styles.customContainer]}>
                                <View style={[styles.customTextContainer]}>
                                <Text
                                  style={[
                                      styles.cardHeadingText,
                                      commonFontStyles.workSans,
                                      customCommonStyles.wordBreakWord,
                                      commonStyles.tableValue,
                                      commonStyles.fontWeight600,
                                      styles.customDisplayNone
                                  ]}>
                                  {userType === USER_TYPE.PCH_PATIENT ? "Practice Group: " : "Patient Mobile No: "}
                                </Text>
                                </View>
                                <View style={[styles.customValueContainer]}>
                                <Text
                                  style={[styles.cardHeadingText, commonFontStyles.workSans, customCommonStyles.wordBreakWord, commonStyles.tableValue]}
                                  numberOfLines={1}>
                                  {userType === USER_TYPE.PCH_PATIENT ?
                                    !!item.parentName ? item.parentName : "N/A"
                                    : CommonUtility.mobileNumberFormatting(item.mobileNumber)}
                                </Text>
                                </View>
                              </View>
                            </Touchable>
                          </View>
                        ) : (
                          <View
                            style={[
                              styles.td,
                              commonStyles.flexGrow1,
                              styles.tdType,
                              styles.billerSubj,
                              styles.tdBillerSubj,
                              commonStyles.wordBreakWord,
                              styles.customContainer
                            ]}>
                            <View style={[styles.customTextContainer]}>
                            <Text
                              style={[
                                  styles.cardHeadingText,
                                  commonFontStyles.workSans,
                                  customCommonStyles.wordBreakWord,
                                  commonStyles.tableValue,
                                  commonStyles.fontWeight600,
                                  styles.customDisplayNone
                              ]}>
                              {userType === USER_TYPE.PCH_PATIENT ? "Practice Group: " : "Patient Mobile No: "}
                            </Text>
                            </View>
                            <View style={[styles.customValueContainer]}>
                            <Text
                              style={[styles.cardHeadingText, commonFontStyles.workSans, customCommonStyles.wordBreakWord, commonStyles.tableValue]}
                              numberOfLines={1}>
                              {userType === USER_TYPE.PCH_PATIENT ?
                                !!item.parentName ? item.parentName : "N/A"
                                : CommonUtility.mobileNumberFormatting(item.mobileNumber)}
                            </Text>
                            </View>
                          </View>
                          
                        )}
                          <View style={[styles.td, commonStyles.flexGrow1, styles.tdDueIn, styles.dueOn, commonStyles.wordBreakWord, styles.customContainer]}>
                            <View style={[styles.customTextContainer]}>
                            <Text
                              style={[
                                  styles.cardHeadingText,
                                  commonFontStyles.workSans,
                                  customCommonStyles.wordBreakWord,
                                  commonStyles.tableValue,
                                  commonStyles.fontWeight600,
                                  styles.customDisplayNone
                              ]}>
                              {!!item.providerName ? "Provider: " : null }
                            </Text>
                            </View>
                            <View style={[styles.customValueContainer]}>
                                <Text style={[commonStyles.heading6, commonFontStyles.workSans, customCommonStyles.wordBreakWord, commonStyles.tableValue]} numberOfLines={1}>
                                {!!item.providerName ? item.providerName : ""}
                                </Text>
                            </View>
                            
                          </View>
                        <View style={[styles.td, commonStyles.flexGrow1, styles.tdDueIn, styles.dueOn, !!isMobileDevice ? null : commonStyles.displayNone, BILL_STATUS[item.billStatus] || userType === USER_TYPE.PCH_PROVIDER ? commonStyles.displayNone : null]}>
                          {!!isMobileDevice ? (
                            <View style={[commonStyles.marginTopBottom5, userType === USER_TYPE.PCH_PATIENT ? customCommonStyles.customSubmitClaimButton : null]}>
                              {BILL_STATUS[item.billStatus] || userType === USER_TYPE.PCH_PROVIDER ? null : (
                                <Touchable
                                  onPress={() =>
                                    BILL_STATUS[item.billStatus] || userType === USER_TYPE.PCH_PROVIDER ? null : this.onSubmitBill(item)
                                  }>
                                  <View style={[
                                    styles.paynowBtn,
                                    clientStyle.submitButton,
                                    BILL_STATUS[item.billStatus] || userType === USER_TYPE.PCH_PROVIDER ? customCommonStyles.disabledBtn : null,
                                    commonStyles.textAlignCenter,
                                    commonStyles.wordBreakWord
                                  ]}>
                                    <Text
                                      style={[
                                        commonStyles.exSmallHeading,
                                        commonStyles.fontWeight500,
                                        commonFontStyles.workSans,
                                        commonStyles.transformUpperCase,
                                        commonStyles.whiteColor,
                                        commonStyles.marB0,
                                        clientStyle.submitButtonText,
                                        BILL_STATUS[item.billStatus] || userType === USER_TYPE.PCH_PROVIDER ? customCommonStyles.disabledBtnText : null
                                      ]}>
                                     {BILL_STATUS[item.billStatus] ? "  Submitted  ": "Submit Claim"}
                                    </Text>
                                  </View>
                                </Touchable>
                              )}
                            </View>
                          ) : null}
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        commonStyles.flex1,
                        styles.dashboardAlignItemsStart,
                        styles.customFlexDirection,
                        styles.rightSectionTd,
                        styles.patientRightSectionTD,
                        userType === USER_TYPE.PCH_PROVIDER ? null : BILL_STATUS[item.billStatus] ? null : styles.customFlexWrap,
                        userType === USER_TYPE.PCH_PATIENT ? null : styles.customWidth50
                      ]}>
                      <View style={[styles.billStatus, userType === USER_TYPE.PCH_PATIENT ? styles.patientStatus : styles.customWidth55,  userType === USER_TYPE.PCH_PATIENT ? styles.customWidth35 : null, commonStyles.wordBreakWord, styles.customContainer]}>
                        {/* <StatusComponent marginRight={[commonStyles.marRT0, commonStyles.marB0]} label={item.billStatus} isSelected={false} paymentStatusLabel={item.paymentStatus} circleNotAllowed={true}/> */}
                        <View style={[styles.customTextContainer]}>
                        <Text
                          style={[
                              styles.cardHeadingText,
                              commonFontStyles.workSans,
                              customCommonStyles.wordBreakWord,
                              commonStyles.tableValue,
                              commonStyles.fontWeight600,
                              styles.customDisplayNone
                          ]}>
                          {"Patient Name: "}
                        </Text>
                        </View>
                        <View style={[styles.customValueContainer]}>
                        <Text style={[commonStyles.darkGrayColor, commonFontStyles.workSans, styles.labelValue, styles.cardSubHeadingText, commonStyles.tableValue]}>
                              {this.getPatientNameAndAddress(item)}
                            </Text>
                            </View>
                        
                      </View>
                      <View style={[styles.billStatus, userType === USER_TYPE.PCH_PATIENT ? styles.patientStatus : styles.customWidth55,  userType === USER_TYPE.PCH_PATIENT ? styles.customWidth35 : null, commonStyles.wordBreakWord, styles.customContainer]}>
                        {/* <StatusComponent marginRight={[commonStyles.marRT0, commonStyles.marB0]} label={item.billStatus} isSelected={false} paymentStatusLabel={item.paymentStatus} circleNotAllowed={true}/> */}
                        <View style={[styles.customTextContainer]}>
                        <Text
                          style={[
                              styles.cardHeadingText,
                              commonFontStyles.workSans,
                              customCommonStyles.wordBreakWord,
                              commonStyles.tableValue,
                              commonStyles.fontWeight600,
                              styles.customDisplayNone
                          ]}>
                          {"Insurance Name/Address: "}
                        </Text>
                        </View>
                        <View style={[styles.customValueContainer]}>
                        <Text style={[commonStyles.darkGrayColor, commonFontStyles.workSans, styles.labelValue, styles.cardSubHeadingText, commonStyles.tableValue]}>
                              {this.getInsuranceNameAndAddress(item)}
                            </Text>
                            </View>
                      </View>
                      <View style={[styles.billStatus, userType === USER_TYPE.PCH_PATIENT ? styles.patientStatus : null, commonStyles.wordBreakWord, styles.customBlockDisplay, styles.customContainer]}>
                        {/* <StatusComponent marginRight={[commonStyles.marRT0, commonStyles.marB0]} label={item.billStatus} isSelected={false} paymentStatusLabel={item.paymentStatus} circleNotAllowed={true}/> */}
                        <View style={[styles.customTextContainer]}>
                        <Text
                          style={[
                              styles.cardHeadingText,
                              commonFontStyles.workSans,
                              customCommonStyles.wordBreakWord,
                              commonStyles.tableValue,
                              commonStyles.fontWeight600,
                              styles.customDisplayNone
                          ]}>
                          {"Uploaded On: "}
                        </Text>
                        </View>
                        <View style={[styles.customValueContainer]}>
                        <Text
                          style={[
                            commonFontStyles.workSans,
                            styles.billStatusChip,
                            customCommonStyles.wordBreakWord,
                            commonStyles.tableValue
                          ]}>
                          {item.uploadedDateTime ? item.uploadedDateTime : DateUtils.dateAndTimeWithAmPm(item.createdAt, "PCH") }
                        </Text>
                        </View>
                      </View>
                      <View style={[styles.billStatus, userType === USER_TYPE.PCH_PATIENT ? styles.patientStatus : null, commonStyles.wordBreakWord, styles.customBlockDisplay, styles.customContainer]}>
                          <View style={[styles.customTextContainer]}>
                        <Text
                          style={[
                              styles.cardHeadingText,
                              commonFontStyles.workSans,
                              customCommonStyles.wordBreakWord,
                              commonStyles.tableValue,
                              commonStyles.fontWeight600,
                              styles.customDisplayNone
                          ]}>
                          {"Status: "}
                        </Text>
                        </View>
                        <View style={[styles.customValueContainer]}>
                        <Text
                          style={[
                            commonFontStyles.workSans,
                            styles.billStatusChip,
                            customCommonStyles.wordBreakWord,
                            commonStyles.tableValue
                          ]}>
                          {item.pchSubmittedStatus}
                        </Text>
                        </View>
                        {!!item.isPatientRequest || item.pchSubmittedStatus !== "New" ? null :
                          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.mailMessageIcon]}>
                            {!!item.mailMessageId ?
                              <ChannelInfoComponent infoTooltipStyle={customCommonStyles.mailGreenSection} source={"mailGreen"} tooltipText="Uploaded bill email delivered" />
                              :
                              <ChannelInfoComponent infoTooltipStyle={customCommonStyles.mailRedSection} source={"mailRed"} tooltipText="Uploaded bill email not delivered" />
                            }
                            {!!item.smsMessageId ?
                              <ChannelInfoComponent infoTooltipStyle={customCommonStyles.smsGreenSection} source={"smsGreen"} tooltipText="Uploaded bill SMS delivered" />
                              :
                              <ChannelInfoComponent infoTooltipStyle={customCommonStyles.smsRedSection} source={"smsRed"} tooltipText="Uploaded bill SMS not delivered" />
                            }
                          </View>
                        }
                      </View>
                      {/* {userType == USER_TYPE.PCH_PROVIDER ? null : ( */}
                      <View
                        style={[
                          styles.td,
                          styles.billAction,
                          userType === USER_TYPE.PCH_PATIENT ? styles.patientBillAction : styles.providerBillAction,
                          commonStyles.flex1,
                          commonStyles.flexDirectionRow,
                          commonStyles.flexGrow0,
                          userType === USER_TYPE.PCH_PROVIDER ? null :  styles.customPatientBillAction,
                          clientStyle.submitClaimButtonWidth,
                          !!isUberDocs ? styles.widthAuto : null,
                        ]}>
                        {!isMobileDevice ? (
                          userType === USER_TYPE.PCH_PROVIDER  ? null : (
                            <Touchable
                              style={[
                                styles.paynowBtn,
                                commonStyles.marLAuto,
                                clientStyle.submitButton,
                                BILL_STATUS[item.billStatus] || userType === USER_TYPE.PCH_PROVIDER ? customCommonStyles.disabledBtn : null,
                                styles.paynowBtn1024,
                                commonStyles.textAlignCenter
                              ]}
                              onPress={() => (BILL_STATUS[item.billStatus] || userType === USER_TYPE.PCH_PROVIDER ? null : this.onSubmitBill(item))}>
                              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                                <Text
                                  style={[
                                    commonStyles.exSmallHeading,
                                    commonStyles.fontWeight500,
                                    commonFontStyles.workSans,
                                    commonStyles.whiteColor,
                                    commonStyles.transformUpperCase,
                                    clientStyle.submitButtonText,
                                    commonStyles.flex1,
                                    commonStyles.marginTop3,
                                    BILL_STATUS[item.billStatus] || userType === USER_TYPE.PCH_PROVIDER ? customCommonStyles.disabledBtnText : null
                                  ]}>
                                  {BILL_STATUS[item.billStatus] ? "  Submitted  ": "Submit Claim"}
                                </Text>
                              </View>
                            </Touchable>
                          )
                        ) : null}
                        {item.billStatus === "Draft" ? 
                        <View style={[styles.customMarLAuto, styles.width30, IsAllSubmmitedBill ? userType === USER_TYPE.PCH_PROVIDER ? null : styles.viewBlueIconCustomMarginRight16 : null]}>
                        </View> :
                        <View style={[styles.customContainer]}>
                        <View style={[styles.actionTextContainer]}>
                        <Text
                          style={[
                              styles.cardHeadingText,
                              commonFontStyles.workSans,
                              customCommonStyles.wordBreakWord,
                              commonStyles.tableValue,
                              commonStyles.fontWeight600,
                              styles.customDisplayNone
                          ]}>
                          {"Action: "}
                        </Text>
                        </View>
                        <View style={[styles.actionValueContainer]}>
                        <Touchable
                          style={[styles.customMarLAuto, IsAllSubmmitedBill ? userType === USER_TYPE.PCH_PROVIDER ? null : styles.viewBlueIconCustomMarginRight16 : null]}
                          onPress={() => item.billStatus === "Draft" ? null : this.onViewClick(item, "View")}>
                           <Image source={viewBlueIcon} style={[styles.viewPdfIconSize]} />
                        </Touchable>
                        </View>
                        </View>
                        }
                      </View>
                      {/* )} */}
                    </View>
                  </View>
                )}
              />
            </View>
          {this.props.app.dm.uploadBillDomain.state.totalPages > 1 ?
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.paginationWrap, commonStyles.flexGrow0, customCommonStyles.customPaginationWrap]}>
              <View style={[commonStyles.rowPerPage]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}>Rows per page: {rowItems}</Text>
              </View>
              <View style={[commonStyles.displayingItem]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}>Displaying item {startIndex}-{endIndex} of {totalBills}</Text>
              </View>

              <View style={[commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, commonStyles.flexGrow0, commonStyles.paginationInnerWrap,]}>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.uploadBillDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.uploadBillDomain.state.currentPage === 1 ? null : this.getFirstBill()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.uploadBillDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowFirst} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.uploadBillDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.uploadBillDomain.state.currentPage === 1 ? null : this.getPreviousBill()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.uploadBillDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowLeft} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.uploadBillDomain.state.currentPage === this.props.app.dm.uploadBillDomain.state.totalPages ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.uploadBillDomain.state.currentPage === this.props.app.dm.uploadBillDomain.state.totalPages ? null : this.getNextBill()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.uploadBillDomain.state.currentPage === this.props.app.dm.uploadBillDomain.state.totalPages ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowRight} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.uploadBillDomain.state.currentPage === this.props.app.dm.uploadBillDomain.state.totalPages ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.uploadBillDomain.state.currentPage === this.props.app.dm.uploadBillDomain.state.totalPages ? null : this.getLastBill()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.uploadBillDomain.state.currentPage === this.props.app.dm.uploadBillDomain.state.totalPages ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowLast} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
            : null}
          </View>
          <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} clientStyle={clientStyle}/>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  table: {
    marginRight: 16,
    marginLeft: 16,
    backgroundColor: "#fff",
    padding: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    height:"100%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          padding: 0,
          marginTop: 2,
          marginRight: 0,
          marginLeft: 0,
        };
    },
  },
  userIconDiv: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
    backgroundColor: "#e7e7e7",
    position: "relative",
    marginLeft: 16,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024)
        return {
          display: "none",
        };
    },
  },
  billName: {
    marginRight: 15,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024)
        return {
          marginLeft: 0,
        };
    },
  },
  decline: {
    height: 13,
    width: 15,
    marginTop: 3,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
  },
  cardHeadingText: {
    // color: "#0C7DA6",
    fontSize: 14,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          fontSize: 16,
          fontWeight: "bold",
        };
    },
  },
  cardSubHeadingText: {
    fontSize: 14,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          fontSize: 12,
        };
    },
  },
  paynowBtn: {
    backgroundColor: "#d71996",
    paddingTop: 9,
    paddingRight: 10,
    paddingBottom: 9,
    paddingLeft: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    minWidth: 123,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024)
        return {
          marginLeft: 0,
        };
    },
  },
  thead: {
    borderBottom: "1px solid #DADADA",
    paddingTop: 3,
    paddingBottom: 3,
    position: "relative",
    zIndex: 999,
    backgroundColor: "#DADADA",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    margin: 0,
    maxHeight: 40,
  },
  thAction: {
    paddingBottom: 16,
  },

  tr: {
    paddingTop: 2,
    paddingBottom: 2,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderBottomWidth: 0,
    // flexWrap: "wrap",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 5,
          paddingBottom: 5,
          width: "100%",
          borderLeft: 0,
          borderRight: 0,
          borderWidth: 0,
          borderBottomWidth: 1,
        };
    },
  },
  patientBillerName: {
    // width: "32%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
        };
    },
  },
  practitionerBillerName: {
    width: "16%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
        };
    },
  },
  billerName: {
    width: "37%",
    paddingRight: 10,
    alignItems: "flex-start",
    marginLeft: 4,
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: "100%",
          marginLeft: 0,
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 144,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          marginLeft: -1,
        };
      }
    },
  },
  billerDetails: {
    width: "60%",
    // alignItems: "center",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          // alignItems: "self-start",
          width: "100%",
        };
      }
      if (width <= 991) {
        return {
          flexDirection: "column",
          width: "auto",
          alignItems: "baseline",
        };
      }
    },
  },
  thLeftSection: {
    width: "40%",
    paddingLeft: 15,
  },
  providerLeftSection: {
    width: "72%",
    paddingLeft: 15,
  },
  tdLeftSection: {
    width: "40%",
    // paddingLeft: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
          width: "100%",
          paddingLeft: 16,
          paddingRight: 16,
        };
      }
    }
  },
  tdRightSectionWithoutSubmitBtn: {
    width: "27.3%",
  },
  tdRightSection: {
    width: "34.3%",
    justifyContent: "space-between",
    // alignItems: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingRight: 16,
          flexDirection: "column",
          alignItems: "flex-start",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "24%",
          flexWrap: "wrap",
        };
      }
      if (width === 1920) {
        return {
          width: "34.1%",
        };
      }
    },
  },
  leftMenuToggleOffTdRightSection: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: "45.5%",
        };
      }
      
    },
  },
  bookedMarkSpacing: {
    marginLeft: 9,
  },
  billerSubj: {
    width: "26%",
    flex: 1,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
          flex: 0,
        };
      }
    },
  },
  pracBillerSubj: {
    width: "25%",
    flex: 1,
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "25%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "25.2%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "25.7%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 147,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          flex: 0,
        };
      }
    },
  },
  tdBillerSubj: {
    width: "25.7%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 110,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          marginTop: 4,
          marginBottom: 4,
        };
      }
    },
  },
  dateAndTime: {
    width: "25.33%",
    // marginLeft: 9,
    marginRight: 9,
    screen: ({ width }) => {
      if (width >= 1601 && width >= 1920) {
        return {
          width: "25.2%",
        };
      }
      if (width >= 1537 && width >= 1600) {
        return {
          width: "25.2%",
        };
      }
      if (width >= 1441 && width >= 1536) {
        return {
          width: "25%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "20%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  practLeftMenuToggleDateAndTime: {
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "16%",
        };
      }
    }
  },
  leftMenuToggleDateAndTime: {
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "52.5%",
        };
      }
    }
  },
  tdDateAndTime: {
    width: "31.33%",
    marginLeft: 15,
    marginRight: 15,
    screen: ({ width }) => {
      if (width <= 1024 && width >= 992) {
        return {
          width: 85,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
        };
      }
    },
  },
  tdDueIn: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          marginRight: 9,
        };
      }
    },
  },
  dueOn: {
    width: "25.55%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "auto",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  dashboardDueOn: {
    width: "27%",
  },
  thBillStatus: {
    width: "35%",
  },
  billStatus: {
    width: "40%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "100%",
          // marginTop: -10,
          // order: 3,
        };
      }
      if (width <= 992) {
        return {
          textAlign: "left",
          width: "auto",
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-start",
        };
      }
    },
  },
  billAmount: {
    width: "47%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
        };
    },
  },
  thBillAmount: {
    width: "44%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "43%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "43%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "44%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "44%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "43%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "43%",
        };
      }
      if (width <= 991)
        return {
          width: "100%",
        };
    },
  },
  thBillAction: {
    width: 170,
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 112,
        };
      }
    },
  },
  billAction: {
    width: 170,
    marginRight: 16,
    // justifyContent: "space-between",
    alignItems: "center",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 60,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          padding: 0,
          marginTop: 5,
          marginRight: 0,
          marginBottom: 4,
          marginLeft: 0,
        };
      }
    },
  },
  providerBillActionTh: {
    width: 55,
  },
  titleInMobile: {
    display: "none",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "block",
        };
    },
  },
  label: {},
  labelValue: {
    fontSize: 14,
    fontWeight: 400,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontSize: 12,
          fontWeight: 500,
        };
      }
    },
  },
  messageComponent: {
    backgroundColor: "#fff",
    position:"relative"
  },

  simpleMessageBillerLeftSection: {
    width: "74%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "45%",
        };
      }
    },
  },

  simpleMessageTdBillerName: {
    width: "25%",
    paddingRight: 0,
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 992 && width >= 1024) {
        return {
          width: "23%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageTheadBillerSubject: {
    width: "31%",
    paddingRight: 20,
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "32.3%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "31.5%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "32%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "31%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "31.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "31%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillerSubject: {
    width: "34%",
    paddingRight: 20,
    screen: ({ width }) => {
      if (width <= 991 && width >= 1024) {
        return {
          width: "36%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillerDetails: {
    width: "33%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillDateTimeTHead: {
    width: "31.3%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "31.9%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillDateTime: {
    width: "33%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillerLastMsg: {
    width: "35%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "32.6%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "33.5%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "33.1%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "31%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "33%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBiller: {
    color: "red",
  },
  simpleMessageBillRightSectionTHead: {
    width: "90px",
    minWidth: 90,
    flexGrow: 0,
    marginRight: 16,
    // screen: ({ width }) => {
    //   if (width >= 991 && width <= 1024) {
    //     return {
    //       width: "11%",
    //     };
    //   }
    // },
  },
  flexGrowUnset: {
    flexGrow: 0,
  },
  simpleMessageBillRightSection: {
    width: 90,
    flexGrow: 0,
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          display: "none",
        };
      }
    },
  },
  simpleMessageBillerAction: {
    width: 90,
    padding: 0,
  },
  userInitial: {
    textIndent: 3,
  },
  // makePaymenttdRightSection: {
  //   width: "32%",
  // },
  billerDirectoryBillerLeftSection: {
    width: "46.8%",
  },
  billerDirectoryBillerThLeftSection: {
    width: "56.8%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "53.8%",
        };
      }
    },
  },
  makePaymentBillName: {
    // width: "22.5%",
    screen: ({ width }) => {
      // if (width >= 1601 && width <= 1920) {
      //   return {
      //     width: "29.4%",
      //   };
      // }
      // if (width >= 1537 && width <= 1600) {
      //   return {
      //     width: "30.4%",
      //   };
      // }
      // if (width >= 1441 && width <= 1536) {
      //   return {
      //     width: "30.5%",
      //   };
      // }
      // if (width >= 1367 && width <= 1440) {
      //   return {
      //     width: "31%",
      //   };
      // }
      // if (width >= 1281 && width <= 1366) {
      //   return {
      //     width: "31.2%",
      //   };
      // }
      // if (width >= 992 && width <= 1024) {
      //   return {
      //     width: "33.5%",
      //   };
      // }
      if (width <= 991) {
        return {
          width: "34%",
        };
      }
    },
  },
  makePaymentDueOn: {
    width: "27%",
  },
  toggleSwitch: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: "auto",
        };
      }
    },
  },
  billerDirectoryStatus: {
    width: "100%",
    // textAlign: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          textAlign: "left",
        };
      }
    },
  },
  billerDirectorytdRightSection: {
    minWidth: 90,
    width: 90,
    margin: 0,
    padding: 0,
  },
  billerDirectoryAction: {
    minWidth: 90,
  },
  dashboardAlignItemsStart: {
    width: "29%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "auto",
        };
      }
    },
  },
  makePaymentTheadRightSection: {
    width: "34%",
  },
  makePaymenttdRightSection: {
    width: "35%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "19%",
        };
      }
    },
  },
  makePaymentTdRightPart: {
    width: "35%",
    screen: ({ width }) => {
      if (width >= 320 && width <= 359) {
        return {
          width: "37%",
        };
      }
      if (width >= 1281) {
        return {
          width: "35.1%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "33%",
          alignItems: "flex-start",
        };
      }
    },
  },
  makePaymentBillAmount: {
    width: "38%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          marginBottom: 10,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 121,
        };
      }
    },
  },

  // dasboard header css
  dashboardScreen: {
    flex: 0,
    alignItems: "flex-start",
    backgroundColor: "#fff",
    padding: 15,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          alignItems: "center",
          margin: 0,
          padding: 10,
        };
    },
  },
  filtereHading: {
    minWidth: 70,
  },
  logoView: {
    marginTop: 32,
    marginRight: 0,
    marginBottom: 48,
    marginLeft: 0,
  },
  textWrapperView: {
    paddingBottom: 16,
  },
  text: {
    color: "#182832",
  },
  viewAllLink: {
    color: "#0B4A87",
  },
  titleDiv: {
    marginBottom: 15,
  },
  btnTexClr: {
    color: "#333",
  },
  modalOuterWrapper: {
    width: "auto",
    backgroundColor: "#0000005c",
  },
  sessionExpirPopupBodyWrapper: {
    width: 315,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: "0px 0px 3px -1px #000",
    position: "relative",
  },
  bodyWrapper: {
    width: 300,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: "0px 0px 3px -1px #000",
    position: "relative",
    // screen: ({ width }) => {
    //   if (width <= 360)
    //     return {
    //       width: "70%",
    //     };
    // },
  },
  popupTitle: {
    textAlign: "center",
    marginTop: 5,
    marginRight: 0,
    marginBottom: 30,
    marginLeft: 0,
    display: "block",
  },
  newBills: {
    backgroundColor: "#ABC6FC",
  },
  overdue: {
    backgroundColor: "#F2A9FC",
  },
  outstanding: {
    backgroundColor: "#FCE5B3",
    marginTop: 0,
    marginRight: 15,
    marginBottom: 0,
    marginLeft: 15,
    screen: ({ width }) => {
      if (width >= 320 && width <= 359) {
        return {
          marginTop: 0,
          marginRight: 5,
          marginBottom: 0,
          marginLeft: 5,
        };
      }
      if (width >= 360 && width <= 640) {
        return {
          marginTop: 0,
          marginRight: 16,
          marginBottom: 0,
          marginLeft: 16,
        };
      }
    },
  },
  countBoxTitle: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 0,
          marginRight: "auto",
          marginBottom: 0,
          marginLeft: "auto",
          paddingTop: 0,
          // paddingRight: 20,
          paddingBottom: 0,
          // paddingLeft: 20,
          minHeight: 43,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        };
    },
  }, 
  countBoxHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "#ccc",
          justifyContent: "space-between",
        };
      }
    },
  },
  flex1: {
    flex: 1,
  },
  marB10: {
    marginBottom: 10,
  },
  headerStyle: {
    color: "#4DC5F2",
    paddingTop: 20,
  },
  sessionModelHeaderStyle: {
    color: "#4DC5F2",
    paddingTop: 8,
  },
  sessionModelOkBtn: {
    width: 100,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    marginBottom: 10,
  },
  dashboardMessageComponent: {
    marginTop: 200, //164,
    marginRight: 33,
    marginLeft: 16,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 16,
    paddingLeft: 16,
    display: "block",
    flexGrow: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTop: 0,
    // position: "unset",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginTop: 200,
          marginRight: 16,
          marginLeft: 16,
        };
      }
      if (width >= 320 && width <= 991) {
        return {
          paddingTop: 15,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
        };
      }
    },
  },
  dashboardHeader: {
    marginTop: -16,
    marginRight: -16,
    marginBottom: 0,
    marginLeft: -16,
    position: "relative",
    zIndex: 99,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
      }
    },
  },

  simpleMessageNoListView: {
    marginTop: 0,
  },
  dashboardNoListView: {
    marginTop: 164, //124,
    // paddingTop: 16,
    borderWidth: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  providerBillerName: {
    width: "29.8%",
    marginLeft: 0,
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 167,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  makePaymentBillerName: {
    width: "40%",
    marginLeft: 15,
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 156,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          marginLeft: 0,
        };
      }
    },
  },
  leftMenuToggleMakePaymentBillerName: {
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 191,
        };
      }
    },
  },
  dashboardBillerName: {
    // width: "26%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "25%",
        };
      }
    },
  },
  dashboardThBillStatus: {
    marginLeft: 0,
  },
  makePaymentThBillStatus: {
    width: "25%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "auto",
        };
      }
    },
  },
  active: {
    color: "#218666",
  },
  requested: {
    color: "#800080",
  },
  new: {
    color: "#800080",
  },
  inprogress: {
    color: "#EE781E",
  },
  accepted: {
    color: "#218666",
  },
  denied: {
    color: "#B40220",
  },
  blocked: {
    color: "#B40220",
  },
  popupMenuStyle: {
    color: "red",
  },
  billerDirectoryBillerName: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          alignItems: "flex-start",
        };
      }
    },
  },
  billerDirectoryTr: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingTop: 10,
          paddingBottom: 10,
        };
      }
    },
  },
  billerDirectoryBillrName: {
    width: "55%",
    screen: ({ width }) => {
      if (width >= 1367 && width <= 1440) {
        return {
          width: "54.4%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "54.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "97%",
        };
      }
    },
  },
  billerDirectoryTdBillrName: {
    width: "51.5%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
      if (width >= 1600) {
        return {
          width: "53%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "60.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "69%",
        };
      }
    },
  },
  leftMenuToggleBillerDirectoryTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1537 && width <= 1600) {
        return {
          width: "51.8%",
        };
      }
    },
  },
  leftMenuToggleOffBillerDirectoryTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "52.2%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "51.8%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "51.5%",
        };
      }
    },
  },
  leftMenuTogglemakePaymentTheadRightSection: {
    width: "60%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "45.2%",
        };
      }
    },
  },
  leftMenuToggleMakePaymentBillname: {
    width: "24%",
    // screen: ({ width }) => {
    //   if (width >= 1601 && width <= 1920) {
    //     return {
    //       width: "28.9%",
    //     };
    //   }
    //   if (width >= 1537 && width <= 1600) {
    //     return {
    //       width: "29.6%",
    //     };
    //   }
    //   if (width >= 1441 && width <= 1536) {
    //     return {
    //       width: "29.7%",
    //     };
    //   }
    //   if (width >= 1367 && width <= 1440) {
    //     return {
    //       width: "30.2%",
    //     };
    //   }
    //   if (width >= 1281 && width <= 1366) {
    //     return {
    //       width: "30.5%",
    //     };
    //   }
    //   if (width >= 992 && width <= 1024) {
    //     return {
    //       width: "26.6%",
    //     };
    //   }
    // },
  },
  leftMenuToggleTheadRightSection: {
    width: "33%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "33.2%",
        };
      }
    },
  },
  leftMenuToggleOffMakePaymentBillerSubj: {
    screen: ({ width }) => {
      if (width >= 1537 && width <= 1600) {
        return {
          width: "24.9%",
        };
      }
    },
  },
  leftMenuToggleBillStatus: {
    width: "34%",
  },
  leftMenuToggleBillAmount: {
    width: "38%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "36.9%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "37%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "36.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "36.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "37%",
        };
      }
    },
  },
  dashboardMakePaymenttdRightSection: {
    width: "29.4%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "33%",
        };
      }
    },
  },
  billerDirectoryMakePaymenttdRightSection: {
    minWidth: 45,
    width: 45,
    marginRight: 16,
    // screen: ({ width }) => {
    //   if (width >= 992 && width <= 1024) {
    //     return {
    //       width: 90,
    //     };
    //   }
    // },
  },
  priorityInfoTooltipHolder: {
    position: "relative",
  },
  priorityInfoTooltip: {
    position: "absolute",
    backgroundColor: "#333",
    color: "#fff",
    top: 35,
    zIndex: 999999,
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    minWidth: 220,
    textAlign: "center",
  },
  priorityInfoTooltipArrow: {
    width: 10,
    height: 10,
    display: "block",
    position: "absolute",
    backgroundColor: "#333",
    top: -4,
    left: 87,
    transform: [{ rotate: "45deg" }],
  },
  bookedMark: {
    height: 22,
    width: 15,
    marginTop: 0,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
    display: "inline-block",
    backgroundSize: "contain",
  },
  top50: {
    top: 50,
  },
  top0: {
    top: 0,
    inset: "0px 0px 0px",
  },
  priorityListTxt: {
    marginRight: -10,
  },
  filterBySection: {
    position: "relative",
    zIndex: 9999,
    backgroundColor: "#fff",
    alignItems: "center",
    marginTop: 16,
  },
  positionStatic: {
    marginTop: 0,
    padding: 0,
    position: "static",
  },
  customPaddingRight16: {
    paddingRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingRight: 0,
        };
      }
    },
  },
  customMarginRight16: {
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  dashboardTitleDiv: {
    backgroundColor: "#fff",
    margin: 0,
    paddingTop: 7,
    paddingRight: 16,
    paddingBottom: 7,
    paddingLeft: 16,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  tableCustomPadding: {
    paddingTop: 16,
    paddingBottom: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          paddingBottom: 0,
        };
      }
    },
  },
  countBoxTitleText: {
    fontSize: 14,
    // textTransform: "uppercase",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontSize: 13,
        };
      }
      // if (width >= 360 && width <= 991) {
      //   return {
      //     fontSize: 25,
      //   };
      // }
    },
  },
  countBoxTextHolder: {
    paddingLeft: 16,
    paddingRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingLeft: 6,
          paddingRight: 6,
          justifyContent: "center",
        };
      }
    },
  },
  noRecords: {
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginTop: 16,
          marginRight: 16,
          marginLeft: 16,
        };
      }
    },
  },
  borderBottom1: {
    borderBottom: "1px solid #dadada",
  },
  newCounter: {
    borderBottomWidth: 2,
    borderColor: "rgba(77, 194, 242, 1.0)",
    borderStyle: "solid",
    marginBottom: -1,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginBottom: 0,
          minHeight: 43,
        };
      }
    },
  },
  selectedBlocktext: {
    // color: "#1277D9",
    fontWeight: 600,
  },
  unSelectedBlocktext: {
    fontWeight: 500,
    // color: "rgb(21, 27, 38, 1)",
  },
  disabledCounter: {
    borderBottomWidth: 2,
    borderColor: "rgba(255,255,255, 0.0)",
    borderStyle: "solid",
    marginBottom: -2,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginBottom: 0,
          minHeight: 43,
        };
      }
    },
  },
  pchCountBox: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          flex: 1,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          flexGrow: 1,
          flexBasis: "33.33%",
          width: "33.33%",
          justifyContent: "center",
          alignItems: "center",
          margin: 0,
          minHeight: 43,
        };
      }
    },
  },
  providerRightSection: {
    width: "19.5%",
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          width: "auto",
          marginRight: 16,
          flexDirection: "column",
        };
      }
    },
  },

  providerMobNum: {
    width: "32.1%",
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          width: "auto",
        };
      }
    },
  },
  providerTdBillerSubj: {
    width: "33%",
  },
  rightSectionTdWithoutSubmitBtn: {
    width: "26%",
  },
  customFlexWrap: {
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          flexWrap: "wrap",
        };
      }
    }
  },
  patientRightSectionTD: {
    width: "60%",
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          width: "100%",
          flexDirection: "column",
          paddingRight: 16,
          paddingLeft: 16,
          justifyContent: "flex-start",
        };
      }
    },
  },
  rightSectionTd: {
    width: "22.7%",
    justifyContent: "space-between",
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          width: "100%",
          flexBasis: "100%",
          flexDirection: "column",
          marginRight: 16,
          justifyContent: "flex-start",
        };
      }
    },
  },
  patientRightSection: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginRight: 16,
          alignItems: 'center'
        };
      } else {
        return {
          width: "60%"
        }
      }
    },
  },

  filePassowrdModalWrapper: {
    width: "100%",
    minHeight: 150,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  pdfViewerModalWrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          minHeight: 150,
        };
      }
    },
  },

  modalWrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  filePassowrdModalBodyWrapper: {
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#a7a7a7",
    padding: 10,
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    minHeight: 150,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minHeight: 180,
        };
      }
    },
  },
  btnHolder: {
    // paddingRight: 10,
    // paddingLeft: 10,
  },
  PDFViewBodyWrapper: {
    width: "80vw",
    borderRadius: 6,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // width: 300,
          minHeight: 460,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
        };
      }
    },
  },
  viewPdfIcon: {
    marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: "auto",
        };
      }
    },
  },
  customMarLAuto: {
    marginLeft: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  viewPdfIconSize: {
    width: 25,
    height: 20,
    minWidth: 30,
    backgroundSize: "contain",
    display: "block",
    backgroundPosition: "center center",
  },
  marginLeftRight: {
    marginRight: 15,
    marginLeft: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          marginLeft: 0,
        };
      }
    },
  },
  dobLabelStyle: {
    // position: "static",
    left: 0,
  },
  billStatusChip: {
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    textTransform: "uppercase",
    fontSize: 14,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingRight: 0,
          paddingLeft: 0,
          fontSize: 12,
          fontWeight: "bold",
          order: 2,
        };
      }
    },
  },
  parctionerProfilePic: {
    marginRight: 8,
  },
  profilePicHolder: {
    display: "none",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "block",
        };
      }
    },
  }, 
  submitClaimButton: {
    marginTop: 10,
    // marginLeft: -44,
  },
  customPatientBillAction: {
    minWidth: 165,
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginTop: 8,
          marginBottom: 8,
          width: 165,
        };
      }
    }
  },
  patientBillAction: {
    width: 177,
    padding: 0,
    marginRight: 5,
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginRight: 10,
          // width: 60,
        };
      }
      if (width <= 991) {
        return {
          width: "auto",
          marginLeft: 0,
          marginRight: 0,
        };
      }
    },
  },
  thPatientBillAction: {
    minWidth: 170,
    padding: 0,
    marginRight: 0,
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 170,
        };
      }
    },
  },
  providerRightTHSection: {
    width: "26.3%",
  },
  providerRightSectionTD: {
    width: "25.5%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // width: "40%",
          // minWidth: 150,
          // flexBasis: "48%",
          // flexGrow: 1,
          // justifyContent: "flex-start",

          width: "22%",
          flexBasis: "22%",
          flexDirection: "column",
          marginRight: 16,
          // justifyContent: "space-between",

        };
      }
    },
  },

  providerBillAction: {
    width: "auto",
    marginLeft: "auto",
    marginRight: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          marginLeft: 0,
          marginTop: 5,
        };
      }
    },
  },
  buttons: {
    height: 24,
    padding: 0,
    minHeight: 24,
    width: 100,
    maxWidth: 100,
    flexBasis: "48%",
  },
  providerActionWithoutSubmitBtn: {
    width: 55,
  },
  patientActionWithoutSubmitBtn: {
    width: 55,
    paddingBottom: 0,
    marginRight: 0,
  },  
  profileIcon: {
    backgroundPosition: "center center",
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column"
        }
      }
    }
  },
  minHeight52: {
    minHeight: 52,
  },
  touchableAria: {
    paddingTop: 12,
    paddingRight: 40,
    paddingBottom: 10,
    paddingLeft: 40,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        }
      }
    }
  },  
  paginationBtn: {
    borderWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  paginationBtnTextSize: {
    fontSize: 14,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontSize: 12,
        }
      }
    },
  },
  leftMenuToggleThAction: {
    width: 170,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: 170,
        }
      }
    },
  },
  billerSubjLeftMenuToggle: {
    width: "24.5%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "11.5%",
        };
      }
    },
  },
  pracBillerSubjLeftMenuToggle: {
    width: "24.5%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "5.5%",
        };
      }
    },
  },
  leftMenuTogglePractitionerBillerName: {
    width: "16%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "49%",
        };
      }
    },
  },
  leftMenuToggleOffPractitionerBillerName: {
    width: "16%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "35%",
        };
      }
    },
  },
  leftMenuTogglePatientBillerName: {
    width: "40%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "53.5%",
        };
      }
    },
  },
  leftMenuToggleOffPatientBillerName: {
    width: "24.5%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "51.5%",
        };
      }
      if (width === 1920) {
        return {
          width: "23.1%",
        };
      }
    },
  },
  paginationBtnWrap: {
    minWidth: 24,
  },
  viewBlueIconCustomMarginRight16: {
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        }
      }
    },
  },
  optFormStyles: {
    paddingBottom: 0,
    height: 80
  },
  statusFieldOuter: {
    marginBottom: 20,
    height: 55,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        }
      }
    },
  },
  statusField: {
    marginBottom: 0,
  },
  mailMessageIcon: {
    paddingLeft: 6,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          order: 1,
          flexGrow: 0,
          marginRight: 16,
        }
      }
    },
  },
  paynowBtn1024: {
    height: 20,
    padding: 0,
    screen: ({ width }) => {
      if (width <= 1024) {
        return {
          marginTop: 10,
        }
      }
    },
  },
  patientStatus: {
    paddingRight: 9,
    width: "25%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "auto",
        }
      } else if (width <= 991) {
        return {
          width: "100%",
          maxWidth: "100%"
        }
      }
    },
  },
  enterPasswordBodyWrapper: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          minHeight: 180,
        };
    },
  },
  modalContent: {
    marginTop: 0,
    width: "100%",
  },
  overFlowAuto: {
    overflow: "auto"
  },
  width30:{
    width: 30
  },
  tboday: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          position: "relative",
          margin: 0,
          padding: 0,
          maxHeight: "calc(100vh - 270px)"
        };
    }, 
  },
  widthAuto: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "auto"
        };
    }, 
  },
  maxHeight24: {
    maxHeight: 24
  },
  noBorder: {
    borderTopWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    marginTop: 30,
    padding: 16,
    paddingTop: 0
  },

  attachmentCard: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#a1a4a8",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
          minHeight: 65,
        };
      }
    },
  },
  confirmationModal: {
    width: 420,
    maxWidth: "calc(90vw)"
  },
  width300: {
    width: 300
  },
  customWidth35: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: "35%"
        };
      } else {
        return {
          width: "100%",
          display: "block"
        };
      }
    },
  },
  customWidth55: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: "55%"
        };
      } else {
        return {
          width: "100%"
        };
      }
    },
  },
  customWidth50: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: "50%"
        };
      } else {
        return {
          width: "100%"
        };
      }
    },
  },
  customTableSize: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customDisplayNone: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          display: "none"
        };
      }
    },
  },
  customBlockDisplay: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          display: "block"
        };
      }
    },
  },
  customContainer: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flex: 1,
          flexDirection: "row"
        };
      }
    },
  },
  customTextContainer: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "45%",
        };
      }
    },
  },
  customValueContainer: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "55%",
        };
      }
    },
  },
  actionTextContainer:{
      screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "42%",
        };
      }
    },
  },
  actionValueContainer: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "58%",
        };
      }
    },
  },
});
