import React, { Component } from "react";
import R14, { 
  View, 
  TextInputField, 
  StyleSheet, 
  SubmitButton, 
  Text, 
  Touchable, 
  Image,
  Platform
 } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import FileUploadComponent from "../components/FileUploadComponent";
import { customCommonStyles } from "../assets/css/customCommonStyle";
const attachments = require("../assets/images/icAttachment.png");

export default R14.connect(
  class MessageUpload extends Component {
    constructor(props) {
      super(props)
      this.state = {
        fileName: "",
      }
      this.child = React.createRef()
    }

    onFileInputChange = async (val, status) => {
      if (status === "done") {
        const {fileName}  = this.state;
        let fizeSize = val.actualsize / 1024 / 1024;
        if (fizeSize > 2) {
          await this.child.current.wrappedInstance.pullErrorText("Maximum 2MB file size allowed.");
          return false;
        } 
        if (fileName === val.filename) {
          await this.child.current.wrappedInstance.pullErrorText("File has been already added.");
          return false;
        }
        const { onValueChange } = this.props;
        let fileObj = {
          filename: val.filename,
          contentType: val.contentType,
          content: val.content,
          size: val.size,
        }
        this.setState({fileName:val.filename});
        onValueChange(fileObj);
      }
    };

    deleteFile = ()=>{
      const { onValueChange } = this.props;
      this.fileToSave = null;
      onValueChange(null,true);
      this.setState({fileName:""}); 
    }

    resetFile = ()=>{
      this.fileToSave = null;
      this.setState({fileName:""}); 
    }
    render() {
      const { commentsProps, onSend, isDisabled, onChangeText, padding, customHeight, helperTextStyle, errorMsgHolder, textInputFileUploadHolder} = this.props;
      return (
        <>
          {
            !!this.state.fileName ?
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd]}>
                <View style={[customCommonStyles.textOverflowEllipsis, customCommonStyles.attachedFileName]}>
                  <Text style={[commonStyles.exSmallHeading, commonStyles.fontWeight400, commonFontStyles.workSans]}>{this.state.fileName}</Text>
                </View>
                <Touchable onPress={this.deleteFile}>
                  <View style={[styles.closeIcon, commonStyles.marginLeft5]}>
                    <Text style={[commonStyles.heading6, styles.activeCls, customCommonStyles.attachedFileCloseBtn]}>&#10006;</Text>
                  </View>
                </Touchable>
              </View>
              : null
          }
          <View style={[styles.fieldsHolder, commonStyles.flex1, commonStyles.flexDirectionRow, textInputFileUploadHolder]}>
            <View style={[styles.messageTextInput, !!onSend ? styles.messageTextWhenBtn : null]}>
              <TextInputField
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                name="comments"
                label="Enter Message*"
                validator="cmtsVali"
                onChangeText={onChangeText}
                multiline={true}
                {...commentsProps}
                style={[commonStyles.margin0, styles.messageTextField, commonStyles.resizeNone, padding, customHeight, customCommonStyles.inputHeight, commonFontStyles.workSans, commonStyles.heading6]}
                helperTextStyle={[commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0, helperTextStyle, Platform.OS === "ios" ? customCommonStyles.iosPadding : null]}
              // iconRight="close"
              // onIconRightPress={this.onFileInputChange}
              />
            </View>
            <View style={[styles.attachmentField, commonStyles.marginTop16]}>
              <FileUploadComponent
                ref={this.child}
                maxFiles={1}
                source={"XBP"}
                labelText={<View><Image source={attachments} resizeMode={"contain"} style={commonStyles.icon14} /></View>}
                // labelText="Add attachments"
                fileTypes={"application/pdf,text/xml,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                fileTypeLabel={"pdf,xml,excel,word"}
                onValueChange={this.onFileInputChange}
                fileInputStyle={[commonStyles.margin0Auto]}
                attachmentFieldOuterCustomHeight={[styles.attachmentFieldOuterCustomHeight,]}
                errorMsgHolder={errorMsgHolder}
                showErrorInModal={true}
              />
            </View>
            {onSend ? (
              <SubmitButton
                type="button"
                title="Send"
                name="sendBtn"
                disabled={isDisabled}
                style={[styles.blueButton, commonStyles.blueButton, customCommonStyles.noBoxShadow, customCommonStyles.customSendBtn, commonStyles.btnHeight24]}
                titleTextStyle={[commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.whiteColor]}
              />
            ) : null}
          </View>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  fieldsHolder: {
    position: "relative",
    minHeight: 56,
    maxHeight: 60,
    width: "100%",
    flexGrow: 1,
  },
  messageTextField: {
    position: "relative",
    zIndex: 1,
    resize: "vertical",
    maxHeight: 48,
  },
  attachmentCls: {
    height: 53,
    width: 56,
  },
  attachmentCover: {
    backgroundColor: "#fff",
  },
  messageTextInput: {
    width: Platform.OS === "web" ? "calc(100% - 40px)" : "89%",
    screen: ({ width }) => {
      if (width >= 760) {
        return {
          width: Platform.OS === "web" ? "calc(100% - 40px)" : "89%",
        };
      }
    },
  },
  messageTextWhenBtn: {
    flexBasis: Platform.OS === "web" ? "calc(100% - 104px)" : "68%",
    screen: ({ width }) => {
      if (width >= 760) {
        return {
          // flexBasis: "68%",
          flexBasis: Platform.OS === "web" ? "calc(100% - 40px)" : "68%",
        };
      }
    },
  },
  attachmentField: {
    // position: "relative",
    // right: 0,
    // bottom: 0,
    // top: 6,
    // zIndex: 99,
    // // backgroundColor: "#fff",
    position: "relative",
    // right: -1,
    zIndex: 99,
    maxWidth: 40,
    height: 24,
    // paddingRight: 16,
    backgroundColor: "#fff",
    marginTop: 8,
    marginLeft: 8,
    screen: ({width}) => {
      if (width <= 991) {
          return {
            marginTop: 6,
          }
      } 
    }
  },
  blueButton: {
    width: 75,
    marginLeft: 10,
    border: 0,
    minHeight: 48,
    paddingRight: 8,
    paddingLeft: 8,
    marginTop: 16
  },
  labelStyle: {
    whiteSpace: "nowrap",
    color: "green",
  },
  attachmentFieldOuterCustomHeight: {
    // position: "relative",
    // height: 22,
    // maxHeight: 22,
    // // width: "6vw",
    // minWidth: 22,
  },
  customMarTop8: {
    screen: ({width}) => {
      if (width <= 991) {
          return {
            marginTop: 8,
          }
      } 
    }
  }
});
