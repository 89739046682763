import React from "react";
import R14, { 
  Form,
  View,
  Text,
  StyleSheet,
  Touchable,
  SelectMenuField,
  Image,
  TextInputField,
  Button,
  SubmitButton,
  ScrollView,
  PopUpMenu,
  PopUpMenuItem,
  Platform,
  AsyncStorage
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import CommonValidators from "../../utils/CommonValidators";
import DateUtils from "../../utils/DateUtils";
import { USER_TYPE, MESSAGE_TYPE } from "../../constant/app.data.constant";
import CommonUtility from "../../utils/CommonUtility";
import FileUploadComponent from "../../components/FileUploadComponent";
import ModalPopUp from "../../components/ModalPopUp";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import PopupModalComponent from "../../components/PopupModalComponent";
import { analyticsLogs } from "../../utils/AnalyticLogs";

import { customCommonStyles } from "../../assets/css/customCommonStyle";
import DatePicker from "../../components/PCH/DatePicker"
import ReactDatePicker from "../../components/PCH/ReactDatePicker"
import { NATIVE_APP_URL } from "../../constant/app.constant";
import { CommonString } from "../../constant/appCommonText";
const deleteIcon = require("../../assets/images/delete.png");
const plusSolidIcon = require("../../assets/images/plus_solid.png");

export default R14.connect(
  class PractUploadBillsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAddAttachmentsVisible: false,
        attachments: [],
        isDisable: false,
        uid: null,
        isVisible: false,
        uploadBill: {},
        isEnterDob: false,
        isEnterSSID: false,
        isCamVisible: false,
        isAutoFilled: false,
        patients: [],
        isAutoFillVisible: false,
        isEnterMobileNo: false,
        isEnterEmail: false,
        filename: "",
        hasOnlyOnePOBox: false,
        isDocVisible: false,
        loaderVisible: false,
        isAlertVisible: false,
        alertMessage: "",
        showDobDatePicker: false,
        doctorTreatedAddresses: []
      };
      this.form = null;
      this.doctors = [];
      this.ssid = "";
      this.child = React.createRef();
    }

    componentDidMount = async () => {
      try {
        this.form = this.props.app.ui.form("uploadBills");
        const { providers } = this.props;
        this.doctors = [];
        if (!!this.form.elmts.doctorName) {
          if (!!providers && providers.length > 0) {
            providers.forEach((doctor) => {
              this.doctors.push({
                label: doctor.DoctorName,
                value: doctor.uid,
              });
            });
            this.form.elmts.doctorName.setItems(this.doctors);
            this.form.elmts.doctorName.setValue(null);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Please add Provider.",
            });
          }
        }
        if (!!this.form.elmts.countryCode)
          this.form.elmts.countryCode.setValue({ label: "+1", value: "+1" });
      }
      catch (err) {
      }
    };

    onDoctorSearch = async (val) => {
      const { label, value } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.doctorName.setValue(null);
        return false;
      }
      this.form.elmts.doctorName.setValue({ label: label, value: value });
      this.form.elmts.doctorName.setError(null);

      // provider address
      let filter = {
        filter: {
          doctorUid: { eq: value },
          isActive: { eq: "true" },
        },
        sort: { field: "createdAt", order: "DESC" },
      };
      let doctorTreatedAddresses = await this.props.app.dm.addDoctorsDomain.getTreatmentAddressList(filter);

      doctorTreatedAddresses = doctorTreatedAddresses.map(address => {
        if (address) {
          return {
            ...address,
            label: `${address.addressLine1}${address.town ? ", " + address.town : ""}${address.state ? ", " + address.state : ""}${address.postCode ? ", " + address.postCode : ""}`,
            value: address.uid
          }
        }
        else
          return null;
      })
      doctorTreatedAddresses = doctorTreatedAddresses.filter(x=> x !== null);
      
      const primaryAddress = doctorTreatedAddresses.find(address => address.isPrimary);

      this.form.elmts.doctorTreatmentAddress.setValue(!!primaryAddress ? primaryAddress : doctorTreatedAddresses[0]);
      this.form.elmts.doctorTreatmentAddress.setItems(doctorTreatedAddresses);

      this.setState({
        doctorTreatedAddresses: doctorTreatedAddresses
      });
    };

    onSearchProvider = async(val)=>{
      const { providers } = this.props;
      this.doctors = [];
      if (!!providers && providers.length > 0) {
        providers.forEach((doctor) => {
          this.doctors.push({
            label: doctor.DoctorName,
            value: doctor.uid,
          });
        });
      }

      if (!!val) {
        this.doctors = this.doctors.filter((item) => {
            return item.label.toLowerCase().includes(val.toLowerCase());
          }).sort((a, b) => b.label - a.label);

       // if (this.doctors.length === 0) {
          let docs = await this.props.app.dm.addDoctorsDomain.getProviderOnSearch(val);
          if (!!docs && docs.length > 0) {
            docs.forEach((doctor) => {
              this.doctors.push({
                label: doctor.DoctorName,
                value: doctor.uid,
              });
            });
          }
       // }
      }
      this.doctors = await this.filterItems(this.doctors);
      this.form.elmts.doctorName.setItems(this.doctors);
      return this.doctors;
    }
    
    filterItems = async(list) => {
      let filterArray = list.reduce((acc, current) => {
        const x = acc.find(item => item.value === current.value);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filterArray;
    }

    onCancelClick = async () => {
      const { uid } = this.props;
      if (!!uid) {
        this.props.app.nav.to("pracDashboard");
      } else {
        this.form.reset();
        this.setState({
          attachments: [],
          isDisable: false,
          isEnterDob: false,
          isEnterSSID: false,
          loaderVisible: false
        });
        this.props.app.nav.to("pracDashboard");
      }
    };

    // Mobile number validation.
    async mobileNumberText(val, from) {
      let mobNumText = val;
      if (!!val) {
        let cleaned = ("" + mobNumText).replace(/\D/g, "").substring(0, 10);
        this.form.elmts.mobileNumber.setValue(cleaned);
        if (!!from && cleaned.length > 0) {
          this.setState({
            isEnterMobileNo: true,
          });
        } else {
          this.setState({
            isEnterMobileNo: false,
          });
        }
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          let formattedString = "";
          if (match[1] !== undefined) {
            match[1] = this.form.elmts.countryCode.value.value;
          } else {
            match[1] = "+1 ";
          }
          formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
          this.form.elmts.mobileNumber.setValue(formattedString);
          this.form.elmts.mobileNumber.setError(null);
        }
      } else {
        this.setState({
          isEnterMobileNo: false,
        });
      }
    }

    onEmailChangeText = async (val) => {
      if (!!val && val.trim().length > 0) {
        this.setState({
          isEnterEmail: true,
        });
      } else {
        this.setState({
          isEnterEmail: false,
        });
      }
    };

    uploadDocumentatS3 = async (signedUrl, file) => {
      var options = {
        headers: {
          "content-type": file.contentUri.replace("data:", "").replace(";base64", ""),
        }
      };
      await this.props.app.dm.rest.putS3(signedUrl, Buffer.from(file.content, 'base64'), options);
    }

    handleSubmit = async (form) => {
      this.setState({
        isDisable: true,
        loaderVisible: true
      });
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const { userLoginDetail } = this.props;
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      try {
        const { name, email, dOB, ssId, mobileNumber, doctorName, countryCode, doctorTreatmentAddress } = form.values;
       if (!!dOB) await this.onDateBlur();
        if (userType === USER_TYPE.PCH_PROVIDER) {
          if ((!ssId || ssId === "") && (!dOB || dOB === "")) {
            this.form.elmts.ssId.setError(CommonString.SSIDRequired);
            this.form.elmts.dOB.setError(CommonString.DOBRequired);
            this.setState({
              isDisable: false,
              loaderVisible: false
            });
            return false;
          }
          else if ((!ssId || ssId === "") && !await this.validateDate(dOB)) {
            this.form.elmts.dOB.setError(CommonString.DateFormatRequired);
            this.setState({
              isDisable: false,
              loaderVisible: false
            });
            return false;
          }
        }
        const { attachments, isAutoFilled } = this.state;
        if (!attachments || attachments.length === 0) {
          this.setState({
            isDisable: false,
            loaderVisible: false
          });
          let error = "Please upload the bill.";
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Please upload the bill."
            })
          }
          return false;
        }
        let isHasPractioner = false;
        const { uid, EIN } = this.props.userLoginDetail;
        isHasPractioner = !!EIN ? true : false;
        if (!isHasPractioner) {
          let users = await this.props.app.dm.userRegistration.bUserExistsByEmail(this.props.userLoginDetail, "Email", "EIN");
          if (!!users && users.length > 0) {
            isHasPractioner = !!users[0].EIN ? true : false;
          }
        }
        if (!isHasPractioner) {
          this.setState({
            isDisable: false,
            loaderVisible: false
          });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.EINMissing);
          return false;
        }

        // TODO check mobile/email must be not belong to any Practice Group user
        let documentId = CommonUtility.generate20Character();
        let currentDateTime = DateUtils.dateAndTimeWithAmPm(new Date().toUTCString(), "PCH");
        const uploadBill = {
          name: name,
          email: email,
          mobileNumber: countryCode.value + " " + mobileNumber.replace(/[^\w\d\s]/g, "").replace(" ", ""), //mobileNumber.replace(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, ""),
          ssId: !!ssId ? ssId : "",
          dOB: !!dOB ? dOB : "",
          doctorUid: doctorName.value,
          providerName: doctorName.label,
          userProfileUid: uid,
          createdBy: uid,
          uploadedDateTime: currentDateTime,
          parentUid: !!userLoginDetail.parentUid ? userLoginDetail.parentUid : uid,
          parentName: !!userLoginDetail.parentUid ? `${userLoginDetail.parentUserDetail.firstName} (${userLoginDetail.firstName} ${userLoginDetail.lastName})`  : userLoginDetail.firstName,
          documentId: documentId
        };

        let pracDetails = await this.props.app.dm.userRegistration.bUserExistsByEmail(uploadBill, "Phone", "uid payerId firstName lastName email mobileNumber userType");
        if (!!pracDetails && !!pracDetails.length > 0 && pracDetails[0].userType === USER_TYPE.PCH_PROVIDER) {
          this.setState({
            isDisable: false,
            loaderVisible: false
          });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.PhoneExistsWithPractice);
          return false;
        }
        else {
          let pracDetails = await this.props.app.dm.userRegistration.bUserExistsByEmail(uploadBill, "Email", "uid payerId firstName lastName email mobileNumber userType");
          if (!!pracDetails && !!pracDetails.length > 0 && pracDetails[0].userType === USER_TYPE.PCH_PROVIDER) {
            this.setState({
              isDisable: false,
              loaderVisible: false
            });
            this.props.app.dm.commonDomain.showToastr("error", CommonString.EmailExistsWithPractice);
            return false;
          }
        }
        let location = Platform.OS === "web" ? window.location.origin : NATIVE_APP_URL;
        let uploadDocs = attachments.map(async(doc, i) => {
            doc.documentId = documentId;
            doc.seqNo = i + 1;
            let fileData = {...doc};
            delete fileData.content;
            let response = await this.props.app.dm.uploadBillDomain.uploadDocumentOnServer(fileData);
            if(!!response && !!response.content)
                await this.uploadDocumentatS3(response.content, doc)
            return response;
          });
         let docRes = await Promise.all(uploadDocs);
         if (docRes.filter(x => x.uid === null).length === 0) {
          if(doctorTreatmentAddress && doctorTreatmentAddress.label) uploadBill.insuranceMemberTreatedAt = { label: doctorTreatmentAddress.label, value: doctorTreatmentAddress.value };
          let res = await this.props.app.dm.uploadBillDomain.uploadBill(uploadBill);
          if (!!res && !!res.uid) {
            let uniqueCode = DateUtils.formatYYYYMMDDHHMMSS() + res.uid.toUpperCase();
            uniqueCode = uniqueCode.replace(/[^a-zA-Z0-9]/g, "");
            this.props.app.dm.uploadBillDomain.uploadDocumentAtSFTP(res.uid, `${location}/claims/uploaded-bills/${!!isAutoFilled ? "E": "N"}/${res.uid}`, uniqueCode);
            uploadBill.uid = res.uid;
            uploadBill.uniqueCode = uniqueCode;
            uploadBill.parentName = userLoginDetail.firstName;
            uploadBill.createdAt = new Date().toUTCString();
            analyticsLogs(CommonString.PROVIDER_UPLOAD_BILL, CommonString.PROVIDER_BILL_SUBMITTED_ACTION, this.props.app);
            await AsyncStorage.setItem("receipts", JSON.stringify(uploadBill));
            this.props.app.dm.commonDomain.showToastr("success", CommonString.BillUploaded);
            this.props.app.nav.to("submittedReceiptRoute", { uid: res.uid });
            return false;
          } else {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }
        }
        else {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.ErrorOnFileUpload);
        }
      } catch (error) {
        this.props.app.dm.commonDomain.showToastr("error", error);
      }
      this.setState({
        isDisable: false,
        loaderVisible: false
      });
    };

    onFileInputChange = async (val, status) => {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const { userLoginDetail } = this.props;
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      if(!!!isMobileDevice)
        await this.child.current.wrappedInstance.pullErrorText("");

      if (status === "done") {
        let x = [val];
        const { attachments } = this.state;
        let existingFiles = attachments.filter((value) => value.filename === val.filename && value.actualsize === val.actualsize);
        if (existingFiles.length > 0) {
          let error = "File has been already added."
          if(!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "File has been already added."
            })
          }
          return false;
        }

        if (userType === USER_TYPE.PCH_PROVIDER && attachments.length > 20) {
          let error = "Maximum 20 attachment allowed.";
          if(!!!isMobileDevice)
            await this.child.current.wrappedInstance.pullErrorText(error);
          else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Maximum 20 attachment allowed.",
              isAddAttachmentsVisible: false
            })
          }
          return false;
        }
        
        let filesSize = attachments.reduce((prevVal, val) => prevVal + val.actualsize, 0);
        filesSize = filesSize + val.actualsize;
        if (filesSize / 1024 / 1024 > 10) {
          let error = "Maximum 10MB file size allowed.";
          if(!!!isMobileDevice){
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Maximum 10MB file size allowed."
            })
          }
            // this.props.app.dm.commonDomain.showToastr("error", error);
            // alert(error)
          return false;
        }
        this.setState({
          loaderVisible: true //false //true
        });
        this.setState({ isAddAttachmentsVisible: false, loaderVisible: false, attachments: [...attachments, ...x] });
      } else if (status === "removed") {
        const { attachments } = this.state;
        this.setState({
          attachments: attachments.filter((value) => value.filename !== val.filename && value.actualsize !== val.actualsize),
        });
      }
    };

    onDeleteFile = async (ind) => {
      const { attachments } = this.state;
      this.setState({
        attachments: attachments.filter((value, index, arr) => index !== ind),
      });
    };

    clearText = async () => {
      this.form.elmts.ssId.setValue("");
      this.setState({
        isEnterSSID: false,
      });
    };

    clearPatientDetail = async () => {
      this.form.elmts.email.setValue(null);
      this.form.elmts.mobileNumber.setValue(null);
      this.setState({
        isAutoFilled: false,
        isEnterMobileNo: false,
        isEnterEmail: false,
      });
    };

    uniqueItems = async (a) => {
      var prims = { boolean: {}, number: {}, string: {} },
        objs = [];

      return a.filter(function (item) {
        var type = typeof item;
        if (type in prims) return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
        else return objs.indexOf(item) >= 0 ? false : objs.push(item);
      });
    };

    validatePatientDetails = async (type, search) => {
      const { email, mobileNumber, countryCode } = this.form.values;
      const { isAutoFilled } = this.state;
      if (!isAutoFilled && type === "email" && !!email) {
        let res = await this.props.app.dm.uploadBillDomain.getUsersByEmail(email, type);
        this.setState({
          patients: await this.uniqueItems([...new Set(res)]),
        });
        if (res.length === 1) {
          this.mobileNumberText(res[0].mobileNumber.split(" ").pop());
          this.form.elmts.email.setValue(res[0].email);
          this.form.elmts.email.setError(null);
          this.setState({
            isAutoFilled: true,
          });
        } else if (!!search && (!res || res.length === 0)) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.NoRecord);
        }
        if (!!search) {
          this.setState({
            isAutoFillVisible: true,
          });
        }
      } else if (!isAutoFilled && type === "mobileNumber" && !!mobileNumber) {
        let mob = countryCode.value + " " + mobileNumber.replace(/[^\w\d\s]/g, "").replace(" ", "");
        let res = await this.props.app.dm.uploadBillDomain.getUsersByMobileNumber(mob, type);
        this.setState({
          patients: await this.uniqueItems([...new Set(res)]),
        });
        if (res.length === 1) {
          this.mobileNumberText(res[0].mobileNumber.split(" ").pop());
          this.form.elmts.email.setValue(res[0].email);
          this.form.elmts.email.setError(null);
          this.setState({
            isAutoFilled: true,
          });
        } else if (!!search && (!res || res.length === 0)) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.NoRecord);
        }
        if (!!search) {
          this.setState({
            isAutoFillVisible: true,
          });
        }
      }
    };

    selectUserDetail = async (row) => {
      this.mobileNumberText(row.mobileNumber.split(" ").pop());
      this.form.elmts.email.setValue(row.email);
      this.form.elmts.email.setError(null);
      this.setState({
        isAutoFilled: true,
        isAutoFillVisible: false,
      });
    };

    onCancelAutoFill = async () => {
      this.setState({
        isAutoFilled: false,
        isAutoFillVisible: false,
      });
    };
    onEnterSSIdText = async (val) => {
      if (!!val) {
        let value = ("" + val).replace(/\D/g, "").substring(0, 4);
        if (!!value) {
          this.form.elmts.ssId.setValue(value);
          this.form.elmts.ssId.setError(null);
          this.form.elmts.dOB.setValue(null);
          this.form.elmts.dOB.setError(null);
          this.setState({
            isEnterSSID: true,
            isEnterDob: false,
            showDobDatePicker: false,
          });
        } else this.clearText();
      } else this.clearText();
    };

    validateDate = async (testdate) => {
      let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
      return date_regex.test(testdate);
    };

    onDateBlur = async () => {
      const { dOB } = this.form.values;
      this.handleDobShowHideDatePicker();
      if (!!dOB) {
        let dat = await this.validateDate(dOB);
        if (!!dat) {
          let formatedDate = DateUtils.formatDateInMMDDYYYY(dOB);
          dat = await this.validateDate(formatedDate);
          if (!!dat) {
            this.form.elmts.dOB.setValue(formatedDate);
            this.form.elmts.dOB.setError(null);
            this.form.elmts.ssId.setError(null);
            this.onDateValueChange(dOB, false);
          } else {
            this.form.elmts.dOB.setValue(dOB);
            this.form.elmts.dOB.setError(CommonString.DateFormatRequired);
          }
        } else {
          this.form.elmts.dOB.setValue(dOB);
          this.form.elmts.dOB.setError(CommonString.DateFormatRequired);
        }
      } else {
        this.form.elmts.dOB.setError(null);
      }
    };
 
    onDateValueChange = async (val, autoformat = true) => {
      // this.onDateBlur()
      const { isEnterSSID } = this.state;
      this.handleDobShowHideDatePicker();
      if (!!isEnterSSID) {
        this.form.elmts.dOB.setValue(null);
        return false;
      }
      if (!!val) {
        if (!!autoformat) {
          let date = DateUtils.formatDateInMMDDYYYY(val);
          if (!!date && new Date(date) > new Date()) {
            this.form.elmts.dOB.setValue(null);
            this.form.elmts.dOB.setError(CommonString.DOBCanNotFuture);
            return false;
          } else if (!!date && DateUtils.isOlderThanMaxLimit(val)) {
            this.form.elmts.dOB.setValue(null);
            this.form.elmts.dOB.setError(CommonString.DOBCanNotOlderThan125);
            return false;
          }
          else
          {
            this.form.elmts.dOB.setValue(date);
          }
           
        }
        else
        {
          if (!!val && new Date(val) > new Date()) {
            this.form.elmts.dOB.setValue(null);
            this.form.elmts.dOB.setError(CommonString.DOBCanNotFuture);
            return false;
          }
          else
            this.form.elmts.dOB.setValue(val);
        }
        this.setState({
          isEnterSSID: false,
          isEnterDob: true,
          showDobDatePicker: false,
        });
      } else {
        this.setState({
          isEnterSSID: false,
          isEnterDob: false,
          showDobDatePicker: false,
        });
      }
    };

    onCancel = async () => {
      this.setState({
        isVisible: false,
      });
    };

    readText = async (val) => {
      this.ssid = val;
    };

    onPDFClose = async () => {
      this.setState({ isDocVisible: false });
    };

    alertOk = async (msg) => {
      if (msg.includes("Please add Provider")) {
        const { userLoginDetail } = this.props;
        let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
        if (userType === USER_TYPE.PCH_PATIENT) {
          this.props.app.nav.to("addInsuranceRoute", { mode: "add" });
        } else if (userType === USER_TYPE.PCH_PROVIDER) {
          this.props.app.nav.to("addProviderRoute", { mode: "add" });
        }
      }
      this.setState({
        isAlertVisible: false,
        alertMessage: ''
      })
    };
    closeAlertModal = () => { 

    }
    
    addProvider = () => {
      this.props.app.nav.to("addProviderRoute", { mode: "add" });
    }

    handleDobShowHideDatePicker = (val) => {
      if(val === "open" && this.state.showDobDatePicker) {
        return;
      } else if (val === "close" || this.state.isEnterSSID) {
        this.setState({showDobDatePicker: false});
        return;
      } else {
        this.setState({showDobDatePicker: !this.state.showDobDatePicker})
      }
    }

    onClearDobDate = () => {
        const form = this.props.app.ui.form("uploadBills");
        form.elmts.dOB.setValue(null);
        form.elmts.dOB.setError(null);
        this.setState({
            showDobDatePicker: false,
            isEnterSSID: false,
            isEnterDob: false
        })
    }

    onAddressChange = (val) => {
      if (val && val.label && (val.label === "No Results Found" || val.label.includes("Type in a search value"))) {
        this.form.elmts.doctorTreatmentAddress.setValue(null);
        return false;
      };
    };

    renderAlertPopup() {
      const { isAlertVisible, alertMessage } = this.state;
      return (
        <PopupModalComponent
          visibility={!!isAlertVisible}
          bodyWrapper={[commonStyles.smallModalBodyWrapper]}
          messageType={MESSAGE_TYPE.info}
          headingIcon={true}
          heading={"Info"}
          modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
          bodyContent={alertMessage}
          okLabel={"Ok"}
          handleOk={()=> this.alertOk(alertMessage)}
        />
      );
    }
    addPracPopupMenu() {
      let items = [];
      items.push(<PopUpMenuItem onPress={() => this.alertOk()} label={"Add Provider"} key={"Add Provider"} value={"Add Provider"} />);
      return (
        <PopUpMenu
          controlIcon="dotsVertical"
          controlIconSize="small"
          key="trippleDot"
          direction="downLeft"
          style={[commonFontStyles.workSans, commonStyles.heading6]}>
          {items}
        </PopUpMenu>
      );
    }

    renderAutoFillPopup() {
      const { isAutoFillVisible, patients } = this.state;
      return (
        <ModalPopUp
          visibility={isAutoFillVisible && patients.length > 1}
          isFooter={true}
          bodyWrapper={[commonStyles.mediumModalBodyWrapper]}
          heading="Patient Details"
          handleCancel={this.onCancelAutoFill}
        >
          <View style={[styles.autoFillModelBodyWrapper]}>
            <ScrollView style={[styles.autoFillModel, customCommonStyles.customAutoFillModel]}>
              {patients.map((pat, i) => {
                return (
                  <Touchable key={i} style={[styles.tr]} onPress={() => this.selectUserDetail(pat)}>
                    <View style={[styles.innertTr]}>
                      <View style={[styles.fillWidth]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.listLabel]}>Name: </Text>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.listValue]}>
                          {pat.firstName} {pat.lastName}
                        </Text>
                      </View>
                      <View style={[styles.fillWidth]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.listLabel]}>Mobile Number: </Text>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.listValue]}>{pat.mobileNumber}</Text>
                      </View>
                      <View style={[styles.fillWidth]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.listLabel]}>Email ID: </Text>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.listValue]}>{pat.email}</Text>
                      </View>
                    </View>
                  </Touchable>
                );
              })}
            </ScrollView>
            {/* <View style={[styles.btnHolder]}>
              <Button
                title={"Close"}
                style={[commonStyles.blueBorderButton, commonStyles.margin0Auto, styles.cancelBtn]}
                onPress={this.onCancelAutoFill}
                key="billCancel"
                titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading6, commonStyles.transperentBtnColor]}
              />
            </View> */}
          </View>
        </ModalPopUp>
      );
    }

    render() {
      const { attachments, uid, isEnterSSID, isEnterDob, isDisable, isAutoFilled, isEnterMobileNo, isEnterEmail } = this.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <>
            {this.renderAutoFillPopup()}
            {this.renderAlertPopup()} 
            <View style={[styles.uploadBillSection, commonStyles.flexGrow1]}>
              <Form
                name="uploadBills"
                style={[
                  styles.practUploadBillsScreenForm,
                  this.props.r14.navigation.activeRouteName === "uploadedBillsRoute" ? styles.uploadBillsForm : null,
                ]}
                validateBeforeSubmit={true}
                validators={CommonValidators.getFormValidator()}
                onSubmit={this.handleSubmit}>
                <View style={[commonStyles.flex1, styles.customflexDirection, styles.inputHolder, customCommonStyles.customAttachFileScetion, styles.customflexGrow, commonStyles.justifyContentSpaceBetween]}>
                  <View
                    style={[
                      styles.attachOpt,
                      commonStyles.flex1,
                      styles.leftInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.flex1,
                      commonStyles.flexDirectionRow,
                      styles.extraRightMargin
                    ]}>
                    <View style={[styles.fileUploadWrapper]}>
                      <FileUploadComponent
                        ref ={this.child}
                        maxFiles={20}
                        fileTypes={"application/pdf,image/jpeg,image/jpg,image/png"} //,application/pdf
                        fileTypeLabel={"pdf,jpeg,jpg,png,"}
                        onValueChange={this.onFileInputChange}
                        labelText="Drag & Drop Bill (*.pdf, *.jpg, *.jpeg, *.png)"
                        fileInputStyle={[commonStyles.margin0Auto]}
                        attachmentFieldOuterCustomHeight={[styles.attachmentFieldOuter, customCommonStyles.customAttachmentFieldOuter]}
                      />
                    </View>
                  </View>
                  <View style={[commonStyles.dFlex, commonStyles.flex1, commonStyles.flexBasis48, !!uid ? null : styles.rightInputHolder, styles.extraLeftMargin]}>
                    <View
                      style={[
                        commonStyles.flexGrow1,
                        customCommonStyles.customAttachmentsHolder,
                        attachments && !!attachments.length ? null : [commonStyles.dFlex, commonStyles.alignItemsCenter],
                      ]}>
                      {attachments && !!attachments.length ? (
                        attachments.map((el, i) => (
                            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.attachmentCard, commonStyles.justifyContentSpaceBetween]} key={`atta_${i}`}>
                              <View style={[customCommonStyles.textOverflowEllipsis]}>
                              <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight500, commonStyles.dBlock, customCommonStyles.textOverflowEllipsis]}>
                                  {el.filename}
                                </Text>
                                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.lightGray]}>
                                  {el.size}
                                </Text>
                              </View>
                              <Touchable onPress={() => this.onDeleteFile(i)}>
                                <Image source={deleteIcon} style={commonStyles.icon20} />
                              </Touchable>
                            </View>
                        ))
                      ) : null}
                    </View>
                  </View>
                </View>
                <View style={[commonStyles.flex1, styles.customflexDirection, styles.inputHolder, styles.customAlignItemsStart, styles.customMargin, styles.customflexGrow, commonStyles.justifyContentSpaceBetween]}>
                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.leftInputHolder, commonStyles.flexBasis48, styles.extraRightMargin, styles.maxWidth48]}>
                    <SelectMenuField
                      name="doctorName"
                      label={CommonString.ProviderNameLabel + "*"}
                      required={CommonString.SelectProviderNameRequired}
                      items={this.doctors}
                      onValueChange={this.onDoctorSearch}
                      searchable
                      itemLoader={async ({ search }) => {
                        return await this.onSearchProvider(search);
                      }}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, styles.customSelectMenuInputLabelStyle]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      style={[customCommonStyles.inputHeight, styles.maxWidth75]}
                    />
                    <Touchable
                        style={[
                        isMobileDevice ? commonStyles.marginBottom30 : [commonStyles.marginTop5],
                          styles.addInsuranceProvider,
                        ]}
                          onPress={this.addProvider}>
                        <View
                          style={[
                            commonStyles.flex1,
                            commonStyles.alignItemsCenter,
                            commonStyles.flexDirectionRow,
                          ]}
                        >
                          <View style={styles.plusIcon}>
                            <Image
                              source={plusSolidIcon}
                              style={[commonStyles.icon18]}
                            />
                          </View>
                          <View>
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight400,
                                commonStyles.smallHeading,
                                styles.custompaddingLeftRight,
                              ]}
                            >
                              Add Provider
                            </Text>
                          </View>
                        </View>
                      </Touchable>
                  </View>
                    <View
                      style={[
                        commonStyles.flex1,
                        styles.rightInputHolder,
                        commonStyles.flexBasis48,
                        styles.addIncProText,
                        commonStyles.itemWithTwoCol,
                        styles.customAlignItemsStart,
                        styles.maxWidth48
                      ]}>
                      <SelectMenuField
                        name="doctorTreatmentAddress"
                        label={CommonString.ProviderAddressLabel}
                        items={this.state.doctorTreatedAddresses}
                        onValueChange={this.onAddressChange}
                        searchable
                        itemLoader={async ({ search }) => {
                        let items = this.state.doctorTreatedAddresses;
                        return items
                            .filter((item) => {
                            return item.label.toLowerCase().includes(search.toLowerCase());
                            })
                            .sort((a, b) => b.label - a.label);
                        }}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, styles.customSelectMenuInputLabelStyle]}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                        style={[customCommonStyles.inputHeight, styles.maxWidth100]}
                      />
                    </View>
                </View>
                <View style={[commonStyles.flex1, styles.customflexDirection, styles.inputHolder, styles.customflexGrow, commonStyles.justifyContentSpaceBetween]}>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.leftInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.flexDirectionRow,
                      styles.mobileNumberInput,
                      styles.extraRightMargin,
                    ]}>
                    <TextInputField
                      name="+1"
                      value="+1"
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.countryCode, commonStyles.marB0]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}

                      helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      disabled={true}
                      readOnly={true}
                    />
                    <SelectMenuField
                      style={[commonFontStyles.workSans, commonStyles.heading6, styles.countryCode, styles.hideField]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                      name="countryCode"
                      required={CommonString.SelectCountryCodeRequired}
                      items={[{ label: "+1", value: "+1" }]}
                      // onValueChange={this.onDomainExtensionChange}
                      searchable
                      disabled={true}
                      itemLoader={async ({ search }) => {
                        let items = [{ label: "+1", value: "+1" }];
                        return items
                          .filter((item) => {
                            return item.label.toLowerCase().includes(search.toLowerCase());
                          })
                          .sort((a, b) => b.label - a.label);
                      }}
                    />
                    <TextInputField
                      name="mobileNumber"
                      label={CommonString.PatientMobileNoLabel + "*"}
                      required={CommonString.EnterPatientMobileNoRequired}
                      validator="max10phoneNoValidator"
                      onChangeText={(val) => this.mobileNumberText(val, "control")}
                      onBlur={() => this.validatePatientDetails("mobileNumber")}
                      iconRight={!!isAutoFilled ? "close" : !!isEnterMobileNo ? "search" : null}
                      onIconRightPress={() => (!!isAutoFilled ? this.clearPatientDetail() : this.validatePatientDetails("mobileNumber", "search"))}
                      readOnly={!!isAutoFilled}
                      style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.margin0, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                      helperTextStyle={[
                        styles.customHelperTextStyleMobile,
                        styles.leftMinus12,
                        commonStyles.helperTextFontStyle,
                        commonFontStyles.workSans,
                      ]}
                    />
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis48, styles.emailInput, customCommonStyles.customEmailInput, styles.extraLeftMargin]}>
                    <TextInputField
                      name="email"
                      label={CommonString.PatientEmailLabel}
                      required={CommonString.EnterPatientEmailRequried}
                      validator="validateEmail"
                      onBlur={(val) => this.validatePatientDetails("email")}
                      iconRight={!!isAutoFilled ? "close" : !!isEnterEmail ? "search" : null}
                      onChangeText={(val) => this.onEmailChangeText(val)}
                      onIconRightPress={() => (!!isAutoFilled ? this.clearPatientDetail() : this.validatePatientDetails("email", "search"))}
                      readOnly={!!isAutoFilled}
                      style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.margin0, customCommonStyles.inputHeight,]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    />
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, styles.inputHolder, styles.customflexDirection, customCommonStyles.customSSIDDOBHolder, styles.customflexGrow, commonStyles.justifyContentSpaceBetween]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, commonStyles.fullWidth, customCommonStyles.ssidandDobHolder]}>
                    <TextInputField
                      name="ssId"
                      label={CommonString.PatientSsidLabel + "*"}
                      validator="only4Num"
                      disabled={!!isEnterDob}
                      readOnly={!!isEnterDob}
                      iconRight={!!isEnterSSID ? "close" : null}
                      onChangeText={this.onEnterSSIdText}
                      onIconRightPress={() => this.clearText()}
                      style={[commonFontStyles.workSans, commonStyles.heading6, !!isEnterDob ? styles.inputDisable : null, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                      ]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    />
                  </View>
                  <View
                    style={[
                      commonStyles.textAlignCenter,
                      customCommonStyles.orSection,
                    ]}
                  >
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading5,
                        commonStyles.textAlignCenter,
                      ]}
                    >
                      OR
                    </Text>
                  </View>
                    {Platform.OS === 'web' ? <>
                  <View style={[styles.rightInputHolder, commonStyles.dFlex, commonStyles.flexDirectionColumn, commonStyles.flexBasis48, styles.dobInput, commonStyles.fullWidth, customCommonStyles.customBobInputFieldHolder, !!isEnterSSID && styles.disabledPOBox]}>
                      {this.state.showDobDatePicker && !isEnterSSID ? <ReactDatePicker
                        name={"dOB"}
                        form="uploadBills"
                        value={this.props.app.ui.form("uploadBills").values.dOB}
                        mode={"modal"}
                        onValueChange={this.onDateValueChange}
                        onBlur={this.onDateBlur}
                        visible={this.state.showDobDatePicker}
                        closeDatePicker={this.handleDobShowHideDatePicker}
                      /> : null }
                      <TextInputField
                        name={"dOB"}
                        label={CommonString.PatientDobLabel + "*"}
                        secure={false}
                        readOnly={true}
                        disabled={!!isEnterSSID}
                        onFocus={() => this.handleDobShowHideDatePicker('open')}
                        icon="calendar"
                        onIconPress={() => this.handleDobShowHideDatePicker()}
                        iconRight={this.props.app.ui.form("uploadBills") && this.props.app.ui.form("uploadBills").values.dOB ? "close" : ""}
                        onIconRightPress={this.onClearDobDate}
                        style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                        helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                      />
                    </View>
                    </> : <>
                  <View style={[styles.rightInputHolder, commonStyles.dFlex, commonStyles.flexDirectionRow, commonStyles.flexBasis48, styles.dobInput, commonStyles.fullWidth, customCommonStyles.customBobInputFieldHolder]}>
                    <DatePicker
                      style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.marB0, !!isEnterSSID ? styles.inputDisable : null, customCommonStyles.customBobInputField, customCommonStyles.inputHeight]}
                      name={"dOB"}
                      label={CommonString.PatientDobLabel + "*"}
                      mode={"dropdown"}
                      textInputEditable={true}
                      disabled={!!isEnterSSID}
                      readOnly={!!isEnterSSID}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                      helperTextStyle={[styles.labelStyle, commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      onValueChange={this.onDateValueChange}
                      onBlur={this.onDateBlur}
                    />
                    { Platform.OS !== "web" &&
                    <TextInputField
                        name={"dOB"}
                        label={CommonString.PatientDobLabel}
                        secure={false}
                        disabled={true}
                        style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                        helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                        />
                        }
                      </View>
                        </>}
                </View>
                <View
                  style={[
                    styles.customMarTAuto,
                    commonStyles.flex1,
                    styles.customFlexProperties,
                    styles.btnWrapper,
                    commonStyles.flexDirectionRow,
                  ]}
                >
                  <View
                    style={[commonStyles.marginRight8, styles.formBtnsHolder]}
                  >
                    <Button
                      title={"Cancel"}
                      style={[commonStyles.blueBorderButton, styles.formBtns]}
                      titleTextStyle={[
                        commonStyles.blueBorderButtonColor,
                        commonStyles.fontWeight500,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                      ]}
                      onPress={this.onCancelClick}
                    />
                  </View>
                  <View style={[commonStyles.marginLeft8, styles.formBtnsHolder]}>
                    <SubmitButton
                      title={"Upload"}
                      disabled={isDisable}
                      style={[commonStyles.blueButton, styles.submitBtn, styles.formBtns, customCommonStyles.noBoxShadow,]}
                      titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6]}
                    />
                  </View>
                </View>
              </Form>
            </View>
            <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} />
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  uploadBillsScreen: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          backgroundColor: "#fff",
          marginBottom: 45,
        };
    },
  },
  modalBackdrop: {
    backgroundColor: "#00000052",
    overflowX: "auto",
  },
  bodyWrapper: {
    width: 300,
    borderRadius: 4,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // minHeight: 150,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          fontWeight: 600,
        };
    },
  },
  addAttachmentBodyWrapper: {
    marginTop: "auto",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: "100vw", ///  Need to check with Ganesh
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
  },
  commentsField: {
    minHeight: 128,
    resize: "vertical",
  },
  closePopup: {
    width: "20%",
    height: 8,
    borderRadius: 25,
    backgroundColor: "#e1e1e1",
    marginBottom: 25,
  },
  attachOpt: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          //padding: 5,
        };
      }
    },
  },
  attachments: {
    // paddingLeft: 50,
  },
  ucountIconDiv: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
    backgroundColor: "#cff2ff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    marginTop: 5,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  modalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  filePassowrdModalBodyWrapper: {
    backgroundColor: "#fff",
    // border: "1px solid #a7a7a7",
    padding: 10,
    width: 350,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // width: "100vw",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          minHeight: 400,
          // marginTop: "auto",
        };
      }
    },
  },

  addInsuranceAlert: {
    height: 150,
  },
  inputDisable: {
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: 0.5,
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMarTAuto: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: -15,
        };
      }
    },
  },
  customFlexProperties: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
        };
      }
      if (width >= 991) {
        return {
          justifyContent: "flex-end",
        };
      }
    },
  },
  submitBtn: {
    minWidth: 108,
  },
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  formBtnsHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  formBtns: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailRow: {
    borderBottom: "1px solid rgb(218, 218, 218)",
    paddingBottom: 16,
    marginBottom: 15,
    flexBasis: "48%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingBottom: 0,
          flexBasis: "100%",
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  addIncProText: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  countryCode: {
    maxWidth: 70,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 30,
        };
      }
    },
  },
  customHelperTextStyleMobile: {
    marginLeft: -58,
  },
  btnHolder: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  uploadBillSection: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dfdfdf",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          borderWidth: 0,
          // paddingTop: 6,
        };
      }
    },
  },
  dobInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: -13,
        };
      }
    },
  },
  emailInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: -5,
        };
      }
    },
  },
  cameraOpt: {
    // border: "2px solid #d9d9d9",
    // borderRadius: 3,
    height: "100%",
    display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    paddingTop: 8,
    paddingBottom: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 6,
          paddingBottom: 6,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  marginTopMin20: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -20,
        };
      }
    },
  },
  marginTopMin10: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -10,
        };
      }
    },
  },
  pageTitle: {
    marginTop: 15,
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
          marginBottom: 10,
        };
      }
    },
  },
  mobileNumberInput: {
    marginBottom: 32,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: 15,
          // marginBottom: 30,
        };
      }
    },
  },
  addPracPopupMenu: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: -16,
        };
      }
    },
  },
  attachmentsHolder: {
    maxHeight: 120,
    overflowY: "auto",
    flexBasis: null,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  cameraOptHolder: {
    flex: 1,
    flexGrow: 1,
  },
  uploadedBillAttachFile: {
    // marginBottom: 10,
  },
  helperTextStyleDob: {
    position: "static",
  },
  leftMinus12: {
    left: -12,
  },
  autoFillModelBodyWrapper: {
    maxHeight:500,
    height:500,
    // maxHeight: "80vh",
    // overflowY: "auto",
    screen: ({ width }) => {
      if (width >= 320 && width <= 359) {
        return {
          height: 400,
          width: "100%",
        };
      }
      if (width >= 360 && width <= 991) {
        return {
          height: 400,
        };
      }
    },
  },
  autoFillModel: {
    height: "100%",
    overflowY: "auto",
    marginBottom: 15
  },
  fillWidth: {
    width: "100%",    
    flex: 1,
    flexDirection: "row",
    wordBreak: "break-all",
    screen: ({ width }) => {
      if (width >= 320 && width <= 359) {
        return {
          flexDirection: "column",
          marginBottom: 10,
        };
      }
    },
  },
  listLabel: {
    minWidth: 125,
    fontWeight: 500,
    display: "inline-flex"
  },
  tr: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    // borderBottomWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#cfcfcf",
    borderBottom: "1px solid #a7a7a7",
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 5,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          borderBottomWidth: 0,
        }
      };
    }
  },
  innertTr: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 10,
          paddingRight: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "#cfcfcf",     
        };
      }
    },
  },
  addInsuranceProvider: {
    alignItems: "center",
    marginLeft: 15,
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          minWidth: "25%",
          maxWidth: "25%",
        };
      }
    },
  },
  customAlignItemsStart: {
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: null,
        };
      }
    },
  },
  mViewFontStyle: {
    fontSize: 16,
    fontWeight: 600,
  },
  attachmentCard: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    padding: 10,
    marginBottom: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 5,
          marginBottom: 0,
        };
      }
    },
  },
  customMargin: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginBottom: -25,
        };
      }
    },
  },
  updatedonForMView: {
    flexDirection: "row",
  },
  PDFViewBodyWrapper: {
    width: "80vw",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          // height: "75vh",
          marginTop: "auto",
          borderRadius: "10px 10px 0 0",
        };
      }
      if (width === 411 && height === 731) {
        return {
          height: "64vh",
        };
      }
    },
  },
  PDFModalContent: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          overflowY: "scroll",
          marginTop: 0,
        };
      }
    },
  },
  custompaddingLeftRight: {
    ...StyleSheet.padding(0, 0, 0, 5),
  },
  disabledPOBox: {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "default",
  },
  fileUploadWrapper: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#a1a4a8",
    borderRadius: 5,
    flexBasis: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {};
      }
    },
  },
  buttons: {
    height: 38,
    marginRight: "auto",
    marginLeft: "auto",
  },
  practUploadBillsScreenForm: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minHeight: 800,
        };
      }
    },
  },
  uploadBillsForm: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          minHeight: 800,
        };
      }
    },
  },
  attachmentFieldOuter: {
    width: "100%",
    position: "relative",
  },  
  btnWrapper: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          justifyContent: "flex-end",
          marginBottom: 150,
        };
      }
    },
  },
  modalBtnWrapper: {
    flex: 1,
    justifyContent: "center",
    marginTop: 24,
    // marginBottom: 15,
  },
  cancelBtn: {
    minWidth: 150,
  },
  customflexGrow: {
    flexGrow: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexGrow: 1,
        };
      }
    },
  },
  closeBtn: {
    position: "absolute",
    right: -14,
    top: -10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          right: 0,
        };
      }
    },
  },
  practUploadBillsScreen: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
        };
      }
    },
  },
  listValue: {},
  closeBtn1: {
    position: "absolute",
    right: -25,
    top: -22,
  },
  modalContentMargin: {
    marginTop: 0,
  },
  extraLeftMargin: {
    marginLeft: 19,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  extraRightMargin: {
    marginRight: 19,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  hideField: {
    display: "none",
  },
  labelText: {
    color: "#585858",
  },
  maxWidth48: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          maxWidth: "48.5%"
        }
      }
    }
  },
  maxWidth75: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          maxWidth: "75%",
          minWidth: "75%"
        }
      }
    }
  },
  maxWidth100: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          maxWidth: "100%"
        }
      }
    }
  },
  customSelectMenuInputLabelStyle: {
    paddingBottom: 4
  }
});