import React from "react";
import R14, {
  Form,
  View,
  Text,
  StyleSheet,
  Touchable,
  SelectMenuField,
  Image,
  TextInputField,
  Button,
  SubmitButton,
  Platform,
  AsyncStorage,
  FlatList
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import CommonValidators from "../../utils/CommonValidators";
import DateUtils from "../../utils/DateUtils";
import { USER_TYPE, MESSAGE_TYPE } from "../../constant/app.data.constant";
import ModalPopUp from "../../components/ModalPopUp";
import PDFViewerComponent from "../../components/PCH/PDFViewerComponent";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import DatePicker from "../../components/PCH/DatePicker"
import ReactDatePicker from "../../components/PCH/ReactDatePicker"
import HtmlDeCode from '../../utils/HtmlDecode';
import ServiceChargePaymentComponent from '../../components/PCH/ServiceChargePaymentComponent'
import { CommonString } from "../../constant/appCommonText";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import PopupModalComponent from "../../components/PopupModalComponent";
import {analyticsLogs} from "../../utils/AnalyticLogs";
import UploadBillConfirmationComponent from "../../components/PCH/UploadBillConfirmationComponent";

const viewBlueIcon = require("../../assets/images/viewBlue.png");
const plusSolidIcon = require("../../assets/images/plus_solid.png");

export default R14.connect(
  class PatientBillClaimScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isDisable: false,
        isVisible: false,
        hasOnlyOnePOBox: true,
        isDocVisible: false,
        loaderVisible: false,
        isShowPaymentService: false,
        bill: null,
        selectedInsurance: null,
        isServiceChargeEnabled: true, // true //false
        selectedMemberId: "",
        membersList: [],
        isInsuranceCompanyError: false,
        isMemeberNameError: false,
        showDobDatePicker: false,
        isPdfConfirmation: false,
        selectedItem: {},
        selectedItemType: "",
        pdfs: [],
        dataSourceType: "pdf",
        signedUrl: "",
        fileContent: "",
        fileContentUnsecure: "",
        confirmationPopUpVisibility: false,
        claimEnsureCompleted: false,
        selectClient: null
      };
      this.form = null;
      this.insuranceItems = [];
      this.poBoxNoItems = [];
      this.ssid = "";
      this.clients = [];
    }

    componentDidMount = async () => {
      try {
        this.form = this.props.app.ui.form("uploadBills");
        const {uploadBill, insurances } = this.props;
        const { isUberDocs } = this.props.app.dm.userSession.state;
        let userBUs = await this.props.app.dm.rest.getuserBUDetails();
        this.insuranceItems = [];
       if (!!uploadBill) {
          if (!!insurances && insurances.length > 0) {
            insurances.forEach((insurance) => {
              this.insuranceItems.push({
                label: insurance.name,
                value: insurance.uid,
                poBoxNo: insurance.poBoxNo,
                address1: insurance.address1,
                city: insurance.city,
                state: insurance.state,
                groupNumber: insurance.groupNumber
              });
            });

            if (!!this.form.elmts.doctorName) {
              this.form.elmts.doctorName.setItems(this.insuranceItems);
              let selectPrimary = insurances.filter((ins) => ins.isPrimary === "true");
              if (!!selectPrimary && selectPrimary.length > 0) {
                let val = this.insuranceItems.filter((ins) => ins.value === selectPrimary[0].uid)[0];
                this.form.elmts.doctorName.setValue(val);
                this.onDoctorSearch(val);
              }
            }
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Please add insurance company.",
            });
          }
          if (!isUberDocs && !!insurances && insurances.length > 0 && (!userBUs || !userBUs.length || !userBUs[0].addressLine1 || !userBUs[0].town || !userBUs[0].state || !userBUs[0].postCode) ) {
            this.setState({
              isAlertVisible: true,
              alertMessage: CommonString.EnterAddressRequiredAlert,
            });
          }

          this.getPageData(null, true);
        }
      }
      catch (err) {
      }
    };

    async getPageData(search = null, defaultLoad = false) {
      let res = await this.props.app.dm.client.findMemberClients(
        {
          page: 1,
          resultsPerPage: 10,
          filter: !!search ? search : "",
          sort: [
            {
              field: "name",
              order: "ASC"
            }
          ]
        }
      );
      if (!!res && res.nodes) {
        if (!!defaultLoad) {
          res.nodes.forEach((client) => {
            this.clients.push({
              label: `${client.name}`,
              value: client.uid
            });})
        }
        else {
          this.clients = res.nodes.map((client) => {
            return {
              label: `${client.name}`,
              value: client.uid
            }
          });
        }
      }
      return this.clients;
    }

    
    onSearchClient = async (search) => {
      this.clients = [];
      return await this.getPageData(search);
    }

    handleConfirmationPopUp = async (hidePopUpConfirmation, selectClient) => {
      const { claimEnsureCompleted } = this.state;
      if (hidePopUpConfirmation === true) {
        this.setState({
          claimEnsureCompleted: false,
          confirmationPopUpVisibility: false,
          isDisable: false,
          selectClient: selectClient
        })
        return false;
      };

      if (claimEnsureCompleted === true) {
        this.setState({
          claimEnsureCompleted: true,
          confirmationPopUpVisibility: false,
          selectClient: selectClient
        });
        this.handleSubmit(this.props.app.ui.form("uploadBills"), selectClient)
      } else {
        this.setState({
          claimEnsureCompleted: true,
          confirmationPopUpVisibility: true
        })
      }
      return false
    };

    membersList = async(insuranceId) => {
      if(!insuranceId) return false;
      const filter = {
        filter: {
          isActive: { eq: "true" },
          insuranceId: { eq: insuranceId }
        },
        sort: { field: "createdAt", order: "DESC" },
        // page: currentPage,
        // resultsPerPage: resultsPerPage
      };
      this.setState({
        loaderVisible: true,
        isInsuranceCompanyError: false,
      });
      let members = await this.props.app.dm.addInsuranceDomain.getInsuranceMembersList(filter);

      members = members.forEach(member => {
        if(member){
          return {
            ...member,
            label: `${member.firstName} ${member.lastName ? member.lastName : ''}`,
            value: member.memberId
          }
        }
      })
      
      this.setState({
        membersList: members,
        selectedMemberId: members.length ? members.filter(member => member.isPrimaryPolicyHolder === "yes")[0] ? members.filter(member => member.isPrimaryPolicyHolder === "yes")[0].value : members[0].value: '',
        loaderVisible: false,
        isMemeberNameError: !members.length,
        isInsuranceCompanyError: false
      })

      let docForm = this.props.app.ui.form("uploadBills");
      if(members.length) {
        docForm.elmts.patientName.setValue(members.filter(member => member.isPrimaryPolicyHolder === "yes")[0] ? members.filter(member => member.isPrimaryPolicyHolder === "yes")[0] : members[0])
      } else {
        docForm.elmts.patientName.setValue("")
      };
      docForm.elmts.patientName.setItems(members);

      return !!members && members.length ? members : []
      
    }

    onDoctorSearch = async (val) => {
      const { label, value } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.doctorName.setValue(null);
        return false;
      }
      this.form.elmts.doctorName.setValue({ label: label, value: value });
      this.form.elmts.doctorName.setError(null);
      this.poBoxNoItems = [];
      const { userLoginDetail } = this.props;
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      this.membersList(value);
      if (userType === USER_TYPE.PCH_PATIENT && !!this.form.elmts.poBoxNo && !!value && !!this.insuranceItems && this.insuranceItems.length > 0) {
        this.form.elmts.poBoxNo.setError(null);
        this.form.elmts.poBoxNo.setValue(null);
        let insurance = this.insuranceItems.find((ins) => ins.value === value);
        if (!!insurance) {
          let address = !!insurance.address1 ? insurance.address1 : '';
          address = !!address && !!insurance.city ? `${address}, ${insurance.city}` : !!insurance.city ? insurance.city : address;
          address = !!address && !!insurance.state ? `${address}, ${insurance.state.split('-').pop()}` : !!insurance.state ? insurance.state.split('-').pop() : address;
          this.poBoxNoItems = [{
            label: ((!!insurance.poBoxNo && address) ? `${insurance.poBoxNo} ${address}` : !!insurance.poBoxNo ? insurance.poBoxNo : address),
            value: insurance.poBoxNo.trim(),
          }];
          this.form.elmts.poBoxNo.setValue(this.poBoxNoItems[0]);
          this.form.elmts.poBoxNo.setItems(this.poBoxNoItems);
          if (this.poBoxNoItems.length === 1) {
            this.setState({
              hasOnlyOnePOBox: true,
            });
          } else {
            this.setState({
              hasOnlyOnePOBox: false,
            });
          }
        }
      }
    };

    onSearchInsurance = async (val) => {
      const { insurances, userLoginDetail } = this.props;
      this.insuranceItems = [];
      if (!!insurances && insurances.length > 0) {
        insurances.forEach((insurance) => {
          this.insuranceItems.push({
            label: insurance.name,
            value: insurance.uid,
            poBoxNo: insurance.poBoxNo,
            address1: insurance.address1,
            city: insurance.city,
            state: insurance.state,
            groupNumber: insurance.groupNumber
          });
        });
      }
      if (!!val) {
        this.insuranceItems = this.insuranceItems
          .filter((item) => {
            return item.label.toLowerCase().includes(val.toLowerCase());
          }).sort((a, b) => b.label - a.label);

        // if (this.insuranceItems.length === 0) {
          let ins = await this.props.app.dm.addInsuranceDomain.getInsuranceOnSearch(userLoginDetail.uid, val);
          if (!!ins && ins.length > 0) {
            ins.forEach((insurance) => {
              this.insuranceItems.push({
                label: insurance.name,
                value: insurance.uid,
                poBoxNo: insurance.poBoxNo,
                address1: insurance.address1,
                city: insurance.city,
                state: insurance.state,
                groupNumber: insurance.groupNumber
              })
            });
          // }
        }
      }
      this.insuranceItems = await this.filterItems(this.insuranceItems);
      this.form.elmts.doctorName.setItems(this.insuranceItems);
      return this.insuranceItems;
    }

    filterItems = async(list) => {
      let filterArray = list.reduce((acc, current) => {
        const x = acc.find(item => item.value === current.value);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filterArray;
    }

    onPOBoxSearch = async (val) => {
      const { label } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.poBoxNo.setValue(null);
        return false;
      }
      this.form.elmts.poBoxNo.setValue(val);
      this.form.elmts.poBoxNo.setError(null);
    };

    onCancelClick = async () => {
      const { uid } = this.props;
      if (!!uid) {
        this.props.app.nav.to("patientDashboard");
      } else {
        this.form.reset();
        this.setState({
          isDisable: false,
        });
        this.props.app.nav.to("patientDashboard");
      }
    };

    handleSubmit = async (form, clientUid) => {
      this.setState({
        isDisable: true,
      });
      const { uploadBill, userLoginDetail } = this.props;
      const { doctorName, poBoxNo } = form.values;
      const { isServiceChargeEnabled, membersList,claimEnsureCompleted, selectClient } = this.state;

      let currentDate = new Date().toUTCString();
      let currentDateTime = DateUtils.dateAndTimeWithAmPm(currentDate, "PCH");
      let patientUid = null;
      if(!!this.state.selectedMemberId)
      { 
        let member = membersList.filter(member => member.memberId === this.state.selectedMemberId);
        if(!!member && member.length > 0)
            patientUid = member[0].uid;
      }
      
      if (!patientUid || !doctorName || !doctorName.value) {
        this.setState({
          isDisable: false,
          isInsuranceCompanyError: !doctorName || !doctorName.value ? true : false,
          isMemeberNameError: !patientUid ? true : false,
        })
        return false
      } else {
        this.setState({
          isInsuranceCompanyError: false,
          isMemeberNameError: false,
        })
      }
      // handle upload bill confirmation pop up
      if (claimEnsureCompleted === false) {
        analyticsLogs(CommonString.CLAIM_BILL, CommonString.SUBMIT_BILL_ACTION, this.props.app);
        this.handleConfirmationPopUp();
        return false;
      }

      let bill =  {
        uid: uploadBill.uid,
        submittedDate: currentDate,
        submittedDateTime: currentDateTime,
        billStatus: "Submitted",
        pchSubmittedStatus: "Received",
        insuranceUid: doctorName.value,
        poBoxNo: !!poBoxNo ? poBoxNo.value : null,
        updatedBy: userLoginDetail.uid,
        insuranceMemberUid: patientUid,
        clientUid: (!!selectClient ? selectClient : (!!clientUid ? clientUid : ""))
      };

      if (!!isServiceChargeEnabled) {
        this.setState({
          isShowPaymentService: true,
          selectedInsurance: doctorName,
          bill: bill
        });
      }
      else {
        this.setState({
          loaderVisible: true,
          selectedInsurance: doctorName,
          bill: bill
        });

        let paymentResponse = {
          success: true,
          transactionId:"dummy_0000000012345",
          paymentMode:"Service Charge Disable",
          totalAmount: 0.00
        };
        await this.onPaymentProcess(paymentResponse);
      }
      return false;
    };

    onPaymentProcess = async (paymentResponse) => {
      if (!!paymentResponse && !!paymentResponse.success && !!paymentResponse.transactionId) {
        try {
          const { uploadBill } = this.props;
          const { bill, selectedInsurance } = this.state;
          bill.transactionId = paymentResponse.transactionId;
          bill.paymentMode = paymentResponse.paymentMode;
          bill.serviceAmount = paymentResponse.totalAmount;
          let res = await this.props.app.dm.uploadBillDomain.submitUploadedBill(bill);
          if (res) {
            let uniqueCode = DateUtils.formatYYYYMMDDHHMMSS() + res.uid.toUpperCase();
            uniqueCode = uniqueCode.replace(/[^a-zA-Z0-9]/g, "");
            uploadBill.pchInsuranceDetail = {
              name: selectedInsurance.label,
              groupNumber: this.insuranceItems.filter((ins) => ins.value === selectedInsurance.value)[0].groupNumber
            };
            let providerName = !!uploadBill.providerName ? uploadBill.providerName : "";
            uploadBill.uniqueCode = uniqueCode;
            uploadBill.providerName = providerName;
            uploadBill.patientName = !!this.props.app.ui.form("uploadBills").values.patientName ? this.props.app.ui.form("uploadBills").values.patientName.label : "" ;
            uploadBill.insuranceAddress = this.poBoxNoItems && this.poBoxNoItems.length ? this.poBoxNoItems[0].label : "";

            await AsyncStorage.setItem("receipts", JSON.stringify(uploadBill));
            this.props.app.dm.uploadBillDomain.uploadDocumentAtSFTP(uploadBill.uid, "", uniqueCode);
            analyticsLogs(CommonString.CLAIM_BILL, CommonString.BILL_SUBMITTED_ACTION, this.props.app);
            this.props.app.dm.commonDomain.showToastr("success", `Your claim reference ${uniqueCode} received successfully.`);
            this.props.app.nav.to("submittedReceiptRoute", { uid: uploadBill.uid });
            this.setState({
              isShowPaymentService: false,
              isDisable: false,
              loaderVisible: false
            });
            return false;
          }
          else {
            this.setState({
              isShowPaymentService: false,
              isDisable: false,
              loaderVisible: false
            });
            this.props.app.dm.commonDomain.showToastr("error", res.error);
          }

        } catch (error) {
          this.setState({
            isShowPaymentService: false,
            isDisable: false,
            loaderVisible: false
          });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
      }
      else {
        this.setState({
          isShowPaymentService: false,
          isDisable: false,
          loaderVisible: false
        });
        if (!!paymentResponse.error)
          this.props.app.dm.commonDomain.showToastr("error", paymentResponse.error);
      }
    }

    hideServiceChargePayment =()=>{
      this.setState({
        isShowPaymentService: false,
        isDisable: false,
      });
    }

    hideLoader =()=>{
      this.setState({
        loaderVisible: false
      });
    }

    displayLoader =()=>{
      this.setState({
        loaderVisible: true
      });
    }

    validateDate = async (testdate) => {
      let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
      return date_regex.test(testdate);
    };

    onPasswordDateBlur = async () => {
      const { dOBPassword } = this.props.app.ui.form("uploadedBillPassword").values;
      this.handleDobShowHideDatePicker();
      if (!!dOBPassword) {
        let dat = this.validateDate(dOBPassword);
        if (!!dat) {
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(dOBPassword);
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(null);
          this.onPassDateValueChange(dOBPassword, false);
        } else {
          this.setState({
            password: "",
          });
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue("");
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(CommonString.DateFormatRequired);
        }
      } else {
        this.setState({
          password: "",
        });
        this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(null);
      }
    };

    onDownloadFile = async (item, action = "View") => {
      this.ssid = "";
      // const { uploadBill } = this.props;
      // let isPatientReq = false;
      // if (!!uploadBill.isPatientRequest) {
      //   isPatientReq = true;
      // }
      this.setState({
        isVisible: true,
        docAction: action,
        password: "",
        dataSourceType: item.dataSourceType
      });
      // if (item.isSuperBill) {
      //   await this.downloadOrViewBillDocuments();
      // }
    };

    onCancel = async () => {
      this.setState({
        isVisible: false,
      });
    };

    readText = async (val) => {
      // this.ssid = val;
      // let text = "";
      // if (val.length < this.ssid.length && this.ssid != "") {
      //   this.ssid = this.ssid.substr(0, this.ssid.length - 1);
      // } else {
      //   this.ssid = val.length === 1 || !!!this.ssid ? val : this.ssid + val.substr(val.length - 1);
      // }
      // this.setState({
      //   password: this.ssid,
      // });

      // for (let i = 0; i < val.length; i++) {
      //   text = text + "*";
      // }
      // this.props.app.ui.form("uploadedBillPassword").elmts.filePassword.setValue(text);
      let ssidText = val;
      if (!!val) {
        let cleaned = ("" + ssidText).replace(/\D/g, "").substring(0, 4);
        this.props.app.ui.form("uploadedBillPassword").elmts.filePassword.setValue(cleaned);
        this.ssid = cleaned;
      };
    };

    onPassDateValueChange = async (val, autoformat = true) => {
      this.handleDobShowHideDatePicker();
      if (!!autoformat) {
        let date = DateUtils.formatDateInMMDDYYYY(val);
        if (!!date && new Date(date) > new Date()) {
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(null);
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotFuture);;
          return false;
        } else if (!!date && DateUtils.isOlderThanMaxLimit(val)) {
            this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(null);
            this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotOlderThan125);
            return false;
          }
        else
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(date);
      }
      else
      {
        if (!!val && new Date(val) > new Date()) {
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(null);
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotFuture);;
          return false;
        }
        else
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(val);
      }
      this.setState({
        password: val,
        showDobDatePicker: false,
      });
    };

    onPDFClose = async () => {
      this.setState({ isDocVisible: false });
    };

    onViewClick = async (item, type) => {
        const { mergedFileName, documentId } = item;
        const filter = {
            filter: {
            documentId:{
                eq: documentId
            }
            },
            sort: {
            field: "seqNo",
            order: "ASC"
            }
        }
        let documentLogs =  await this.props.app.dm.uploadBillDomain.documentLogs(filter);
        // if(item && isSuperBill) {   // merged file show only when super bill exists 
        if(item) {      // merged file show (superBill or not)
          const isDocumentAlreadyExists = documentLogs.findIndex(documentFile => documentFile.filename === mergedFileName);
          if(isDocumentAlreadyExists === -1 && !!mergedFileName) documentLogs.unshift({uid: "", filename: mergedFileName, contentType: "pdf", seqNo: 1, isSuperBill: true});
          documentLogs = documentLogs.map((doc, idx) => {
            return {
                ...doc,
                seqNo: idx + 1
            }
          })
        }
        this.setState({isPdfConfirmation: true, selectedItem: item, selectedItemType: type, pdfs: documentLogs})
    }

    downloadOrViewBillDocuments = async () => {
      this.setState({ loaderVisible: true });
      const { uploadBill } = this.props;
      if (!!uploadBill.isProcessed) {
        let options = {
          responseType:"arraybuffer",
          headers: {
            "content-type": "application/pdf",
          }
        };
        let signedUrl = await this.props.app.dm.uploadBillDomain.getObjectSignedUrl(uploadBill.mergedFileName);
        let response = await this.props.app.dm.rest.getS3(signedUrl, options);
        let billDocuments = Buffer.from(response.data, 'binary').toString('base64');
        let signedUnsecuredUrl = !!uploadBill.mergedUnsecuredFileName && !!uploadBill.isSuperBill ? await this.props.app.dm.uploadBillDomain.getObjectSignedUrl(uploadBill.mergedUnsecuredFileName) : signedUrl;
        let responseUnsecure = !!signedUnsecuredUrl && !!uploadBill.isSuperBill ? await this.props.app.dm.rest.getS3(signedUnsecuredUrl, options) : signedUrl;
        let billDocumentsUnsecure = !!responseUnsecure && !!uploadBill.isSuperBill ? Buffer.from(responseUnsecure.data, 'binary').toString('base64') : billDocuments;
        // let billDocuments = await this.props.app.dm.uploadBillDomain.readPdfServer(uploadBill.mergedFileName);
        if (!!billDocuments) {
          if (this.state.docAction === "View") {
            this.setState({ fileContent: billDocuments, signedUrl: signedUrl, isDocVisible: true, fileContentUnsecure: billDocumentsUnsecure, });
            this.onCancel();
          } else {
            const item = {
              content: `data:application/octet-stream;base64,${billDocuments}`,
              contentType:  'PDF',
              filename: uploadBill.mergedFileName
            };
            HtmlDeCode.downloadFile(item);
            this.onCancel();
          }
        }
        else
          this.props.app.dm.commonDomain.showToastr("error", CommonString.FileNotFound);
      }
      else
        this.props.app.dm.commonDomain.showToastr("error", CommonString.FileInProcess);
      this.setState({ loaderVisible: false })
    };

    onOk = async (form) => {
      const { dOBPassword } = form.values;
      const filePassword = this.ssid //this.state.password;
      const { uploadBill } = this.props;
      if (!!uploadBill.ssId ? filePassword === uploadBill.ssId : new Date(dOBPassword).toString() === new Date(uploadBill.dOB).toString()) {
        await this.downloadOrViewBillDocuments();
      } else {
        this.props.app.dm.commonDomain.showToastr("error", !!uploadBill.ssId ? CommonString.IncorrectSSID : CommonString.IncorrectDOB);
        if (form.elmts.filePassword) {
          this.setState(
            {
              password: "",
            },
            () => {
              form.elmts.filePassword.setValue(null);
              this.ssid = "";
            }
          );
        }
      }
      return false;
    };

    handleDobShowHideDatePicker = (val) => {
      if(val === "open" && this.state.showDobDatePicker) {
        return;
      } else if (val === "close") {
        this.setState({showDobDatePicker: false});
        return;
      } else {
        this.setState({showDobDatePicker: !this.state.showDobDatePicker})
      }
    }

    onClearDobDate = () => {
        const form = this.props.app.ui.form("uploadedBillPassword");
        form.elmts.dOBPassword.setValue(null);
        form.elmts.dOBPassword.setError(null);
        this.setState({
            showDobDatePicker: false,
        })
    }
    renderModelPopup(clientStyle, commonFontStyles, isUberDocs) {
      const { isVisible } = this.state;
      const { uploadBill } = this.props;
      return (

        <ModalPopUp
          visibility={isVisible}
          isFooter={true}
          heading={!!uploadBill.ssId ? CommonString.SSIDRequired : CommonString.DOBRequired}
          bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
          modalContent = {[commonStyles.marT0, commonStyles.marB0]}
          modalHeading={styles.headingStyle}
          closeButton={customCommonStyles.customDisplayNone}>
            <Form
              name="uploadedBillPassword"
              style={[commonStyles.padding0]}
              onSubmit={this.onOk}
              validateBeforeSubmit={true}
              validators={CommonValidators.getFormValidator()}>
              <View>
                {!!uploadBill.ssId ? (
                  <View style={[commonStyles.marginTop10, styles.minHeight52, commonStyles.marginBottom20]}>
                    <TextInputField
                      name="filePassword"
                      label={`${CommonString.SSIDRequiredLabel}`}
                      required={CommonString.SSIDRequired}
                      secure={true}
                      onChangeText={this.readText}
                      style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.marB0, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                    />
                  </View>
                ) : (
                    <View style={[commonStyles.marginTop10, commonStyles.dFlex, commonStyles.flexDirectionRow, commonStyles.marginBottom20, styles.minHeight52]}>
                    {Platform.OS === 'web' ? <>
                    {this.state.showDobDatePicker ? <ReactDatePicker
                      name={"dOBPassword"}
                      form="uploadedBillPassword"
                      value={this.props.app.ui.form("uploadedBillPassword").values.dOBPassword}
                      mode={"modal"}
                      onValueChange={this.onPassDateValueChange}
                      onBlur={this.onPasswordDateBlur}
                      visible={this.state.showDobDatePicker}
                      closeDatePicker={this.handleDobShowHideDatePicker}
                      isUberDocs={isUberDocs}
                    /> : null }
                      <TextInputField
                        name={"dOBPassword"}
                        label={`${CommonString.DOBRequired}*`}
                        secure={false}
                        readOnly={true}
                        onFocus={() => this.handleDobShowHideDatePicker('open')}
                        icon="calendar"
                        onIconPress={() => this.handleDobShowHideDatePicker()}
                        iconRight={this.props.app.ui.form("uploadedBillPassword") && this.props.app.ui.form("uploadedBillPassword").values.dOBPassword ? "close" : ""}
                        onIconRightPress={this.onClearDobDate}
                        style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,clientStyle.secondaryBgColor]}
                        helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                      />
                    </> : <>
                    <DatePicker
                        style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.marB0, customCommonStyles.inputHeight]}
                        name={"dOBPassword"}
                        label={`${CommonString.DOBRequired}*`}
                        value={this.state.dOBPassword}
                        mode={"dropdown"}
                        textInputEditable={true}
                        required={CommonString.DOBRequired}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        helperTextStyle={[styles.dobLabelStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                        onValueChange={this.onPassDateValueChange}
                        onBlur={this.onPasswordDateBlur}
                        onFocus={this.onPasswordDateBlur}
                      />
                      { Platform.OS !== "web" &&<TextInputField
                        name="dOBPassword"
                        label={`${CommonString.DOBRequired}*`}
                        required={CommonString.DOBRequired}
                        secure={false}
                        disabled={true}
                        style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, clientStyle.secondaryBgColor]}
                        helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                        />}
                        </>}
                  </View>
                )}
              </View>

              <View style={[styles.btnHolder, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow, customCommonStyles.customBtnHolder,]}>
                <Button
                  title={"Cancel"}
                  style={[
                    commonStyles.blueBorderButton,
                    commonStyles.marginRight8,
                    commonStyles.flex1,
                    styles.cancelBtn,
                    styles.buttons,
                    clientStyle.backAndCancelButton
                  ]}
                  onPress={() => this.onCancel()}
                  key="billCancel"
                  titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonStyles.transperentBtnColor,clientStyle.backAndCancelButtonText]}
                />
                <SubmitButton
                  title="Ok"
                  key="billOk"
                  style={[
                    commonStyles.blueButton,
                    styles.declinedBtn,
                    commonStyles.marginLeft8,
                    commonStyles.flex1,
                    styles.buttons,
                    commonStyles.flexBasis48,
                    customCommonStyles.noBoxShadow,
                    clientStyle.submitButton
                  ]}
                  titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonStyles.blueButtonColor, commonFontStyles.workSans, commonStyles.whiteColor, clientStyle.submitButtonText]}
                />
              </View>
            </Form>
          
        </ModalPopUp>
      );
    }


    renderPDFconfirmationModelPopup(clientStyle, commonFontStyles) {
      const { isPdfConfirmation, selectedItem, selectedItemType, pdfs } = this.state;
      return (
        <ModalPopUp
            visibility={isPdfConfirmation}
            isFooter={true}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.confirmationModal, clientStyle.secondaryBgColor, pdfs.length ? null : styles.width300,]}
            modalContent = {[commonStyles.marB0]}>
            <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, ]}>
              <Text style={[commonFontStyles.workSans, commonStyles.dBlock, commonStyles.fontWeight500, commonStyles.textAlignCenter]}>{`Document password is Patient's ${!selectedItem.dOB ? "4 digits SSID" : "DOB(MM/DD/YYYY)"}`}</Text>
            </View>
            <FlatList
                style={[
                  styles.dashboardMessageComponent,
                  customCommonStyles.customDashboardMessageComponent,
                  styles.noBorder,
                  styles.tboday,
                  pdfs.length ? null : commonStyles.displayNone,
                  clientStyle.secondaryBgColor,
                ]}
                data={pdfs}
                keyExtractor={(el, i) => i}
                renderItem={({ item, index }) => (
                  <View
                    style={[
                      styles.tr,
                      commonStyles.flex1,
                      commonStyles.alignItemsStart,
                      commonStyles.flexDirectionRow,
                      styles.attachmentCard,
                      styles.customPadding
                    ]}>
                      <View style={[commonStyles.marginRight16, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight500, customCommonStyles.wordBreakWord]}>
                          {item.seqNo + ")  "}
                        </Text>
                        <View style={[commonStyles.marginRight16]}>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, customCommonStyles.wordBreakWord]}>
                            {item.filename}
                          </Text>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight300, customCommonStyles.wordBreakWord]}>
                            {item.size}
                          </Text>
                        </View>

                      </View>
                      <Touchable
                          style={[styles.customMarLAuto, ]}
                          onPress={() => {              
                            selectedItem.mergedFileName = item.filename;  
                            selectedItem.dataSourceType = item.contentType ? item.contentType.toLowerCase() : ""
                            this.onDownloadFile(selectedItem, selectedItemType)
                          }}>
                           <Image source={viewBlueIcon} style={[styles.viewPdfIconSize]} />
                        </Touchable>
                  </View>
                )}
              />
            <View style={[commonStyles.smallModalBtnWrapper, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow]}>
              <Button
                title={"OK"}
                style={[
                    commonStyles.blueButton,
                    styles.buttons,
                    customCommonStyles.noBoxShadow,
                    clientStyle.submitButton,
                    ]}
                onPress={() =>  {
                    this.setState({isPdfConfirmation: false, selectedItem: {}, selectedItemType: ""})
                } }
                titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonStyles.blueButtonColor, commonFontStyles.workSans, commonStyles.whiteColor, clientStyle.submitButtonText]}
              />
            </View>
          </ModalPopUp>
      );
    }


    renderPDFViewer() {
      const { isDocVisible, signedUrl, fileContent, fileContentUnsecure } = this.state;
      return (
        <ModalPopUp
          visibility={isDocVisible}
          isFooter={false}
          bodyWrapper={[commonStyles.largModalBodyWrapper,]}
          heading={"View Document"}
          handleCancel={this.onPDFClose}
          // closeButton={styles.closeBtn}
          animationType="fade"
          >
          <View>
            <PDFViewerComponent closePDF={this.onPDFClose} signedUrl={signedUrl} bills={this.props.uploadBill} dataSourceUnsecured={fileContentUnsecure} dataSource={fileContent} dataSourceType="pdf" disableFileName={true} />
          </View>
        </ModalPopUp>
      );
    }

    alertOk = async () => {
      const { userLoginDetail } = this.props;
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      if (userType === USER_TYPE.PCH_PATIENT) {
        const { isUberDocs } = this.props.app.dm.userSession.state;
        if(this.state.alertMessage === CommonString.EnterAddressRequiredAlert){
          this.props.app.nav.to("pchProfileEditRoute", {mode: "bill"})
        } else if (!!isUberDocs) {
          this.props.app.nav.to("addInsuranceRoute", { mode: "add" });
        } else {
          this.props.app.nav.to("insuranceListRoute");
        }
      } else if (userType === USER_TYPE.PCH_PROVIDER) {
        this.props.app.nav.to("addProviderRoute", { mode: "add" });
      }
      this.setState({
        isAlertVisible: false,
        alertMessage: ''
      })
    };

    onPatientNameChange = (val) => {
      if(!val || val.label === "No Results Found" || val.label.includes("Type in a search value")){
        this.props.app.ui.form("uploadBills").elmts.patientName.setValue("");
        this.setState({selectedMemberId: ""})
      } else if(val && val.value){
        this.setState({
          selectedMemberId: val.value
        })
      }
    };

    renderAlertPopup() {
      const { isAlertVisible, alertMessage } = this.state;
      return (
          <PopupModalComponent
            visibility={!!isAlertVisible}
            bodyWrapper={[commonStyles.smallModalBodyWrapper]}
            messageType={MESSAGE_TYPE.info}
            headingIcon={true}
            heading={"Info"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={alertMessage}
            okLabel={"Ok"}
            handleOk={this.alertOk}
            />
      );
    }

    render() {
      const { isDisable, hasOnlyOnePOBox, isShowPaymentService, isServiceChargeEnabled, confirmationPopUpVisibility } = this.state;
      const { uploadBill, userLoginDetail } = this.props;
      const { isUberDocs } = this.props.app.dm.userSession.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <>
          {this.renderModelPopup(clientStyle, commonFontStyles, isUberDocs)}
          {this.renderAlertPopup()}
          {this.renderPDFViewer()}
          {this.renderPDFconfirmationModelPopup(clientStyle, commonFontStyles)}
          {!!isServiceChargeEnabled ?
            <ServiceChargePaymentComponent isShowPaymentService={isShowPaymentService} onPaymentProcess={this.onPaymentProcess} hideServiceChargePayment={this.hideServiceChargePayment} displayLoader={this.displayLoader} hideLoader={this.hideLoader} />
            : null}
          <View style={[styles.uploadBillSection, customCommonStyles.customFlex1]}>
            <Form
              name="uploadBills"
              validateBeforeSubmit={true}
              validators={CommonValidators.getFormValidator()}
              onSubmit={this.handleSubmit}
              style={[styles.formStyles]}
            >
              <View style={[styles.attachmentsHolder,]}>
                <View key={`att_${uploadBill.uid}`}>
                  <View style={[styles.attachmentCard]}>
                    <Touchable style={styles.ViewPdfIcon} onPress={() => this.onViewClick(uploadBill, "View")}>
                      <View>
                        <Image source={viewBlueIcon} style={commonStyles.icon40} />
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
              <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, styles.addIncProTextWrap]}>
                <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown]}>
                  <SelectMenuField
                    name="doctorName"
                    label={"Insurance Company*"}
                    // required={"Select Insurance Co."}
                    items={this.insuranceItems}
                    onValueChange={this.onDoctorSearch}
                    searchable
                    itemLoader={async ({ search }) => {
                      return await this.onSearchInsurance(search);
                    }}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                  {!!this.state.isInsuranceCompanyError ? (
                    <View style={[styles.mins30Top, commonStyles.marginBottom10]}>
                      <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >{"Select Insurance Co."}</Text>
                    </View>
                  ) : null}
                </View>
                <View
                  style={[
                    commonStyles.flex1,
                    styles.rightInputHolder,
                    commonStyles.flexBasis48,
                    styles.addIncProText,
                    commonStyles.customFlexDirection,
                    styles.customAlignItemsStart,
                    styles.incNameDropDown
                  ]}>
                  <SelectMenuField
                    name="poBoxNo"
                    label="PO Box#/Address"
                    // required="Select PO Box No"
                    items={this.poBoxNoItems}
                    onValueChange={this.onPOBoxSearch}
                    style={[!!!!hasOnlyOnePOBox ? styles.disabledPOBox : null, styles.poBoxinput]}
                    searchable
                    itemLoader={async ({ search }) => {
                      let items = this.poBoxNoItems;
                      return items
                        .filter((item) => {
                          return item.label.toLowerCase().includes(search.toLowerCase());
                        })
                        .sort((a, b) => b.label - a.label);
                    }}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                  <View>
                    <Touchable
                      style={[
                        styles.addIncBtn,
                      ]}
                      onPress={() => this.alertOk()}
                    >
                      <View style={[
                        commonStyles.flex1,
                        commonStyles.alignItemsCenter,
                        styles.addInsuranceProvider,
                      ]}>
                        <View style={styles.plusIcon}>
                          <Image source={plusSolidIcon} style={[commonStyles.icon18]} />
                        </View>
                        <View>
                          <Text
                            style={[commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.smallHeading, styles.custompaddingLeftRight]}>
                            Add Insurance
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                </View>
                {/* )} */}
              </View>
              {/* Patient Drop down */}
              <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, styles.addIncProTextWrap]}>
                <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.maxWith49]}>
                  <SelectMenuField
                    name="patientName"
                    label={"Patient/Member*"}
                    // required={"Select Patient"}
                    items={this.state.membersList}
                    onValueChange={this.onPatientNameChange}
                    searchable
                    itemLoader={async ({ search }) => {
                      let items = this.state.membersList;
                      return items
                        .filter((item) => {
                          return item.label.toLowerCase().includes(search.toLowerCase());
                        })
                        .sort((a, b) => b.label - a.label);
                    }}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                  {!!this.state.isMemeberNameError ? (
                    <View style={[styles.mins30Top]}>
                      <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >{this.state.isInsuranceCompanyError ? "Select Patient" : "Please add insurance patient/member"}</Text>
                    </View>
                  ) : null}
                </View>
                <View
                  style={[
                    commonStyles.flex1,
                    styles.rightInputHolder,
                    commonStyles.flexBasis48,
                    styles.addIncProText,
                    commonStyles.customFlexDirection,
                    styles.customAlignItemsStart,
                    styles.textAlign
                  ]}>
                  <Text
                    style={[
                      commonStyles.dBlock,
                      commonStyles.fontWeight500,
                      commonStyles.heading6,
                      commonFontStyles.workSans,
                      commonStyles.marginBottom5,
                    ]}
                  >{this.state.selectedMemberId && "Member ID: " + this.state.selectedMemberId}</Text>
                </View>
              </View>

              {!!uploadBill.parentName ? (
                <View style={[commonStyles.flex1, styles.customflexDirection,]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow,]}>
                    <View style={[commonStyles.webView]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                        ]}>
                        Practice Group
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          styles.userDetailsVla,
                          // isMobileDevice ? styles.mViewFontStyle : null,
                        ]}>
                        {
                          uploadBill.parentName
                        }
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow,]}>
                    <View style={[commonStyles.webView]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                        ]}>
                        Provider
                      </Text>
                    </View>
                    <View>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {!!uploadBill.providerName ? uploadBill.providerName : ""}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : null}
              <View style={[commonStyles.flex1, styles.customflexDirection,]}>
                <View
                  style={[
                    commonStyles.flex1,
                    styles.leftInputHolder,
                    styles.detailRow,
                    isMobileDevice ? styles.updatedonForMView : null,
                  ]}>
                  <View style={[isMobileDevice ? commonStyles.marginRight10 : null]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                      ]}>
                      Uploaded On {isMobileDevice ? " :" : null}
                    </Text>
                  </View>
                  <View>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {DateUtils.dateAndTimeWithAmPm(uploadBill.createdAt, "PCH")}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    commonStyles.flex1,
                    styles.rightInputHolder,
                    styles.detailRow,
                    isMobileDevice ? styles.updatedonForMView : null,
                  ]}>
                  <View style={[]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                      ]}>
                      Status {isMobileDevice ? " : " : null}
                    </Text>
                  </View>
                  <View style={[]}>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {uploadBill.pchSubmittedStatus}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[styles.customMarTAuto, commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow]}>
                <View style={[commonStyles.marginRight10, styles.formBtnsHolder]}>
                  <Button
                    title={"Cancel"}
                    style={[commonStyles.blueBorderButton, styles.formBtns]}
                    titleTextStyle={[
                      commonStyles.blueBorderButtonColor,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.heading6,
                    ]}
                    onPress={this.onCancelClick}
                  />
                </View>
                <View style={[styles.formBtnsHolder]}>
                  <SubmitButton
                    title={"Submit"}
                    disabled={isDisable}
                    style={[commonStyles.blueButton, styles.submitBtn, styles.formBtns, customCommonStyles.noBoxShadow,]}
                    titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6]}
                  />
                </View>
              </View>
            </Form>
          </View>
          <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} />
          <UploadBillConfirmationComponent
            handleConfirmationPopUp={this.handleConfirmationPopUp}
            confirmationPopUpVisibility={confirmationPopUpVisibility}
            isUberDocs={isUberDocs}
            userLoginDetail={userLoginDetail}
            onSearchClient={this.onSearchClient}
            clients={this.clients}
          />
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  uploadBillsScreen: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          backgroundColor: "#fff",
          marginBottom: 45,
        };
    },
  },
  modalBackdrop: {
    backgroundColor: "#00000052",
    overflowX: "auto",
  },
  bodyWrapper: {
    width: 300,
    borderRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          fontWeight: 600,
        };
    },
  },
  enterPasswordBodyWrapper: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          minHeight: 180,
        };
    },
  },
  addAttachmentBodyWrapper: {
    marginTop: "auto",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: "100vw", ///  Need to check with Ganesh
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
  },
  commentsField: {
    minHeight: 128,
    resize: "vertical",
  },
  closePopup: {
    width: "20%",
    height: 8,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: "#e1e1e1",
    marginBottom: 25,
  },
  attachOpt: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          //padding: 5,
          marginBottom: 15,
        };
      }
    },
  },
  ucountIconDiv: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
    backgroundColor: "#cff2ff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    marginTop: 5,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },

  filePassowrdModalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  uploadPassowrdModelwapper: {
    width: "100%",
    minHeight: 150,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  
  modalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  filePassowrdModalBodyWrapper: {
    backgroundColor: "#fff",
    border: "1px solid #a7a7a7",
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    width: 350,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          marginTop: "auto",
        };
      }
    },
  },
  addInsuranceAlert: {
    height: 150,
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMarTAuto: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: -15,
        };
      }
    },
  },
  customFlexProperties: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
        };
      }
      if (width >= 991) {
        return {
          justifyContent: "flex-end",
        };
      }
    },
  },
  submitBtn: {
    minWidth: 108,
  },
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customPaddingTop15: {
    paddingTop: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          marginTop: 15,
          marginLeft: 10,
        };
      }
    },
  },
  formBtnsHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  formBtns: {
    minWidth: 108,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailRow: {
    borderBottomWidth: 1, 
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 15,
    flexBasis: "48%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          paddingBottom: 0,
          flexBasis: "100%",
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  addIncProText: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  // addIncProTextWrap: {
  //   screen: ({ width }) => {
  //     if (width <= 991) {
  //       return {
  //         minHeight: 187,
  //       };
  //     }
  //   },
  // },
  countryCode: {
    maxWidth: 70,
  },
  customHelperTextStyleMobile: {
    marginLeft: 58,
  },
  btnHolder: {
    // paddingRight: 10,
    // paddingLeft: 10,
  },
  uploadBillsScreenSection: {},
  uploadBillSection: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    height:"100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 6,
        };
      }
    },
  },
  mobView: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "flex",
          marginTop: -10,
          flexDirection: "row",
        };
      }
    },
  },
  dobInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -13,
        };
      }
    },
  },
  emailInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -11,
        };
      }
    },
  },
  cameraOpt: {
    // border: "2px solid #d9d9d9",
    // borderRadius: 3,
    height: "100%",
    display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    paddingTop: 8,
    paddingBottom: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 6,
          paddingBottom: 6,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  marginTopMin20: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -20,
        };
      }
    },
  },
  marginTopMin10: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -10,
        };
      }
    },
  },
  pageTitle: {
    marginTop: 15,
    justifyContent: "space-between",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
        };
      }
    },
  },
  mobileNumberInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 15,
        };
      }
    },
  },
  attachmentsHolder: {
    maxHeight: 120,
    overflowY: "auto",
    flexBasis: null,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  cameraOptHolder: {
    flex: 1,
    flexGrow: 1,
  },
  uploadedBillAttachFile: {
    // marginBottom: 10,
  },
  helperTextStyleDob: {
    left: 12,
  },
  leftMinus12: {
    left: -12,
  },
  autoFillModelBodyWrapper: {
    width: 500,
    maxHeight: "80vh",
    overflowY: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          height: "100vh",
        };
      }
    },
  },
  autoFillModel: {
    height: "100%",
    overflowY: "auto",
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: "100vh",
        };
      }
    },
  },
  fillWidth: {
    width: "33%",
  },
  tr: {
    display: "flex",
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    cursor: "pointer",
    borderBottom: "1px solid rgb(161 164 168)",
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 5,
    flexDirection: "column",
  },
  addInsuranceProvider: {
    // backgroundColor: "#e2e2e2",
    alignItems: "center",
    // borderRadius: 20,
    // paddingTop: 2,
    // paddingRight: 4,
    // paddingBottom: 2,
    // paddingLeft: 4,
    // border: "1px solid #333",
    // marginTop: 14,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "row",
          marginLeft: 0,
        };
      }
    },
  },
  customAlignItemsStart: {
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: null,
        };
      }
    },
  },
  mViewFontStyle: {
    fontSize: 16,
    fontWeight: 600,
  },
  attachmentCard: {
    // border: "1px solid #e2e2e2",
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: "flex-start",
    marginBottom: 16,
    // marginLeft: -12,
  },
  ViewPdfIcon: {
    position: "relative",
    zIndex: 999,
    display: "inline-block",
  },
  updatedonForMView: {
    flexDirection: "row",
  },
  PDFViewBodyWrapper: { 
    width: "80vw",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          // width: 300,
          minHeight: 400,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderTopLeftRadius: 6,
borderTopRightRadius: 6,
borderBottomLeftRadius: 6,
borderBottomRightRadius: 6,
        };
      }
    },
  },
  // closeBtn: {
  //   position: "absolute",
  //   right: 0,
  //   top: 0,
  //   screen: ({ width }) => {
  //     if (width <= 991) {
  //       return {
  //         top: -5,
  //         right: -15,
  //       };
  //     }
  //   },
  // },
  custompaddingLeftRight: {
    paddingTop: 0,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 5,
  },
  disabledPOBox: {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "default",
  },
  buttons: {
    height: 24,
    padding: 0,
    minHeight: 24,
    width: 100,
    maxWidth: 100,
    flexBasis: "48%",
  },
  uploadBillsForm: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  attachmentFieldOuter: {
    width: "100%",
    position: "relative",
    height: 60,
  },
  modalContent: {
    marginTop: 0,
    width: "100%",
  },
  minHeight52: {
    minHeight: 52,
  },
  formStyles: {
    flexGrow: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          marginBottom: 60,
        };
      }
    },
  },
  poBoxinput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      } else {
        return {
          maxWidth: "75%",
          minWidth: "75%",
        }
      }
    },
  },
  addIncBtn: {
    marginTop: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 15,
          marginBottom: 20,
          display: "inline-block",
        };
      }
    },
  },
  mins30Top: {
    marginTop: -30,
    marginLeft: 0,
    marginBottom:5
  },
  textAlign: {
    alignItems: "center",
    height: "55%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -16,
          marginBottom: 16,
          alignItems: "flex-start",
        };
      }
    },
  },
  incNameDropDown: {
    screen: ({ width }) => {
      if (width >= 991)
        return {
          maxWidth: "49.5%",
          minWidth: "49.5%",
        };
    },
  },
  maxWith49: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          maxWidth: "49.5%",
        };
    },
  },

  viewPdfIconSize: {
    width: 25,
    height: 30,
    minWidth: 30,
    backgroundSize: "contain",
    display: "block",
    backgroundPosition: "center center",
  },
  dashboardMessageComponent: {
    marginTop: 200, //164,
    marginRight: 33,
    marginLeft: 16,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 16,
    paddingLeft: 16,
    display: "block",
    flexGrow: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTop: 0,
    backgroundColor: "#fff",
    // position: "unset",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginTop: 200,
          marginRight: 16,
          marginLeft: 16,
        };
      }
      if (width >= 320 && width <= 991) {
        return {
          paddingTop: 15,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
          position: "relative",
          margin: 0,
          padding: 0,
          height: "calc(100vh - 360px)"
        };
      }
    },
  },
  noBorder: {
    borderTopWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    marginTop: 70,
    padding: 16,
    paddingTop: 0
  },
  confirmationModal: {
    width: 420,
    maxHeight: "calc(100vh - 30vh)"
  },
  width300: {
    width: 300
  },
  customMarLAuto: {
    marginLeft: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customPadding: {
    padding: 0,
    paddingTop: 8
  },
  tboday: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          position: "relative",
          margin: 0,
          padding: 0,
          maxHeight: "calc(100vh - 360px)",
          marginTop: 24,
        };
    }, 
  },
  width100: {
    width: 100,
    minWidth: 100
  },
});
