import React from "react";
import R14, { View, TextInputField, StyleSheet, Form, SubmitButton, Text, Touchable, Image, Platform} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import CommonValidators from "../utils/CommonValidators";
import FileUploadComponent from "../components/FileUploadComponent";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";
const attachments = require("../assets/images/icAttachment.png");
export default R14.connect(
  class ChatInputField extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        fileName: "",
      }
      this.message = this.props.messageType;
      this.fileToSave = null;
      this.form = null;
      this.child = React.createRef()
    }

    componentDidMount() {
      this.form = this.props.app.ui.form("chatBox");
    }

    onSubmit = async (form) => {
      const { onAfterSent } = this.props;
      form = this.props.app.ui.form("chatBox");
      if (!!this.fileToSave && 'content' in this.fileToSave && !!this.fileToSave.content) {
        var attac = {
          filename: this.fileToSave.filename,
          contentType: this.fileToSave.contentType,
          content: this.fileToSave.content,
        };
      }
      if (!!this.fileToSave && 'content' in this.fileToSave && !!this.fileToSave.content && !form.values.enterMessage) {
        form.elmts.enterMessage.setValue("Attachment");
      }
      if (await form.validate()) {
        const { billData, messageType } = this.props;

        let messages = this.props.msgList;
        messages = messages.filter((el) => el.envelope.messageBody);
        billData.note = form.values.enterMessage;
        billData.messageType = messageType;
        let lastLeaf;
        if (!!this.fileToSave && 'content' in this.fileToSave && !!this.fileToSave.content) {
          lastLeaf = { ...messages[0], billData, attac };
        } else {
          lastLeaf = { ...messages[0], billData };
        }
        let payload = await this.props.app.dm.billDetails.getNotePayload(lastLeaf);

        try {
          let res = await this.props.app.dm.billDetails.postMessageForPayment(payload);
          if (res.threadId) {
            analyticsLogs(this.props.eventName || CommonString.MESSAGE_SECTION, `${CommonString.MESSAGE_SENT}`, this.props.app);
            form.reset();
            onAfterSent(payload);
            this.deleteFile();
            this.props.app.dm.commonDomain.showToastr("success", CommonString.MessageSent);
          }
          else
          {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }  
        } catch (err) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
      }
    };

    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.enterMessage.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.enterMessage.setError(CommonString.ContentExceed140);
      }
    };

    onFileInputChange = async (val, status) => {
      if (status === "done") {
        const {fileName}  = this.state;
        let fizeSize = val.actualsize / 1024 / 1024;
        if (fizeSize > 2) {
          await this.child.current.wrappedInstance.pullErrorText(CommonString.MaxTwoMBAllowed);
          return false;
        }
        if (fileName === val.filename) {
          await this.child.current.wrappedInstance.pullErrorText(CommonString.DuplicateFile);
          return false;
        }
        analyticsLogs(this.props.eventName || CommonString.MESSAGE_SECTION, `${CommonString.ATTACHMENT_ADDED}`, this.props.app);
        this.fileToSave = val;
        this.setState({ fileName: val.filename });
      }
    };

    deleteFile = (removed) => {
      if(removed === 'removed') analyticsLogs(this.props.eventName || CommonString.MESSAGE_SECTION, `${CommonString.ATTACHMENT_REMOVED}`, this.props.app);
      this.fileToSave = null;
      this.setState({ fileName: "" });
    }

    render() {
      const { notAllowedChat, sendBtnStyle} = this.props;

      return notAllowedChat === false ? null : (
          <Form
            name="chatBox"
            style={[styles.formContainer]}
            validators={CommonValidators.getFormValidator()}
            validateBeforeSubmit={true}
            onSubmit={this.onSubmit}>
            {
              !!this.state.fileName ?
                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd]}>
                  <View style={[customCommonStyles.textOverflowEllipsis, customCommonStyles.attachedFileName]}>
                    <Text style={[commonStyles.exSmallHeading, commonStyles.fontWeight400, commonFontStyles.workSans]}>{this.state.fileName}</Text>
                  </View>
                  <Touchable onPress={() => this.deleteFile('removed')} style={[styles.closeIcon, commonStyles.marginLeft16]}>
                    <View>
                      <Text style={[commonStyles.exSmallHeading, styles.activeCls, customCommonStyles.attachedFileCloseBtn]}>&#10006;</Text>
                    </View>
                  </Touchable>
                </View>
                : null
            }
            <View style={[styles.fieldsHolder, commonStyles.flex1, commonStyles.flexDirectionRow]}>
              {/* <View style={[commonStyles.flex1, commonStyles.flexDirectionRow,]}> */}
                <View style={[styles.messageTextInput, commonStyles.flexGrow1, customCommonStyles.chatMessageTextInputOuter]}>
                  <TextInputField
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    name="enterMessage"
                    label={`${CommonString.ChatInputLabel}*`}
                    validator="cmtsVali"
                    required= {CommonString.MessageRequired}
                    multiline
                    onChangeText={this.readText}
                    style={[commonStyles.marB0, styles.messageTextField, commonStyles.resizeNone, commonFontStyles.workSans, commonStyles.heading6, 
                      customCommonStyles.customMessageTextField
                    ]}
                    helperTextStyle={[commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0, commonStyles.flex1, styles.flexNoWrap, customCommonStyles.customChatMessageTextInputError]}
                  />
                </View>
                <View style={[styles.attachmentField, styles.customMarTop16, Platform.OS !== "web" ? commonStyles.marginTop16 : null]}>
                  <FileUploadComponent
                    ref={this.child}
                    maxFiles={1}
                    source={"XBP"}
                    labelText={<View><Image source={attachments} resizeMode="contain" style={commonStyles.icon14} /></View>}
                    fileTypes={"application/pdf,text/xml,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                    fileTypeLabel={"pdf,xml,excel,word"}
                    onValueChange={this.onFileInputChange}
                    attachmentFieldOuterCustomHeight={[styles.attachmentFieldOuterCustomHeight]}
                    showErrorInModal={true}
                  />

                  <Text style={[styles.attachmentCover]}></Text>
                </View>
              {/* </View> */}
              <SubmitButton
                type="button"
                title="Send"
                name="sendBtn"
                style={[customCommonStyles.noBoxShadow, commonStyles.blueButton, styles.blueButton, sendBtnStyle, Platform.OS === 'web' ? commonStyles.marginLeft16 : null, commonStyles.btnHeight24, styles.customMarTop16]}
                titleTextStyle={[commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.whiteColor]}
              />
            </View>
          </Form>
      );
    }
  }
);

const styles = StyleSheet.create({
  fieldsHolder: {
    position: "relative",
    flexGrow: 1,
    // minHeight: 56,
    screen: ({width}) => {
      if (width >= 991 && width <= 1024) {
          return {
            paddingLeft: 16,
            paddingRight: 16      
          }
      } 
    }
  },
  messageTextField: {
    position: "relative",
    zIndex: 1,    
  },
  attachmentCls: {
    height: 52,
    width: 56,
    paddingTop: 5,
  },
  attachmentCover: {
    backgroundColor: "#fff",
  },
  messageTextInput: {
    // color: "red",
  },
  attachmentField: {
    position: "relative",
    zIndex: 99,
    maxWidth: 20,
    height: 24,
    backgroundColor: "#fff",
    marginTop: 16,
    marginLeft: 10,
    screen: ({width}) => {
      if (width <= 991) {
          return {
            marginTop: 0,
            marginLeft: 8,
          }
      } 
    }
  },
  blueButton: {
    border: 0,
    minHeight: 24,
    height: 24,
    marginTop: 8,
    screen: ({ width }) => {
      if (width <= 1024)
        return {
          paddingRight: 8,
          paddingLeft: 8,
          marginTop: 0,
        };
    },
  },
  fileIcon: {
    color: "red",
  },
  formContainer: {
    paddingLeft: 0, 
    paddingRight: 0
  },   
  chatMessageTextInputError: {},
  flexNoWrap: {
    flexWrap: "nowrap",
  },
  closeIcon: {
    minHeight: 20,
    minWidth: 20,
    maxWidth: 20,
    flex: 1,
    flexGrow: 0,
    justifyContent: "center",
    alignItems: "center",

  },
  customMarTop16: {
    marginTop: Platform.OS === "web" ? 16 : 8,
  }
});
