import React from "react";
import R14, { View, Touchable, ScrollView, StyleSheet, FlatList, Text, Image, Platform } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import NoRecordsFound from "../../components/NoRecordsFound";
import DateUtils from "../../utils/DateUtils";
import { CURRENCY } from "../../constant/app.data.constant";
import CommonUtility from "../../utils/CommonUtility";
import { SEPA_ACCEPT_TRANS, SEPA_CANCEL_RSNS, SEPA_REJCT_RSNS } from "../../constant/sepa.constant";
import { analyticsLogs } from "../../utils/AnalyticLogs";
import { CommonString } from "../../constant/appCommonText";

const attachedFileIcon = require("../../assets/images/attachedFileIcon.png");
const smsArrowL = require("../../assets/images/smsarrowl.png");
const smsArrowR = require("../../assets/images/smsarrowr.png");
const paidMark = require("../../assets/images/tickMark.png");
const cancelMark = require("../../assets/images/cancelMark.png");

export default R14.connect(
  class BubbleChatComponent extends React.Component {
    constructor(props) {
      super(props);
      this.scrollRef = React.createRef();
    }

    downloadFile = async (billDetail, sepaBillDetail, file) => {
      let payload = {
        rId: billDetail.resourceId,
        pmtInfId: billDetail.selectedPaymentType,
        bId: sepaBillDetail.InstalmentId,
        fileId: file.id,
      };
      this.props.onDownloadFile(payload, file.fileName);
    };

    payNow = async (billDetail, sepaBillDetail) => {
      let payload = {
        billDetail: billDetail,
        sepaBillDetail: sepaBillDetail,
        isPaymentCheckout: true
      };
      analyticsLogs(CommonString.SEPA_BILL_DETAILS, `${CommonString.PAY_NOW_ACTION}`, this.props.app);

      await this.props.paymentInit(payload);
    }

    renderMessages(billDetail, sepaBillDetail, item, isOneTime, style, unPaidInstalment) {
      var displayTime = DateUtils.dateTodayYesterday(item.actionOn, "DD/MM/YYYY");
      switch (item.messageType) {
        case "NEW":
          return (
            <View style={!!style ? [styles.sentSMS] : [styles.recieveSMS]}>
              <View style={!!style ? [styles.senderImg] : [styles.reciverimg]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight600, commonStyles.exSmallHeading]}>
                  {CommonUtility.shortName(item.actionBy)}
                </Text>
              </View>
              <View style={[styles.messages]}>
                <View style={[styles.bubble]}>
                  <View style={[styles.row]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexStart, styles.width]}>Request Initiated</Text>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexEnd, styles.width]}>{CURRENCY[billDetail.currency]}{CommonUtility.formatAmount(sepaBillDetail.billAmount)}</Text>
                  </View>
                  <View style={[styles.smsTextHeading]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>EndtoEndId: {sepaBillDetail.endToEndIdentification}</Text>
                  </View>
                  {/* <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Transaction Status: Requested</Text>
                  </View> */}
                  <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Requested execution date: {DateUtils.formatDate(sepaBillDetail.executionDate, "DD/MM/YYYY")}
                    </Text>
                  </View>
                  {/* <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Expiry Date: {DateUtils.formatDate(billDetail.expiryDate, "DD/MM/YYYY")}</Text>
                  </View> */}
                  {item.attachments && !!item.attachments.length ? (
                    item.attachments.map((file, ind) => {
                      return <Touchable onPress={() => { this.downloadFile(billDetail, sepaBillDetail, file) }} key={"file_" + ind}
                        style={[commonStyles.marginBottom5,]}>
                        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart, commonStyles.marginTop5]}>
                          <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                          <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>
                            {CommonUtility.textSubstring(file.fileName, 25, "native")}
                          </Text>
                        </View>
                      </Touchable>
                    })
                  ) : null}
                  <Image source={!!style ? smsArrowR : smsArrowL} style={!!style ? [styles.hostTextBorder, commonStyles.icon15] : [styles.guestTextBorder, commonStyles.icon15]} />
                  {(!!!isOneTime && ["ACCEPTED", "PART PAID"].includes(billDetail.billStatus) && sepaBillDetail.billStatus === "ACCEPTED" && sepaBillDetail.uid === unPaidInstalment) &&
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd]} >
                      <Touchable onPress={() => this.payNow(billDetail, sepaBillDetail, item)}>
                        <View style={[commonStyles.textAlignCenter, styles.blueButton]}>
                          <Text style={[commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.exSmallHeading,
                          commonStyles.whiteColor,
                          Platform.OS === 'web' && styles.fullWidth,
                          commonStyles.textAlignCenter
                          ]}>Pay Now</Text>
                        </View>
                      </Touchable>
                    </View>
                  }
                </View>
                <View style={[commonStyles.textAlignLeft, commonStyles.paddingLeftRight10]}>
                  <Text
                    style={[
                      commonStyles.exSmallHeading,
                      commonStyles.chatTiming,
                      commonStyles.blackTextColor,
                      commonStyles.marLAuto,
                      commonFontStyles.workSans,
                    ]}>
                    {DateUtils.dateTodayYesterday(billDetail.billDate, "DD/MM/YYYY")}
                  </Text>
                </View>
              </View>
            </View>
          );
        case "REJECTED":
          return (
            <View style={!!style ? [styles.sentSMS] : [styles.recieveSMS]}>

              <View style={[styles.messages]}>
                <View style={[styles.bubble]}>
                  <View style={[styles.row]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexStart, styles.width]}>Request Rejected</Text>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexEnd, styles.width]}>{CURRENCY[billDetail.currency]}{CommonUtility.formatAmount(billDetail.billAmount)}</Text>
                  </View>
                  <View style={[styles.smsTextHeading]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Rejected Date Time: {DateUtils.dateAndTimeWithFormat(item.actionOn, "DD/MM/YYYY")}</Text>
                  </View>
                  {/* <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Status: {SEPA_REJCT_TRANS.find(val => val.value === item.transactionStatus).label.replace(`${item.transactionStatus} - `, '')}</Text>
                  </View> */}
                  <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Reject Reason: {!!item.statusReasonInfo ? SEPA_REJCT_RSNS.find(val => val.value === item.statusReasonInfo).label.replace(`${item.statusReasonInfo} - `, '') : "N/A"}</Text>
                  </View>

                  {item.attachments && !!item.attachments.length ? (
                    item.attachments.map((file, ind) => {
                      return <Touchable onPress={() => { this.downloadFile(billDetail, sepaBillDetail, file) }} key={"file_" + ind}
                        style={[commonStyles.marginBottom5,]}>
                        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart, commonStyles.marginTop5]}>
                          <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                          <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>
                            {file.fileName}
                          </Text>
                        </View>
                      </Touchable>
                    })
                  ) : null}
                  <Image source={!!style ? smsArrowR : smsArrowL} style={!!style ? [styles.hostTextBorder, commonStyles.icon15] : [styles.guestTextBorder, commonStyles.icon15]} />
                </View>
                <View style={[commonStyles.textAlignRight, commonStyles.paddingLeftRight10]}>
                  <Text
                    style={[
                      commonStyles.exSmallHeading,
                      commonStyles.chatTiming,
                      commonStyles.blackTextColor,
                      commonStyles.marLAuto,
                      commonFontStyles.workSans,
                    ]}>
                    {displayTime}
                  </Text>
                </View>
              </View>
              <View style={!!style ? [styles.senderImg] : [styles.reciverimg]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight600, commonStyles.exSmallHeading]}>
                  {CommonUtility.shortName(item.actionBy)}
                </Text>
              </View>
            </View>
          );
        case "ACCEPTED":
          return (
            <View style={!!style ? [styles.sentSMS] : [styles.recieveSMS]}>
              <View style={[styles.messages]}>
                <View style={[styles.bubble]}>
                  <View style={[styles.row]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexStart, styles.width]}>Request Accepted</Text>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexEnd, styles.width]}>{CURRENCY[billDetail.currency]}{CommonUtility.formatAmount(billDetail.billAmount)}</Text>
                  </View>
                  <View style={[styles.smsTextHeading]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Accepted Date Time: {DateUtils.dateAndTimeWithFormat(item.actionOn, "DD/MM/YYYY")}</Text>
                  </View>
                  <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Accept Reason: {SEPA_ACCEPT_TRANS.find((val) => val.value === item.transactionStatus).label.replace(`${item.transactionStatus} - `, '')}</Text>
                  </View>
                  {item.attachments && !!item.attachments.length ? (
                    item.attachments.map((file, ind) => {
                      return <Touchable onPress={() => { this.downloadFile(billDetail, sepaBillDetail, file) }} key={"file_" + ind}
                        style={[commonStyles.marginBottom5]}>
                        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart, commonStyles.marginTop5]}>
                          <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                          <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                            {file.fileName}
                          </Text>
                        </View>
                      </Touchable>
                    })
                  ) : null}
                  <Image source={!!style ? smsArrowR : smsArrowL} style={!!style ? [styles.hostTextBorder, commonStyles.icon15] : [styles.guestTextBorder, commonStyles.icon15]} />
                  {(!!isOneTime && billDetail.billStatus === "ACCEPTED" && sepaBillDetail.billStatus === "ACCEPTED") &&
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd]} >
                      <Touchable onPress={() => this.payNow(billDetail, sepaBillDetail, item)}>
                        <View style={[commonStyles.textAlignCenter, styles.blueButton]}>
                          <Text style={[commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.exSmallHeading,
                          commonStyles.whiteColor,
                          Platform.OS === 'web' && styles.fullWidth,
                          commonStyles.textAlignCenter
                          ]}>Pay Now</Text>
                        </View>
                      </Touchable>
                    </View>
                  }
                </View>
                <View style={[commonStyles.textAlignRight, commonStyles.paddingLeftRight10]}>
                  <Text
                    style={[
                      commonStyles.exSmallHeading,
                      commonStyles.chatTiming,
                      commonStyles.blackTextColor,
                      commonStyles.marLAuto,
                      commonFontStyles.workSans,
                    ]}>
                    {displayTime}
                  </Text>
                </View>
              </View>
              <View style={!!style ? [styles.senderImg] : [styles.reciverimg]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight600, commonStyles.exSmallHeading]}>
                  {CommonUtility.shortName(item.actionBy)}
                </Text>
              </View>
            </View>
          );
        case "CANCELLED":
          return (
            <View style={!!style ? [styles.sentSMS] : [styles.recieveSMS]}>
              <View style={!!style ? [styles.senderImg] : [styles.reciverimg]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight600, commonStyles.exSmallHeading]}>
                  {CommonUtility.shortName(item.actionBy)}
                </Text>
              </View>
              <View style={[styles.messages]}>
                <View style={[styles.bubble]}>
                  <View style={[styles.row]}>
                    <View style={[styles.width, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                      <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexStart]}>Request Cancelled</Text>
                      <Image source={cancelMark} style={[commonStyles.icon10, commonStyles.marginLeft10]} />
                    </View>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexEnd, styles.width]}>{CURRENCY[billDetail.currency]}{CommonUtility.formatAmount(billDetail.billAmount)}</Text>
                  </View>
                  <View style={[styles.smsTextHeading]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Cancelled Date Time: {DateUtils.dateAndTimeWithFormat(item.actionOn, "DD/MM/YYYY")}</Text>
                  </View>
                  <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Cancel Reason: {SEPA_CANCEL_RSNS.find(val => val.value === item.transactionStatus).label.replace(`${item.transactionStatus} - `, '')}</Text>
                  </View>
                  <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Additional Info: {item.statusReasonInfo || "N/A"}</Text>
                  </View>

                  {item.attachments && !!item.attachments.length ? (
                    item.attachments.map((file, ind) => {
                      return <Touchable onPress={() => { this.downloadFile(billDetail, sepaBillDetail, file) }} key={"file_" + ind}
                        style={[commonStyles.marginBottom5]}>
                        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart, commonStyles.marginTop5]}>
                          <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                          <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>
                            {file.fileName}
                          </Text>
                        </View>
                      </Touchable>
                    })
                  ) : null}
                  <Image source={!!style ? smsArrowR : smsArrowL} style={!!style ? [styles.hostTextBorder, commonStyles.icon15] : [styles.guestTextBorder, commonStyles.icon15]} />
                </View>
                <View style={[commonStyles.textAlignLeft, commonStyles.paddingLeftRight10]}>
                  <Text
                    style={[
                      commonStyles.exSmallHeading,
                      commonStyles.chatTiming,
                      commonStyles.blackTextColor,
                      commonStyles.marLAuto,
                      commonFontStyles.workSans,
                    ]}>
                    {displayTime}
                  </Text>
                </View>
              </View>
            </View>
          );
        case "Paid":
        case "PAID":
          return (
            <View style={!!style ? [styles.sentSMS] : [styles.recieveSMS]}>
              <View style={[styles.messages]}>
                <View style={[styles.bubble]}>
                  <View style={[styles.row]}>
                    <View style={[styles.width, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                      <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexStart]}>Amount Paid</Text>
                      <Image source={paidMark} style={[commonStyles.icon14, commonStyles.marginLeft10]} />
                    </View>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexEnd, styles.width]}>{CURRENCY[billDetail.currency]}{CommonUtility.formatAmount(sepaBillDetail.billAmount)}</Text>
                  </View>
                  <View style={[styles.smsTextHeading]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Payment Date Time: {DateUtils.dateAndTimeWithFormat(item.actionOn, "DD/MM/YYYY")}</Text>
                  </View>
                  <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Payment Mode: {item.statusReasonInfo}</Text>
                  </View>
                  <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>End to End Id: {sepaBillDetail.endToEndIdentification}</Text>
                  </View>
                  <View style={[styles.smsText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>Payment Ref: {item.transactionStatus}</Text>
                  </View>
                  {item.attachments && !!item.attachments.length ? (
                    item.attachments.map((file, ind) => {
                      return <Touchable onPress={() => { this.downloadFile(billDetail, sepaBillDetail, file) }} key={"file_" + ind}
                        style={[commonStyles.marginBottom5]}>
                        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart, commonStyles.marginTop5]}>
                          <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                          <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                            {file.fileName}
                          </Text>
                        </View>
                      </Touchable>
                    })
                  ) : null}
                  <Image source={!!style ? smsArrowR : smsArrowL} style={!!style ? [styles.hostTextBorder, commonStyles.icon15] : [styles.guestTextBorder, commonStyles.icon15]} />
                </View>
                <View style={[commonStyles.textAlignRight, commonStyles.paddingLeftRight10]}>
                  <Text
                    style={[
                      commonStyles.exSmallHeading,
                      commonStyles.chatTiming,
                      commonStyles.blackTextColor,
                      commonStyles.marLAuto,
                      commonFontStyles.workSans,
                    ]}>
                    {displayTime}
                  </Text>
                </View>
              </View>
              <View style={!!style ? [styles.senderImg] : [styles.reciverimg]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight600, commonStyles.exSmallHeading]}>
                  {CommonUtility.shortName(item.actionBy)}
                </Text>
              </View>
            </View>
          );
        default:
          return null;
      }
    }

    componentDidMount() {
      this.scrollToEnd();
    }

    scrollToEnd(options = { animated: false }) {
      if (this.scrollRef.current) this.scrollRef.current.scrollToEnd(options);
    }

    render() {
      let { customClass, billDetails, displayMessages, sepaBillDetail, userDetails, isOneTime, isPaymentCheckout, unPaidInstalment } = this.props;
      return (
        <ScrollView
          ref={this.scrollRef}
          style={[styles.messageComponent, commonStyles.padding0, !!isPaymentCheckout && commonStyles.displayNone, customClass]}>
          <FlatList
            style={[styles.messageComponent, !!displayMessages && !!displayMessages.length ? null : styles.noRecordsStyles,]}
            ListEmptyComponent={<NoRecordsFound message={"No message found."} />}
            data={displayMessages || []}
            keyExtractor={(el) => el.uid}
            renderItem={({ item, index }) => (
              <View style={[commonStyles.flex1]} key={`msg_${index}`}>
                {this.renderMessages(billDetails, sepaBillDetail, item, isOneTime, userDetails.pid === item.actionId, unPaidInstalment)}
              </View>
            )}
          />
        </ScrollView>
      );
    }
  }
);

const styles = StyleSheet.create({
  attachedFileIcon: {
    marginRight: 5,
  },
  messageComponent: {
    position: "static",
  },
  blueButton: {
    backgroundColor: "#d71996",
    minHeight: 24,
    height: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    width: 100,
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullWidth: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10
  },

  // new css

  sentSMS: {
    flex: 1,
    flexDirection: "row",
    maxWidth: "100%",
  },
  recieveSMS: {
    flex: 1,
    flexDirection: "row",
    maxWidth: "100%",
    minWidth: "100%",
  },
  reciverimg: {
    backgroundColor: "#e2f0ff",
    top: 0,
    padding: 2,
    borderRadius: 35,
    marginRight: 5,
    width: 35,
    height: 35,
    maxWidth: 35,
    minWidth: 35,
    maxHeight: 35,
    textAlign: "center",
    boxShadow: "0px 3px 4px #dadada",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  senderImg: {
    backgroundColor: "#ecf8e3",
    marginLeft: 0,
    top: 0,
    padding: 2,
    borderRadius: "50%",
    width: 35,
    height: 35,
    maxWidth: 35,
    minWidth: 35,
    maxHeight: 35,
    textAlign: "center",
    boxShadow: "0px 3px 4px #dadada",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginLeft: 5,
        }
    }
  },
  messages: {
    marginBottom: 10,
    flex: 1,
    flexDirection: "column",
    position: "relative",
    width: "100%",
    // alignItems: "flex-start"
  },
  bubble: {
    borderRadius: 10,
    maxWidth: "100%",
    padding: "10px 13px",
    // marginBottom: 5,
    wordBreak: "break-word",
    position: "relative",
    background: "#fff",
    border: "1px solid #ded9d9",
    width: "98%",
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  width: {
    width: "50%"
  },
  smsTextHeading: {
    borderTop: "1px solid #dadada",
    paddingTop: 5,
    marginTop: 5
  },
  smsText: {
    marginBottom: 0
  },
  hostTextBorder: {
    height: 0,
    width: 0,
    position: "absolute",
    top: Platform.OS !== "web" ? -1 : -2.7,
    right: -7.7
  },
  guestTextBorder: {
    height: 0,
    width: 0,
    position: "absolute",
    top: Platform.OS !== "web" ? -1 : -2.7,
    left: -7.2,
  },
  cancelled: {
    color: "#b40220"
  },
  noRecordsStyles: {
    height: 70
  }
});
