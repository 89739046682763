import React from "react";
import R14, { 
  Form,
  View,
  Text,
  StyleSheet,
  Touchable,
  SelectMenuField,
  Image,
  Button,
  SubmitButton, 
  TextInputField,
  Platform,
  AsyncStorage,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import CommonValidators from "../../utils/CommonValidators";
import { USER_TYPE, MESSAGE_TYPE, LOGIN_TYPE } from "../../constant/app.data.constant";
import CommonUtility from "../../utils/CommonUtility";
import FileUploadComponent from "../../components/FileUploadComponent";
import InAppCameraComponent from "../../components/InAppCameraComponent";
import DeviceUtils from "../../utils/DeviceUtils";
import Linking from "../../utils/Linking";
import PopupModalComponent from "../../components/PopupModalComponent";

import { customCommonStyles } from "../../assets/css/customCommonStyle";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import ServiceChargePaymentComponent from '../../components/PCH/ServiceChargePaymentComponent'
import DateUtils from "../../utils/DateUtils";
import TreatmentAddressComponent from "../../components/PCH/TreatmentAddressComponent";
import DiagnosticDetailsComponent from "../../components/PCH/DiagnosticDetailsComponent";
import CptDetailsComponent from "../../components/PCH/CptDetailsComponent";
import ReactDatePicker from "../../components/PCH/ReactDatePicker"
import DatePicker from "../../components/PCH/DatePicker"
import UploadBillConfirmationComponent from "../../components/PCH/UploadBillConfirmationComponent"
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import RSAencryption from "../../utils/RSAencryption";
import { CommonString } from "../../constant/appCommonText";
import { analyticsLogs } from "../../utils/AnalyticLogs";

const cameraIcon = require("../../assets/images/cameraIcon.png");
const deleteIcon = require("../../assets/images/delete.png");
const plusSolidIcon = require("../../assets/images/plus_solid.png");

export default R14.connect(
  class PatientUploadBillsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAddAttachmentsVisible: false,
        attachments: [],
        isDisable: false,
        isCamVisible: false,
        isEnterMobileNo: false,
        filename: "",
        hasOnlyOnePOBox: true,
        osName: "",
        hasPermission: false,
        loaderVisible: false,
        bill: null,
        selectedInsurance: null,
        isServiceChargeEnabled: true, // true //false
        alertMessage: "",
        isAlertVisible: false,
        membersList: [],
        isSuperBill: !!this.props.draftBill && !!this.props.draftBill.uid ? false : true,
        treatedAtType: {},
        selectedMemberId: "",
        selectedTreatedAt: { label: "Office", value: "11" },
        providerTreatedAddresses: [],
        isEditOrAddCpt: false,
        cptRowId: "",
        isCptCodeError: false,
        isCptFeeError: false,
        isCptUnitError: false,
        isDateOfServiceError: false,
        cptItems: [],
        isDiagnosisError: false,
        isTotalClaimAmountError: false,
        cptDetailsList: [],
        isConfirmationVisible: false,
        isEnterSSID: false,
        isEnterDob: false,
        isProviderNameError: false,
        isMemeberNameError: false,
        confirmationPopUpVisibility: false,
        claimEnsureCompleted: false,
        showDatePicker: false,
        showDobDatePicker: false,
        showDateOfServiceDatePicker: false,
        taxIdNumber: "",
        memberId: "",
        insuranceGroupId: "",
        nonRegisterUser: false,
        selectClient: null
      };
      this.form = null;
      this.providers = [];
      this.insuranceItems = [];
      this.poBoxNoItems = [];
      this.patientVisitTypes = [];
      this.patientAddress = {};
      this.child = React.createRef();
      this.cptList = [];
      this.diagnosisList = [];
      this.clients = [];
    }

    componentDidMount = async () => {
      try {
        this.form = this.props.app.ui.form("uploadBills");
        const { insurances, userLoginDetail, draftBill } = this.props;
        if (!userLoginDetail) {
          this.setState({
            nonRegisterUser: true,
            confirmationPopUpVisibility: true,
          })
          return false;
        }
        let userBUs = await this.props.app.dm.rest.getuserBUDetails();
        const { isExternalLogin } = this.props.app.dm.userSession.state;
        this.setState({
          osName: Platform.OS,
        });
        this.insuranceItems = [];
        let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
        if (userType === USER_TYPE.PCH_PATIENT) {
          if (!!insurances && insurances.length > 0) {
            insurances.forEach((insurance) => {
              this.insuranceItems.push({
                label: insurance.name,
                value: insurance.uid,
                poBoxNo: insurance.poBoxNo,
                address1: insurance.address1,
                city: insurance.city,
                state: insurance.state,
                groupNumber: insurance.groupNumber
              });
            });
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Please add insurance company.",
            });
          }
          if (!isExternalLogin && !!insurances && insurances.length > 0 && (!userBUs || !userBUs.length || !userBUs[0].addressLine1 || !userBUs[0].town || !userBUs[0].state || !userBUs[0].postCode)) {
            this.setState({
              isAlertVisible: true,
              alertMessage: CommonString.EnterAddressRequiredAlert,
            });
          }
          this.patientAddress = userBUs[0];
          if (!!this.form.elmts.doctorName) {
            this.form.elmts.doctorName.setItems(this.insuranceItems);
            let selectPrimary = insurances.filter((ins) => ins.isPrimary === "true");
            if (!!selectPrimary && selectPrimary.length > 0) {
              let val = this.insuranceItems.filter((ins) => ins.value === selectPrimary[0].uid)[0];
              this.form.elmts.doctorName.setValue(val);
              this.onDoctorSearch(val);
            }
          }
          if (userLoginDetail.uid) {
            if (!!this.form.elmts.mobileNumber && userLoginDetail.mobileNumber) this.mobileNumberText(userLoginDetail.mobileNumber.split(" ")[1]);
            if (!!this.form.elmts.email) this.form.elmts.email.setValue(userLoginDetail.email);
          }
        }
        if (!!this.form.elmts.countryCode) this.form.elmts.countryCode.setValue({ label: "+1", value: "+1" });
        // set default date and time
        let date = DateUtils.formatDateInMMDDYYYY(new Date());
        this.form.elmts.patientTreatedOn.setValue(date);

        // get provider list
        let docs = [];
        if (!!draftBill && draftBill.uid) {
          date = DateUtils.formatDateInMMDDYYYY(draftBill.insuranceMemberTreatedOn);
          this.form.elmts.patientTreatedOn.setValue(date);
          if (!!draftBill.notes)
            this.form.elmts.notes.setValue(draftBill.notes);

          this.form.elmts.totalClaimAmount.value = this.numberWithCommas(draftBill.insuranceMemberClaimsAmount.replace(/[a-zA-Z\s!@#$%^&*()_\-+|~=`{}[\]:";'<>?,\\/]/g, "").substring(0, 70));
          this.setState({ isTotalClaimAmountError: false });

          docs = await this.props.app.dm.addDoctorsDomain.getProvidersOnSearch({
            uid: { eq: draftBill.doctorUid },
            parentUid: { eq: userLoginDetail.uid }
          });
        }
        else
          docs = await this.props.app.dm.addDoctorsDomain.getProvidersOnSearch({
            DoctorName: { like: `%%` },
            isActive: { eq: "true" },
            parentUid: { eq: userLoginDetail.uid }
          });

        if (!!docs && docs.length > 0) {
          docs.forEach((doctor) => {
            this.providers.push({
              label: `${doctor.DoctorName} (${doctor.NPI})`,
              value: doctor.uid,
              taxIdNumber: doctor.taxIdNumber
            });
          });
        };
        this.providers = await this.filterNPIs(this.providers);
        this.form.elmts.providerName.setItems(this.providers);
        if (!!draftBill && draftBill.uid) {
          await this.onProviderSearch(this.providers[0]);
          if (!!this.props.draftBill && !!this.props.draftBill.insuranceMemberTreatedType) {
            if (this.props.draftBill.insuranceMemberTreatedType.toLowerCase() === "telemedicine")
              await this.onTreatedAtChange({ label: "10 - Telehealth Provided in Patient's Home", value: "10" });
            else if (this.props.draftBill.insuranceMemberTreatedType.toLowerCase() === "home")
              await this.onTreatedAtChange({ label: "12 - Home", value: "12" })
          }
        }

        //Client data
        await this.getPageData(null, true);
      }
      catch (err) {
        console.log(err)
      }
    };

    async getPageData(search = null, defaultLoad = false) {
      let res = await this.props.app.dm.client.findMemberClients(
        {
          page: 1,
          resultsPerPage: 10,
          filter: !!search ? search : "",
          sort: [
            {
              field: "name",
              order: "ASC"
            }
          ]
        }
      );
      if (!!res && res.nodes) {
        if (!!defaultLoad) {
          res.nodes.forEach((client) => {
            this.clients.push({
              label: `${client.name}`,
              value: client.uid
            });})
        }
        else {
          this.clients = res.nodes.map((client) => {
            return {
              label: `${client.name}`,
              value: client.uid
            }
          });
        }
      }
      return this.clients;
    }

    onNonRegistrationCancel = async (val) => {
      this.props.app.nav.to("nonRegisterReceipt", { action: val });
      return false;
    }

    onNonRegistrationSubmit = async (payload) => {
      this.setState({ loaderVisible: true });
      if (!!payload && payload.email && payload.mobileNumber && payload.firstName) {
        let res = await this.props.app.dm.kcAPI.validateNonRegister(payload);
        if (!!res && typeof res === "boolean") {
          this.props.app.nav.to("nonRegisterSubmission");
        }
        else {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
      }
      this.setState({ loaderVisible: false });
      return false;
    }

    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.notes.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.notes.setError("Max 140 chars allowed in notes");
      }
    };

    onProviderSearch = async (val) => {
      const { label, value } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.providerName.setValue(null);
        this.setState({ taxIdNumber: "" })
        return false;
      }
      this.form.elmts.providerName.setValue({ label: label, value: value });
      this.form.elmts.providerName.setError(null);

      const providerDetails = this.providers.find(e => e.value === value);

      // provider address
      let filter = {
        filter: {
          doctorUid: { eq: value },
          isActive: { eq: "true" },
        },
        sort: { field: "createdAt", order: "DESC" },
      };
      this.setState({ loaderVisible: true, taxIdNumber: providerDetails.taxIdNumber || "" })
      let providerTreatedAddresses = await this.props.app.dm.addDoctorsDomain.getTreatmentAddressList(filter);

      providerTreatedAddresses = providerTreatedAddresses.map(address => {
        if (address) {
          return {
            ...address,
            label: `${address.addressLine1}${address.town ? ", " + address.town : ""}${address.state ? ", " + address.state : ""}${address.postCode ? ", " + address.postCode : ""}`,
            value: address.uid
          }
        }
        else return null;
      });

      providerTreatedAddresses = providerTreatedAddresses.filter(x=> x !== null);
      const primaryAddress = providerTreatedAddresses.find(address => address.isPrimary);

      this.form.elmts.patientVisitType.setValue({ label: "11 - Office", value: "11" });
      this.form.elmts.patientTreatedAt.setValue(!!primaryAddress ? primaryAddress : providerTreatedAddresses[0]);
      this.form.elmts.patientTreatedAt.setItems(providerTreatedAddresses);

      this.setState({
        selectedTreatedAt: { label: "Office", value: "11" },
        providerTreatedAddresses: providerTreatedAddresses,
        isProviderNameError: false,
        loaderVisible: false,
      });
    };

    onDoctorSearch = async (val) => {
      const { label, value } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.doctorName.setValue(null);
        this.form.elmts.poBoxNo.setValue(null);
        this.setState({ insuranceGroupId: "" })
        return false;
      }

      this.form.elmts.doctorName.setValue({ label: label, value: value });
      this.form.elmts.doctorName.setError(null);
      this.poBoxNoItems = [];
      const { userLoginDetail } = this.props;
      // analyticsLogs(CommonString.UPLOAD_BILL, CommonString.INSURANCE_SELECTED_ACTION, this.props.app);
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      this.membersList(value);
      if (userType === USER_TYPE.PCH_PATIENT && !!this.form.elmts.poBoxNo && !!value && !!this.insuranceItems && this.insuranceItems.length > 0) {
        this.form.elmts.poBoxNo.setError(null);
        this.form.elmts.poBoxNo.setValue(null);
        let insurance = this.insuranceItems.find((ins) => ins.value === value);
        if (!!insurance) {
          let address = !!insurance.address1 ? insurance.address1 : '';
          address = !!address && !!insurance.city ? `${address}, ${insurance.city}` : !!insurance.city ? insurance.city : address;
          address = !!address && !!insurance.state ? `${address}, ${insurance.state.split('-').pop()}` : !!insurance.state ? insurance.state.split('-').pop() : address;
          this.poBoxNoItems = [{
            label: ((!!insurance.poBoxNo && address) ? `${insurance.poBoxNo} ${address}` : !!insurance.poBoxNo ? insurance.poBoxNo : address),
            value: insurance.poBoxNo.trim(),
          }];
          this.form.elmts.poBoxNo.setValue(this.poBoxNoItems[0]);
          this.form.elmts.poBoxNo.setItems(this.poBoxNoItems);
          if (this.poBoxNoItems.length === 1) {
            this.setState({
              hasOnlyOnePOBox: true,
              insuranceGroupId: insurance.groupNumber || ""
            });
          } else {
            this.setState({
              hasOnlyOnePOBox: false,
              insuranceGroupId: insurance.groupNumber || ""
            });
          }
        }
      }
    };

    onSearchInsurance = async (val) => {
      const { insurances, userLoginDetail } = this.props;
      this.insuranceItems = [];
      if (!!insurances && insurances.length > 0) {
        insurances.forEach((insurance) => {
          this.insuranceItems.push({
            label: insurance.name,
            value: insurance.uid,
            poBoxNo: insurance.poBoxNo,
            address1: insurance.address1,
            city: insurance.city,
            state: insurance.state,
            groupNumber: insurance.groupNumber
          });
        });
      }
      if (!!val) {
        this.insuranceItems = this.insuranceItems
          .filter((item) => {
            return item.label.toLowerCase().includes(val.toLowerCase());
          }).sort((a, b) => b.label - a.label);

        // if (this.insuranceItems.length === 0) {
        let ins = await this.props.app.dm.addInsuranceDomain.getInsuranceOnSearch(userLoginDetail.uid, val);
        if (!!ins && ins.length > 0) {
          ins.forEach((insurance) => {
            this.insuranceItems.push({
              label: insurance.name,
              value: insurance.uid,
              poBoxNo: insurance.poBoxNo,
              address1: insurance.address1,
              city: insurance.city,
              state: insurance.state,
              groupNumber: insurance.groupNumber
            })
          });
          // }
        }
      }
      this.insuranceItems = await this.filterItems(this.insuranceItems);
      this.form.elmts.doctorName.setItems(this.insuranceItems);
      return this.insuranceItems;
    }

    filterItems = async (list) => {
      let filterArray = list.reduce((acc, current) => {
        const x = acc.find(item => item.value === current.value);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filterArray;
    }

    onPOBoxSearch = async (val) => {
      const { label } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.poBoxNo.setValue(null);
        return false;
      }
      this.form.elmts.poBoxNo.setValue(val);
      this.form.elmts.poBoxNo.setError(null);
    };

    backtoDraftBill = async (message) => {
      let homeScreen = await AsyncStorage.getItem("homeScreen");
      if (homeScreen === "externalClientbillHistoryRoute" || homeScreen === "patientDashboard") {
        this.props.app.nav.back();
      }
      else {
        const { appointment, redirect_url, access_token } = this.props;
        Linking.LinkingURL(`${redirect_url}&appointment=${appointment}&${message}&access_token=${access_token}`, "_self")
        await this.props.app.dm.dashboard.resetDashboardDomain();
      }
      return false;
    }

    onCancelClick = async () => {
      this.form.reset();
      this.setState({
        attachments: [],
        isDisable: false,
        loaderVisible: false,
        showDobDatePicker: false,
        showDatePicker: false,
        taxIdNumber: ""
      });
      const { draftBill } = this.props;
      if (!!draftBill && !!draftBill.uid) {
        await this.backtoDraftBill("errorMessage=dismiss");
        return false;
      }
      else {
        if (!!this.state.nonRegisterUser || this.props.userLoginDetail.loginType === LOGIN_TYPE.NONREGISTER)
          await this.onNonRegistrationCancel("cancelled");
        else
          this.props.app.nav.to("patientDashboard");

        return false;
      }
    };


    membersList = async (insuranceId) => {
      if (!insuranceId) return false;
      const filter = {
        filter: {
          isActive: { eq: "true" },
          insuranceId: { eq: insuranceId }
        },
        sort: { field: "createdAt", order: "DESC" },
        // page: currentPage,
        // resultsPerPage: resultsPerPage
      };
      this.setState({
        loaderVisible: true,
        isInsuranceCompanyError: false,
      });
      let members = await this.props.app.dm.addInsuranceDomain.getInsuranceMembersList(filter);

      members = members.map(member => {
        if (member) {
          return {
            ...member,
            label: `${member.firstName} ${member.lastName ? member.lastName : ''}`,
            value: member.uid
          }
        }
        else return null;
      })
      members = members.filter(x=> x !== null);
      const member = members.length ? members.filter(member => member.isPrimaryPolicyHolder === "yes")[0] ? members.filter(member => member.isPrimaryPolicyHolder === "yes")[0] : members[0] : {};

      this.setState({
        membersList: members,
        selectedMemberId: !!member.value ? member.value : '',
        loaderVisible: false,
        isMemeberNameError: !members.length,
        isInsuranceCompanyError: false,
        isEnterSSID: false,
        isEnterDob: !!member.dob ? true : false,
        memberId: member.memberId || ""
      })

      let docForm = this.props.app.ui.form("uploadBills");
      if (member.value) {
        let date = DateUtils.formatDateInMMDDYYYY(member.dob);
        docForm.elmts.dOB.setValue(date);
        docForm.elmts.patientName.setValue(member);
      } else {
        docForm.elmts.patientName.setValue("")
        docForm.elmts.dOB.setValue("")
      };
      docForm.elmts.patientName.setItems(members);

      return !!members && members.length ? members : []

    }

    handleBackCptBtn = () => {
      const form = this.props.app.ui.form("uploadBills");
      form.elmts.editCptCode.setValue(null);
      form.elmts.editFee.setValue(null);
      form.elmts.modifiers.setValue(null);

      form.elmts.editCptUnit.setError(null);
      form.elmts.editFee.setError(null);
      form.elmts.dateOfService.setError(null);
      form.elmts.modifiers.setError(null);

      this.setState({
        cptRowId: "",
        isEditOrAddCpt: false,
        isCptCodeError: null,
        isCptFeeError: null,
        isCptUnitError: null,
        isDateOfServiceError: false,
        isTotalClaimAmountError: false,
        showDateOfServiceDatePicker: false,
        isDisable: false,
      })
    };

    // Mobile number validation.
    async mobileNumberText(val, from) {
      let mobNumText = val;
      if (!!val) {
        let cleaned = ("" + mobNumText).replace(/\D/g, "").substring(0, 10);
        this.form.elmts.mobileNumber.setValue(cleaned);
        if (!!from && cleaned.length > 0) {
          this.setState({
            isEnterMobileNo: true,
          });
        } else {
          this.setState({
            isEnterMobileNo: false,
          });
        }
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          let formattedString = "";
          if (match[1] !== undefined) {
            match[1] = this.form.elmts.countryCode.value.value;
          } else {
            match[1] = "+1 ";
          }
          formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
          this.form.elmts.mobileNumber.setValue(formattedString);
          this.form.elmts.mobileNumber.setError(null);
        }
      } else {
        this.setState({
          isEnterMobileNo: false,
        });
      }
    }

    openPopScreen = async (code) => {
      this.setState({ cptRowId: code, isConfirmationVisible: true });
    };

    closePopScreen = () => {
      this.setState({ isConfirmationVisible: false, cptRowId: "" });
    };

    onEnterSSIdText = async (val) => {
      if (!!val) {
        let value = ("" + val).replace(/\D/g, "").substring(0, 4);
        if (!!value) {
          this.form.elmts.ssId.setValue(value);
          this.form.elmts.ssId.setError(null);
          this.form.elmts.dOB.setValue(null);
          this.form.elmts.dOB.setError(null);
          this.setState({
            isEnterSSID: true,
            isEnterDob: false,
            showDobDatePicker: false
          });
        } else this.clearText();
      } else this.clearText();
    };

    clearText = async () => {
      this.form.elmts.ssId.setValue("");
      this.setState({
        isEnterSSID: false,
      });
    };

    removeCptItem = async () => {
      const { cptRowId, cptDetailsList } = this.state;
      const updatedCptList = cptDetailsList.filter(cpt => {
        if (cpt.cptRowId === cptRowId) {
          let totalAmount = +(this.removeCommas(this.form.values.totalClaimAmount)) - +(this.removeCommas(cpt.fees));
          totalAmount = `${totalAmount}`;
          if (+totalAmount && totalAmount.indexOf(".") !== -1) {
            totalAmount = +totalAmount;
            totalAmount = totalAmount.toFixed(2);
          }
          this.form.elmts.totalClaimAmount.setValue(this.numberWithCommas(totalAmount));
          this.setState({ isTotalClaimAmountError: false });
          return false;
        } else {
          return true;
        }
      });
      this.setState({
        cptDetailsList: updatedCptList,
        isConfirmationVisible: false,
        cptRowId: ""
      });
    };

    onTreatedAtChange = async (val) => {
      if (val && val.label && (val.label === "No Results Found" || val.label.includes("Type in a search value"))) {
        this.form.elmts.patientTreatedAt.setValue(null);
        return false;
      };
      // patient home = 12
      // Telehealth Provided in Patient's Home = 10
      this.form.elmts.patientVisitType.setValue(val);
      if (val && ["12", "10"].includes(val.value)) {
        let userLoginDetail = this.patientAddress;
        let address = [{
          value: userLoginDetail.uid,
          label: userLoginDetail.addressLine1 ? `${userLoginDetail.addressLine1}${userLoginDetail.addressLine2 ? ", " + userLoginDetail.addressLine2 : ""}${userLoginDetail.town ? ", " + userLoginDetail.town : ""}${userLoginDetail.state ? ", " + userLoginDetail.state : ""}${userLoginDetail.postCode ? ", " + userLoginDetail.postCode : ""}` : userLoginDetail.country
        }]

        address = address.filter(x => !!x.label);
        if (!!address && address.length > 0) {
          this.form.elmts.patientTreatedAt.setValue(address[0]);
        } else this.form.elmts.patientTreatedAt.setValue("");
        this.form.elmts.patientTreatedAt.setItems(address);
        this.setState({
          selectedTreatedAt: val,
        });
        // other = 99
      } else if (val && (val.value === "99")) {
        this.setState({
          selectedTreatedAt: val,
        });
        // office 11
      } else if (val && val.value) {
        if (this.state.providerTreatedAddresses && this.state.providerTreatedAddresses.length) {
          const primaryAddress = this.state.providerTreatedAddresses.find(address => address.isPrimary);

          this.form.elmts.patientTreatedAt.setValue(!!primaryAddress ? primaryAddress : this.state.providerTreatedAddresses[0]);
          this.form.elmts.patientTreatedAt.setItems(this.state.providerTreatedAddresses);
        } else {
          this.form.elmts.patientTreatedAt.setValue(null);
          this.form.elmts.patientTreatedAt.setItems([]);
          this.setState({
            providerTreatedAddresses: [],
          })
        }
        this.setState({
          selectedTreatedAt: val,
        });
      }

    };

    onAmountChange = (amount) => {
      const units = this.form.values.editCptUnit;
      const fees = this.form.values.editFee;
      const totalAmt = this.removeCommas(amount.value);
      if (isNaN(totalAmt)) {
        if (amount.field === "units") this.form.elmts.editCptUnit.setValue(!!units ? units.substr(0, units.length - 1) : null);
        if (amount.field === "fees") this.form.elmts.editFee.setValue(!!fees ? fees.substr(0, fees.length - 1) : null);
        return;
      } else {
        this.form.elmts.editCptUnit.setValue(this.numberWithCommas(units))
        this.form.elmts.editFee.setValue(this.numberWithCommas(fees))
      }

      // if (!fees && !units) return this.form.elmts.totalFee.setValue(null);
      // if (fees === "" || units === "") return this.form.elmts.totalFee.setValue("0");

      // if (+amount.value && amount.field === "fees") {
      //   // this.form.elmts.totalFee.setValue(this.fixedAmountDecimal(`${(+units || 1) * +amount.value}`))
      // } else if (+amount.value && amount.field === "units") {
      //   // this.form.elmts.totalFee.setValue(this.fixedAmountDecimal(`${(+amount.value || 1) * +fees}`))
      // }
    }

    uploadDocumentatS3 = async (signedUrl, file) => {
      var options = {
        headers: {
          "content-type": file.contentUri.replace("data:", "").replace(";base64", ""),
        }
      };
      return await this.props.app.dm.rest.putS3(signedUrl, Buffer.from(file.content, 'base64'), options);
    }

    handleEditOrAddCpt = async (form) => {
      let { editFee, editCptUnit, editCptCode, dateOfService, modifiers } = form.values;
      let { cptRowId, cptDetailsList } = this.state;
      editCptUnit = !editCptUnit || editCptUnit === "0" || +editCptUnit <= 1 ? "1" : editCptUnit;
      if (+editFee && editFee.indexOf(".") !== -1) {
        editFee = +editFee;
        editFee = editFee.toFixed(2);
      };

      editCptUnit = this.removeCommas(editCptUnit);
      editFee = this.removeCommas(editFee);

      const isUnitError = +editCptUnit <= 0.99 || +editCptUnit > 99999 || editCptUnit.indexOf(".") !== -1 || !Number.isInteger(+editCptUnit);
      const isFeeError = +editFee <= 0.009 || !(editFee % 1 || +editFee) || +editFee > 999999.99 || editFee.length >= 12;


      if (!editCptCode || !editCptCode.value || !editCptCode.label || !editCptUnit || !editFee || !dateOfService || isUnitError || isFeeError) {
        form.elmts.editFee.setError("");
        form.elmts.editCptUnit.setError("");
        form.elmts.editCptCode.setError("");
        form.elmts.dateOfService.setError("");

        this.setState({
          isCptCodeError: !(editCptCode && editCptCode.value && editCptCode.label),
          isCptUnitError: !editCptUnit || isUnitError || false,
          isCptFeeError: !editFee || isFeeError || false,
          isDateOfServiceError: !dateOfService || false
        });
        return;
      };

      let totalAmount = +editFee || 0;
      // totalAmount = (+totalClaimAmount || 0) + totalAmount;
      cptDetailsList.forEach(cptItem => {
        totalAmount = totalAmount + (+this.removeCommas(cptItem.fees))
      });

      dateOfService = DateUtils.formatDateInMMDDYYYY(dateOfService);
      if (!dateOfService || dateOfService === "" || !await this.validateDate(dateOfService)) {
        form.elmts.dateOfService.setError(CommonString.DateFormatRequired);
        return false;
      }
      if (cptRowId) {
        cptDetailsList = cptDetailsList.map(cpt => {
          if (cpt.cptRowId === cptRowId) {
            totalAmount = totalAmount - (+this.removeCommas(cpt.fees));
            return { code: editCptCode.value, description: editCptCode.label, units: this.numberWithCommas(editCptUnit), fees: this.numberWithCommas(editFee), dateOfService: dateOfService, cptRowId: cpt.cptRowId, modifiers }
          } else {
            return { ...cpt, units: this.numberWithCommas(cpt.units), fees: this.numberWithCommas(cpt.fees) };
          }
        })
      } else {
        cptDetailsList.push({
          code: editCptCode.value, description: editCptCode.label, units: this.numberWithCommas(editCptUnit), fees: this.numberWithCommas(editFee), dateOfService: dateOfService, cptRowId: CommonUtility.generate20Character(), modifiers
        })
      };

      totalAmount = `${totalAmount}`;
      if (+totalAmount && totalAmount.indexOf(".") !== -1) {
        totalAmount = +totalAmount;
        totalAmount = totalAmount.toFixed(2);
      }

      form.elmts.totalClaimAmount.setValue(this.numberWithCommas(totalAmount));
      form.elmts.editFee.setValue(null);
      form.elmts.editCptUnit.setValue(null);
      // form.elmts.totalFee.setValue(null);

      this.setState({
        cptDetailsList: cptDetailsList,
        cptRowId: "",
        isEditOrAddCpt: false,
        isCptCodeError: false,
        isCptUnitError: false,
        isDateOfServiceError: false,
        isCptFeeError: false,
        isTotalClaimAmountError: false,
      })

    }

    onSearchClient = async (search) => {
      this.clients = [];
      return await this.getPageData(search);
    }

    handleConfirmationPopUp = async (hidePopUpConfirmation, selectClient) => {
      const { claimEnsureCompleted } = this.state;
      if (hidePopUpConfirmation === true) {
        this.setState({
          claimEnsureCompleted: false,
          confirmationPopUpVisibility: false,
          isDisable: false,
          selectClient: selectClient
        })
        return false;
      };

      if (claimEnsureCompleted === true) {
        this.setState({
          claimEnsureCompleted: true,
          confirmationPopUpVisibility: false,
          selectClient: selectClient
        });
        this.handleSubmit(this.props.app.ui.form("uploadBills"), selectClient)
      } else {
        this.setState({
          claimEnsureCompleted: true,
          confirmationPopUpVisibility: true
        })
      }
      return false
    };

    numberWithCommas = (number = "") => {
      const num = this.removeCommas(number);
      return !!num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
    };

    removeCommas = (formattedNumber = "") => {
      return !!formattedNumber ? formattedNumber.toString().replace(/,/g, "") : "";
    };

    handleSubmit = async (form, clientUid) => {
      this.setState({
        isDisable: true,
        // loaderVisible: true
      });
      const { userLoginDetail } = this.props;
      const { isServiceChargeEnabled, claimEnsureCompleted, selectClient } = this.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;

      try {
        const { attachments, isSuperBill, isEnterDob, isEnterSSID } = this.state;
        if (!attachments || attachments.length === 0) {

          if ((!form.values.ssId || form.values.ssId === "") && (!form.values.dOB || form.values.dOB === "")) {
            form.elmts.ssId.setError(CommonString.SSIDRequired);
            form.elmts.dOB.setError(CommonString.DOBRequired);
          } else if ((!form.values.ssId || form.values.ssId === "") && !await this.validateDate(form.values.dOB)) {
            form.elmts.dOB.setError(CommonString.DateFormatRequired);
          }

          this.setState({
            isDisable: false,
            isProviderNameError: !isSuperBill && !form.values.providerName ? true : false,
            isTotalClaimAmountError: !isSuperBill && (!form.values.totalClaimAmount || !(+(this.removeCommas(form.values.totalClaimAmount)))) ? true : false,
            isInsuranceCompanyError: !form.values.doctorName || !form.values.doctorName.value ? true : false,
            isMemeberNameError: !form.values.patientName ? true : false,
            // loaderVisible: false            
          });
          let error = "Please upload the bill.";
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Please upload the bill."
            })
          }
          return false;
        }
        const { name, email, dOB, ssId, mobileNumber, countryCode, doctorName, providerName, poBoxNo, patientTreatedOn, diagnosisCodes, totalClaimAmount, patientTreatedAt, patientName, notes, patientOthTreatedAt } = form.values;

        if (!isSuperBill && ((!providerName || !totalClaimAmount || !(+(this.removeCommas(totalClaimAmount)))) || !doctorName || !doctorName.value || !patientName || !(isEnterDob || isEnterSSID) || !(ssId || dOB))) {
          if ((!form.values.ssId || form.values.ssId === "") && (!form.values.dOB || form.values.dOB === "")) {
            form.elmts.ssId.setError(CommonString.SSIDRequired);
            form.elmts.dOB.setError(CommonString.DOBRequired);
          } else if ((!form.values.ssId || form.values.ssId === "") && !await this.validateDate(dOB)) {
            form.elmts.dOB.setError(CommonString.DateFormatRequired);
          };

          this.setState({
            isProviderNameError: !providerName || false,
            isDisable: false,
            isTotalClaimAmountError: !totalClaimAmount || !(+(this.removeCommas(totalClaimAmount))) || false,
            isInsuranceCompanyError: !doctorName || !doctorName.value ? true : false,
            isMemeberNameError: !patientName ? true : false,
          })
          return false
        } else {
          this.setState({
            isProviderNameError: false,
            isTotalClaimAmountError: false,
            isInsuranceCompanyError: false,
            isMemeberNameError: false,
          })
        }

        if (patientTreatedOn && !isSuperBill) {
          const isValid = this.onValidateDiagnosticAndTreatedOn(patientTreatedOn);
          if (!isValid) {
            this.setState({ isDisable: false });
            return false;
          }
        }
        let documentId = CommonUtility.generate20Character();
        let currentDate = new Date().toUTCString();
        let currentDateTime = DateUtils.dateAndTimeWithAmPm(currentDate, "PCH");

        if (!this.state.selectedMemberId.length) {
          this.setState({
            isDisable: false,
            // loaderVisible: true
          });
          return false;
        }
        const patientUid = this.state.selectedMemberId;

        // handle upload bill confirmation pop up
        if (claimEnsureCompleted === false) {
          analyticsLogs(CommonString.UPLOAD_BILL, CommonString.SUBMIT_BILL_ACTION, this.props.app);
          this.handleConfirmationPopUp()
          return false;
        }

        let docName = null;
        if (!!providerName && !!providerName.label) {
          let items = providerName.label.split('(');
          if (items.length > 1)
            items.pop();

          docName = items.toString();
        }
        const uploadBill = {
          name: name,
          email: email,
          mobileNumber: countryCode.value + " " + mobileNumber.replace(/[^\w\d\s]/g, "").replace(" ", ""), //mobileNumber.replace(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, ""),
          ssId: !!ssId ? ssId : "",
          dOB: !!dOB ? dOB : "",
          createdBy: userLoginDetail.uid,
          isPatientRequest: true,
          submittedDate: currentDate,
          submittedDateTime: currentDateTime,
          uploadedDateTime: currentDateTime,
          billStatus: "Submitted",
          pchSubmittedStatus: "Received",
          insuranceUid: doctorName.value,
          poBoxNo: !!poBoxNo ? poBoxNo.value : null,
          documentId: documentId,
          insuranceMemberUid: patientUid,
          providerName: docName,
          notes: !!notes ? RSAencryption.EnctyptString(notes, true) : notes,
          insuranceMemberClaimsAmount: `${this.removeCommas(totalClaimAmount)}`,
          insuranceMemberTreatedOn: patientTreatedOn,
          insuranceMemberTreatedOnFrom: patientTreatedOn,
          insuranceMemberTreatedOnTo: patientTreatedOn,
          clientUid: (!!selectClient ? selectClient : (!!clientUid ? clientUid : ""))
        };

        if (!isSuperBill) {
          uploadBill.insuranceMemberTreatedType = this.state.selectedTreatedAt ? this.state.selectedTreatedAt.label : "";
          uploadBill.doctorUid = !!providerName && !!providerName.value ? providerName.value : null;
          if (patientOthTreatedAt && this.state.selectedTreatedAt && this.state.selectedTreatedAt.value === "99")
            uploadBill.insuranceMemberTreatedAt = { label: patientOthTreatedAt, value: patientOthTreatedAt };
          else if (patientTreatedAt && patientTreatedAt.label)
            uploadBill.insuranceMemberTreatedAt = { label: patientTreatedAt.label, value: patientTreatedAt.value };

          if (diagnosisCodes && diagnosisCodes.length) {
            uploadBill.insuranceMemberDiagnosisCodes = diagnosisCodes.map(codes => RSAencryption.EnctyptString(`${codes.label}`, true)).join(", ");
          }

          if (this.state.cptDetailsList && this.state.cptDetailsList.length) {
            const cptServiceDates = this.state.cptDetailsList.map(cpt => cpt.dateOfService);
            uploadBill.insuranceMemberTreatedOnFrom = DateUtils.getShortestDate(cptServiceDates);
            uploadBill.insuranceMemberTreatedOnTo = DateUtils.getGreatestDate(cptServiceDates);
            const cptDetails = this.state.cptDetailsList.map(cpt => {
              return {
                code: RSAencryption.EnctyptString(cpt.code, true),
                description: RSAencryption.EnctyptString(cpt.description, true),
                units: this.removeCommas(cpt.units),
                fees: this.removeCommas(cpt.fees),
                dateOfService: RSAencryption.EnctyptString(cpt.dateOfService, true),
                modifiers: cpt.modifiers,
              }
            })
            uploadBill.insuranceMemberCptDetails = cptDetails;
          };

          if (!totalClaimAmount) {

            this.setState({
              isDisable: false,
              isTotalClaimAmountError: true,
              confirmationPopUpVisibility: false,
              // loaderVisible: true
            });
            return false;
          }
        };

        if (!!isServiceChargeEnabled) {
          this.setState({
            isShowPaymentService: true,
            selectedInsurance: doctorName,
            bill: uploadBill,
            confirmationPopUpVisibility: false,
          });
        }
        else {
          this.setState({
            loaderVisible: true,
            selectedInsurance: doctorName,
            bill: uploadBill,
            confirmationPopUpVisibility: false,
          });
          let paymentResponse = {
            success: true,
            transactionId: "dummy_0000000012345",
            paymentMode: "Service Charge Disable",
            totalAmount: 0.00
          };
          await this.onPaymentProcess(paymentResponse);
        }
        return false;
      } catch (error) {
        this.setState({
          isDisable: false,
          loaderVisible: false
        });
        this.props.app.dm.commonDomain.showToastr("error", error);
      }
    };

    fixedAmountDecimal = (amount) => {
      if (+amount && amount.indexOf(".") !== -1) {
        amount = +amount;
        amount = this.numberWithCommas(amount.toFixed(2));
      };
      return isNaN(amount) ? "0" : this.numberWithCommas(amount);
    }

    getCptList = async (search = "", limit = 10) => {
      const form = this.props.app.ui.form("uploadBills");
      try {
        // graphql API
        let cptList = await this.props.app.dm.addInsuranceDomain.getMstCptDetails({
          search,
          page: 1,
          resultsPerPage: limit,
          sort: {
            field: "createdAt",
            order: "DESC"
          },
        });
        // cptList = !!cptList && cptList.length ? (
        //   cptList = cptList.map(cpt => { return {...cpt, label: `${cpt.value} ${!!cpt.label ? ('- ' + cpt.label) : '' }` }})
        // ) : [];
        this.cptList = cptList;
        form.elmts.editCptCode.setItems(cptList);
        return cptList;
      } catch (error) {
        console.log('error while fetching cpt list', error);
        form.elmts.editCptCode.setItems([]);
        return [];
      }
    };

    onCptChange = async (val) => {
      if (val.label === "No Results Found" || val.label.includes("Type in a search value")) {
        this.form.elmts.editCptCode.setValue(null);
        return false;
      }
      const { dateOfService } = this.form.values;
      const selectedCpt = this.cptList.find(cpt => cpt.value === val.value);
      const isValidCptDate = DateUtils.inBetweenDateChecker(dateOfService, selectedCpt.effectiveDate, selectedCpt.expiryDate);
      if (!isValidCptDate) {
        this.form.elmts.dateOfService.setError(CommonString.InvalidDOS);
        this.form.elmts.editCptCode.setError(null);
        this.setState({ isDisable: true })
      } else {
        this.form.elmts.dateOfService.setError(null);
        this.form.elmts.editCptCode.setError(null);
        this.setState({ isDisable: false })
      }
      return false;
    };

    getDiagnosisList = async (search = "", limit = 30) => {
      const form = this.props.app.ui.form("uploadBills");
      try {
        // graphql API
        let diagnosisList = await this.props.app.dm.addInsuranceDomain.getMstDiagnosisDetails({
          search: search,
          page: 1,
          resultsPerPage: limit,
          sort: {
            field: "createdAt",
            order: "DESC"
          },
        });
        // diagnosisList = !!diagnosisList && diagnosisList.length ? (
        //   diagnosisList = diagnosisList.map(diagnosis => { return {...diagnosis, label: `${diagnosis.value} ${!!diagnosis.label ? ('- ' + diagnosis.label) : ''}` }})
        // ) : [];
        this.diagnosisList = diagnosisList;
        form.elmts.diagnosisCodes.setItems(diagnosisList);
        return diagnosisList;
      } catch (error) {
        console.log('error while fetching diagnosis list', error);
        return []
      }
    };

    onDiagnosticChange = async (val) => {
      if (!!val && (val.label === "No Results Found" || (val.label && val.label.includes("Type in a search value")))) {
        this.props.form.elmts.diagnosisCodes.setValue(null);
        return false;
      }
      const { patientTreatedOn } = this.form.values;
      let selectedDiagnostic = {};
      if (!!val)
        selectedDiagnostic = this.diagnosisList.find(diagnostic => diagnostic.value === val.value);
      const isValidDiagnosticDate = DateUtils.inBetweenDateChecker(patientTreatedOn, !!selectedDiagnostic ? selectedDiagnostic.effectiveDate : "", selectedDiagnostic ? selectedDiagnostic.expiryDate : "");
      if (!isValidDiagnosticDate) {
        this.form.elmts.patientTreatedOn.setError(CommonString.InvalidTreatmentDate);
        this.form.elmts.diagnosisCodes.setError(null);
      } else {
        this.form.elmts.patientTreatedOn.setError(null);
        this.form.elmts.diagnosisCodes.setError(null);
      }
      return false;
    }

    getModifiersList = async (search = "", limit = 10) => {
      const form = this.props.app.ui.form("uploadBills");
      try {
        // graphql API
        let modifiersList = await this.props.app.dm.addInsuranceDomain.getMstModifiersDetails({
          search: search,
          page: 1,
          resultsPerPage: limit,
          sort: {
            field: "label",
            order: "ASC"
          },
        });
        modifiersList = !!modifiersList && modifiersList.length ? modifiersList : [];
        form.elmts.modifiers.setItems(modifiersList);
        return modifiersList;
      } catch (error) {
        console.log('error while fetching modifiers list', error);
        return []
      }
    };

    getVisitTypesList = async (search = "", limit = 10) => {
      const form = this.props.app.ui.form("uploadBills");
      try {
        // graphql API
        let visitType = await this.props.app.dm.addInsuranceDomain.getVisitTypesDetails({
          search,
          page: 1,
          resultsPerPage: limit,
          sort: {
            field: "createdAt",
            order: "DESC"
          },
        });
        visitType = !!visitType && visitType.length ? visitType : [];
        form.elmts.patientVisitType.setItems(visitType);
        return visitType;
      } catch (error) {
        console.log('error while fetching cpt list', error);
        form.elmts.editCptCode.setItems([]);
        return [];
      }
    };

    onModifierChange = async (val) => {
      const form = this.props.app.ui.form("uploadBills");
      let modifiers = form.values.modifiers;
      if (!!val && !!val.label && (val.label === "No Results Found" || val.label.includes("Type in a search value"))) {
        modifiers.pop();
        form.elmts.modifiers.setValue(modifiers);
        return false;
      }
      if (modifiers && modifiers.length && modifiers.length >= 5) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.ModifierMaxLimitError);
        modifiers = modifiers.splice(0, 4);
        form.elmts.modifiers.setValue(modifiers);
      }
    }

    onEditCptDetails = async (item) => {
      const form = this.props.app.ui.form("uploadBills");
      if (item.cptRowId) {
        this.setState({
          cptRowId: item.cptRowId,
          isEditOrAddCpt: true
        })
        form.elmts.editCptCode.setValue({ label: item.description, value: item.code });
        form.elmts.editFee.setValue(this.numberWithCommas(item.fees));
        form.elmts.editCptUnit.setValue(this.numberWithCommas(item.units));
        form.elmts.dateOfService.setValue(item.dateOfService);
        form.elmts.modifiers.setValue(item.modifiers);
        // form.elmts.totalFee.setValue(this.fixedAmountDecimal(`${+item.units * +item.fees}`));
      } else {
        const { patientTreatedOn } = form.values;
        this.setState({ cptRowId: "", isEditOrAddCpt: true })
        form.elmts.editCptCode.setValue(null);
        form.elmts.editFee.setValue(null);
        form.elmts.editCptUnit.setValue(null);
        // form.elmts.totalFee.setValue(null);
        form.elmts.dateOfService.setValue(patientTreatedOn || DateUtils.formatDateInMMDDYYYY(new Date()));
        form.elmts.modifiers.setValue(null);
      };
      // call cpt list
      if (!(form.elmts.editCptCode.items && form.elmts.editCptCode.items.length)) this.getCptList();
      if (!(form.elmts.modifiers.items && form.elmts.modifiers.items.length)) this.getModifiersList();
    };

    onPatientNameChange = async (val) => {
      if (!val || val.label === "No Results Found" || val.label.includes("Type in a search value")) {
        this.props.app.ui.form("uploadBills").elmts.patientName.setValue("");
        this.setState({ selectedMemberId: "", memberId: "" })
      } else if (val && val.value) {
        let memberDetails = await this.props.app.dm.addInsuranceDomain.insuranceMemberDetail(val.value);
        let date = DateUtils.formatDateInMMDDYYYY(memberDetails.dob);
        this.props.app.ui.form("uploadBills").elmts.dOB.setValue(date);
        this.setState({
          selectedMemberId: val.value,
          memberId: memberDetails.memberId || ""
        })
      }
    };

    handleDobShowHideDatePicker = (val) => {
      if (val === "open" && this.state.showDobDatePicker) {
        return;
      } else if (val === "close" || this.state.isEnterSSID) {
        this.setState({ showDobDatePicker: false });
        return;
      } else {
        this.setState({ showDobDatePicker: !this.state.showDobDatePicker })
      }
    }

    onDobDateValueChange = async (val, autoformat = true) => {
      // this.onDateBlur()
      const { isEnterSSID } = this.state;
      this.handleDobShowHideDatePicker();
      if (!!isEnterSSID) {
        this.form.elmts.dOB.setValue(null);
        return false;
      }

      if (!!val) {
        if (!!autoformat) {
          let date = DateUtils.formatDateInMMDDYYYY(val);
          if (!!date && new Date(date) > new Date()) {
            this.form.elmts.dOB.setValue(null);
            this.form.elmts.dOB.setError(CommonString.DOBCanNotFuture);
            return false;
          } else if (!!date && DateUtils.isOlderThanMaxLimit(val)) {
            this.form.elmts.dOB.setValue(null);
            this.form.elmts.dOB.setError(CommonString.DOBCanNotOlderThan125);
            return false;
          }
          else {
            this.form.elmts.dOB.setError(null);
            this.form.elmts.dOB.setValue(null);
            this.form.elmts.dOB.setValue(date);
          }

        }
        else {
          if (!!val && new Date(val) > new Date()) {
            this.form.elmts.dOB.setValue(null);
            this.form.elmts.dOB.setError(CommonString.DOBCanNotFuture);
            return false;
          }
          else {
            this.form.elmts.dOB.setError(null)
            this.form.elmts.dOB.setValue(val);
          }
        }
        this.setState({
          isEnterSSID: false,
          isEnterDob: true
        });
      } else {
        this.setState({
          isEnterSSID: false,
          isEnterDob: false,
          showDobDatePicker: false,
        });
      }
    };

    validateDate = async (testdate) => {
      let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
      return date_regex.test(testdate);
    };

    onClearDobDate = () => {
      const form = this.props.app.ui.form("uploadBills");
      form.elmts.dOB.setValue(null);
      form.elmts.dOB.setError(null);
      form.elmts.ssId.setError(null);
      this.setState({
        showDobDatePicker: false,
        isEnterSSID: false,
        isEnterDob: false
      })
    }

    onClearDateOfServiceDate = () => {
      const form = this.props.app.ui.form("uploadBills");
      form.elmts.dateOfService.setValue(null);
      form.elmts.dateOfService.setError(null);
      this.setState({ showDateOfServiceDatePicker: false })
    }

    onClearDate = () => {
      const form = this.props.app.ui.form("uploadBills");
      form.elmts.patientTreatedOn.setValue(null);
      form.elmts.patientTreatedOn.setError(null);
      this.setState({ showDatePicker: false })
    }

    onDobDateBlur = async () => {
      const { dOB } = this.form.values;
      this.handleDobShowHideDatePicker();
      if (!!dOB) {
        let dat = await this.validateDate(dOB);
        if (!!dat) {
          let formatedDate = DateUtils.formatDateInMMDDYYYY(dOB);
          dat = await this.validateDate(formatedDate);
          if (!!dat) {
            this.form.elmts.dOB.setValue(formatedDate);
            this.form.elmts.dOB.setError(null);
            this.form.elmts.ssId.setError(null);
            this.onDobDateValueChange(dOB, false);
          } else {
            this.form.elmts.dOB.setValue(dOB);
            this.form.elmts.dOB.setError(CommonString.DateFormatRequired);
          }
        } else {
          this.form.elmts.dOB.setValue(null);
          this.form.elmts.dOB.setError(CommonString.DateFormatRequired);
        }
      } else {
        this.form.elmts.dOB.setError(null);
      }
    };

    onDateValueChange = async (val, autoformat = true) => {
      const form = this.props.app.ui.form("uploadBills");
      this.handleShowHideDatePicker();
      if (!!val) {
        if (!!autoformat) {
          let date = DateUtils.formatDateInMMDDYYYY(val);
          if (!!date && new Date(date) > new Date()) {
            form.elmts.patientTreatedOn.setValue(null);
            form.elmts.patientTreatedOn.setError("Patient treated on can not be in future");
            return false;
          } else {
            form.elmts.patientTreatedOn.setValue(date);
            form.elmts.patientTreatedOn.setError("");
          }
        } else {
          if (!!val && new Date(val) > new Date()) {
            form.elmts.patientTreatedOn.setValue(null);
            form.elmts.patientTreatedOn.setError("Patient treated on can not be in future");
            return false;
          } else {
            form.elmts.patientTreatedOn.setValue(val);
            form.elmts.patientTreatedOn.setError("");
          }
        }
        this.onValidateDiagnosticAndTreatedOn(val);
        return false;
      }
    };

    onValidateDiagnosticAndTreatedOn = (date) => {
      const { diagnosisCodes } = this.form.values;
      if (!diagnosisCodes || !diagnosisCodes.length) {
        this.form.elmts.diagnosisCodes.setValue(null);
        this.form.elmts.diagnosisCodes.setError(null);
        return true;
      }
      const selectedDiagnostics = diagnosisCodes.map(diagnostic => {
        let diagnosis = {}
        this.diagnosisList.forEach(code => {
          if (code.value === diagnostic.value) return diagnosis = code;
        })
        return diagnosis;
      });
      let isValidDiagnosticDate = true;
      selectedDiagnostics.forEach(diagnosis => {
        isValidDiagnosticDate = DateUtils.inBetweenDateChecker(date, diagnosis.effectiveDate, diagnosis.expiryDate);
        if (!isValidDiagnosticDate) return isValidDiagnosticDate = false;
      })

      if (!isValidDiagnosticDate) {
        this.form.elmts.diagnosisCodes.setError(CommonString.InvalidDiagnosis);
        this.form.elmts.patientTreatedOn.setError(null);
      } else {
        this.form.elmts.diagnosisCodes.setError(null);
        this.form.elmts.patientTreatedOn.setError(null);
        return true;
      }
      return false;
    }

    handleDateOfServiceShowHideDatePicker = (val) => {
      if (val === "open" && this.state.showDateOfServiceDatePicker) {
        return;
      } else if (val === "close") {
        this.setState({ showDateOfServiceDatePicker: false });
        return;
      } else {
        this.setState({ showDateOfServiceDatePicker: !this.state.showDateOfServiceDatePicker })
      }
    }

    handleShowHideDatePicker = (val) => {
      if (val === "open" && this.state.showDatePicker) {
        return;
      } else if (val === "close") {
        this.setState({ showDatePicker: false });
        return;
      } else {
        this.setState({ showDatePicker: !this.state.showDatePicker })
      }
    }

    onDateOfServiceValueChange = async (val, autoformat = true) => {
      const form = this.props.app.ui.form("uploadBills");
      form.elmts.dateOfService.setError(null);
      this.handleDateOfServiceShowHideDatePicker();
      if (!!val) {
        this.setState({ isDateOfServiceError: false })
        if (!!autoformat) {
          let date = DateUtils.formatDateInMMDDYYYY(val);
          if (!!date && new Date(date) > new Date()) {
            form.elmts.dateOfService.setValue(null);
            form.elmts.dateOfService.setError("Date of service can not be in future");
            return false;
          } else {
            form.elmts.dateOfService.setValue(date);
            form.elmts.dateOfService.setError("");
          }
        } else {
          if (!!val && new Date(val) > new Date()) {
            form.elmts.dateOfService.setValue(null);
            form.elmts.dateOfService.setError("Date of service can not be in future");
            return false;
          } else {
            form.elmts.dateOfService.setValue(val);
            form.elmts.dateOfService.setError("");
          }
        }

        const { editCptCode } = form.values;
        if (!editCptCode || !editCptCode.label) {
          form.elmts.editCptCode.setValue(null);
          form.elmts.editCptCode.setError(null);
          return false;
        }
        const selectedCpt = this.cptList.find(cpt => cpt.value === editCptCode.value);
        const isValidCptDate = DateUtils.inBetweenDateChecker(val, selectedCpt.effectiveDate, selectedCpt.expiryDate);
        if (!isValidCptDate) {
          form.elmts.editCptCode.setError(CommonString.InvalidCPT);
          form.elmts.dateOfService.setError(null);
          this.setState({ isDisable: true })
        } else {
          form.elmts.editCptCode.setError(null);
          form.elmts.dateOfService.setError(null);
          this.setState({ isDisable: false })
        }
        return false;
      }
    };

    onTotalClaimAmount = async (amount) => {
      let totalAmount = amount.replace(/[a-zA-Z\s!@#$%^&*()_\-+|~=`{}[\]:";'<>?,\\/]/g, "").substring(0, 70);
      totalAmount = this.numberWithCommas(totalAmount);
      this.props.app.ui.form("uploadBills").elmts.totalClaimAmount.value = totalAmount;
      this.props.app.ui.form("uploadBills").elmts.totalClaimAmount.setError(null);
      this.setState({ isTotalClaimAmountError: false });
    }

    onDateOfServiceBlur = async () => {
      const form = this.props.app.ui.form("uploadBills");
      const { dateOfService } = form.values;
      this.handleDateOfServiceShowHideDatePicker()
      if (!!dateOfService) {
        this.setState({ isDateOfServiceError: false });
        let dat = await this.validateDate(dateOfService);
        if (!!dat) {
          let formatedDate = DateUtils.formatDateInMMDDYYYY(dateOfService);
          dat = await this.validateDate(formatedDate);
          if (!!dat) {
            form.elmts.dateOfService.setValue(formatedDate);
            form.elmts.dateOfService.setError(null);
            this.onDateOfServiceValueChange(dateOfService, false);
          } else {
            form.elmts.dateOfService.setValue(dateOfService);
            form.elmts.dateOfService.setError(CommonString.DateFormatRequired);
          }
        } else {
          form.elmts.dateOfService.setValue(null);
          form.elmts.dateOfService.setError(CommonString.DateFormatRequired);
        }
      } else {
        form.elmts.dateOfService.setError(null);
      }
    };

    onDateBlur = async () => {
      const form = this.props.app.ui.form("uploadBills");
      const { patientTreatedOn } = form.values;
      this.handleShowHideDatePicker();
      if (!!patientTreatedOn) {
        let dat = await this.validateDate(patientTreatedOn);
        if (!!dat) {
          let formatedDate = DateUtils.formatDateInMMDDYYYY(patientTreatedOn);
          dat = await this.validateDate(formatedDate);
          if (!!dat) {
            form.elmts.patientTreatedOn.setValue(formatedDate);
            form.elmts.patientTreatedOn.setError(null);
            this.onDateValueChange(patientTreatedOn, false);
          } else {
            form.elmts.patientTreatedOn.setValue(patientTreatedOn);
            form.elmts.patientTreatedOn.setError(CommonString.DateFormatRequired);
          }
        } else {
          form.elmts.patientTreatedOn.setValue(null);
          form.elmts.patientTreatedOn.setError(CommonString.DateFormatRequired);
        }
      } else {
        form.elmts.patientTreatedOn.setError(null);
      }
    };

    toggleIsSuperBill = async () => {
      if (!!this.state.isSuperBill) {
        this.getDiagnosisList();
        this.getModifiersList();
        this.getCptList();
        this.getVisitTypesList();
      }
      this.setState({ isSuperBill: !this.state.isSuperBill });
    };

    onPaymentProcess = async (paymentResponse) => {
      if (!!paymentResponse && !!paymentResponse.success && !!paymentResponse.transactionId) {
        const { bill, selectedInsurance, attachments } = this.state;
        const { draftBill, userLoginDetail } = this.props;
        bill.transactionId = paymentResponse.transactionId;
        bill.paymentMode = paymentResponse.paymentMode;
        bill.serviceAmount = paymentResponse.totalAmount;

        let uploadDocs = attachments.map(async (doc, i) => {
          doc.documentId = bill.documentId;
          doc.seqNo = i + 1;
          let fileData = { ...doc };
          delete fileData.content;
          let response = await this.props.app.dm.uploadBillDomain.uploadDocumentOnServer(fileData);
          if (!!response && !!response.content)
            await this.uploadDocumentatS3(response.content, doc)
          return response;
        });
        let docRes = await Promise.all(uploadDocs);
        if (docRes.filter(x => x.uid === null).length === 0) {
          let res = {};
          if (!!draftBill && !!draftBill.uid) {
            bill.uid = draftBill.uid;
            bill.uploadedDateTime = draftBill.uploadedDateTime;

            res = await this.props.app.dm.uploadBillDomain.submitUploadedBill(bill);
          }
          else
            res = await this.props.app.dm.uploadBillDomain.uploadBill(bill);
          if (!!res && !!res.uid) {
            let uniqueCode = DateUtils.formatYYYYMMDDHHMMSS() + res.uid.toUpperCase();
            uniqueCode = uniqueCode.replace(/[^a-zA-Z0-9]/g, "");
            this.props.app.dm.uploadBillDomain.uploadDocumentAtSFTP(res.uid, "", uniqueCode);
            let providerName = !!this.form.values.providerName ? this.form.values.providerName.label : "";
            providerName = !!providerName ? providerName.split(" (")[0] : "";
            bill.uid = res.uid;
            bill.createdAt = new Date().toUTCString();
            bill.pchInsuranceDetail = {
              name: selectedInsurance.label,
              groupNumber: this.insuranceItems.filter((ins) => ins.value === selectedInsurance.value)[0].groupNumber
            };
            bill.uniqueCode = uniqueCode;
            bill.providerName = providerName;
            bill.patientName = !!this.form.values.patientName ? this.form.values.patientName.label : "";
            bill.insuranceAddress = this.poBoxNoItems && this.poBoxNoItems.length ? this.poBoxNoItems[0].label : "";
            await AsyncStorage.setItem("receipts", JSON.stringify(bill));
            this.setState({
              isShowPaymentService: false,
              isDisable: false,
              loaderVisible: false
            });

            const { isUberDocs } = this.props.app.dm.userSession.state;
            analyticsLogs(CommonString.UPLOAD_BILL, CommonString.BILL_SUBMITTED_ACTION, this.props.app);
            this.props.app.dm.commonDomain.showToastr("success", "Your claim reference " + uniqueCode + " received successfully.");
            if (!!draftBill && !!draftBill.uid && !!isUberDocs) {
              await this.backtoDraftBill(`successMessage=${CommonString.ClaimReceived}`);
              return false;
            }
            else {
              if (userLoginDetail.loginType === LOGIN_TYPE.NONREGISTER)
                this.onNonRegistrationCancel(bill.uid);
              else
                this.props.app.nav.to("submittedReceiptRoute", { uid: bill.uid });
            }
            return false;
          } else this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
        else {
          this.setState({
            isShowPaymentService: false,
            isDisable: false,
            loaderVisible: false
          });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.ErrorOnFileUpload);
        }
      }
      else {
        this.setState({
          isShowPaymentService: false,
          isDisable: false,
          loaderVisible: false
        });
        if (!!paymentResponse.error)
          this.props.app.dm.commonDomain.showToastr("error", paymentResponse.error);
      }
    }

    addProvider = () => {
      this.props.app.nav.to("addProviderRoute", { mode: "new" });
    }

    onSearchProvider = async (val) => {
      this.providers = [];

      if (!!val) {
        this.providers = this.providers.filter((item) => {
          return item.label.toLowerCase().includes(val.toLowerCase());
        }).sort((a, b) => b.label - a.label);
        const { userLoginDetail } = this.props;
        const filter = {
          DoctorName: { like: `%${val}%` },
          isActive: { eq: "true" },
          parentUid: { eq: userLoginDetail.uid }
        };
        let docs = await this.props.app.dm.addDoctorsDomain.getProvidersOnSearch(filter);
        if (!!docs && docs.length > 0) {
          docs = docs.filter(x => x.DoctorName.toLowerCase().indexOf(val.toLowerCase()) > -1);
          docs.forEach((doctor) => {
            this.providers.push({
              label: `${doctor.DoctorName} (${doctor.NPI})`,
              value: doctor.uid,
              taxIdNumber: doctor.taxIdNumber
            });
          });
        }
        // }
      }
      this.providers = await this.filterNPIs(this.providers);
      this.form.elmts.providerName.setItems(this.providers);
      return this.providers;
    };

    filterNPIs = async (list) => {
      let filterArray = list.reduce((acc, current) => {
        const x = acc.find(item => item.label === current.label);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filterArray;
    }

    filterItems = async (list) => {
      let filterArray = list.reduce((acc, current) => {
        const x = acc.find(item => item.value === current.value);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filterArray;
    }

    hideServiceChargePayment = () => {
      this.setState({
        isShowPaymentService: false,
        isDisable: false,
      });
    }

    hideLoader = () => {
      this.setState({
        loaderVisible: false
      });
    }

    displayLoader = () => {
      this.setState({
        loaderVisible: true
      });
    }

    onFileInputChange = async (val, status) => {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const { userLoginDetail } = this.props;
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      if (!!!isMobileDevice)
        await this.child.current.wrappedInstance.pullErrorText("");

      if (status === "done") {
        let x = [val];
        const { attachments } = this.state;
        let existingFiles = attachments.filter((value) => value.filename === val.filename && value.actualsize === val.actualsize);
        if (existingFiles.length > 0) {
          let error = "File has been already added."
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "File has been already added."
            })
          }
          //this.props.app.dm.commonDomain.showToastr("error", error);
          return false;
        }

        let filesSize = attachments.reduce((prevVal, val) => prevVal + val.actualsize, 0);
        filesSize = filesSize + val.actualsize;
        if (filesSize / 1024 / 1024 > 10) {
          let error = "Maximum 10MB file size allowed.";
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Maximum 10MB file size allowed."
            })
          }
          //this.props.app.dm.commonDomain.showToastr("error", error);
          return false;
        }

        if (userType === USER_TYPE.PCH_PROVIDER && attachments.length > 20) {
          let error = "Maximum 20 attachments allowed.";
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Maximum 20 attachments allowed."
            })
          }
          //this.props.app.dm.commonDomain.showToastr("error", error);
          this.setState({ isAddAttachmentsVisible: false });
          return false;
        }
        this.setState({
          loaderVisible: true //false //true
        });
        attachments.push(x[0]);
        this.setState({ isAddAttachmentsVisible: false, loaderVisible: false, attachments: attachments });
      } else if (status === "removed") {
        const { attachments } = this.state;
        this.setState({
          attachments: attachments.filter((value) => value.filename !== val.filename && value.actualsize !== val.actualsize),
        });
      }
    };

    onDeleteFile = async (ind) => {
      const { attachments } = this.state;
      this.setState({
        attachments: attachments.filter((value, index, arr) => index !== ind),
      });
    };

    alertOk = async (msg) => {
      const { isExternalLogin } = this.props.app.dm.userSession.state;
      if (!!msg && msg.includes("Please add insurance company")) {
        if (!!isExternalLogin) this.props.app.nav.to("addInsuranceRoute", { mode: "add" });
        else this.props.app.nav.to("insuranceListRoute");
      } else if (!!msg && msg.includes("Please add provider")) {
        if (!!isExternalLogin) this.props.app.nav.to("addProviderRoute", { mode: "new" });
        else this.props.app.nav.to("providerListRoute");
      } else if (!!msg && msg.includes(CommonString.EnterAddressRequiredAlert)) {
        this.props.app.nav.to("pchProfileEditRoute", { mode: "bill" });
      }
      this.setState({
        isAlertVisible: false,
        alertMessage: ''
      });
      return false;
    };

    renderAlertPopup() {
      const { isAlertVisible, alertMessage } = this.state;
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;

      return (
        <PopupModalComponent
          visibility={!!isAlertVisible}
          bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
          messageType={MESSAGE_TYPE.info}
          headingIcon={true}
          heading={"Info"}
          modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
          bodyContent={`${alertMessage}`}
          okLabel={"Ok"}
          handleOk={() => this.alertOk(alertMessage)}
        />
      );
    }

    onCamClose = async () => {
      this.setState({ isCamVisible: false });
    };

    openCamera = async () => {
      const { osName } = this.state;
      if (osName !== "web") {
        let resPermission = DeviceUtils.getPermission();
        resPermission.then(
          (status) => {
            if (status === "granted")
              this.setState({ isCamVisible: true, hasPermission: status === "granted" });
          })
      }
      else {
        let deviceRes = DeviceUtils.getDevice()
        try {
          deviceRes.then(
            (res) => {
              if (res.includes("granted"))
                this.setState({ isCamVisible: true });
              else if (osName === 'web' && (window.innerWidth <= 800) && (window.innerHeight <= 720)) {
                this.setState({ isCamVisible: true });
              } else
                this.props.app.dm.commonDomain.showToastr("error", res);
            }
          )
        } catch (e) {
          this.props.app.dm.commonDomain.showToastr("error", e);
        }
      }
    }

    renderCamViewer() {
      const { isCamVisible, hasPermission } = this.state;
      return <InAppCameraComponent isCamVisible={isCamVisible} onValueChange={this.onFileInputChange} onCamClose={this.onCamClose} hasPermission={hasPermission} />;
    }

    render() {
      const { attachments,
        isDisable,
        hasOnlyOnePOBox,
        isShowPaymentService,
        isServiceChargeEnabled,
        isEnterSSID,
        isEnterDob,
        isInsuranceCompanyError,
        isMemeberNameError,
        confirmationPopUpVisibility,
        memberId,
        insuranceGroupId,
        nonRegisterUser
      } = this.state;
      const { leftMenuToggle, isUberDocs } = this.props.app.dm.userSession.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const treatedAtType = [{ label: "Provider Office", value: "providerOffice" }, { label: "Patient Home", value: "patientHome" }, { label: "Telehealth", value: "teleHealth" }, { label: "Other", value: "other" }];
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <>
          {this.renderCamViewer()}
          {this.renderAlertPopup()}
          {!!isServiceChargeEnabled ?
            <ServiceChargePaymentComponent isShowPaymentService={isShowPaymentService} onPaymentProcess={this.onPaymentProcess} hideServiceChargePayment={this.hideServiceChargePayment} displayLoader={this.displayLoader} hideLoader={this.hideLoader} isUberDocs={isUberDocs} />
            : null}
          <View style={[styles.uploadBillSection, clientStyle.secondaryBgColor]}>
            <Form
              name="uploadBills"
              style={[styles.uploadBillsForm, clientStyle.form]}
              validateBeforeSubmit={true}
              validators={CommonValidators.getFormValidator()}
              onSubmit={this.handleSubmit}>
              <View style={[commonStyles.flex1, styles.customflexDirection, styles.inputHolder, styles.attachFileScetion, this.state.isEditOrAddCpt && styles.displayNone,]}>
                <View
                  style={[
                    commonStyles.flex1,
                    commonStyles.flexBasis48,
                    commonStyles.flexDirectionRow,
                    commonStyles.justifyContentCenter,
                    styles.attachOpt,
                    styles.leftInputHolder,
                  ]}>
                  <View style={[commonStyles.marginRight15, styles.fileUploadWrapper]}>
                    <FileUploadComponent
                      ref={this.child}
                      maxFiles={20}
                      fileTypes={"application/pdf,image/jpeg,image/jpg,image/png"} //,application/pdf
                      fileTypeLabel={"pdf,jpeg,jpg,png"}
                      onValueChange={this.onFileInputChange}
                      labelText="Drag & Drop Bill (*.pdf, *.jpg, *.jpeg, *.png)"
                      fileInputStyle={[commonStyles.margin0Auto]}
                      attachmentFieldOuterCustomHeight={styles.attachmentFieldOuter}
                    />
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, styles.rightInputHolder, commonStyles.justifyContentCenter]}>
                    <Touchable style={styles.cameraOpt} onPress={() => this.openCamera()}>
                      <View style={[customCommonStyles.customCameraOpt]}>
                        <Image source={cameraIcon} style={[commonStyles.icon20]} />
                      </View>
                    </Touchable>
                  </View>
                </View>
                <View style={[
                  styles.attachmentsHolder,
                  customCommonStyles.customAttachmentsHolder,
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.rightInputHolder,
                  attachments && !!attachments.length ? null : [commonStyles.dFlex, commonStyles.alignItemsCenter]]}>
                  {attachments && !!attachments.length ? (
                    attachments.map((el, i) => (
                      // <View style={[styles.attachments]} >
                      <View style={[commonStyles.flex1, styles.attachmentCard, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween]} key={`atta_${i}`}>
                        <View>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight500, commonStyles.dBlock, customCommonStyles.wordBreakWord]}>
                            {el.filename}
                          </Text>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.lightGray]}>
                            {el.size}
                          </Text>
                        </View>
                        <Touchable onPress={() => this.onDeleteFile(i)}>
                          <View>
                            <Image source={deleteIcon} style={commonStyles.icon20} />
                          </View>
                        </Touchable>
                      </View>
                      // </View>
                    ))
                  ) : null }
                </View>
              </View>
              <View style={[commonStyles.dFlex, styles.inputHolder, styles.customAlignItemsStart, this.state.isEditOrAddCpt && styles.displayNone,]}>
                <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown]}>
                  <SelectMenuField
                    name="doctorName"
                    label={"Insurance Company*"}
                    // required={"Select Insurance Co."}
                    items={this.insuranceItems}
                    onValueChange={this.onDoctorSearch}
                    searchable
                    itemLoader={async ({ search }) => {
                      return await this.onSearchInsurance(search);
                    }}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    style={[customCommonStyles.inputHeight, styles.marginBottom0]}
                  />
                  {!!isInsuranceCompanyError || !!insuranceGroupId ? (
                    <View style={[styles.mins30Top]}>
                      <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle, !!insuranceGroupId ? commonStyles.lightGrayColor : null]} >{!!insuranceGroupId ? `Group Number: ${insuranceGroupId}` : "Select Insurance Co."}</Text>
                    </View>
                  ) : null}
                </View>
                {!!isMobileDevice ? (
                  <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.addIncProText]}>
                    <View style={[commonStyles.positionRelative, styles.poBoxHolder]}>
                      <SelectMenuField
                        name="poBoxNo"
                        label="PO Box#/Address"
                        // required="Select PO Box No"
                        items={this.poBoxNoItems}
                        onValueChange={this.onPOBoxSearch}
                        style={[!!hasOnlyOnePOBox ? styles.disabledPOBox : null, customCommonStyles.inputHeight]}
                        searchable
                        itemLoader={async ({ search }) => {
                          let items = this.poBoxNoItems;
                          return items
                            .filter((item) => {
                              return item.label.toLowerCase().includes(search.toLowerCase());
                            })
                            .sort((a, b) => b.label - a.label);
                        }}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      />
                      {!!hasOnlyOnePOBox ? <View style={[styles.emptyElement, clientStyle.secondaryBgColor]}></View> : null}
                    </View>
                    <View>
                      <Touchable
                        style={[
                          commonStyles.marginBottom30,
                          styles.addInsuranceProvider,
                        ]}
                        onPress={() => this.alertOk("Please add insurance company")}
                      >
                        <View style={[
                          commonStyles.flex1,
                          commonStyles.alignItemsCenter,
                          commonStyles.flexDirectionRow
                        ]}>
                          <View style={styles.plusIcon}>
                            <Image
                              source={plusSolidIcon}
                              style={[commonStyles.icon18]}
                            />
                          </View>
                          <View style={commonStyles.textAlignCenter}>
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight400,
                                commonStyles.smallHeading,
                                styles.custompaddingLeftRight,
                                commonStyles.textAlignCenter
                              ]}
                            >
                              Add Insurance
                            </Text>
                          </View>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                ) : (
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.rightInputHolder,
                      commonStyles.flexBasis48,
                      styles.addIncProText,
                      commonStyles.itemWithTwoCol,
                      styles.customAlignItemsStart,
                      styles.maxWidth48
                    ]}>
                    <View style={[commonStyles.positionRelative, styles.poBoxHolder]}>
                      <SelectMenuField
                        name="poBoxNo"
                        label="PO Box#/Address"
                        // required="Select PO Box No"
                        items={this.poBoxNoItems}
                        onValueChange={this.onPOBoxSearch}
                        style={[!!hasOnlyOnePOBox ? styles.disabledPOBox : null, customCommonStyles.inputHeight]}
                        searchable
                        itemLoader={async ({ search }) => {
                          let items = this.poBoxNoItems;
                          return items
                            .filter((item) => {
                              return item.label.toLowerCase().includes(search.toLowerCase());
                            })
                            .sort((a, b) => b.label - a.label);
                        }}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      />
                      {!!hasOnlyOnePOBox ? <View style={[styles.emptyElement, clientStyle.secondaryBgColor]}></View> : null}

                    </View>
                    <Touchable
                      style={[
                        commonStyles.dFlex,
                        commonStyles.alignItemsCenter,
                        commonStyles.marginLeft10,
                        styles.addInsuranceProvider,
                      ]}
                      onPress={() => this.alertOk("Please add insurance company")}
                    >
                      <View>
                        <View style={styles.plusIcon}>
                          <Image
                            source={plusSolidIcon}
                            style={[commonStyles.icon18]}
                          />
                        </View>
                        <View style={commonStyles.textAlignCenter}>
                          <Text
                            style={[
                              commonFontStyles.workSans,
                              commonStyles.fontWeight400,
                              commonStyles.smallHeading,
                              styles.custompaddingLeftRight,
                            ]}
                          >
                            Add Insurance
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                )}
              </View>
              {/* Patient Drop down */}
              <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap, this.state.isEditOrAddCpt && styles.displayNone]}>
                <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, styles.marginBottom28]}>
                  <SelectMenuField
                    name="patientName"
                    label={"Patient/Member*"}
                    items={this.state.membersList}
                    onValueChange={this.onPatientNameChange}
                    searchable
                    itemLoader={async ({ search }) => {
                      let items = this.state.membersList;
                      return items
                        .filter((item) => {
                          return item.label.toLowerCase().includes(search.toLowerCase());
                        })
                        .sort((a, b) => b.label - a.label);
                    }}
                    style={[styles.marginBottom0]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                  {!!isMemeberNameError || !!memberId ? (
                    <View style={[styles.mins30Top, styles.patientError]}>
                      <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle, !!memberId && commonStyles.lightGrayColor]} >{!!memberId ? `Member Id: ${memberId}` : isInsuranceCompanyError ? "Select Patient" : "Please add insurance patient/member"}</Text>
                    </View>
                  ) : null}
                </View>
                <View
                  style={[
                    styles.rightInputHolder,
                    commonStyles.flexBasis48,
                    commonStyles.customFlexDirection,
                    styles.customAlignItemsStart,
                    styles.textAlign,
                    styles.marginBottom28
                  ]}>
                  {this.state.showDatePicker ? <ReactDatePicker
                    name={"patientTreatedOn"}
                    value={this.props.app.ui.form("uploadBills") && this.props.app.ui.form("uploadBills").values && this.props.app.ui.form("uploadBills").values.patientTreatedOn ? this.props.app.ui.form("uploadBills").values.patientTreatedOn : ""}
                    mode={"modal"}
                    onValueChange={this.onDateValueChange}
                    onBlur={this.onDateBlur}
                    visible={this.state.showDatePicker}
                    closeDatePicker={this.handleShowHideDatePicker}
                    isUberDocs={isUberDocs}
                  /> : null}
                  <TextInputField
                    name={"patientTreatedOn"}
                    label={CommonString.PatientTreatedOnLabel + "*"}
                    secure={false}
                    readOnly={true}
                    onFocus={() => this.handleShowHideDatePicker('open')}
                    icon="calendar"
                    onIconPress={() => this.handleShowHideDatePicker()}
                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, commonStyles.marB0, commonStyles.fullWidth]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                    helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                  />
                </View>
              </View>
              <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap, this.state.isEditOrAddCpt && styles.displayNone]}>
                <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown]}>
                  <TextInputField
                    name={"totalClaimAmount"}
                    label={"Total Claim Amount*($) "}
                    secure={false}
                    required={"Enter the total claim amount"}
                    onChangeText={(amount) => this.onTotalClaimAmount(amount)}
                    style={[styles.formFields, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                    helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                  />
                  {!this.state.isSuperBill && this.state.isTotalClaimAmountError ? (
                    <View style={[styles.totalAmountErrorLabel]}>
                      <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >Enter the total claim amount</Text>
                    </View>
                  ) : null}
                </View>
                <View
                  style={[
                    commonStyles.flex1,
                    styles.rightInputHolder,
                    commonStyles.flexBasis48,
                    commonStyles.customFlexDirection,
                    styles.customAlignItemsStart,
                    styles.textAlign
                  ]}>
                  <TextInputField
                    name="notes"
                    label="Notes"
                    validator="cmtsVali"
                    multiline
                    onChangeText={this.readText}
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.heading6,
                      styles.commentsField,
                      commonStyles.margin0,
                      commonStyles.fullWidth,
                    ]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, styles.top15, clientStyle.secondaryBgColor]}
                    helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
              </View>
              <TreatmentAddressComponent
                isSuperBill={this.state.isSuperBill}
                isEditOrAddCpt={this.state.isEditOrAddCpt}
                accountHolderError={this.state.accountHolderError}
                insuranceItems={this.insuranceItems}
                treatedAtType={treatedAtType}
                selectedTreatedAt={this.state.selectedTreatedAt}
                toggleIsSuperBill={this.toggleIsSuperBill}
                isProviderTreatedAddresses={this.state.providerTreatedAddresses}
                providers={this.providers}
                onProviderSearch={this.onProviderSearch}
                onSearchProvider={this.onSearchProvider}
                taxIdNumber={this.state.taxIdNumber}
                isProviderNameError={this.state.isProviderNameError}
                alertOk={this.alertOk}
                form={this.props.app.ui.form("uploadBills")}
                isUberDocs={isUberDocs}
                patientVisitTypes={this.patientVisitTypes}
                getVisitTypesList={this.getVisitTypesList}
                onTreatedAtChange={this.onTreatedAtChange}
              />
              <DiagnosticDetailsComponent
                isSuperBill={this.state.isSuperBill}
                isDiagnosisError={this.state.isDiagnosisError}
                isEditOrAddCpt={this.state.isEditOrAddCpt}
                getDiagnosisList={this.getDiagnosisList}
                form={this.props.app.ui.form("uploadBills")}
                onDiagnosticChange={this.onDiagnosticChange}
              />
              <CptDetailsComponent
                isSuperBill={this.state.isSuperBill}
                isEditOrAddCpt={this.state.isEditOrAddCpt}
                cptCode={this.state.cptRowId}
                isCptCodeError={this.state.isCptCodeError}
                isCptUnitError={this.state.isCptUnitError}
                isDateOfServiceError={this.state.isDateOfServiceError}
                isCptFeeError={this.state.isCptFeeError}
                onEditCptDetails={this.onEditCptDetails}
                leftMenuToggle={leftMenuToggle}
                openPopScreen={this.openPopScreen}
                cptItems={this.state.cptDetailsList}
                onAmountChange={this.onAmountChange}
                onDateOfServiceBlur={this.onDateOfServiceBlur}
                onDateOfServiceValueChange={this.onDateOfServiceValueChange}
                onClearDateOfServiceDate={this.onClearDateOfServiceDate}
                form={this.props.app.ui.form("uploadBills")}
                showDateOfServiceDatePicker={this.state.showDateOfServiceDatePicker}
                handleDateOfServiceShowHideDatePicker={this.handleDateOfServiceShowHideDatePicker}
                getCptList={this.getCptList}
                getModifiersList={this.getModifiersList}
                onModifierChange={this.onModifierChange}
                onCptChange={this.onCptChange}
              />
              {/* Passcode */}
              <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis49, commonStyles.flexDirectionRow, commonStyles.marginBottom5, this.state.isEditOrAddCpt && styles.displayNone,]}>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.heading5,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}
                >Document Passcode</Text>
              </View>
              {/* SSID */}
              <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, styles.inputHolder, styles.customflexDirection, customCommonStyles.customSSIDDOBHolder, styles.customflexGrow, commonStyles.justifyContentSpaceBetween, this.state.isEditOrAddCpt && styles.displayNone,]}>
                <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, commonStyles.fullWidth, customCommonStyles.ssidandDobHolder]}>
                  <TextInputField
                    name="ssId"
                    label="Patient's SSID*(last 4 digits)"
                    validator="only4Num"
                    disabled={!!isEnterDob}
                    readOnly={!!isEnterDob}
                    iconRight={!!isEnterSSID ? "close" : null}
                    onChangeText={this.onEnterSSIdText}
                    onIconRightPress={() => this.clearText()}
                    style={[commonFontStyles.workSans, commonStyles.heading6, !!isEnterDob ? styles.inputDisable : null, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                    labelTextStyle={[
                      commonStyles.labelText,
                      commonFontStyles.workSans,
                      commonFontStyles.workSans,
                      commonStyles.heading6,
                      clientStyle.secondaryBgColor
                    ]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
                <View
                  style={[
                    commonStyles.textAlignCenter,
                    customCommonStyles.orSection,
                  ]}
                >
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.fontWeight500,
                      commonStyles.heading5,
                      commonStyles.textAlignCenter,
                    ]}
                  >
                    OR
                  </Text>
                </View>
                {Platform.OS === 'web' ? <>
                  <View style={[styles.rightInputHolder, commonStyles.flexDirectionColumn, commonStyles.flexBasis48, styles.dobInput, commonStyles.fullWidth, customCommonStyles.customBobInputFieldHolder, !!isEnterSSID && styles.disabledPOBox]}>
                    {this.state.showDobDatePicker ? <ReactDatePicker
                      name={"dOB"}
                      form="uploadBills"
                      value={this.props.app.ui.form("uploadBills").values.dOB}
                      mode={"modal"}
                      onValueChange={this.onDobDateValueChange}
                      onBlur={this.onDobDateBlur}
                      visible={this.state.showDobDatePicker}
                      closeDatePicker={this.handleDobShowHideDatePicker}
                      isUberDocs={isUberDocs}
                    /> : null}
                    <TextInputField
                      name={"dOB"}
                      label={CommonString.PatientDobLabel + "*"}
                      secure={false}
                      readOnly={true}
                      onFocus={() => this.handleDobShowHideDatePicker('open')}
                      icon="calendar"
                      onIconPress={() => this.handleDobShowHideDatePicker()}
                      iconRight={this.props.app.ui.form("uploadBills") && this.props.app.ui.form("uploadBills").values.dOB ? "close" : ""}
                      onIconRightPress={this.onClearDobDate}
                      style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonFontStyles.workSans, commonStyles.helperTextFontStyle]}
                    />
                  </View>
                </> : <>
                  <View style={[styles.rightInputHolder, commonStyles.dFlex, commonStyles.flexDirectionRow, commonStyles.flexBasis48, styles.dobInput, commonStyles.fullWidth, customCommonStyles.customBobInputFieldHolder]}>
                    <DatePicker
                      style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.marB0, !!isEnterSSID ? styles.inputDisable : null, customCommonStyles.customBobInputField, customCommonStyles.inputHeight]}
                      name={"dOB"}
                      label={CommonString.PatientDobLabel + "*"}
                      // mode={!!isEnterSSID ? "dropdown" : "modal"}
                      // textInputEditable={!!isEnterSSID}
                      mode={"dropdown"}
                      textInputEditable={false}
                      disabled={!!isEnterSSID}
                      readOnly={!!isEnterSSID}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[styles.labelStyle, commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      onValueChange={this.onDobDateValueChange}
                      onBlur={this.onDobDateBlur}
                    />
                    <TextInputField
                      name={"dOB"}
                      label={CommonString.PatientDobLabel + "*"}
                      secure={false}
                      disabled={true}
                      style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, clientStyle.secondaryBgColor]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonFontStyles.workSans, commonStyles.helperTextFontStyle]}
                    />
                  </View>
                </>}
              </View>
              {
                <View style={[commonStyles.dFlex, styles.inputHolder, styles.displayNone]}>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.leftInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.flexDirectionRow,
                      styles.mobileNumberInput,
                    ]}>
                    <SelectMenuField
                      style={[commonFontStyles.workSans, commonStyles.heading6, styles.countryCode, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                      name="countryCode"
                      required={CommonString.SelectCountryCodeRequired}
                      items={[{ label: "+1", value: "+1" }]}
                      // onValueChange={this.onDomainExtensionChange}
                      searchable
                      disabled={true}
                      itemLoader={async ({ search }) => {
                        let items = [{ label: "+1", value: "+1" }];
                        return items
                          .filter((item) => {
                            return item.label.toLowerCase().includes(search.toLowerCase());
                          })
                          .sort((a, b) => b.label - a.label);
                      }}
                    />
                    <TextInputField
                      name="mobileNumber"
                      label={CommonString.MobilerNumberLabel + "*"}
                      required={CommonString.EnterMobileNoRequired}
                      validator="max10phoneNoValidator"
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[styles.customHelperTextStyleMobile, styles.leftMinus12, commonStyles.helperTextFontStyle]}
                    />
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis48, styles.emailInput]}>
                    <TextInputField
                      name="email"
                      label={CommonString.EmailLabel + "*"}
                      required={CommonString.EmailRequired}
                      validator="validateEmail"
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    />
                  </View>
                </View>
              }
              <View style={[styles.customMarTAuto, commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow]}>
                <View style={[commonStyles.marginRight8, styles.formBtnsHolder]}>
                  <Button
                    title={this.state.isEditOrAddCpt ? "Back" : "Cancel"}
                    style={[commonStyles.blueBorderButton, styles.submitBtn, styles.formBtns, clientStyle.backAndCancelButton]}
                    titleTextStyle={[
                      commonStyles.blueBorderButtonColor,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      clientStyle.backAndCancelButtonText
                    ]}
                    onPress={this.state.isEditOrAddCpt ? this.handleBackCptBtn : this.onCancelClick}
                  />
                </View>
                <View style={[styles.formBtnsHolder, commonStyles.marginLeft8]}>
                  {this.state.isEditOrAddCpt ? (
                    <Button
                      title={this.state.isEditOrAddCpt ? this.state.cptRowId ? "Update" : "Add" : "Submit"}
                      disabled={isDisable}
                      style={[commonStyles.blueButton, styles.submitBtn, styles.formBtns, customCommonStyles.noBoxShadow, clientStyle.submitButton]}
                      titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, clientStyle.submitButtonText]}
                      onPress={() => this.state.isEditOrAddCpt ? this.handleEditOrAddCpt(this.props.app.ui.form("uploadBills")) : this.handleSubmit(this.props.app.ui.form("uploadBills"))}
                    />
                  ) : (
                    <SubmitButton
                      title={this.state.isEditOrAddCpt ? this.state.cptRowId ? "Update" : "Add" : "Submit"}
                      disabled={isDisable}
                      style={[commonStyles.blueButton, styles.submitBtn, styles.formBtns, customCommonStyles.noBoxShadow, clientStyle.submitButton]}
                      titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, clientStyle.submitButtonText]}
                    />
                  )}
                </View>
              </View>
            </Form>
          </View>
          <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} clientStyle={clientStyle} />

          <PopupModalComponent
            visibility={!!this.state.isConfirmationVisible}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
            messageType={MESSAGE_TYPE.delete}
            headingIcon={true}
            heading={"Delete CPT"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={`Are you sure you want to delete?`}
            okLabel={"No"}
            handleOk={() => this.closePopScreen()}
            closeLabel={"Yes"}
            handleClose={() => this.removeCptItem()}
          />
          <UploadBillConfirmationComponent
            handleConfirmationPopUp={this.handleConfirmationPopUp}
            confirmationPopUpVisibility={confirmationPopUpVisibility}
            isUberDocs={isUberDocs}
            userLoginDetail={this.props.userLoginDetail}
            onSearchClient={this.onSearchClient}
            clients={this.clients}
            // for Non register only
            nonRegisterUser={nonRegisterUser}
            onNonRegistrationCancel={this.onNonRegistrationCancel}
            onNonRegistrationSubmit={this.onNonRegistrationSubmit}
          />
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  uploadBillsScreen: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          backgroundColor: "#fff",
          marginBottom: 45,
        };
    },
  },
  modalBackdrop: {
    backgroundColor: "#00000052",
    overflowX: "auto",
  },
  commentsField: {
    minHeight: 70,
    lineHeight: 15,
    resize: "none",
  },
  closePopup: {
    width: "20%",
    height: 8,
    borderRadius: 25,
    backgroundColor: "#e1e1e1",
    marginBottom: 25,
  },
  attachOpt: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 15,
        };
      }
    },
  },
  attachments: {
    // paddingLeft: 50,
  },
  ucountIconDiv: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
    backgroundColor: "#cff2ff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    marginTop: 5,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  alertModalWrapper: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  filePassowrdModalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  modalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  filePassowrdModalBodyWrapper: {
    backgroundColor: "#fff",
    border: "1px solid #a7a7a7",
    padding: 10,
    width: 350,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  addInsuranceAlert: {
    height: 150,
  },
  inputDisable: {
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: 0.5,
  },
  leftInputHolder: {
    marginRight: 8,
    maxWidth: '49%',
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: '100%',
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMarTAuto: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: -15,
        };
      }
    },
  },
  customFlexProperties: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
          marginBottom: 30
        };
      }
      if (width >= 991) {
        return {
          justifyContent: "flex-end",
        };
      }
    },
  },
  submitBtn: {
    minWidth: 108,
  },
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customPaddingTop15: {
    paddingTop: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          marginTop: 15,
          marginLeft: 10,
        };
      }
    },
  },
  formBtnsHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  formBtns: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailRow: {
    borderBottom: "1px solid rgb(218, 218, 218)",
    paddingBottom: 16,
    marginBottom: 15,
    flexBasis: "48%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingBottom: 0,
          flexBasis: "100%",
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  addIncProText: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  countryCode: {
    maxWidth: 70,
  },
  customHelperTextStyleMobile: {
    marginLeft: 58,
  },
  btnHolder: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  uploadBillSection: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    height:"100%",
    // alignItems: "flex-start",
    // flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 16,
        };
      }
    },
  },
  mobView: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "flex",
          marginTop: -10,
          flexDirection: "row",
        };
      }
    },
  },
  dobInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
        };
      }
    },
  },
  emailInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -11,
        };
      }
    },
  },
  cameraOpt: {
    paddingTop: 8,
    paddingBottom: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          paddingBottom: 0,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  marginTopMin20: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -20,
        };
      }
    },
  },
  marginTopMin10: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -10,
        };
      }
    },
  },
  pageTitle: {
    marginTop: 15,
    display: "flex",
    justifyContent: "space-between",
    minHeight: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
          marginBottom: 10,
        };
      }
    },
  },
  mobileNumberInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 15,
        };
      }
    },
  },
  cameraOptHolder: {
    flex: 1,
    flexGrow: 1,
  },
  uploadedBillAttachFile: {
    // marginBottom: 10,
  },
  helperTextStyleDob: {
    position: "static",
  },
  leftMinus12: {
    left: -12,
  },
  autoFillModelBodyWrapper: {
    width: 500,
    maxHeight: "80vh",
    overflowY: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          height: "100vh",
        };
      }
    },
  },
  autoFillModel: {
    height: "100%",
    overflowY: "auto",
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: "100vh",
        };
      }
    },
  },
  fillWidth: {
    width: "33%",
  },
  addInsuranceProvider: {
    // backgroundColor: "#e2e2e2",
    alignItems: "center",
    // borderRadius: 20,
    // paddingTop: 2,
    // paddingRight: 4,
    // paddingBottom: 2,
    // paddingLeft: 4,
    // border: "1px solid #333",
    // marginTop: 14,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "inline-block"
        };
      }
    },
  },
  customAlignItemsStart: {
    alignItems: "baseline",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: null,
        };
      }
    },
  },
  mViewFontStyle: {
    fontSize: 16,
    fontWeight: 600,
  },
  attachmentCard: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#a1a4a8",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
          minHeight: 65,
        };
      }
    },
  },
  updatedonForMView: {
    flexDirection: "row",
  },
  PDFViewBodyWrapper: {
    width: "80vw",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          // height: "75vh",
          marginTop: "auto",
          borderRadius: "10px 10px 0 0",
        };
      }
      if (width === 411 && height === 731) {
        return {
          height: "64vh",
        };
      }
    },
  },
  PDFModalContent: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          overflowY: "scroll",
          marginTop: 0,
        };
      }
    },
  },
  custompaddingLeftRight: {
    paddingTop: 0,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 5,
  },
  disabledPOBox: {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "default",
  },
  fileUploadWrapper: {
   borderStyle:"dashed",
   borderWidth:1,
   borderColor:"#a1a4a8",
   borderRadius:5,
    flexBasis: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "80%",
        };
      }
    },
  },
  buttons: {
    height: 24,
  },
  uploadBillsForm: {
    width: '100%',
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flex: 1,
          width: '100%'
        };
      }
      if (width <= 991) {
        return {
          paddingTop: 0,
          //   width: "90%"
        };
      }
    },
  },
  attachmentFieldOuter: {
    width: "100%",
    position: "relative",
    height: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: 36,
        };
      }
    },
  },

  attachFileScetion: {
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 6,
        };
      }
    },
  },
  attachmentsHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 16,
        };
      }
    },
  },
  emptyElement: {
    height: 25,
    width: 25,
    position: "absolute",
    top: 5,
    right: 5,
  },
  poBoxHolder: {
    width: "73%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "63%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  bodyWrapper: {
    width: 300,    
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          fontWeight: 600,
        };
    },
  },
  modalContent: {
    paddingLeft: 26,
    paddingRight: 26,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingLeft: 26,
          paddingRight: 26,
        };
    },
  },
  closeBtn: {
    position: "absolute",
    right: -14,
    top: -10,
  },
  btnWrapper: {
    flex: 1,
    justifyContent: "center",
    marginTop: 15,
  },
  incNameDropDown: {
    screen: ({ width }) => {
      if (width >= 991)
        return {
          maxWidth: "49%",
        };
    },
  },
  textAlign: {
    alignItems: "center",
    height: "55%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginBottom: 16,
          alignItems: "flex-start",
        };
      }
    },
  },
  orTextSection: {
    marginTop: 12
  },
  mins30Top: {
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 20,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -30,
        };
      }
    }
  },
  maxWidth48: {
    screen: ({ width }) => {
      if (width > 991) {
        return {
          maxWidth: "48%"
        };
      }
    },
  },
  top15: {
    top: 15
  },
  marginBottom28: {
    marginBottom: 28
  },
  patientError: {
    position: "absolute",
    marginTop: 30,
    marginBottom: 0
  },
  totalAmountErrorLabel: {
    marginLeft: 0,
    marginTop: -28
  },
  marginBottom0:{
    marginBottom: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 32,
        };
    }
  }
});
