import { StyleSheet, Platform } from "../../core";
const commonStyles = StyleSheet.create({
  //https://fonts.google.com/specimen/Work+Sans
  fontWeight100: { //Thin
    fontWeight: "100",
  },
  fontWeight200: { //ExtraLight
    fontWeight: "200",
  },
  fontWeight300: { //Light
    fontWeight: "300",
  },
  fontWeight400: { // Regular
    fontWeight: "400",
  },
  fontWeight500: { //Medium
    screen: ({ width }) => {
    if(Platform.OS === 'android'){
      return {
        fontWeight: "700"
      }
    } else {
      return {
        fontWeight: "500",

      }
    }
    },
  },
  fontWeight600: { //SemiBold
    fontWeight: "600",
  },
  fontWeight700: { //Bold
    fontWeight: "700",
  },
  fontWeight800: { //ExtraBold
    fontWeight: "800",
  },
  fontWeight900: { // Black
    fontWeight: "900",
  },
  fontStyleItalic: {
    fontStyle: "italic",
  },
  largeHeading32: {
    fontSize: 32,
  },
  heading1: {
    fontSize: 24,
  },
  heading2: {
    fontSize: 22,
  },
  heading3: { //Header
    fontSize: 20,
  },
  heading4: { //Sub Header
    fontSize: 18,
  },
  heading5: { //Sub Sub Header
    fontSize: 16,
  },
  heading6: { // All Text
    fontSize: 13,
  },
  heading7: {
    fontSize: 15,
  },
  smallHeading: { //Model/Form/Data Table's Thead Text
    fontSize: 12,
  },
  exSmallHeading: { //Button Text
    fontSize: 11,
  },
  tooSmallHeading: { //Button Text
    fontSize: 10,
  },
  invisibleBlock: {
    display: "none",
  },
  // Company Address Form css starts
  addressForm: {
    flex: 0,
    backgroundColor: "#fff",
  },
  whiteColor: {
    color: "#fff",
  },
  lightGrayColor: {
    color: "#737373",
  },
  whiteBackgroundColor: {
    backgroundColor: "#fff",
  },
  detailsForm: {
    flex: 0,
    backgroundColor: "#fff",
  },
  financialForm: {
    flex: 0,
    backgroundColor: "#fff",
  },
  item: {
    flex: 1,
    marginRight: 14,
    width: "48%",
    screen: ({ width }) => {
      if (width <= 767)
        return {
          marginRight: 0,
          width: "100%",
        };
    },
  },
  threeColumnItem: {
    flex: 1,
    marginRight: 14,
    width: "31.5%",
    screen: ({ width }) => {
      if (width <= 767)
        return {
          marginRight: 0,
          width: "100%",
        };
    },
  },
  fourColumnItem: {
    flex: 1,
    marginRight: 14,
    width: "24%",
    screen: ({ width }) => {
      if (width <= 767)
        return {
          marginRight: 0,
          width: "100%",
        };
    },
  },
  saveBtn: {
    backgroundColor: "#4cc5f2",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          width: "100%",
          marginBottom: 30,
        };
    },
  },
  cancelBtn: {
    color: "#356f83",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          width: "100%",
          margin: 0,
          marginBottom: 30,
        };
    },
  },
  outlinedBtn: {
    borderColor: "#356f83",
  },
  btnSpace: {
    marginTop: 0,
    marginRight: 10,
    marginBottom: 0,
    marginLeft: 10,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          width: "100%",
          margin: 0,
          marginBottom: 30,
        };
    },
  },
  textInputField: {
    border: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  singleFlexContainer: {
    flex: 1,
    marginRight: "2%",
    width: "50%",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          width: "98%",
        };
    },
  },
  singleItem: {
    flex: 1,
    marginRight: "2%",
    width: "96%",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          width: "100%",
        };
    },
  },
  labels: {
    color: "red",
  },
  sectionHeader: {
    color: "#000",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 20,
  },
  companyIdLabel: {
    color: "#000",
    fontSize: 18,
    fontWeight: 700,
  },
  normaldLabel: {
    color: "#000",
    fontSize: 18,
    fontWeight: "normal",
  },
  marB10: {
    marginBottom: 10,
  },
  transformUpperCase: {
    textTransform: "uppercase",
  },
  transformCapitalize: {
    textTransform: "capitalize",
  },
  transformNone: {
    textTransform: "none",
  },
  textTransformLowercase: {
    textTransform: "lowercase",
  },
  fullWidthBtn: {
    width: "100%",
  },
  modalStyle: {
    width: "40%",
    backgroundColor: "white",
    height: 300,
  },
  
  // modalStyle: {
  //   position: "fixed",
  //   width: "100%",
  //   top: 0,
  //   bottom: 0,
  //   backgroundColor: "#b5b5b5",
  //   zIndex: 9,
  // },
  marB20: {
    marginBottom: 20,
  },
  marB0: {
    marginBottom: 0,
  },
  labelText: {
    color: "#585858",
    backgroundColor: "#fff",
    fontSize: 13,
    flex: 1,
    textAlign: "center",
    marginBottom: 0,
    bottom: 0,
  },
  // Phase 2 CSS starts
  tableLabelContainer: {
    marginLeft: 20,
    marginBottom: 15,
    marginTop: 15,
    fontWeight: 500,
  },
  catlogFormLayout: {
    marginRight: 20,
    marginLeft: 20,
  },
  smallTableLabel: {
    display: "block",
    fontWeight: "normal",
    color: "#182832",
    fontSize: 12,
    marginTop: 2,
  },
  fullFlexContainer: {
    width: "100%",
  },
  singleFullFlexItem: {
    width: "100%",
  },
  itemWithTwoCol: {
    flexDirection: "row",
  },
  twoColumnInSingleItem: {
    width: "100%",
    marginLeft: "2%",
  },
  // All css for Margin & Padding
  marRT0: {
    marginRight: 0,
  },
  marLT0: {
    marginLeft: 0,
  },
  marT0: {
    marginTop: 0,
  },
  marTMinus10: {
    marginTop: -10,
  },
  marginTop3: {
    marginTop: 3
  },
  marginTop5: {
    marginTop: 5,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop15: {
    marginTop: 15,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop25: {
    marginTop: 25,
  },
  marginTop50: {
    marginTop: 50,
  },
  marTop30: {
    marginTop: 30,
  },
  marTop40: {
    marginTop: 40,
  },
  marTAuto: {
    marginTop: "auto",
  },
  marRAuto: {
    marginRight: "auto",
  },
  marBAuto: {
    marginBottom: "auto",
  },
  marLAuto: {
    marginLeft: "auto",
  },
  marRT2Percent: {
    marginRight: "2%",
  },
  marginRightMinus_5: {
    marginRight: -5,
  },
  marLT10: {
    marginLeft: 10,
  },
  marLT15: {
    marginLeft: 15,
  },
  marLT2Percent: {
    marginLeft: "2%",
  },
  padding5: {
    padding: 5,
  },
  padding4x8: {
    paddingTop: 3.5,
    paddingRight: 8,
    paddingBottom: 3.5,
    paddingLeft: 8,
  },
  padding10: {
    padding: 10,
  },
  padding15: {
    padding: 15,
  },
  padding16: {
    padding: 16,
  },
  padding5_0: {
    paddingTop: 5,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  marginLeftRight16: {
    marginLeft: 16,
    marginRight: 16,
  },
  paddingLeftRight30: {
    paddingLeft: 30,
    paddingRight: 10,
  },
  paddingRight20: {
    paddingRight: 20,
  },
  paddingRight0: {
    paddingRight: 0,
  },
  paddingLeft0: {
    paddingLeft: 0,
  },
  paddingRight15: {
    paddingRight: 15,
  },
  paddingLeftRight16: {
    paddingRight: 16,
    paddingLeft: 16,
  },
  paddingRight16: {
    paddingRight: 16,
  },
  paddingLeftRight10: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  paddingRight10: {
    paddingRight: 10,
  },
  paddingLeftRight5: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  paddingTop0: {
    paddingTop: 0,
  },
  paddingTop3: {
    paddingTop: 3,
  },
  paddingTop5: {
    paddingTop: 5,
  },
  paddingTop6: {
    marginTop: 6
  },
  paddingTop8: {
    paddingTop: 8,
  },
  paddingTop10: {
    paddingTop: 10,
  },
  paddingTop15: {
    paddingTop: 15,
  },
  paddingTop20: {
    paddingTop: 20,
  },
  paddingTopBottom5: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  paddingTopBottom10: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  paddingTopBottom15: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  paddingTopBottom20: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  resizeVertical: {
    resize: "vertical",
  },
  resizeNone: {
    resize: "none",
  },
  blueButton: {
    backgroundColor: "#d71996",
    color: "#fff",
    height: 24,
    width: 64,
    // marginRight: 'auto',
    // marginLeft: 'auto',
    borderWidth: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
  },
  blueButtonBorder: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#37c2ef",
  },
  blueButtonColor: {
    color: "#333",
  },
  whiteButton: {
    backgroundColor: "#fff",
    color: "#333",
    height: 38,
  },
  blueBorderButtonColor: {
    color: "#1277d9",
  },
  blueBorderButtonFontStyle: {
    color: "#307089",
    textTransform: "uppercase",
    fontWeight: 600,
  },
  blueBorderButton: {
    backgroundColor: "rgba(255,255,255, 1.0)",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#1277d9",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
    height: 24,
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between",
  },
  justifyContentSpaceEvenly: {
    justifyContent: "space-evenly",
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
  },
  justifyContentFlexStart: {
    justifyContent: "flex-start",
  },
  justifyContentSpaceAround: {
    justifyContent: "space-around",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  alignItemsEnd: {
    alignItems: "flex-end",
  },
  alignItemsStart: {
    alignItems: "flex-start",
  },
  alignItemsBaseLine: {
    alignItems: "baseline",
  },
  flexDirectionRow: {
    flexDirection: "row",
  },
  flexDirectionColumn: {
    flexDirection: "column",
  },
  flexColumn: {
    screen: ({ width }) => {
      if (width <= 767)
        return {
          flexDirection: "column",
        };
    },
  },
  dFlex: {
    display: "flex",
  },
  flex0: {
    flex: 0,
  },
  dTable: {
    display: "table",
  },
  dInlineFlex: {
    display: "inline-flex",
  },
  dBlock: {
    display: "block",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  flexGrow1: {
    flexGrow: 1,
  },
  marginBottom45: {
    marginBottom: 45,
  },
  flexBasis50: {
    flexBasis: "50%",
  },
  flexBasis49: {
    flexBasis: "49%",
  },
  flexBasis48: {
    flexBasis: "48%",
  },
  flexBasis100: {
    flexBasis: "100%",
  },  
  flexBasis33: {
    flexBasis: "33%",
    marginBottom: 35,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          flexBasis: "100%",
        };
    },
  },
  flexGrow2: {
    flexGrow: 2,
  },
  textAlignRight: {
    textAlign: "right",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  logoutButton: {
    marginLeft: "auto",
    marginRight: 0,
    marginTop: 20,
    width: "30%",
    backgroundColor: "#37c2ef",
  },
  dateField: {
    width: "35%",
    maxWidth: "35%",
  },
  fromDate: {},
  toDate: {
    marginLeft: 15,
  },
  border0: {
    border: 0,
  },
  borderBox: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTopLeftRadius: 1,
    borderTopRightRadius: 1,
    borderBottomLeftRadius: 1,
    borderBottomRightRadius: 1,
    paddingTop: 20,
    paddingRight: 25,
    paddingBottom: 20,
    paddingLeft: 25,
  },
  borderBottomSpace25: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    paddingBottom: 25,
    marginBottom: 25,
  },
  borderBottomSpace15: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    paddingBottom: 15,
    marginBottom: 15,
  },
  borderBottomSpace20: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    paddingBottom: 20,
    marginBottom: 20,
  },
  borderBottomSpace10: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    paddingBottom: 10,
    marginBottom: 10,
  },
  borderBottomPaddingBottom10: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    paddingBottom: 10,
  },
  paddingBottom0: {
    paddingBottom: 0,
  },
  paddingBottom10: {
    paddingBottom: 10,
  },
  paddingBottom20: {
    paddingBottom: 20,
  },
  marginLeft5: {
    marginLeft: 5,
  },
  marginLeft8: {
    marginLeft: 8,
  },
  marginRight8: {
    marginRight: 8,
  },
  staticSection: {
    borderColor: "red",
  },
  marginBottom3: {
    marginBottom: 3,
  },
  marginBottom35: {
    marginBottom: 35,
  },
  marginBottom30: {
    marginBottom: 30,
  },
  marginBottom25: {
    marginBottom: 25,
  },
  marginBottom24: {
    marginBottom: 24,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginBottom15: {
    marginBottom: 15,
  },
  marginBottom13: {
    marginBottom: 13,
  },
  marginTop16: {
    marginTop: 16,
  },
  marginBottom16: {
    marginBottom: 16,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginBottom5: {
    marginBottom: 5,
  },  
  marginBottom0: {
    marginBottom: 0
  },
  marginLeft15: {
    marginLeft: 15,
  },
  marginLeft30: {
    marginLeft: 30,
  },
  marginLeft10: {
    marginLeft: 10,
  },
  marginLeft12: {
    marginLeft: 12,
  },
  marginLeftRight5: {
    marginTop: 0,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
  },
  marginLeftRight5TopBottom10: {
    marginTop: 10,
    marginRight: 5,
    marginBottom: 10,
    marginLeft: 5,
  },
  marginTop6:{
    marginTop:6
  },
  margin0Auto: {
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
  },
  marginLeftRight15: {
    marginTop: 0,
    marginRight: 15,
    marginBottom: 0,
    marginLeft: 15,
  },
  marginLeftRightMinus15: {
    marginTop: 0,
    marginRight: -15,
    marginBottom: 0,
    marginLeft: -15,
  },
  marginLeftRight20: {
    marginTop: 0,
    marginRight: 20,
    marginBottom: 0,
    marginLeft: 20,
  },
  marginLeftRightMinus20: {
    marginTop: 0,
    marginRight: -20,
    marginBottom: 0,
    marginLeft: -20,
  },
  margin0: {
    margin: 0,
  },
  padding0: {
    padding: 0,
  },
  paddingAll0: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  fullWidth: {
    width: "100%",
    height: 24
  },
  marginRight16: {
    marginRight: 16,
  },
  marginLeft16: {
    marginLeft: 16,
  },
  marginRight15: {
    marginRight: 15,
  },
  marginRight30: {
    marginRight: 30,
  },
  marginRight10: {
    marginRight: 10,
  },
  marginRight25: {
    marginRight: 25,
  },
  marginRight5: {
    marginRight: 5,
  },
  marginRight40: {
    marginRight: 40,
  },  
  marginRight45: {
    marginRight: 45,
  },
  marginTopBottom5: {
    marginTop: 5,
    marginRight: 0,
    marginBottom: 5,
    marginLeft: 0,
  },
  marginTopBottom10: {
    marginTop: 10,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
  },
  marginTopBottom15: {
    marginTop: 15,
    marginRight: 0,
    marginBottom: 15,
    marginLeft: 0,
  },
  marginTopBottom20: {
    marginTop: 20,
    marginRight: 0,
    marginBottom: 20,
    marginLeft: 0,
  },
  postIniBgColo1: {
    backgroundColor: "#00688d",
  },
  postIniBgColo2: {
    backgroundColor: "#172832",
  },
  transperentBtn: {
    backgroundColor: "rgba(255,255,255, 0.0)",
    boxShadow: "none",
  },
  transperentBtnColor: {
    color: "#1277d9",
  },
  asterisk: {
    color: "red",
  },
  displayNone: {
    display: "none",
  },
  filterChips: {
    paddingTop: 3,
    paddingRight: 5,
    paddingBottom: 3,
    paddingLeft: 5,
    minHeight: 24,
    screen: ({ width }) => {
      if (width <= 1024)
        return {
            paddingRight: 0,
            paddingLeft: 0,
        }
    }
  },
  statusColor: {
    display: "block",
    height: 10,
    width: 10,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
  },
  skyBlueBackground: {
    backgroundColor: "#36B9EF",
  },
  blueBackground: {
    backgroundColor: "#4dc5f2",
  },
  greenBackground: {
    backgroundColor: "#218666",
  },
  redBackground: {
    backgroundColor: "#b40220",
  },
  purpleBackground: {
    backgroundColor: "#8d2a6e",
  },
  yellowBackground: {
    backgroundColor: "#ee781e",
  },
  guestChat: {
    // alignSelf: "flex-end",
  },
  guestName: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 12,
    whiteSpace: "nowrap",
    color: "#182832",
    // minWidth: "100%",
    screen: ({ width }) => {
      if (width <= 400)
        return {
            // width: "100%",
        }
    }
    // width: "100%",
  },
  chatTiming: {
    fontSize: 10,
    width: "100%",
    textAlign: "right"
  },
  guestText: {
    // display: "block",
    // backgroundColor: "#efefef",  //"#e8e5e5",
    backgroundColor: "#ecf3fe",  //"#e8e5e5",
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
    position: "relative",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    marginTop: 2,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    wordBreak: "break-word",
    flex: 1,
  },
  guestTextBorder: {
    height: 0,
    width: 0,
    position: "absolute",
    top: -0,
    left: -9,
    borderTop: "5px solid transparent",
    borderRight: "5px solid #ecf3fe",
    borderBottom: "5px solid transparent",
    borderLeft: "5px solid transparent",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          borderBottomLeftRadius: 2,
          borderBottomRightRadius: 2,
        };
    },
  },
  hostChat: {
    // display: "flex",
    marginBottom: 5,
    alignSelf: "flex-start",
    width: "100%",
  },
  HostName: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  hostText: {
    // display: "block",
    backgroundColor: "#f4f4f4",
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
    position: "relative",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 2,
    marginTop: 2,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    wordBreak: "break-word",
    flex: 1,
  },

  fileSize: {
    fontSize: 9,
  },
  hostTextBorder: {
    height: 0,
    width: 0,
    position: "absolute",
    bottom: -0,
    right: -9,
    borderStyle: "solid",
    borderTop: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderLeft: "5px solid #f4f4f4",
  },
  borderTop: {
    borderTop: "1px solid #D6D6D6",
  },
  borderTopBottom: {
    borderTop: "1px solid #D6D6D6",
    borderBottom: "1px solid #D6D6D6",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  darkGrayColor: {
    color: "#182832",
  },
  grayBorderradious50: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
  },
  greenColor: {
    color: "#218666",
  },
  redColor: {
    color: "#B40220",
  },
  minWidth110: {
    minWidth: 110,
  },
  width76: {
    minWidth: 76,
  },
  width85: {
    minWidth : 85
  },
  minWidth150: {
    // minWidth: 150,
  },
  minWidth50: {
    minWidth: 50,
  },
  maxWidth90percent: {
    maxWidth: "90%",
  },
  maxWidth80percent: {
    maxWidth: "80%",
  },
  lightGray: {
    color: "#888888",
  },
  exLightGray: {
    backgroundColor: "#cbcbcb",
  },
  icon36x22: {
    width: 36,
    height: 22,
    display: "block",
    backgroundSize: "contain",
  },
  icon36: {
    width: 36,
    height: 36,
    display: "block",
    backgroundSize: "contain",
  },
  icon30: {
    width: 30,
    height: 30,
    display: "block",
    backgroundSize: "contain",
  },
  darkGray: {
    color: "#444444",
  },
  icon35: {
    height: 35,
    width: 35,
    display: "block",
    backgroundSize: "contain",
  },
  icon15: {
    height: 15,
    width: 15,
    minWidth: 15,
    display: "block",
    backgroundSize: "contain",
    backgroundPosition: "center center",
  },
  icon20: {
    height: 20,
    width: 20,
    minWidth: 20,
    display: "block",
    backgroundSize: "contain",
    backgroundPosition: "center center",
  },
  icon18: {
    height: 18,
    width: 18,
    minWidth: 18,
    display: "block",
    backgroundSize: "contain",
    backgroundPosition: "center center",
  },
  icon24: {
    height: 24,
    width: 24,
    display: "block",
    backgroundSize: "contain",
  },
  icon34: {
    height: 34,
    width: 34,
    display: "block",
    backgroundSize: "contain",
  },
  icon14: {
    height: 14,
    minWidth: 14,
    display: "block",
    backgroundSize: "contain",
  },
  icon10: {
    height: 10,
    minWidth: 10,
    display: "block",
    backgroundSize: "contain",
  },
  icon12: {
    height: 12,
    minWidth: 12,
    display: "block",
    backgroundSize: "contain",
  },
  icon48: {
    height: 48,
    width: 48,
    display: "block",
    backgroundSize: "contain",
  },
  icon42: {
    height: 42,
    minWidth: 42,
    display: "block",
    backgroundSize: "contain",
  },
  icon40: {
    height: 40,
    minWidth: 40,
    display: "block",
    backgroundSize: "contain",
  },
  icon60: {
    height: 60,
    minWidth: 60,
    display: "block",
    backgroundSize: "cover",
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
  },
  icon100: {
    height: 100,
    width: 100,
    display: "block",
    backgroundSize: "contain",
  },
  icon16: {
    height: 16,
    minWidth: 16,
    display: "block",
    backgroundSize: "contain",
  },
  userIconDiv: {
    minWidth: 32,
    height: 32,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    backgroundColor: "#e7e7e7",
  },
  amountPayable: {
    screen: ({ width }) => {
      if (width <= 374)
        return {
          fontSize: "16px",
        };
    },
  },
  datePickerFieldLabel: {
    left: 12,
  },

  shadowBox: {
    boxShadow: "0px 0px 6px #00000033",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  },

  statusIconHolder: {
    backgroundColor: "#F1F1F1",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#d6d6d6",
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    paddingTop: 3,
    paddingRight: 6,
    paddingBottom: 3,
    paddingLeft: 6,
  },
  statusIcon: {
    height: 10,
    width: 10,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    marginRight: 3,
    display: "block",
  },
  nowrap: {
    left: 296,
    textIndent: -285,
  },
  marginTopBottom40: {
    marginTop: 40,
    marginBottom: 40,
  },

  userInitial: {
    color: "#333",
    // whiteSpace: "normal",
  },

  whiteBox: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 6px #00000033",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 2,
  },
  declineIcon: {
    height: 13,
    width: 15,
    marginTop: 0,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
    backgroundSize: "cover",
    display: "inline-block",
  },
  notificationHolder: {
    // flex: 1,
    // alignItems: "flex-start",
    position: "relative",
    paddingTop: 5
  },
  notificationSection: {
    backgroundColor: "#C63F9B",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 6,
    paddingTop: 0,
    paddingRight: 3,
    paddingBottom: 0,
    paddingLeft: 3,
    lineHeight: 10,
    position: "absolute",
    top: 0,
    left: 11,
    // marginTop: -5,
    // marginRight: 0,
    // marginBottom: 0,
    // marginLeft: -7,
  },
  notificationCountPerMsg: {
    backgroundColor: "#C63F9B",
    paddingTop: 0,
    paddingRight: 3,
    paddingLeft: 3,
    lineHeight: 10,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    height: 20,
    width: 20,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0,
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
  },
  mView: {
    display: "block",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          display: "none",
        };
      }
    },
  },
  webView: {
    display: "none",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          display: "block",
        };
      }
    },
  },
  wView: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          display: "none",
        };
      }
    },
  },
  wordBreakAll: {
    wordBreak: "break-all",
  },
  wordBreakWord: {
    wordBreak: "break-word",
  },
  facebookColor: {
    color: "#4267B2",
  },
  marginRight4: {
    marginRight: 4,
  },
  borderRadius50: {
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
  },
  pageTitle: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
          paddingTop: 12,
          paddingRight: 16,
          paddingBottom: 12,
          paddingLeft: 16,
          margin: 0,
        };
      }
    },
  },
  copyRightSection: {
    marginTop: "auto",
    textAlign: "center",
    // marginBottom: 16,
    position: "relative",
    zIndex: 999,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // display: "none",
          margin: 0,
          // visibility: "hidden",
        };
    },
  },
  dashboardScrollView: {
    // backgroundColor: "#F4F4F4",
    transition: "all 0.3s ease",
    zIndex: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // backgroundColor: "#FFFFFF",
          // marginBottom: 56,
          minHeight: 450,
        };
    },
  },
  openMenu: {
    marginLeft: 180,
    screen: ({ width }) => {
      if (Platform.isPad && width <= 1024)
        return {
          marginLeft: 1,
        };
      if (width <= 991)
        return {
          marginLeft: 1,
        };
    },
  },
  closeMenu: {
    marginLeft: 45,
    screen: ({ width }) => {
      if (Platform.isPad && width <= 1024)
        return {
          marginLeft: 1,
        };
      if (width <= 991)
        return {
          marginLeft: 1,
        };
    },
  },
  leftMinus12: {
    left: -12,
  },
  left0: {
    left: 0,
  },
  helperTextFontStyle: {
    fontSize: 10,
    fontWeight: 400,
    width: "100%",
    color: 'rgb(176, 0, 32)',
  },
  pointerEventsNone: {
    pointerEvents: "none",
  },
  formLabelStyle: {
    backgroundColor: "#fff",
    opacity: 1,
  },
  flexGrow0: {
    flexGrow: 0,
  },
  displayNoneInMobView: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "none",
        };
      }
    },
  },
  positionRelative: {
    position: "relative",
  },
  lineHeight16: {
    lineHeight: 16,
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  primaryButton: {
    backgroundColor: "#d71996",
    paddingTop: 9,
    paddingRight: 8,
    paddingBottom: 9,
    paddingLeft: 8,
    borderRadius: 4,
    height: 24,
  },
  primaryBlueButton: {
    backgroundColor: "#1b63b5",
    paddingTop: 9,
    paddingRight: 8,
    paddingBottom: 9,
    paddingLeft: 8,
    borderRadius: 4,
    height: 24,
  },
  loginButton: {
    width: "100%",
    backgroundColor: "#d71996",
    border: 0,
    textTransform: "none",
    borderRadius: 4,
    height: 38,
  },

  titleTextColor: {
    color: "#182832",
  },
  rowPerPage: {
    marginLeft: 0,
    marginRight: 32,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display:"none",
          marginRight: 16,
        };
    },
  },
  displayingItem: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 8,
          marginBottom: 8,
        };
    },
  },
  paginationInnerWrap: {
    screen: ({ width }) => {
      if (width >= 991)
        return {
          marginLeft: "auto"
        };
    },
  },  
  paginationWrap: {
    borderStyle: "solid",
    borderTopWidth: 1,
    borderColor: "#dadada",
    marginTop: "auto",
    marginBottom: 0,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          marginBottom: 130,
          // paddingTop: 20,
          // paddingLeft: 10,
          // paddingRight: 10,
          alignItems: "center",
          justifyContent:"space-between"
        }
      }
    }
  },

  // Common classes

  addInsuranceAlertText: {
    marginTop: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    textAlign: "center",
  },
  addInsuranceAlertOkBtn: {
    marginTop: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    minWidth: 108,
    flexBasis: "48%",
    paddingRight: 16,
    paddingLeft: 16,
  },


  // Css for Modal popup

  modalWrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modalBodyWrapper: {
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#a7a7a7",
    padding: 15,
    flexGrow: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    position: "relative",
    minWidth: 300,
    minHeight:150
  },
  modalHeader: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    width: "100%", // Newly added
    position: "relative",
  },
  heading: {
    flex: 1,
    padding: 0,
  },
  modalContent: {
    alignItems: "center",
    flexGrow: 1,
    marginBottom:25,
    marginTop: 10
  },
  innerContent: {
    height: "auto", 
  },

  // Modal popup size 

  smallModalBodyWrapper: {
    width: 300,
  },
  mediumModalBodyWrapper: {
    width: 400,
    screen: ({ width }) => {
      if (width >= 320 && width <= 359) {
        return {
          width: "100%",
          maxWidth: 300,
        };
      }
      if (width >= 360 && width <= 991) {
        return {
          width: "90%",
          maxWidth: 400,
          minWidth: 320,
        };
      }
    },
  },
  largModalBodyWrapper: {
    width: "73vw", // need to be deifne in customCommonStles.web and .native for vw
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  exLargModalBodyWrapper: {
    width: "98vw", // need to be deifne in customCommonStles.web and .native for vw
    maxWidth: 500
  },
  ex: {
    minHeight: 500,
  borderRadius: 6,
  screen: ({ width }) => {
    if (width <= 991)
      return {
        width: 300,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        fontWeight: 600,
      };
    },
  },
  smallModalBtnWrapper: {
    marginTop: 24,
  },
  socialLoginText: {
    fontSize: 14,
  },

  logoImage: {
    height: 55,
    minHeight: 55,
    width: 150,
    marginTop: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    flex: 1,
    backgroundPosition:"center"
  },
  passwordFieldContainer: {
    width: "100%",
    // position: "relative",
  },
  hideEyeIconWrap: {
    position: "absolute",
    height: 30,
    width: 30,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    top: 8,
    right: 49,
    // marginTop: 5,
  },
  confirmPasswordhideEyeIconWrap: {
    position: "absolute",
    height: 30,
    width: 30,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    top: 8,
    right: 9,
    // marginTop: 5,1
  },
  backgroundImageHideIcon: {
    width: 30,
    height: 30,
    flex: 1,
    position: "relative",
    // top: 7,
    // right: "-7px",
  },
  labelDetails: {
    color: "#182832"
  },
  copyRightWrapper: {
    zIndex: 999
  },
  textDecorationUnderline: {
    textDecorationLine: "underline",
  },
  blueLinkColor: {
    color: "#38c2ef",
  },
  marginTopMinus20:{
    marginTop:-20
  },
  marginTopMinus8:{
    marginTop:-8
  },
  marginTopMinus15:{
    marginTop:-15
  },
  blackTextColor: {
    color: "#182832"
  },
  primaryTextColor: {
    color: "#fff",
  },
  contentColor: {
    color: "#182832"
  },
  tableKey: {
    fontSize: 12,
    fontWeight: 500
  },
  tableValue: {
    fontSize: 11,
    fontWeight: 400,
    paddingTop:5,
    paddingBottom:5
  },
  disabledInput: {
    opacity: 0.5,
    pointerEvents: "none"
  },
  maxWidth100: {
    maxWidth: "100%"
  },
  btnHeight24: {
    height: 24,
    minHeight: 24
  },
  secondayWhiteBtn: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#1277d9",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
    height:24,
    backgroundColor: "#fff",
  },
  secondayWhiteBtnText: {
    color: "#1277d9",
    fontWeight: 500,
    fontSize: 13,
  },
  primaryBtn: {
    backgroundColor: "#d71996",
    color: "#fff",
    width: 108,
    borderWidth: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  primaryBtnText: {
    color: "#fff",
    fontWeight: 500,
    fontSize: 13
  },
  bottomMinus18: {
    bottom: -18
  },
  chartContainer:{
    width: "45%",
  },
  btnWidth108: {
    width: 108
  },
  height15:{
    height: 15
  },
  height32:{
    height: 32
  },
  secondaryBtn: {
    backgroundColor: "#1277d9",
  },
  disabledBtn: {
    opacity: 1,
    backgroundColor: "#f4f4f4",
  },
  disabledBtnText: {
    color: "#888888",
  },
  widthAuto: {
    width: "auto",
  },
  bgTransparent: {
    backgroundColor: "transparent",
  },
  disable: {
    opacity: 1,
    pointerEvents: "none",
    backgroundColor: "#f4f4f4",
    cursor: "auto",
    borderColor: "#f4f4f4"
  },
  disableText: {
    color: "#888888",
  },

});

export { commonStyles };
