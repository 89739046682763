import React from "react";
import R14, {
  View,
  Text,
  StyleSheet,
  SelectMenuField,
  TextInputField,
  Platform,
  Checkbox,
  Image,
  Touchable
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import ReactDatePicker from "./ReactDatePicker"
import DatePicker from "./DatePicker"
import { CommonString } from "../../constant/appCommonText";
const plusSolidIcon = require("../../assets/images/plus_solid.png");

export default R14.connect(
  class TreatmentAddressComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        alertMessage: ""
      };
    }

    render() {
      const { isUberDocs, selectedTreatedAt, taxIdNumber } = this.props;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <View style={[commonStyles.marginTopMinus15]}>
          {/* TreateMent Details & Super Bill Check Box */}
          <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, styles.addIncProTextWrap, this.props.isSuperBill && styles.marginBottom32, this.props.isEditOrAddCpt && styles.displayNone]}>
            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis49, commonStyles.flexDirectionRow, styles.treatmentDetails]}>
              <View style={[commonStyles.marginTop5]}>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.heading5,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}
                >Treatment Details</Text>
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentCenter, commonStyles.flexGrow0, styles.marginRight12, styles.superBiller]}>
                <Checkbox
                  name="isSuperBill"
                  label={CommonString.SuperBillLabel}
                  value={this.props.isSuperBill}
                  colorBlank="#585858"
                  colorMarked="#4dc5f2"
                  onPress={() => this.props.toggleIsSuperBill()}
                // disabled={!this.props.isEditInsurance && !this.props.isAddInsurance}
                />
                <View style={[]}>
                  <Text
                    style={[
                      commonStyles.fontWeight500,
                      commonStyles.smallHeading,
                      commonFontStyles.workSans                      
                    ]}
                  >Super Bill Exists</Text>
                </View>
              </View>
            </View>
          </View>
          {/* Provider Text box */}
          <View style={[commonStyles.flex1, styles.customflexDirection, styles.inputHolder, styles.customAlignItemsStart, commonStyles.justifyContentSpaceBetween, this.props.isSuperBill && styles.displayNone, this.props.isEditOrAddCpt && styles.displayNone, commonStyles.alignItemsStart, commonStyles.marginTop10]}>
            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.extraRightMargin,]}>
              <SelectMenuField
                name="providerName"
                label={CommonString.ProviderLabel + "* "}
                // required={"Select Provider Name"}
                items={this.props.providers}
                onValueChange={this.props.onProviderSearch}
                searchable
                itemLoader={async ({ search }) => {
                  return await this.props.onSearchProvider(search);
                }}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
              {this.props.isProviderNameError || !!taxIdNumber ? (
                <View style={[styles.mins30Top]}>
                  <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle, !!taxIdNumber ? commonStyles.lightGrayColor : null]} >{!!taxIdNumber ? `Tax ID: ${taxIdNumber}` : "Select Provider"}</Text>
                </View>
              ) : null}
            </View>
            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis49, commonStyles.customFlexDirection, styles.treatmentDetails]}>
              <View style={[styles.customMarginTop10, styles.width49]}>
                <View
                  style={[commonStyles.marginBottom30, styles.addInsuranceProvider]}>
                  <View style={[
                    commonStyles.flex1,
                    commonStyles.alignItemsCenter,
                    commonStyles.flexDirectionRow
                  ]}>
                    <Touchable onPress={() => this.props.alertOk(CommonString.AddProviderError)} style={styles.plusIcon}>
                      <Image
                        source={plusSolidIcon}
                        style={[commonStyles.icon18]}
                      />
                    </Touchable>
                    <Touchable style={[]} onPress={() => this.props.alertOk(CommonString.AddProviderError)}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight400,
                          commonStyles.smallHeading,
                          styles.custompaddingLeftRight,
                        ]}
                      >
                        Add Provider
                      </Text>
                    </Touchable>
                  </View>
                </View>
              </View>
              {Platform.OS === "web" ? <>
                <View style={[commonStyles.fullWidth, styles.customMarginBottom30, commonStyles.displayNone]} >
                  {this.props.showDatePicker ? <ReactDatePicker
                    name={"patientTreatedOn_copy"}
                    value={this.props.form && this.props.form.values && this.props.form.values.patientTreatedOn ? this.props.form.values.patientTreatedOn : ""}
                    mode={"modal"}
                    onValueChange={this.props.onDateValueChange}
                    onBlur={this.props.onDateBlur}
                    visible={this.props.showDatePicker}
                    closeDatePicker={this.props.handleShowHideDatePicker}
                    isUberDocs={isUberDocs}
                  /> : null}
                  <TextInputField
                    name={"patientTreatedOn_copy"}
                    label={CommonString.PatientTreatedOnLabel}
                    secure={false}
                    readOnly={true}
                    onFocus={() => this.props.handleShowHideDatePicker('open')}
                    icon="calendar"
                    onIconPress={() => this.props.handleShowHideDatePicker()}
                    iconRight={this.props.form && this.props.form.values.patientTreatedOn ? "close" : ""}
                    onIconRightPress={this.props.onClearDate}
                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, commonStyles.marB0]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                    helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                  />
                </View>
              </>
                : <>
                  <DatePicker
                    style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.marB0, customCommonStyles.customBobInputField, customCommonStyles.inputHeight, commonStyles.displayNone]}
                    name={"patientTreatedOn_copy"}
                    label={CommonString.PatientTreatedOnLabel}
                    // mode={!!isEnterSSID ? "dropdown" : "modal"}
                    // textInputEditable={!!isEnterSSID}
                    mode={"dropdown"}
                    readOnly={true}
                    textInputEditable={false}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                    helperTextStyle={[styles.labelStyle, commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    onValueChange={this.props.onDateValueChange}
                    onBlur={this.props.onDateBlur}
                  />
                  {Platform.OS !== "web" &&
                    <TextInputField
                      name={"patientTreatedOn_copy"}
                      label={CommonString.PatientTreatedOnLabel}
                      secure={false}
                      disabled={true}
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, commonStyles.displayNone]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                    />
                  }
                </>}
            </View>
          </View>
          {/* Patient Treated At */}
          <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, styles.addIncProTextWrap, this.props.isSuperBill && styles.displayNone, this.props.isEditOrAddCpt && styles.displayNone]}>
            <View style={[styles.leftInputHolder, commonStyles.flexBasis48, styles.minMax49Width]}>
              <SelectMenuField
                name="patientVisitType"
                label={"Visit Type"}
                onValueChange={this.props.onTreatedAtChange}
                searchable
                itemLoader={async ({ search }) => await this.props.getVisitTypesList(search)}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                style={[customCommonStyles.inputHeight]}
              />
                {this.props.accountHolderError ? (
                  <View style={[styles.accountTypeErrorLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >{CommonString.SelectVisitType}</Text>
                  </View>
                ) : null}
            </View>
            <View
              style={[
                styles.rightInputHolder,
                commonStyles.flexBasis50,
                commonStyles.customFlexDirection,
                styles.minMax49Width,
                (selectedTreatedAt.value === "12" && !!isUberDocs) ? styles.displayNone : null,
              ]}>
              <SelectMenuField
                name="patientTreatedAt"
                label={CommonString.PatientTreatedAtLabel}
                items={this.props.isProviderTreatedAddresses}
                searchable
                itemLoader={async ({ search }) => {
                  let items = this.props.isProviderTreatedAddresses;
                  return items
                    .filter((item) => {
                      return item.label.toLowerCase().includes(search.toLowerCase());
                    })
                    .sort((a, b) => b.label - a.label);
                }}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                style={[customCommonStyles.inputHeight]}
              />
              <TextInputField
                name={"patientOthTreatedAt"}
                label={CommonString.PatientTreatmentLabel}
                style={[styles.formFields, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, 1 == 2 ? null : commonStyles.displayNone]}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
              />
            </View>
          </View>
        </View>
      )
    }
  }
);
const styles = StyleSheet.create({
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customAlignItemsStart: {
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: null,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  treatmentDetails: {
    width: "100%",
    justifyContent: "space-between",
    flexGrow: 0,
    marginRight: 0,
  },
  custommarTop30: {
    marginTop: 30,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 0,
        };
    },
  },
  width49: {
    width: "30%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    }
  },
  marginBottom32: {
    marginBottom: 32
  },
  accountTypeContainer: {
    flex: 1,
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'rgba(21, 27, 38, 0.4)',
    borderStyle: 'solid',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 16,
    paddingRight: 16,
    position: 'relative',
    maxHeight: 48,
    minHeight: 48,
  },
  accountTypeLabel: {
    position: 'absolute',
    top: -15,
    left: 8,
    backgroundColor: 'white',
    paddingLeft: 5,
    paddingRight: 5
  },
  radioButtonsOuter: {
    marginRight: 5,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  accountTypeErrorLabel: {
    marginLeft: 10,
    marginTop: 2
  },
  flexBasis100: {
    flexBasis: "100%"
  },
  minMax49Width: {
    minWidth: '49%',
    maxWidth: '50%',
    width: '49%',
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: '100%',
          maxWidth: '100%',
        };
      }
    }
  },
  marginRight12: {
    marginRight: 12,
    marginLeft: "auto"
  },
  customflexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  inputDisable: {
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: 0.5,
  },
  mins30Top: {
    marginTop: -34,
    marginLeft: 0,
    marginBottom: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -30,
          marginBottom: 16,
        };
      }
    },
  },
  addInsuranceProvider: {
    // backgroundColor: "#e2e2e2",
    alignItems: "center",
    // borderRadius: 20,
    // paddingTop: 2,
    // paddingRight: 4,
    // paddingBottom: 2,
    // paddingLeft: 4,
    // border: "1px solid #333",
    // marginTop: 14,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "inline-block"
        };
      }
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  custompaddingLeftRight: {
    paddingTop: 0,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 5,
  },
  marginTop36: {
    marginTop: 36
  },
  customHeight: {
    screen: ({ width }) => {
      if (width <= 1024) {
        return {
          maxHeight: 60
        };
      }
    },
  },
  extraRightMargin: {
    maxWidth: "49%",
    screen: ({ width }) => {
      if (width <= 1024) {
        return {
          maxWidth: "100%",
          width: "100%"
        };
      }
    },
  },
  superBiller: {
    minWidth: 150,
    alignItems: 'center',
  },
  customMarginTop10: {
    marginTop: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -10
        };
      }
    },
  },
  customMarginBottom30: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 30
        };
      }
    },
  }
});
