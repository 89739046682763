import React from "react";
import R14, {
  Form,
  View,
  Text,
  StyleSheet,
  Touchable,
  SelectMenuField,
  Image,
  TextInputField,
  Button,
  SubmitButton,
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import CommonValidators from "../utils/CommonValidators";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import FileUploadComponent from "../components/FileUploadComponent";
import { CommonString } from "../constant/appCommonText";
import { MESSAGE_TYPE } from "../constant/app.data.constant";
import PopupModalComponent from "../components/PopupModalComponent";
import { analyticsLogs } from "../utils/AnalyticLogs";

const deleteIcon = require("../assets/images/delete.png");
const attachmentIcons = require("../assets/images/icAttachment.png");

export default R14.connect(
  class CreatingMessageScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        attachments: [],
        isDisable: false,
        isErrorPopUp: false,
        errorPopUpMessage: "",
        senderPid: props.senderPid,
        isMobileDevice: props.isMobileDevice
      };
      this.form = this.props.app.ui.form("creatingMessage");
      this.child = React.createRef()
    }

    componentDidMount() {
      this.form = this.props.app.ui.form("creatingMessage");
    }

    alertOk = async () => {
      this.setState({
        isErrorPopUp: false,
        errorPopUpMessage: ''
      })
    };

    renderAlertPopup() {
      const { isErrorPopUp, errorPopUpMessage } = this.state;
      return (
        <PopupModalComponent
          visibility={isErrorPopUp}
          bodyWrapper={[commonStyles.smallModalBodyWrapper]}
          messageType={MESSAGE_TYPE.error}
          headingIcon={true}
          heading={"Error"}
          modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
          bodyContent={errorPopUpMessage}
          okLabel={"Ok"}
          handleOk={() => this.alertOk(errorPopUpMessage)}
          closeLabel={""}
          handleClose={null}
        />
      );
    }
 
    handleSubmit = async (form) => {
      this.setState({ isDisable: true });
      try {
        const { billerName, subject, messageBody } = form.values;
        const { attachments } = this.state;
        let biller = await this.props.app.dm.rest.billerBUdetails(billerName.value);
        const data = {
          subject,
          messageBody,
          recipientName: billerName.label,
          attachments: !!attachments.length ? attachments : null,
        };
        let rtpPayload = await this.props.app.dm.simpleMessage.getMessagePayload({ ...data, ...biller });
        let res = await this.props.app.dm.simpleMessage.postSimpleMessage(rtpPayload);
        if (res && !!res.threadId) {
          analyticsLogs(CommonString.CREATE_NEW_MESSAGE, `${CommonString.MESSAGE_SENT}`, this.props.app);
          this.props.app.dm.commonDomain.showToastr("success", CommonString.MessageSent);
          let payload = await this.props.app.dm.simpleMessage.getMsgPayloadr14(rtpPayload);
          await this.props.app.dm.simpleMessage.saveMessage(payload);
          this.props.app.nav.to("simpleMessageRoute");
        } else {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.MessageFail);
          this.setState({ isDisable: false });
        }
      } catch (error) {
        this.props.app.dm.commonDomain.showToastr("error", error);
        this.setState({ isDisable: false });
      }
      return false;
    };

    onFileInputChange = async (val) => {
      let x = [val];
      const { attachments } = this.state;

      let existingFiles = attachments.filter((value) => value.filename === val.filename && value.actualsize === val.actualsize);
      if (existingFiles.length > 0) {
        await this.child.current.wrappedInstance.pullErrorText("File has been already added.");
        return false;
      }

      let filesSize = attachments.reduce((prevVal, val) => prevVal + val.actualsize, 0);
      filesSize = filesSize + val.actualsize;
      if (filesSize / 1024 / 1024 > 2) {
        await this.child.current.wrappedInstance.pullErrorText("Maximum 2MB file size allowed.");
        return false;
      }

      if (attachments.length > 0) {
        await this.child.current.wrappedInstance.pullErrorText("Maximum 1 attachment allowed.");
        return false;
      }
      analyticsLogs(CommonString.CREATE_NEW_MESSAGE, `${CommonString.ATTACHMENT_ADDED}`, this.props.app);
      this.setState({attachments: [...attachments, ...x] });
    };

    onDeleteFile = (ind) => {
      analyticsLogs(CommonString.CREATE_NEW_MESSAGE, `${CommonString.ATTACHMENT_REMOVED}`, this.props.app);
      const { attachments } = this.state;
      this.setState({
        attachments: attachments.filter((value, index, arr) => index !== ind),
      });
    };

    onBillerPid = (val) => {
      const { label, value } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        // this.form.elmts.recipientPid.setValue(null);
        this.form.elmts.billerName.setValue(null);
      } else {
        this.form.elmts.billerName.setValue({ label: value, value: label });
      }
      // this.form.elmts.recipientPid.setError(null);
      this.form.elmts.billerName.setError(null);
      this.form.elmts.subject.setValue(null);
      this.form.elmts.messageBody.setValue(null);
    };

    onBillerName = (val) => {
      const { label, value } = val;
      this.form = this.props.app.ui.form("creatingMessage");
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        // this.form.elmts.recipientPid.setValue(null);
        this.form.elmts.billerName.setValue(null);
        // return false;
      } else {
        this.form.elmts.billerName.setValue({ label: label, value: value });
      }
      this.form.elmts.billerName.setError(null);
      // this.form.elmts.recipientPid.setError(null);
      this.form.elmts.subject.setValue(null);
      this.form.elmts.messageBody.setValue(null);
    };

    onCancel = () => {
      analyticsLogs(CommonString.CREATE_NEW_MESSAGE, `${CommonString.CLICKED_ON_CANCEL_BUTTON}`, this.props.app);
      this.props.app.nav.to("simpleMessageRoute");
    };

    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.messageBody.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.messageBody.setError("Character Limit Exceeds 140 Characters");
      }
    };

    readTextForSubject = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.subject.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.subject.setError("Character Limit Exceeds 140 Characters");
      }
    };

    render() {
      const { attachments, senderPid, isMobileDevice } = this.state;

      let { billerNameList } = this.props;
      billerNameList = [...new Map(billerNameList.map(buUser => [buUser["buId"], buUser])).values()];
      billerNameList = [...new Map(billerNameList.map(buUser => [buUser["billerRef"], buUser])).values()];
      return (
        <>
          <View style={[commonStyles.flex1, commonStyles.flexGrow1, commonStyles.marginTop15, styles.creatingMessageScreenInnerWrap]}>
            <Form
              name="creatingMessage"
              style={[commonStyles.flex0, commonStyles.flexGrow0, isMobileDevice ? styles.createMessageForm : null, styles.customCreateMessageForm]}
              validateBeforeSubmit={true}
              validators={CommonValidators.getFormValidator()}
              // controlsBottomRight={[]}
              onSubmit={this.handleSubmit}>
              <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                <View style={[styles.leftInputField]}>
                  <SelectMenuField
                    style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.marginBottom25]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    name="billerName"
                    label={CommonString.BillerBULabel + "*"}
                    required={CommonString.SelectBillerBURequired}
                    items={billerNameList}
                    onValueChange={this.onBillerName}
                    searchable
                    itemLoader={async ({ search }) => {
                      let items = billerNameList;
                      return items
                        .filter((item) => {
                          return item.label.toLowerCase().includes(search.toLowerCase());
                        })
                        .sort((a, b) => b.label - a.label);
                    }}
                    disabled={senderPid ? true : false}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                    helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
                <View style={[styles.rightInputField]}>
                  <TextInputField
                    name="subject"
                    label={CommonString.SubjectLabel + "*"}
                    required={CommonString.EnterSubjectRequired}
                    validator="cmtsVali"
                    onChangeText={this.readTextForSubject}
                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
              </View>
              <View>
                <View style={commonStyles.marginBottom25}>
                  <TextInputField
                    name="messageBody"
                    label={CommonString.EnterMessageLable}
                    required={CommonString.EnterMessageRequired}
                    multiline
                    validator="cmtsVali"
                    onChangeText={this.readText}
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.heading6,
                      styles.commentsField,
                      commonStyles.margin0,
                      commonStyles.fullWidth,
                    ]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, styles.top15]}
                    helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
                <View>
                  <FileUploadComponent
                    ref={this.child}
                    maxFiles={1}
                    source={"XBP"}
                    labelText={
                      <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.marginBottom10, commonStyles.flexDirectionRow]}>
                        <View style={[commonStyles.marginRight5, styles.addattachmentBtnWrap]}>
                          <Image source={attachmentIcons} style={[commonStyles.icon18]} />
                        </View>
                        <View>
                          <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight500]}>Add Attachment</Text>
                        </View>
                      </View>}
                    fileTypes={CommonString.XBPMessageFileType}
                    fileTypeLabel={CommonString.XBPMessageFileTypeLabel}
                    onValueChange={this.onFileInputChange}
                    fileInputStyle={[commonStyles.margin0Auto]}
                    attachmentFieldOuterCustomHeight={
                      styles.attachmentFieldOuterCustomHeight
                    }
                    showErrorInModal={true}
                  />
                  <View style={[commonStyles.dFlex]}>
                    <View style={commonStyles.flexGrow1}>
                      {attachments && !!attachments.length
                        ? attachments.map((el, i) => (
                          <View key={`atta_${i}`}>
                            <View style={[commonStyles.dFlex, commonStyles.borderBottomSpace10]}>
                              <View>
                                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight500, commonStyles.dBlock]}>
                                  {el.filename && el.filename.length > 28 ? `${el.filename.substring(0, 25)}...` : el.filename}
                                </Text>
                                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.lightGray]}>
                                  {el.size}
                                </Text>
                              </View>
                              <Touchable  style={[commonStyles.marLAuto,commonStyles.flex1, commonStyles.alignItemsEnd, commonStyles.justifyContentFlexEnd]} onPress={() => this.onDeleteFile(i)}>
                                <View>
                                  <Image source={deleteIcon} style={commonStyles.icon20} />
                                </View>
                              </Touchable>
                            </View>
                          </View>
                        ))
                        : null}
                    </View>
                  </View>
                </View>
              </View>
              <View style={[commonStyles.marTAuto, commonStyles.flex1, styles.formBtns, customCommonStyles.createMessageFormBtn]}>
                <View style={[styles.formBtnsHolder, commonStyles.marginRight10]}>
                  <Button
                    title={"Cancel"}
                    style={[commonStyles.blueBorderButton, commonStyles.fullWidth, customCommonStyles.noBoxShadow]}
                    titleTextStyle={[
                      commonStyles.blueBorderButtonColor,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                    ]}
                    onPress={this.onCancel}
                  />
                </View>
                <View style={[styles.formBtnsHolder, commonStyles.marginLeft10]}>
                  <SubmitButton
                    title={"Submit"}
                    style={[commonStyles.blueButton, commonStyles.fullWidth, customCommonStyles.noBoxShadow,]}
                    titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                  />
                </View>
              </View>
            </Form>
          </View>
          {this.renderAlertPopup()}
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  creatingMessageScreenInnerWrap: {
    backgroundColor: "#fff",
    marginTop: 16,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  },
  bodyWrapper: {
    width: 311,
    padding: 15,
    screen: ({ width }) => {
      if (width <= 359)
        return {
          width: 300,
        };
    },
  }, 
  commentsField: {
    minHeight: 128,
    maxHeight: 128,
    resize: "none",
    lineHeight: 15,
    paddingTop: 5,
  },   
  leftInputField: {
    flexBasis: "50%",
    marginRight: 8,
    maxWidth: "50%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexBasis: "100%",
          marginRight: 0,
          maxWidth: "100%",
        };
    },
  },
  rightInputField: {
    flexBasis: "50%",
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexBasis: "100%",
          marginLeft: 0,
        };
    },
  },
  formBtns: {
    justifyContent: "flex-end",
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          justifyContent: "center",
        };
    },
  },
  formBtnsHolder: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexBasis: "50%",
        };
    },
  },
  createMessageForm: {
    marginBottom: 60,
  }, 
  customHelperTextStyle: {
    left: 0,
  }, 
  attachmentFieldOuterCustomHeight: {
    position: "relative",
    height: 60,
    // width: "6vw",
    minWidth: 55,
  },
  customCreateMessageForm: {
    display: "block",
    overflowX: "auto",
  },
  addattachmentBtnWrap: {
    backgroundColor: "rgb(224, 224, 224)",
    padding: 5,
    borderRadius: "100%",
  },
  top15: {
    top: 15
  }
});
