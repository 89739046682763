import React from "react";
import R14, {
    Form,
    View,
    Text,
    StyleSheet,
    Button,
    SubmitButton,
    TextInputField,
    Platform,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";
import CommonValidators from "../../utils/CommonValidators";
import DatePicker from "../../components/PCH/DatePicker";
import DropDown from "../../components/DropDown";
import DateUtils from "../../utils/DateUtils";

export default R14.connect(
    class ManagePaymentFilterComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                showFromDateOfService: false,
                showToDateOfService: false,
                showFromPaidDate: false,
                showToPaidDate: false,
            };
            this.form = null;
            this.insuranceItems = [];
        }

        componentDidMount = async () => {
            try {
                this.form = this.props.app.ui.form("managePaymentfilters");
                const {insurances} = this.props;
                if (!!insurances && insurances.length > 0) {
                    insurances.forEach((insurance) => {
                        this.insuranceItems.push({
                            label: insurance.name,
                            value: insurance.uid,
                            poBoxNo: insurance.poBoxNo,
                            address1: insurance.address1,
                            city: insurance.city,
                            state: insurance.state,
                            groupNumber: insurance.groupNumber
                        });
                    });
                    this.form.elmts.insuranceName.setItems(this.insuranceItems);
                };
            }
            catch (err) {
                console.log(err)
            }
        };

        onDiscard = async () => {
            this.form.reset();
            this.props.handleReset();
            this.setState({
                showFromDateOfService: false,
                showToDateOfService: false,
                showFromPaidDate: false,
                showToPaidDate: false,
            });
        };

        validateDate = async (testdate) => {
            let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
            return date_regex.test(testdate);
        };

        handleFromDateOfService = (val) => {
            if (val === "open" && this.state.showFromDateOfService) {
                return;
            } else if (val === "close") {
                this.setState({ showFromDateOfService: false });
                return;
            } else {
                this.setState({ showFromDateOfService: !this.state.showFromDateOfService })
            }
        }

        onFromDateOfServiceChange = async (val, autoformat = true) => {
            this.handleFromDateOfService();
            if (!!val) {
                if (!!autoformat) {
                    let date = DateUtils.formatDateInMMDDYYYY(val);
                    this.form.elmts.fromDateOfService.setError(null);
                    this.form.elmts.fromDateOfService.setValue(null);
                    this.form.elmts.fromDateOfService.setValue(date);
                }

            } else {
                this.setState({
                    showFromDateOfService: false,
                });
            }
        };

        onFromDateOfServiceBlur = async () => {
            const { fromDateOfService } = this.form.values;
            this.handleFromDateOfService();
            if (!!fromDateOfService) {
                let dat = await this.validateDate(fromDateOfService);
                if (!!dat) {
                    let formatedDate = DateUtils.formatDateInMMDDYYYY(fromDateOfService);
                    dat = await this.validateDate(formatedDate);
                    if (!!dat) {
                        this.form.elmts.fromDateOfService.setValue(formatedDate);
                        this.form.elmts.fromDateOfService.setError(null);
                        this.onFromDateOfServiceChange(fromDateOfService, false);
                    } else {
                        this.form.elmts.fromDateOfService.setValue(fromDateOfService);
                        this.form.elmts.fromDateOfService.setError(CommonString.DateFormatRequired);
                    }
                } else {
                    this.form.elmts.fromDateOfService.setValue(null);
                    this.form.elmts.fromDateOfService.setError(CommonString.DateFormatRequired);
                }
            } else {
                this.form.elmts.fromDateOfService.setError(null);
            }
        };

        onClearFromDateOfService = () => {
            this.form.elmts.fromDateOfService.setValue(null);
            this.form.elmts.fromDateOfService.setError(null);
            this.setState({
                showFromDateOfService: false,
            })
        }

        handleToDateOfService = (val) => {
            if (val === "open" && this.state.showToDateOfService) {
                return;
            } else if (val === "close") {
                this.setState({ showToDateOfService: false });
                return;
            } else {
                this.setState({ showToDateOfService: !this.state.showToDateOfService })
            }
        }

        onToDateOfServiceChange = async (val, autoformat = true) => {
            this.handleToDateOfService();
            if (!!val) {
                if (!!autoformat) {
                    let date = DateUtils.formatDateInMMDDYYYY(val);
                    this.form.elmts.toDateOfService.setError(null);
                    this.form.elmts.toDateOfService.setValue(null);
                    this.form.elmts.toDateOfService.setValue(date);
                }

            } else {
                this.setState({
                    showToDateOfService: false,
                });
            }
        };

        onToDateOfServiceBlur = async () => {
            const { toDateOfService } = this.form.values;
            this.handleToDateOfService();
            if (!!toDateOfService) {
                let dat = await this.validateDate(toDateOfService);
                if (!!dat) {
                    let formatedDate = DateUtils.formatDateInMMDDYYYY(toDateOfService);
                    dat = await this.validateDate(formatedDate);
                    if (!!dat) {
                        this.form.elmts.toDateOfService.setValue(formatedDate);
                        this.form.elmts.toDateOfService.setError(null);
                        this.onToDateOfServiceChange(toDateOfService, false);
                    } else {
                        this.form.elmts.toDateOfService.setValue(toDateOfService);
                        this.form.elmts.toDateOfService.setError(CommonString.DateFormatRequired);
                    }
                } else {
                    this.form.elmts.toDateOfService.setValue(null);
                    this.form.elmts.toDateOfService.setError(CommonString.DateFormatRequired);
                }
            } else {
                this.form.elmts.toDateOfService.setError(null);
            }
        };

        onClearToDateOfService = () => {
            this.form.elmts.toDateOfService.setValue(null);
            this.form.elmts.toDateOfService.setError(null);
            this.setState({
                showToDateOfService: false,
            })
        }

        handleFromPaidDate = (val) => {
            if (val === "open" && this.state.showFromPaidDate) {
                return;
            } else if (val === "close") {
                this.setState({ showFromPaidDate: false });
                return;
            } else {
                this.setState({ showFromPaidDate: !this.state.showFromPaidDate })
            }
        }

        onFromPaidDateChange = async (val, autoformat = true) => {
            this.handleFromPaidDate();
            if (!!val) {
                if (!!autoformat) {
                    let date = DateUtils.formatDateInMMDDYYYY(val);
                    this.form.elmts.fromPaidDate.setError(null);
                    this.form.elmts.fromPaidDate.setValue(null);
                    this.form.elmts.fromPaidDate.setValue(date);
                }

            } else {
                this.setState({
                    showFromPaidDate: false,
                });
            }
        };

        onFromPaidDateBlur = async () => {
            const { fromPaidDate } = this.form.values;
            this.handleFromPaidDate();
            if (!!fromPaidDate) {
                let dat = await this.validateDate(fromPaidDate);
                if (!!dat) {
                    let formatedDate = DateUtils.formatDateInMMDDYYYY(fromPaidDate);
                    dat = await this.validateDate(formatedDate);
                    if (!!dat) {
                        this.form.elmts.fromPaidDate.setValue(formatedDate);
                        this.form.elmts.fromPaidDate.setError(null);
                        this.onFromPaidDateChange(fromPaidDate, false);
                    } else {
                        this.form.elmts.fromPaidDate.setValue(fromPaidDate);
                        this.form.elmts.fromPaidDate.setError(CommonString.DateFormatRequired);
                    }
                } else {
                    this.form.elmts.fromPaidDate.setValue(null);
                    this.form.elmts.fromPaidDate.setError(CommonString.DateFormatRequired);
                }
            } else {
                this.form.elmts.fromPaidDate.setError(null);
            }
        };

        onClearFromPaidDate = () => {
            this.form.elmts.fromPaidDate.setValue(null);
            this.form.elmts.fromPaidDate.setError(null);
            this.setState({
                showFromPaidDate: false,
            })
        }

        handleToPaidDate = (val) => {
            if (val === "open" && this.state.showToPaidDate) {
                return;
            } else if (val === "close") {
                this.setState({ showToPaidDate: false });
                return;
            } else {
                this.setState({ showToPaidDate: !this.state.showToPaidDate })
            }
        }

        onToPaidDateChange = async (val, autoformat = true) => {
            this.handleToPaidDate();
            if (!!val) {
                if (!!autoformat) {
                    let date = DateUtils.formatDateInMMDDYYYY(val);
                    this.form.elmts.toPaidDate.setError(null);
                    this.form.elmts.toPaidDate.setValue(null);
                    this.form.elmts.toPaidDate.setValue(date);
                }

            } else {
                this.setState({
                    showToPaidDate: false,
                });
            }
        };

        onToPaidDateBlur = async () => {
            const { toPaidDate } = this.form.values;
            this.handleToPaidDate();
            if (!!toPaidDate) {
                let dat = await this.validateDate(toPaidDate);
                if (!!dat) {
                    let formatedDate = DateUtils.formatDateInMMDDYYYY(toPaidDate);
                    dat = await this.validateDate(formatedDate);
                    if (!!dat) {
                        this.form.elmts.toPaidDate.setValue(formatedDate);
                        this.form.elmts.toPaidDate.setError(null);
                        this.onToPaidDateChange(toPaidDate, false);
                    } else {
                        this.form.elmts.toPaidDate.setValue(toPaidDate);
                        this.form.elmts.toPaidDate.setError(CommonString.DateFormatRequired);
                    }
                } else {
                    this.form.elmts.toPaidDate.setValue(null);
                    this.form.elmts.toPaidDate.setError(CommonString.DateFormatRequired);
                }
            } else {
                this.form.elmts.toPaidDate.setError(null);
            }
        };

        onClearToPaidDate = () => {
            this.form.elmts.toPaidDate.setValue(null);
            this.form.elmts.toPaidDate.setError(null);
            this.setState({
                showToPaidDate: false,
            })
        }

        onInvalidTypeText = (txt, key, form = this.props.app.ui.form("managePaymentfilters")) => {
            return form.elmts[key].setValue(txt.slice(0, -1))
        }

        onInsuranceChange = async (val) => {
            const { label, value } = val;
            const form = this.props.app.ui.form("managePaymentfilters")
            if (label === "No Results Found" || label.includes("Type in a search value")) {
                form.elmts.insuranceName.setValue(null);
                return false;
            }
            form.elmts.insuranceName.setValue({ label: label, value: value });
            form.elmts.insuranceName.setError(null);
        };

        onSearchInsurance = async (val) => {
            const { insurances, userLoginDetail } = this.props;
            this.insuranceItems = [];
            if (!!insurances && insurances.length > 0) {
                insurances.forEach((insurance) => {
                    this.insuranceItems.push({
                        label: insurance.name,
                        value: insurance.uid,
                        poBoxNo: insurance.poBoxNo,
                        address1: insurance.address1,
                        city: insurance.city,
                        state: insurance.state,
                        groupNumber: insurance.groupNumber
                    });
                });
            };
            if (!!val) {
                this.insuranceItems = this.insuranceItems
                    .filter((item) => {
                        return item.label.toLowerCase().includes(val.toLowerCase());
                    }).sort((a, b) => b.label - a.label);

                let ins = await this.props.app.dm.addInsuranceDomain.getInsuranceOnSearch(userLoginDetail.uid, val);
                if (!!ins && ins.length > 0) {
                    ins.forEach((insurance) => {
                        this.insuranceItems.push({
                            label: insurance.name,
                            value: insurance.uid,
                            poBoxNo: insurance.poBoxNo,
                            address1: insurance.address1,
                            city: insurance.city,
                            state: insurance.state,
                            groupNumber: insurance.groupNumber
                        });
                    });
                };
            };
            this.insuranceItems = await this.filterItems(this.insuranceItems);
            this.form.elmts.insuranceName.setItems(this.insuranceItems);
            return this.insuranceItems;
        }

        filterItems = async (list) => {
            let filterArray = list.reduce((acc, current) => {
                const x = acc.find(item => item.value === current.value);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            return filterArray;
        }

        handleSubmit = async(form) => {
            await this.props.handleSubmitFilter(form);
            return false;
        }

        render() {
            let clientStyle = commonStyles;
            let commonFontStyles = fontFamilyStyles;
            return (
                <View style={[styles.filterContainer]}>
                    <View style={[styles.filterLabel]}>
                        <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans]} >Filter</Text>
                    </View>
                    <Form
                        name="managePaymentfilters"
                        style={[styles.uploadBillsForm, clientStyle.form]}
                        validateBeforeSubmit={true}
                        validators={CommonValidators.getFormValidator()}
                        onSubmit={this.handleSubmit}>
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.leftInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.customFlexDirection,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    commonStyles.marginBottom10
                                ]}>
                                <DropDown
                                    name="insuranceName"
                                    label={"Insurance"}
                                    onValueChange={this.onInsuranceChange}
                                    searchable={true}
                                    items={this.insuranceItems}
                                    itemLoader={async ({ search }) => {
                                        return await this.onSearchInsurance(search);
                                    }}
                                    // multiple={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                    style={[customCommonStyles.inputHeight, styles.width100, styles.minWidth100, commonFontStyles.workSans, commonStyles.heading6, commonStyles.marginBottom13]}

                                    onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "orgName")}
                                />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    styles.customMarginBottom30
                                ]}>

                                <TextInputField
                                    name="checkNumber"
                                    label="Check Number "
                                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                        </View>
                        {/* Date for service from-to */}
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, Platform.OS === "web" ? null : commonStyles.flexDirectionRow,]}>
                                <DatePicker
                                    name={"fromDateOfService"}
                                    form="managePaymentfilters"
                                    value={this.form && this.form.values && this.form.values.fromDateOfService}
                                    mode={"modal"}
                                    onValueChange={this.onFromDateOfServiceChange}
                                    onBlur={this.onFromDateOfServiceBlur}
                                    onFocus={this.onFromDateOfServiceBlur}
                                    visible={this.state.showFromDateOfService}
                                    closeDatePicker={this.handleFromDateOfService}
                                    isUberDocs={false}
                                    // for native
                                    noMinDate={true}
                                    textInputEditable={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                    helperTextStyle={[styles.dobLabelStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                />
                                <TextInputField
                                    name={"fromDateOfService"}
                                    label={"Date of Service - From "}
                                    secure={false}
                                    readOnly={true}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}

                                    onFocus={() => Platform.OS === "web" ? this.handleFromDateOfService('open') : null}
                                    icon={Platform.OS === "web" ? "calendar" : null}
                                    onIconPress={() => Platform.OS === "web" ? this.handleFromDateOfService() : null}
                                    iconRight={Platform.OS === "web" ? this.form && this.form.values && this.form.values.fromDateOfService ? "close" : null : null}
                                    onIconRightPress={() => Platform.OS === "web" ? this.onClearFromDateOfService() : null}

                                />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.flexDirectionColumn,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    commonStyles.alignItemsStart,
                                    styles.customMarginBottom30,
                                    Platform.OS === "web" ? null : commonStyles.flexDirectionRow,
                                ]}>
                                <DatePicker
                                    name={"toDateOfService"}
                                    form="managePaymentfilters"
                                    value={this.form && this.form.values && this.form.values.toDateOfService}
                                    mode={"modal"}
                                    onValueChange={this.onToDateOfServiceChange}
                                    onBlur={this.onToDateOfServiceBlur}
                                    visible={this.state.showToDateOfService}
                                    closeDatePicker={this.handleToDateOfService}
                                    isUberDocs={false}
                                    // for native
                                    noMinDate={true}
                                    textInputEditable={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                    helperTextStyle={[styles.dobLabelStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                />
                                <TextInputField
                                    name={"toDateOfService"}
                                    label={"Date of Service - To "}
                                    secure={false}
                                    readOnly={true}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100, commonStyles.maxWidth100, commonStyles.fullFlexContainer]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                                    onFocus={() => Platform.OS === "web" ? this.handleToDateOfService('open') : null}
                                    icon={Platform.OS === "web" ? "calendar" : null}
                                    onIconPress={() => Platform.OS === "web" ? this.handleToDateOfService() : null}
                                    iconRight={Platform.OS === "web" ? this.form && this.form.values && this.form.values.toDateOfService ? "close" : null : null}
                                    onIconRightPress={() => Platform.OS === "web" ? this.onClearToDateOfService() : null}
                                />

                            </View>
                        </View>
                        {/* Paid date from-to */}
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, Platform.OS === "web" ? null : commonStyles.flexDirectionRow,]}>
                                <DatePicker
                                    name={"fromPaidDate"}
                                    form="managePaymentfilters"
                                    value={this.form && this.form.values && this.form.values.fromPaidDate}
                                    mode={"modal"}
                                    onValueChange={this.onFromPaidDateChange}
                                    onBlur={this.onFromPaidDateBlur}
                                    onFocus={this.onFromPaidDateBlur}
                                    visible={this.state.showFromPaidDate}
                                    closeDatePicker={this.handleFromPaidDate}
                                    isUberDocs={false}
                                    // for native
                                    noMinDate={true}
                                    textInputEditable={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                    helperTextStyle={[styles.dobLabelStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                />
                                <TextInputField
                                    name={"fromPaidDate"}
                                    label={"Paid Date - From "}
                                    secure={false}
                                    readOnly={true}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}

                                    onFocus={() => Platform.OS === "web" ? this.handleFromPaidDate('open') : null}
                                    icon={Platform.OS === "web" ? "calendar" : null}
                                    onIconPress={() => Platform.OS === "web" ? this.handleFromPaidDate() : null}
                                    iconRight={Platform.OS === "web" ? this.form && this.form.values && this.form.values.fromPaidDate ? "close" : null : null}
                                    onIconRightPress={() => Platform.OS === "web" ? this.onClearFromPaidDate() : null}

                                />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.flexDirectionColumn,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    commonStyles.alignItemsStart,
                                    styles.customMarginBottom30,
                                    Platform.OS === "web" ? null : commonStyles.flexDirectionRow,
                                ]}>
                                <DatePicker
                                    name={"toPaidDate"}
                                    form="managePaymentfilters"
                                    value={this.form && this.form.values && this.form.values.toPaidDate}
                                    mode={"modal"}
                                    onValueChange={this.onToPaidDateChange}
                                    onBlur={this.onToPaidDateBlur}
                                    visible={this.state.showToPaidDate}
                                    closeDatePicker={this.handleToPaidDate}
                                    isUberDocs={false}
                                    // for native
                                    noMinDate={true}
                                    textInputEditable={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                    helperTextStyle={[styles.dobLabelStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                />
                                <TextInputField
                                    name={"toPaidDate"}
                                    label={"Paid Date - To "}
                                    secure={false}
                                    readOnly={true}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100, commonStyles.maxWidth100, commonStyles.fullFlexContainer]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                                    onFocus={() => Platform.OS === "web" ? this.handleToPaidDate('open') : null}
                                    icon={Platform.OS === "web" ? "calendar" : null}
                                    onIconPress={() => Platform.OS === "web" ? this.handleToPaidDate() : null}
                                    iconRight={Platform.OS === "web" ? this.form && this.form.values && this.form.values.toPaidDate ? "close" : null : null}
                                    onIconRightPress={() => Platform.OS === "web" ? this.onClearToPaidDate() : null}
                                />

                            </View>
                        </View>

                        <View style={[commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow]}>
                            <View style={[styles.formBtnsHolder]}>
                                <Button
                                    title={"Cancel"}
                                    style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.btnWidth108, styles.customRestMargin]}
                                    titleTextStyle={[commonStyles.secondayWhiteBtnText,commonStyles.exSmallHeading, commonFontStyles.workSans]}
                                    onPress={() => this.onDiscard()}
                                />
                                <SubmitButton
                                    title={"Search"}
                                    style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.customRestMargin, styles.customMarginLeft16]}
                                    titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, clientStyle.submitButtonText]}
                                />
                            </View>
                        </View>
                    </Form>
                </View>
            );
        }
    }
);
const styles = StyleSheet.create({
    leftInputHolder: {
        marginRight: 8,
        maxWidth: '49%',
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    maxWidth: '100%',
                    marginRight: 0,
                };
            }
        },
    },
    rightInputHolder: {
        marginLeft: 8,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 0,
                };
            }
        },
    },
    customFlexProperties: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: "auto",
                    // marginBottom: 30
                };
            }
            if (width >= 991) {
                return {
                    justifyContent: "flex-end",
                };
            }
        },
    },
    inputHolder: {
        marginBottom: 6,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexDirection: "column",
                };
            }
        },
    },
    formBtnsHolder: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexBasis: "100%",
                    marginBottom: 16,
                    marginTop: 16
                };
            }
        },
    },
    customAlignItemsStart: {
        alignItems: "baseline",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    alignItems: null,
                };
            }
        },
    },
    uploadBillsForm: {
        width: '100%',
        maxWidth: "100%",
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    flex: 1,
                    width: '100%',
                };
            }
            if (width <= 991) {
                return {
                    paddingTop: 10,
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingRight: 0,
                    //   width: "90%"
                };
            }
        },
    },
    incNameDropDown: {
        marginBottom: 24,
        screen: ({ width }) => {
            if (width >= 991)
                return {
                    maxWidth: "49%",
                };
        },
    },
    textAlign: {
        alignItems: "center",
        // height: "55%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginBottom: 0,
                    alignItems: "flex-start",
                };
            }
        },
    },
    width100: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    minWidth100: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minWidth: "100%",
                };
            }
        },
    },
    customMarginBottom30: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 15,
                };
            }
        },
    },

    filterContainer: {
        flex: 1,
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'rgba(21, 27, 38, 0.4)',
        borderStyle: 'solid',
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 16,
        paddingRight: 16,
        position: 'relative',
        marginBottom: 16,
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16,
        maxWidth: "100%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginBottom: 0,
                };
            }
        }
    },

    filterLabel: {
        position: 'absolute',
        top: -11,
        left: 8,
        backgroundColor: 'white',
        paddingLeft: 5,
        paddingRight: 5
    },
    marginBottom16: {
        marginBottom: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 32,
                };
            }
        },
    },
    marginTop10: {
        screen: ({ width }) => {
            if (width > 991) {
                return {
                    marginTop: 10,
                };
            }
        }
    },
    customMarginLeft16: {
        marginLeft: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 0,
                    marginRight: 0
                };
            }
        }
    },
    paymentStatusWrapper: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    paddingTop: 0,
                };
            }
        }
    },
    btnWidth108: {
        width: 108
    },
    customRestMargin: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginRight: 16,
                    flexBasis: "48%",
                };
            }
        }
    }
});
