import R14, { AsyncStorage } from "../core";
import { THREAD_LIST, GET_MSG_FROM_THREAD } from "../constant/app.constant";
import DateUtils from "../utils/DateUtils";
import { MSG_TYPE } from "../constant/app.data.constant";
import CommonUtility from "../utils/CommonUtility";
import { CommonString } from "../constant/appCommonText";
export default class DashboardDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      compiledMsgList: [],
      newBillCount: 0,
      overdueBillCount: 0,
      outstandingBillCount: 0,
      filterBills: [],
      isFilterOpen: false,
      headerSearchList: [],
      IsScannerVisible: false,
      sessionInActive: false,
      isSubscriptionValid: true,
      // PCH
      submittedBillCount: 0,
      lastReadDateTime: null,
      dateTimeHistorys: [],
      isBlockNavigation: false,
      messages: {},
      selectedScreen: "RTP"
    };
  }

  async getThreadList() {
    const { pid } = await this.dm.rest.getUser();
    if (!!pid) {
      let currentDate = new Date();
      let res = await this.getAllDateTimeHistory();
      let x = new Date(
        !!res.length && !!res[0].billLastDateTime ? res[0].billLastDateTime : `01/01/${currentDate.getFullYear()}`
      );
      x.setHours(x.getHours() - 5);
      x.toUTCString();
      let fromDt = parseInt((x.getTime() / 1000).toFixed());
      let nowDt = parseInt((currentDate.getTime() / 1000).toFixed());
      let threadIds = [];
      try {
        threadIds = await this.dm.rest.get(`${THREAD_LIST}?pageSize=1000&from=${fromDt}&to=${nowDt}`);
        return threadIds;
      } catch (error) {
        console.log("Thread List ::: ", error);
      } finally {
        if (!!threadIds && !!threadIds.threads && threadIds.threads.length > 0) {
          currentDate.toUTCString();
          let payload = { payerId: pid, billLastDateTime: currentDate };
          if (!!res.length && !!res[0].uid) {
            payload.uid = res[0].uid;
            const { dateTimeHistorys } = this.state;
            if (!!dateTimeHistorys && dateTimeHistorys.length > 0) {
              dateTimeHistorys[0].billLastDateTime = currentDate
              this.setState({
                dateTimeHistorys: dateTimeHistorys
              });
            }
            this.updateMsgDateHistory(payload);
          } else {
            await this.createDateTimeHistory(payload);
          }
        }
      }
    }
  }

  async findCorrectPId(threadId) {
    let pids = await this.dm.rest.getLinkedPID();
    pids = pids.split(',').find(x => threadId.indexOf(x) > -1);
    return pids;
  }

  async getBillerFromThread(threadId, reload = false, totalCount) {
    const { messages } = this.state;
    try {
      if (!!messages && !!messages[threadId] && !!!reload)
        return messages[threadId];
      else {
        let pid = await this.findCorrectPId(threadId);
        let msgDetails = await this.dm.rest.get(`${GET_MSG_FROM_THREAD}?isValidPAM=true`, { "thread-id": threadId, pid: pid }, totalCount);
        if (!!msgDetails) {
        // commented due to messageDateTime mismatch issue
        //   let msg = msgDetails.messages.sort(
        //     (a, b) => new Date(a.envelope.messageHeader.messageDateTime) - new Date(b.envelope.messageHeader.messageDateTime)
        //     //console.log(new Date(a.envelope.messageHeader.messageDateTime))
        //   );
          let msg = msgDetails.messages;
          msgDetails.messages = msg.filter(
            (message) =>
              !!message.envelope.messageBody &&
              (!message.envelope.messageBody.subMessageType ||
                message.envelope.messageBody.subMessageType === "NoteToPayer" ||
                message.envelope.messageBody.subMessageType === "NoteToBiller")
          );
        }

        let obj = {}
        obj[threadId] = msgDetails;
        this.setState({
          messages: obj
        })
        return obj[threadId];
      }
    } catch (error) {
      console.log("Messages  ::: ", error);
    }
  }

  async dashboardDataForDisplay(res) {
    const { threads } = res;
    let calls = []
    for (let el of threads) {
      calls.push(this.syncBills(el["thread-id"], threads.length));
    }
    await Promise.all(calls);
  }

  async syncBills(threadId, totalCount) {
    try {
      let res = await this.getBillerFromThread(threadId, true, totalCount);
      if (!!res && res.messages && res.messages.length > 0 && res.messages[0].envelope.messageBody && res.messages[0].envelope.threadHeader.profile.toLowerCase() !== "simplemessage") {
        let data = await this.payloadForGraphQl(this.manageBillDetails(res.messages));
        await this.createAndUpdateBill(data);
      }
    } catch (error) {
      console.log("Thread message ::: ", error);
    }
  }

  async getBillExtensionHistoryByBillId(billDetailId, fields = "uid rtpMessageType payerId billerId") {
    let options = {
      filter: {
        billDetailUid: { eq: billDetailId },
        // rtpMessageType:{eq: rtpMessageType}
      },
      page: 1,
      resultsPerPage: 1,
      sort: { field: "createdDate", order: "DESC" },
    };
    try {
      let res = await this.api.qry(
        `query BillExtentionHistorys($filter: BillExtentionHistoryFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          billExtentionHistorys(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            totalCount @include(if: true)
            nodes {
              ${fields}
            }
          }
      }`,
        options
      );
      return res.data.billExtentionHistorys.nodes;
    } catch (error) {
      return error;
    }
  }

  async createBillExtensionHistory(input, fields = "uid") {
    await CommonUtility.htmlDecode(input);
    try {
      this.api.mutate(`mutation CreateBillExtentionHistory($input: CreateBillExtentionHistoryInput) {
        createBillExtentionHistory(input: $input){
          billExtentionHistory {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
    } catch (error) {
    }
  }

  async addBillExtensionHistory(billDetail, data) {
    // add billHistroy. respective of bills
    //Commented 29/12/2022
    if ( 1 === 2 && ["ReqPayExtension", "ExtensionGranted", "ExtensionDeclined", "RequestToPay"].includes(data.rtpMessageType)) {
      let historyPayLoad = {
        payerId: data.payerId,
        billerId: data.billerId,
        billDetailUid: billDetail.uid,
        isExtentionRequested: data.isExtentionRequested,
        extentionRequestedDate: data.extentionRequestedDate,
        isExtentionApproved: data.isExtentionApproved,
        extentionApprovedDate: data.extentionApprovedDate,
        isExtentionDeclined: data.isExtentionDeclined,
        extentionDeclinedDate: data.extentionDeclinedDate,
        rtpMessageType: data.rtpMessageType,
        // createdDate: new Date().toUTCString()
      };
      await CommonUtility.htmlDecode(historyPayLoad);
      this.createBillExtensionHistory(historyPayLoad);
    }
  }

  addedNewBiller(data) {
    CommonUtility.htmlDecodeSanitize(data);
    const { messageBody, threadHeader, messageHeader } = data.envelope;
    const { messageDateTime, messageId, recipientPid, senderPid, senderName } = messageHeader;
    const { dueDate, amount, remittanceInformation, endToEndIdentification, transactionStatus, transactionId, messageType } = messageBody;
    const { threadId, threadDateTime, subject, originatorPid } = threadHeader;
    let paymentOption = {};
    if (!!messageBody.paymentOptions && messageBody.paymentOptions.length > 0) {
      let paymentOptions = messageBody.paymentOptions.filter((payment) => {
        return payment.creditorAccount
          && payment.creditorAccount.identification
          && payment.creditorAccount.identification['merchantType'] === "Stripe"
          && !!payment.creditorAgent && payment.creditorAgent.financialInstitutionId
          && !!payment.creditorAgent.financialInstitutionId["merchantAccountNumber"]
      });
      if (!!paymentOptions && paymentOptions.length > 0) {
        paymentOption.merchantAccountNumber = paymentOptions[0].creditorAgent.financialInstitutionId["merchantAccountNumber"];
      }
    }
    let isOverDueDate = CommonUtility.compareDueDate(dueDate);

    let filterThreads = {
      threadHeader,
      messageHeader,
      subject,
      threadId,
      endToEndIdentification,
      messageId,
      originatorPid,
      recipientPid,
      senderPid,
      senderName,
      transactionId,
      paymentOptions: paymentOption,
      dueDate: `${DateUtils.formatDate(dueDate)}`,
      threadDateTime: threadDateTime,
      messageDateTime: messageDateTime,
      billerName: threadId.split("#")[0],
      amount: amount.dueAmount.toFixed(2),
      currency: amount.currency,
      status: !!isOverDueDate ? "Overdue" : "New",
      rtpStatus: messageType,
      paidAmount: 0,
      shortName: CommonUtility.shortName(threadId.split("#")[0]),
      billingPeriod: remittanceInformation.structured
        ? `${DateUtils.formatDate(remittanceInformation.structured.billingPeriod.billingPeriodFrom)} - ${DateUtils.formatDate(
          remittanceInformation.structured.billingPeriod.billingPeriodTo
        )}`
        : null,
      sortCode: transactionStatus,
      messageMeta: data.messageMeta,
    };
    return filterThreads;
  }

  async setCompiledMsgList(isReload = true) {
    let thrdList = await this.getThreadList();
    if (thrdList) {
      await this.dashboardDataForDisplay(thrdList);
    }
    let billLists = [];
    if (!!isReload || thrdList)
      billLists = await this.getBills();
    else
      billLists = this.state.compiledMsgList;

    if (!!billLists && billLists.length > 0) {
      this.setState({
        compiledMsgList: billLists,
        filterBills: billLists,
        newBillCount: billLists.filter((el) => el.billStatus === "New").length,
        outstandingBillCount: billLists.filter((el) => el.billStatus === "Outstanding").length,
        overdueBillCount: billLists.filter((el) => el.billStatus === "Overdue").length,
        headerSearchList: billLists,
      });

      const { compiledMsgList, filterBills } = this.state;
      await this.updateBillCount(compiledMsgList, filterBills);

      const { isSearchOpen, textInSearchBox, currentOpenScreen } = this.dm.userSearchDomain.state;
      if (isSearchOpen) this.dm.userSearchDomain.keyTypeSearch(textInSearchBox, currentOpenScreen);
    }
  }

  async dashboardDataWithMetadata(res) {
    try {
      if (!!res && res.messages && res.messages.length > 0 && res.messages[0].envelope.messageBody && res.messages[0].envelope.threadHeader.profile.toLowerCase() !== "simplemessage") {
        let data = await this.payloadForGraphQl(this.manageBillDetails(res.messages));
        await this.createAndUpdateBill(data);
      }
    } catch (error) {
      console.log("Thread message ::: ", error);
    }
  }

  async setCompiledMsgMetadata(res, threadId) {
    let billLists = [];
    if (res) {
      let msg = res.messages.sort(
        (a, b) => new Date(a.envelope.messageHeader.messageDateTime) - new Date(b.envelope.messageHeader.messageDateTime)
      );
      res.messages = msg.filter(
        (message) =>
          !!message.envelope.messageBody &&
          (!message.envelope.messageBody.subMessageType ||
            message.envelope.messageBody.subMessageType === "NoteToPayer" ||
            message.envelope.messageBody.subMessageType === "NoteToBiller")
      );
      await this.dashboardDataWithMetadata(res);
      billLists = await this.getBills();
    }
    else
      billLists = this.state.compiledMsgList;

    if (!!billLists && billLists.length > 0) {
      this.setState({
        compiledMsgList: billLists,
        filterBills: billLists,
        newBillCount: billLists.filter((el) => el.billStatus === "New").length,
        outstandingBillCount: billLists.filter((el) => el.billStatus === "Outstanding").length,
        overdueBillCount: billLists.filter((el) => el.billStatus === "Overdue").length,
        headerSearchList: billLists,
      });
      this.dm.billDetails.setState({
        billDetails: billLists.find((e) => e.threadId === threadId)
      });

      const { compiledMsgList, filterBills } = this.state;
      await this.updateBillCount(compiledMsgList, filterBills);

      const { isSearchOpen, textInSearchBox, currentOpenScreen } = this.dm.userSearchDomain.state;
      if (isSearchOpen) this.dm.userSearchDomain.keyTypeSearch(textInSearchBox, currentOpenScreen);
    }
  }

  async setCompiledListNotification(threads) {
    let newThreads = [];
    if (threads && threads.length > 0) {
      let complieList = this.state.compiledMsgList;
      if (!!complieList && complieList.length > 0) {
        let bills = complieList.map( x => { return x.threadId });
        newThreads = threads.filter(x => !bills.includes(x.threadId));
      }
      else
        newThreads = threads;
      
      if (!!newThreads && newThreads.length > 0) {
        let calls = []
        for (let el of newThreads) {
          calls.push(this.syncBills(el.threadId, newThreads.length));
        }
        await Promise.all(calls);
      }
    }

    let billLists = [];
    if (!!newThreads && newThreads.length > 0) 
      billLists = await this.getBills();
    else
      billLists = this.state.compiledMsgList;

    if (!!billLists && billLists.length > 0) {
      this.setState({
        compiledMsgList: billLists,
        filterBills: billLists,
        newBillCount: billLists.filter((el) => el.billStatus === "New").length,
        outstandingBillCount: billLists.filter((el) => el.billStatus === "Outstanding").length,
        overdueBillCount: billLists.filter((el) => el.billStatus === "Overdue").length,
        headerSearchList: billLists,
      });

      const { compiledMsgList, filterBills } = this.state;
      await this.updateBillCount(compiledMsgList, filterBills);

      const { isSearchOpen, textInSearchBox, currentOpenScreen } = this.dm.userSearchDomain.state;
      if (isSearchOpen) this.dm.userSearchDomain.keyTypeSearch(textInSearchBox, currentOpenScreen);
    }
  }


  async updateBookmark(input, fields = "uid bookmark") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `mutation UpdateBillDetail($input: UpdateBillDetailInput) {
          updateBillDetail(input: $input){
            billDetail {
              ${fields}
            }
          }
        }`,
        {
          input,
        }
      );
      return res.data.updateBillDetail.billDetail;
    } catch (err) {
      return new Error(err);
    }
  }

  async addUpdateBookmark(el) {
    let res = !!el.uid ? await this.updateBookmark({ bookmark: el.bookmark, uid: el.uid }) : null;
    return res;
  }

  async resetDashboardDomain() {
    let _sign = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION);
    await AsyncStorage.clear();
    // await AsyncSessionStorage.clear();
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION, _sign);
    this.setState({
      compiledMsgList: [],
      newBillCount: 0,
      overdueBillCount: 0,
      outstandingBillCount: 0,
      filterBills: [],
      isFilterOpen: false,
      headerSearchList: [],
      IsScannerVisible: false,
      sessionInActive: false,
      isSubscriptionValid: true,
      submittedBillCount: 0,
      lastReadDateTime: null,
      dateTimeHistorys: [],
      selectedBlock: [],
      isBlockNavigation: false
    });

    await this.dm.notificationDomain.setState({ notifications: [], notificationCount: 0, notificationThreadIds: [], reCalled: false });
    await this.dm.billerDirectoryDomain.setState({ pamList: [] });
    await this.dm.addInsuranceDomain.setState({ totalBills: 0, isFetched: false, insuranceItems: [], currentPage: 1 });
    await this.dm.addDoctorsDomain.setState({ totalBills: 0, isFetched: false, doctorDetails: [], currentPage: 1 });
    await this.dm.uploadBillDomain.setState({ selectedBlock: "New" });
    await this.dm.user.setState({ subuserItems: [], currentPage: 1 });
    await this.dm.simpleMessage.setState({ simpleMessage: [], searchSimpleMessage: [], messageList: [] });
    await this.dm.userLoginDomain.setState({ socialLoginProfile: null, socialLoginType: null });
    await this.dm.miscellaneousDomain.setState({ countries: [] });
    await this.dm.userSession.setState({ pchRegistrationTypeProvider: false, pchRegistrationTypePatient: false, isLoggedOut: false, userBUDetails: [], userLoginDetail: null });
    await this.dm.userSearchDomain.setState({ isAlreadyOpen: false, isSearchOpen: false, textInSearchBox :"" });

  }

  async getBillDetailById(threadId, fields = "uid dueDate amountPaid amountDue billStatus isExtentionRequested isExtentionApproved isExtentionDeclined rtpMessageType accountNumber paymentStatus failReason", opt) {
    let options = {
      filter: { threadId: { eq: threadId } },
      ...opt,
    };
    try {
      let res = await this.api.qry(
        `query BillDetails($filter: BillDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          billDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            totalCount @include(if: true)
            nodes {
              ${fields}
            }
          }
      }`,
        options
      );
      return res.data.billDetails.nodes;
    } catch (error) {
      return error;
    }
  }

  async updateBill(input, fields = "uid") {
    let user = await this.dm.rest.getUser()
    input.actionBy = user.pid;
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `mutation CreateUpdatedBill($input: UpdateBillDetailInput) {
        createUpdatedBill(input: $input){
          billDetail {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
      let billDetail = res.data.createUpdatedBill.billDetail;
      this.addBillExtensionHistory(billDetail, input);
      return billDetail;
    } catch (error) {
      return error;
    }
  }

  async createAndUpdateBill(data) {
    await CommonUtility.htmlDecode(data);
    delete data.payedAt;
    if(!!!data.uid) data.uid = "";
    await this.updateBill(data);
  }

  onlyMessageBody(arrayOfThreads) {
    try {
      return arrayOfThreads.filter((ele) => ele.envelope.messageBody);
    } catch (error) {
      console.error("hasOnlyMessageBody Message::: ", error);
    }
  }

  async payloadForGraphQl(data) {
    const { threadHeader, rtpStatus, dueDate, amount, paidAmount, status, threadDateTime, messageDateTime, payedAt, fullPaidAndDeclined, paymentOptions, endToEndIdentification, currency } = data;
    let billerList = await this.dm.rest.getBillers();
    let billers = !!billerList ? billerList.find((el) => el.billerId === threadHeader.originatorPid) : null;
    if (!billers) {
      let dbBillers = await this.dm.queryDomain.getBiller({
        filter:
        {
          payerId: { eq: threadHeader.respondentPid },
          billerId: { eq: threadHeader.originatorPid },
        }
      }, "uid", false);

      billers = dbBillers[0];
    }

    let billDetail = {
      payedAt: messageDateTime,
      payerId: threadHeader.respondentPid,
      billerId: threadHeader.originatorPid,
      billerUid: billers.uid,
      threadId: threadHeader.threadId,
      billDate: threadDateTime,
      subject: threadHeader.subject,
      dueDate: dueDate,
      billAmount: parseFloat(amount),
      amountPaid: parseFloat(paidAmount),
      amountDue: ["Paid", "Declined"].includes(status) ? 0.00 : parseFloat(amount) - parseFloat(paidAmount),
      billStatus: status,
      currency: currency,
      paymentOption: paymentOptions,
      endToEndIdentification: endToEndIdentification,
      rtpMessageType: rtpStatus,
      accountNumber: threadHeader.accountNumber,
      paymentStatus: [MSG_TYPE.PAY_ALL, MSG_TYPE.PAY_PART].includes(rtpStatus)
        ? "In Process"
        : status === "Paid" && [MSG_TYPE.ACKNOWLEGE_FULL, MSG_TYPE.ACK_PAYMENT].includes(rtpStatus)
          ? "Paid"
          : null,
      // fullPaidAndDeclined: fullPaidAndDeclined,
    };
    if (billDetail.rtpMessageType === MSG_TYPE.REQ_PAY_EXT) {
      billDetail.isExtentionRequested = true;
      billDetail.isExtentionApproved = false;
      billDetail.isExtentionDeclined = false;

      billDetail.extentionRequestedDate = payedAt;
      billDetail.extentionApprovedDate = null;
      billDetail.extentionDeclinedDate = null;
    }
    else if (billDetail.rtpMessageType === MSG_TYPE.EXT_GRANTED) {
      billDetail.isExtentionApproved = true;
      billDetail.isExtentionRequested = false;
      billDetail.isExtentionDeclined = false;

      billDetail.extentionApprovedDate = payedAt;
      billDetail.extentionRequestedDate = null;
      billDetail.extentionDeclinedDate = null;
    }
    else if (billDetail.rtpMessageType === MSG_TYPE.EXT_DECLINED) {
      billDetail.isExtentionDeclined = true;
      billDetail.isExtentionRequested = false;
      billDetail.isExtentionApproved = false;

      billDetail.extentionDeclinedDate = payedAt;
      billDetail.extentionRequestedDate = null;
      billDetail.extentionApprovedDate = null;
    }
    if (billDetail.paymentStatus === "Paid") billDetail.fullPaidAndDeclined = fullPaidAndDeclined;

    return billDetail;
  }

  async updateBillCount(compiledMsgList, filterBills) {
    this.setState({
      compiledMsgList,
      filterBills,
      newBillCount: compiledMsgList.filter((el) => el.billStatus === "New" && el.paymentStatus !== "In Process").length,
      outstandingBillCount: compiledMsgList.filter((el) => el.billStatus === "Outstanding" && el.paymentStatus !== "In Process").length,
      overdueBillCount: compiledMsgList.filter((el) => el.billStatus === "Overdue" && el.paymentStatus !== "In Process").length,
    });
  }

  manageBillDetails(data) {
    let filterThreads = {}
    if (!!data && data.length > 0) {
      let filterBills = data.filter(
        (el) =>
          el.envelope.messageBody &&
          el.envelope.messageBody.messageType !== MSG_TYPE.NOTE_TO_BILLER &&
          el.envelope.messageBody.messageType !== MSG_TYPE.NOTE_TO_PAYER
      );

      const firstElement = data.filter((el) => el.envelope.messageBody && el.envelope.messageBody.messageType === MSG_TYPE.REQ_PAY)[0];

      let arrayOfThreads = JSON.parse(JSON.stringify(filterBills));
      filterThreads = this.addedNewBiller(firstElement);
      if (!!arrayOfThreads.length && arrayOfThreads.length > 1) {
        let reqExDate = arrayOfThreads.filter((dat) => !!dat.envelope.messageBody && dat.envelope.messageBody.messageType === MSG_TYPE.REQ_PAY_EXT);
        let reqExGranted = arrayOfThreads.filter((dat) => !!dat.envelope.messageBody && dat.envelope.messageBody.messageType === MSG_TYPE.EXT_GRANTED);
        if (reqExGranted.length > 0) {
          let lastExGranted = reqExGranted.pop();
          reqExDate = reqExDate.filter((val) => val.envelope.messageHeader.messageId === lastExGranted.envelope.messageBody.referenceMessageId)[0];
        } else reqExDate = reqExDate[1];

        arrayOfThreads.forEach((itm, i) => {
          filterThreads.rtpStatus = itm.envelope.messageBody.messageType;
          filterThreads.payedAt = itm.envelope.messageHeader.messageDateTime;
          if ([MSG_TYPE.ACKNOWLEGE_FULL, MSG_TYPE.PAY_ALL].includes(itm.envelope.messageBody.messageType)) {
            filterThreads.status = "Paid";
            filterThreads.fullPaidAndDeclined = [MSG_TYPE.ACKNOWLEGE_FULL].includes(itm.envelope.messageBody.messageType)
              ? itm.envelope.messageHeader.messageDateTime
              : null;
            if(MSG_TYPE.PAY_ALL === itm.envelope.messageBody.messageType)
            {
              filterThreads.paidAmount = itm.envelope.messageBody.amount ? (filterThreads.paidAmount + itm.envelope.messageBody.amount.instructedAmount) : filterThreads.paidAmount;
              filterThreads.transactionId = itm.envelope.messageBody.transactionId;
            }
          } else if (itm.envelope.messageBody.messageType === MSG_TYPE.DECLINE) {
            filterThreads.status = "Declined";
            filterThreads.fullPaidAndDeclined = itm.envelope.messageHeader.messageDateTime;
          } else if (itm.envelope.messageBody.messageType === MSG_TYPE.DECLINE_BLOCK) {
            filterThreads.status = "Declined";
          } else if (itm.envelope.messageBody.messageType === MSG_TYPE.EXT_GRANTED) {
            let duDate = new Date(reqExDate.envelope.messageBody.requestedExtensionDate).toJSON().substring(0, 10);
            let isOverDueDate = CommonUtility.compareDueDate(duDate);
            if (!!isOverDueDate) filterThreads.status = "Overdue";
            filterThreads.dueDate = DateUtils.formatDate(reqExDate.envelope.messageBody.requestedExtensionDate);
          } else if (itm.envelope.messageBody.messageType === MSG_TYPE.ACK_PAYMENT) {
            filterThreads.status = filterThreads.paidAmount === firstElement.envelope.messageBody.amount.dueAmount ? "Paid" : "Outstanding";
            if(filterThreads.status === "Paid")
              filterThreads.fullPaidAndDeclined = itm.envelope.messageHeader.messageDateTime;
          } else if (itm.envelope.messageBody.messageType === MSG_TYPE.PAY_PART) {
            filterThreads.paidAmount = itm.envelope.messageBody.amount ? filterThreads.paidAmount + itm.envelope.messageBody.amount.instructedAmount : filterThreads.paidAmount;
            filterThreads.sortCode = itm.envelope.messageBody.transactionStatus;
            filterThreads.transactionId = itm.envelope.messageBody.transactionId;
          }
          // if (!["Paid", "Declined"].includes(filterThreads.status) && ![MSG_TYPE.REQ_PAY_EXT, MSG_TYPE.EXT_GRANTED].includes(filterThreads.rtpStatus)) {
          //   let thDate = new Date();
          //   let duDate = new Date(firstElement.envelope.messageBody.dueDate);
          //   duDate.setDate(duDate.getDate() + 1);
          //   if (thDate > duDate) {
          //     // filterThreads.status = "Overdue";
          //   }
          // }
        });
      }
    }
    return filterThreads;
  }

  async getBills(options,fieldsStr = "uid payerId billerId billerUid threadId billDate billAmount dueDate subject amountPaid amountDue billStatus isExtentionRequested isExtentionApproved fullPaidAndDeclined bookmark rtpMessageType extentionApprovedDate extentionRequestedDate extentionDeclinedDate isExtentionDeclined paymentStatus isFailedPayment failDate failReason lastPaidAmount transactionId paymentMode accountNumber paymentOption { merchantAccountNumber } paymentType endToEndIdentification currency"
     ) {
      
    let pids = await this.dm.rest.getLinkedPID();
    let pidBUs = await this.dm.rest.getLinkedPIDBU();
    if (!!pids && pids.length > 0) {
      if (!fieldsStr) throw new Error("Bill Details Domain Find Error: No fields found");
      let defult = {
        filter: {
          payerId: { in: pids.split(',') },
        },
        sort: { field: "billDate", order: "DESC" },
      };
      let opt = { ...defult, ...options };
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetBillDetails($filter: BillDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          getBills(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            totalCount @include(if: true)
            nodes {
              ${fieldsStr}
            }
          }
        }`,
        opt
      ));
      let res = !!result.data.getBills && !!result.data.getBills.nodes ? result.data.getBills.nodes : [];
      let list = await this.dm.rest.getBillers();
      for (let i = 0; i < res.length; i++) {
        // eslint-disable-next-line no-loop-func
        let biller = list.find(x => x.uid === res[i].billerUid);
        res[i].biller = biller;
      }
      res = res.filter((el) => {
        return pidBUs.some((f) => {
          return ((!!el.biller && f.buId === el.biller.buId && f.pid === el.payerId && f.billerId === el.biller.billerId) || !f.buId);
        });
      });
      return res;
    }
  }

  async getAllDateTimeHistory(fieldsStr = "uid payerId simpleMessageLastDateTime pamMessageLastDateTime billLastDateTime activeBillerLastDateTime") {
    const { dateTimeHistorys } = this.state;
    if (!!dateTimeHistorys && dateTimeHistorys.length > 0)
      return dateTimeHistorys;
    else {
      try {
        let result = await this.dm.queryDomain.middleware(await this.api.qry(
          `query DateTimeHistorys{
          getDateTimeHistorys{
            nodes {
              ${fieldsStr}
            }
        }
      }`));
        let dateHistory = result.data.getDateTimeHistorys.nodes;
        this.setState({
          dateTimeHistorys: dateHistory
        })
        return dateHistory;
      } catch (error) {
        return dateTimeHistorys;
      }
    }
  }
  

  async updateMsgDateHistory(input, fields = "uid") {
    if (!!input.uid) {
      await CommonUtility.htmlDecode(input);
      try {
        this.api.mutate(`mutation UpdateDateTimeHistory($input: UpdateDateTimeHistoryInput) {
        updateDateTimeHistory(input: $input){
          success
        }
      }`,
          { input }
        );
      } catch (error) {
        return error;
      }
    }
  }

  async createDateTimeHistory(input, fields = "uid") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
      mutation CreateDateTimeHistory($input: CreateDateTimeHistoryInput) {
        createDateTimeHistory(input: $input){
          dateTimeHistory {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
      return res.data.createDateTimeHistory.dateTimeHistory;
    } catch (error) {
      return error;
    }
  }
  
  async getBillReference(options, fieldsStr = "uid") {
    let defult = {
      sort: { field: "billDate", order: "DESC" }
    };
    let opt = { ...defult, ...options };
    let result = await this.api.qry(`query BillDetails($filter: BillDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          billDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            totalCount @include(if: true)
            nodes {
              ${fieldsStr}
            }
          }
        }`,
      opt
    );
    let res = !!result.data.billDetails && !!result.data.billDetails.nodes ? result.data.billDetails.nodes : [];
    return res;
  }
  
}
