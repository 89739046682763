import React from "react";
import R14, {
    Form,
    View,
    Text,
    StyleSheet,
    Button,
    SubmitButton,
    TextInputField,
    Platform,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";
import CommonValidators from "../../utils/CommonValidators";
import DatePicker from "../../components/PCH/DatePicker";
import DropDown from "../../components/DropDown";
import DateUtils from "../../utils/DateUtils";

export default R14.connect(
    class VendorFilterComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                showFromDueDate: false,
                showToDueDate: false,
            };
            this.form = null;
            this.vendorList = [];
            this.orgList = [];
            this.paymentStatusList = [{label:"UnPaid", value:"UnPaid"}, {label:"Due", value:"Due"}, {label:"Past Due", value:"Past Due"},{label:"Paid", value:"Paid"},{label:"Partially Paid", value:"Partially Paid"},{label:"In-Progress", value:"In-Progress"},{label:"Cancelled", value:"Cancelled"}];
            this.selectedVender = {};
            this.selectedOrgs = [];
            this.selectedPaymentStatus = [];

        }

        componentDidMount = async () => {
            try {
                this.form = this.props.app.ui.form("vendorfilters");
                let date = DateUtils.formatDateInMMDDYYYY(new Date());
                this.form.elmts.fromDueDate.setValue(date);
                this.form.elmts.toDueDate.setValue(date);
                this.form.elmts.orgName.setItems(this.orgList);
                this.form.elmts.paymentStatus.setItems(this.paymentStatusList);
                this.onSearchVender();
                this.onSearchOrg();
            }
            catch (err) {
                console.log(err)
            }
        };

        onDiscard = async () => {
            this.form.reset();
            this.onClear();
            this.props.handleReset();
        };


        onClear = () => {
            const form = this.props.app.ui.form("vendorfilters");
            form.elmts.orgName.setValue(null);
            form.elmts.venderId.setValue(null);
            form.elmts.extNo.setValue(null);
            form.elmts.entityId.setValue(null);
            form.elmts.fromDueDate.setValue(null);
            form.elmts.toDueDate.setValue(null);
            form.elmts.paymentStatus.setValue(null);


            form.elmts.orgName.setError(null);
            form.elmts.venderId.setError(null);
            form.elmts.extNo.setError(null);
            form.elmts.entityId.setError(null);
            form.elmts.fromDueDate.setError(null);
            form.elmts.toDueDate.setError(null);
            form.elmts.paymentStatus.setError(null);

            let date = DateUtils.formatDateInMMDDYYYY(new Date());
            this.form.elmts.toDueDate.setValue(date);
            this.onSearchVender();
            this.selectedVender = {};
            this.selectedOrgs = [];
            this.selectedPaymentStatus = [];
        };

        onVenderChange = (item) => {
            const { label, value } = item;
            const form = this.props.app.ui.form("vendorfilters");
            const venderDetails = this.vendorList.find(vendor => vendor.value === value);
            form.elmts.extNo.setValue(venderDetails.extRefNumber1);
            if(Platform.OS !== "web") form.elmts.venderId.setValue(label);
            form.elmts.venderId.setError(null);
            this.selectedVender = venderDetails;
            return false;
        }

        onPaymentStatusChange = (item, multiple = false) => {
            try {
            const form = this.props.app.ui.form("vendorfilters");
            // if un-select all from dropdown then item is null for last item
            if(!item) {
                this.selectedPaymentStatus = [];
                form.elmts.paymentStatus.setValue(null);
                return false;
            }
            const { label, value } = item;
            const isAlreadySelected = this.selectedPaymentStatus.findIndex(org => org.value === value);
            if(isAlreadySelected !== -1){
                this.selectedPaymentStatus.splice(isAlreadySelected, 1);
                if(!!multiple && Platform.OS !== "web"){
                    const txt = this.selectedPaymentStatus.map(e => e.label);
                    form.elmts.paymentStatus.setValue(txt.join(", "))
                }
            } else {
                const orgDetails = this.paymentStatusList.find(org => org.value === value);
                this.selectedPaymentStatus.push(orgDetails);
                if(!!multiple && Platform.OS !== "web"){
                    const txt = this.selectedPaymentStatus.map(e => e.label);
                    form.elmts.paymentStatus.setValue(txt.join(", "))
                } else if(Platform.OS !== "web") {
                    form.elmts.paymentStatus.setValue(label);
                }
            }
            form.elmts.paymentStatus.setError(null);
            return false;
            } catch (error) {
                return false;
            }
        }

        onOrgNameChange = (item, multiple = false) => {
            const form = this.props.app.ui.form("vendorfilters");
            // if un-select all from dropdown then item is null for last item
            if(!item) {
                this.selectedOrgs = [];
                form.elmts.orgName.setValue(null);
                return false;
            }
            const { label, value } = item;
            const isAlreadySelected = this.selectedOrgs.findIndex(org => org.value === value);
            if(isAlreadySelected !== -1){
                this.selectedOrgs.splice(isAlreadySelected, 1);
                if(!!multiple && Platform.OS !== "web"){
                    const txt = this.selectedOrgs.map(e => e.label);
                    form.elmts.orgName.setValue(txt.join(", "))
                }
            } else {
                const orgDetails = this.orgList.find(org => org.value === value);
                if(Platform.OS !== "web") form.elmts.orgName.setValue(label);
                this.selectedOrgs.push(orgDetails);
                if(!!multiple && Platform.OS !== "web"){
                    const txt = this.selectedOrgs.map(e => e.label);
                    form.elmts.orgName.setValue(txt.join(", "))
                }
            }
            form.elmts.orgName.setError(null);
            return false;
        }

        onSearchOrg = async (search = "", limit = 10) => {
            const form = this.props.app.ui.form("vendorfilters");
            try {
                const txt = !!search && search.label ? search.label : search;
                // graphql API
                let orgNames = await this.props.app.dm.smbApi.getOrgList({
                    search: txt,
                    pid: this.props.user.pid,
                    resultsPerPage: limit,
                });

                orgNames = !!orgNames && orgNames.length ? orgNames : [];
                orgNames = orgNames.map(org => {
                    return {
                        ...org,
                        label: org.label || org.orgName,
                        value: org.value || org.orgId,
                        isSelected: false
                    }
                });
                this.orgList = (!!orgNames && orgNames.length) ? orgNames : this.orgList;
                form.elmts.orgName.setItems(this.orgList);
                return this.orgList;
            } catch (error) {
                console.log('error while fetching orgNames list', error);
                if (!!form && form.elmts && form.elmts.venderId) form.elmts.venderId.setItems([]);
                return [];
            }
        };

        onSearchVender = async (search = "", limit = 10) => {
            const form = this.props.app.ui.form("vendorfilters");
            try {
                const txt = !!search && search.label ? search.label : search;
                // graphql API
                let venders = await this.props.app.dm.smbApi.getVenderList({
                    search: txt,
                    pid: this.props.user.pid,
                    resultsPerPage: limit,
                });
                venders = !!venders && venders.length ? venders : [];
                venders = venders.map(vendor => {
                    return {
                        ...vendor,
                        label: vendor.businessName,
                        value: vendor.vendorID
                    }
                });
                this.vendorList = (!!venders && venders.length) ? venders : this.vendorList;
                form.elmts.venderId.setItems(this.vendorList);
                return this.vendorList;
            } catch (error) {
                console.log('error while fetching venders list', error);
                if (!!form && form.elmts && form.elmts.venderId) form.elmts.venderId.setItems([]);
                return [];
            }
        };

        handleSubmit = async (form) => {
            try {
                const { extNo, entityId, fromDueDate, toDueDate } = form.values;
                const orgName = this.selectedOrgs;
                const venderId = this.selectedVender;
                const paymentStatus = this.selectedPaymentStatus;
                const payload = {
                    pid: this.props.user ? this.props.user.pid : "",
                    vendorID: venderId ? venderId.value : "",
                    fromDate: fromDueDate,
                    dueDate: toDueDate,
                    extRefNo: extNo,
                    paymentStatus: paymentStatus ? paymentStatus.map(v => v.value).join(",") : "",
                    orgList: orgName ? orgName.map(v => v.value).join(",") : 0,
                    entityID: entityId
                }

                if (new Date(fromDueDate) > new Date(toDueDate)) {
                    form.elmts.fromDueDate.setError("From date should not greater than To date");
                    return false;
                }
                else if ((new Date(toDueDate) - new Date(fromDueDate)) / (1000 * 3600 * 24) > 90) {
                    form.elmts.toDueDate.setError("Maximum 90 day(s) allowed");
                    return false;
                }

                if (!payload.vendorID || !payload.paymentStatus || !payload.orgList) return false;
                await this.props.handleSubmit(payload);
                return false;
            } catch (error) {
                this.props.app.dm.commonDomain.showToastr("error", error);
            }
        };


        validateDate = async (testdate) => {
            let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
            return date_regex.test(testdate);
        };

        handleFromShowHideDatePicker = (val) => {
            if (val === "open" && this.state.showFromDueDate) {
                return;
            } else if (val === "close") {
                this.setState({ showFromDueDate: false });
                return;
            } else {
                this.setState({ showFromDueDate: !this.state.showFromDueDate })
            }
        }

        onFromDueDateValueChange = async (val, autoformat = true) => {
            this.handleFromShowHideDatePicker();
            if (!!val) {
                if (!!autoformat) {
                    let date = DateUtils.formatDateInMMDDYYYY(val);
                    this.form.elmts.fromDueDate.setError(null);
                    this.form.elmts.fromDueDate.setValue(null);
                    this.form.elmts.fromDueDate.setValue(date);
                }

            } else {
                this.setState({
                    showFromDueDate: false,
                });
            }
        };

        onFromDueDateBlur = async () => {
            const { fromDueDate } = this.form.values;
            this.handleFromShowHideDatePicker();
            if (!!fromDueDate) {
                let dat = await this.validateDate(fromDueDate);
                if (!!dat) {
                    let formatedDate = DateUtils.formatDateInMMDDYYYY(fromDueDate);
                    dat = await this.validateDate(formatedDate);
                    if (!!dat) {
                        this.form.elmts.fromDueDate.setValue(formatedDate);
                        this.form.elmts.fromDueDate.setError(null);
                        this.onFromDueDateValueChange(fromDueDate, false);
                    } else {
                        this.form.elmts.fromDueDate.setValue(fromDueDate);
                        this.form.elmts.fromDueDate.setError(CommonString.DateFormatRequired);
                    }
                } else {
                    this.form.elmts.fromDueDate.setValue(null);
                    this.form.elmts.fromDueDate.setError(CommonString.DateFormatRequired);
                }
            } else {
                this.form.elmts.fromDueDate.setError(null);
            }
        };

        onClearFromDueDate = () => {
            this.form.elmts.fromDueDate.setValue(null);
            this.form.elmts.fromDueDate.setError(null);
            this.setState({
                showFromDueDate: false,
            })
        }

        handleToDueShowHideDatePicker = (val) => {
            if (val === "open" && this.state.showToDueDate) {
                return;
            } else if (val === "close") {
                this.setState({ showToDueDate: false });
                return;
            } else {
                this.setState({ showToDueDate: !this.state.showToDueDate })
            }
        }

        onToDueDateValueChange = async (val, autoformat = true) => {
            this.handleToDueShowHideDatePicker();
            if (!!val) {
                if (!!autoformat) {
                    let date = DateUtils.formatDateInMMDDYYYY(val);
                    this.form.elmts.toDueDate.setError(null);
                    this.form.elmts.toDueDate.setValue(null);
                    this.form.elmts.toDueDate.setValue(date);
                }

            } else {
                this.setState({
                    showToDueDate: false,
                });
            }
        };

        onToDueDateBlur = async () => {
            const { toDueDate } = this.form.values;
            this.handleToDueShowHideDatePicker();
            if (!!toDueDate) {
                let dat = await this.validateDate(toDueDate);
                if (!!dat) {
                    let formatedDate = DateUtils.formatDateInMMDDYYYY(toDueDate);
                    dat = await this.validateDate(formatedDate);
                    if (!!dat) {
                        this.form.elmts.toDueDate.setValue(formatedDate);
                        this.form.elmts.toDueDate.setError(null);
                        this.onToDueDateValueChange(toDueDate, false);
                    } else {
                        this.form.elmts.toDueDate.setValue(toDueDate);
                        this.form.elmts.toDueDate.setError(CommonString.DateFormatRequired);
                    }
                } else {
                    this.form.elmts.toDueDate.setValue(null);
                    this.form.elmts.toDueDate.setError(CommonString.DateFormatRequired);
                }
            } else {
                this.form.elmts.toDueDate.setError(null);
            }
        };

        onClearToDueDate = () => {
            this.form.elmts.toDueDate.setValue(null);
            this.form.elmts.toDueDate.setError(null);
            this.setState({
                showToDueDate: false,
            })
        }

        onInvalidTypeText = (txt, key, form = this.props.app.ui.form("vendorfilters")) => {
         return form.elmts[key].setValue(txt.slice(0, -1))
        }

        render() {
            let clientStyle = commonStyles;
            let commonFontStyles = fontFamilyStyles;
            return (
                <View style={[styles.filterContainer]}>
                    <View style={[styles.filterLabel]}>
                        <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans]} >Filter*</Text>
                    </View>
                    <Form
                        name="vendorfilters"
                        style={[styles.uploadBillsForm, clientStyle.form]}
                        validateBeforeSubmit={true}
                        validators={CommonValidators.getFormValidator()}
                        onSubmit={this.handleSubmit}>
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.leftInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.customFlexDirection,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    commonStyles.marginBottom10
                                ]}>
                                <DropDown
                                    name="orgName"
                                    label={"Org Name*"}
                                    required={CommonString.OrgNameRequired}
                                    onValueChange={this.onOrgNameChange}
                                    searchable={false}
                                    itemLoader={async ({ search }) => {
                                        return await this.onSearchOrg(search);
                                    }}
                                    multiple={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                    style={[customCommonStyles.inputHeight, styles.width100, styles.minWidth100, commonFontStyles.workSans, commonStyles.heading6, Platform.OS === "web" ? styles.customMarginBottom10 : null]}

                                    // for native
                                    onItemSelect={this.onOrgNameChange}
                                    // for native - onDataProvider must return array [{label, value}]
                                    onDataProvider={(search) => this.onSearchOrg(search)}
                                    setExistingValue={""}
                                    defaultValue={()=> ""}
                                    searchableTextBoxStyle={[commonStyles.marB0]}
                                    searchableTextBoxStyleHolder={[commonStyles.marginBottom16, commonStyles.fullFlexContainer, commonStyles.maxWidth100,]}
                                    readOnly={false}
                                    disabled={false}
                                    onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "orgName")}
                                />
                            </View>
                            <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis48, commonStyles.customFlexDirection,
                            styles.customAlignItemsStart, styles.textAlign, commonStyles.marginBottom10]}>
                                <DropDown
                                    name="venderId"
                                    label={"Vendor Name*"}
                                    required={CommonString.VendorRequired}
                                    onValueChange={this.onVenderChange}
                                    searchable
                                    itemLoader={async ({ search }) => {
                                        return await this.onSearchVender(search);
                                    }}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                    style={[customCommonStyles.inputHeight, styles.width100, styles.minWidth100, commonFontStyles.workSans, commonStyles.heading6, Platform.OS === "web" ? styles.customMarginBottom10 : null]}
                                    // for native
                                    onItemSelect={this.onVenderChange}
                                    // for native - onDataProvider must return array [{label, value}]
                                    onDataProvider={(search) => this.onSearchVender(search)}
                                    setExistingValue={""}
                                    defaultValue={()=> ""}
                                    searchableTextBoxStyle={[commonStyles.marB0]}
                                    searchableTextBoxStyleHolder={[commonStyles.marginBottom16, commonStyles.fullFlexContainer, commonStyles.maxWidth100,]}
                                    readOnly={false}
                                    disabled={false}
                                    onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "venderId")}
                                />
                            </View>
                        </View>
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown]}>
                                <TextInputField
                                    name="extNo"
                                    label="Ext. Ref"
                                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight, styles.width100,  styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    disabled={true}
                                    readOnly={true}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    styles.customMarginBottom30
                                ]}>

                                <TextInputField
                                    name="entityId"
                                    label="Entity Id"
                                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                        </View>

                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, Platform.OS === "web" ? null : commonStyles.flexDirectionRow,]}>
                                <DatePicker
                                    name={"fromDueDate"}
                                    form="vendorfilters"
                                    value={this.form && this.form.values && this.form.values.fromDueDate}
                                    mode={"modal"}
                                    onValueChange={this.onFromDueDateValueChange}
                                    onBlur={this.onFromDueDateBlur}
                                    onFocus={this.onFromDueDateBlur}
                                    visible={this.state.showFromDueDate}
                                    closeDatePicker={this.handleFromShowHideDatePicker}
                                    isUberDocs={false}
                                    // for native
                                    noMinDate={true}
                                    textInputEditable={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                    helperTextStyle={[styles.dobLabelStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                />
                                <TextInputField
                                    name={"fromDueDate"}
                                    label={"From Due Date*"}
                                    secure={false}
                                    readOnly={true}
                                    required={CommonString.FromDueDateRequired}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.customWidth49]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}

                                    onFocus={() => Platform.OS === "web" ? this.handleFromShowHideDatePicker('open') : null}
                                    icon={Platform.OS === "web" ? "calendar": null}
                                    onIconPress={() => Platform.OS === "web" ? this.handleFromShowHideDatePicker(): null}
                                    iconRight={Platform.OS === "web" ? this.form && this.form.values && this.form.values.fromDueDate ? "close" : null : null}
                                    onIconRightPress={() => Platform.OS === "web" ? this.onClearFromDueDate() : null}

                                />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.flexDirectionColumn,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    commonStyles.alignItemsStart,
                                    styles.customMarginBottom30,
                                    Platform.OS === "web" ? null : commonStyles.flexDirectionRow,
                                ]}>
                                <DatePicker
                                    name={"toDueDate"}
                                    form="vendorfilters"
                                    value={this.form && this.form.values && this.form.values.toDueDate}
                                    mode={"modal"}
                                    onValueChange={this.onToDueDateValueChange}
                                    onBlur={this.onToDueDateBlur}
                                    visible={this.state.showToDueDate}
                                    closeDatePicker={this.handleToDueShowHideDatePicker}
                                    isUberDocs={false}
                                    // for native
                                    noMinDate={true}
                                    textInputEditable={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                    helperTextStyle={[styles.dobLabelStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                />
                                <TextInputField
                                    name={"toDueDate"}
                                    label={"To Due Date*"}
                                    secure={false}
                                    readOnly={true}
                                    required={CommonString.ToDueDateRequired}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.customWidth49, commonStyles.maxWidth100]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                                    onFocus={() => Platform.OS === "web" ? this.handleToDueShowHideDatePicker('open') : null}
                                    icon={Platform.OS === "web" ? "calendar": null}
                                    onIconPress={() => Platform.OS === "web" ? this.handleToDueShowHideDatePicker(): null}
                                    iconRight={Platform.OS === "web" ? this.form && this.form.values && this.form.values.toDueDate ? "close" : null : null}
                                    onIconRightPress={() => Platform.OS === "web" ? this.onClearToDueDate() : null}
                                />

                            </View>
                        </View>
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.paymentStatusWrapper]}>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.leftInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.customFlexDirection,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    styles.marginTop10,
                                    commonStyles.marginBottom10
                                ]}>
                                <DropDown
                                    name="paymentStatus"
                                    label={"Payment Status*"}
                                    required={CommonString.PaymentStatusRequired}
                                    onValueChange={this.onPaymentStatusChange}
                                    searchable={false}
                                    itemLoader={() => this.paymentStatusList}
                                    multiple={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                    style={[customCommonStyles.inputHeight, styles.width100, styles.minWidth100, commonFontStyles.workSans, commonStyles.heading6]}
                                    // for native
                                    onItemSelect={this.onPaymentStatusChange}
                                    // for native - onDataProvider must return array [{label, value}]
                                    onDataProvider={() => this.paymentStatusList}
                                    setExistingValue={""}
                                    defaultValue={()=> ""}
                                    searchableTextBoxStyle={[commonStyles.marB0]}
                                    searchableTextBoxStyleHolder={[commonStyles.marginBottom16, commonStyles.fullFlexContainer, commonStyles.maxWidth100]}
                                    readOnly={false}
                                    disabled={false}
                                    onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "paymentStatus")}
                                />
                            </View>
                            <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, commonStyles.displayNone]}>
                            </View>
                        </View>
                        <View style={[commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow]}>
                            <View style={[styles.formBtnsHolder]}>
                                <Button
                                    title={"Reset"}
                                    style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.btnWidth108,  Platform.OS !== "web" ? commonStyles.flexBasis48 : null, commonStyles.marginRight10]}
                                    titleTextStyle={[commonStyles.secondayWhiteBtnText, commonFontStyles.workSans]}
                                    onPress={() => this.onDiscard()}
                                />
                                <SubmitButton
                                    title={"Search"}
                                    style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24,  Platform.OS !== "web" ? commonStyles.flexBasis48 : null, styles.customMarginLeft16]}
                                    titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6, clientStyle.submitButtonText]}
                                />
                            </View>
                        </View>
                    </Form>
                </View>
            );
        }
    }
);
const styles = StyleSheet.create({
    leftInputHolder: {
        marginRight: 8,
        maxWidth: '49%',
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    maxWidth: '100%',
                    marginRight: 0,
                };
            }
        },
    },
    rightInputHolder: {
        marginLeft: 8,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 0,
                };
            }
        },
    },
    customFlexProperties: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: "auto",
                    marginBottom: 30
                };
            }
            if (width >= 991) {
                return {
                    justifyContent: "flex-end",
                };
            }
        },
    },
    inputHolder: {
        // marginBottom: 6,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexDirection: "column",
                };
            }
        },
    },
    formBtnsHolder: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexBasis: "100%",
                    marginBottom: 16,
                    marginTop: 16
                };
            }
        },
    },
    customAlignItemsStart: {
        alignItems: "baseline",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    alignItems: null,
                };
            }
        },
    },
    uploadBillsForm: {
        width: '100%',
        maxWidth: "100%",
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    flex: 1,
                    width: '100%',
                };
            }
            if (width <= 991) {
                return {
                    paddingTop: 10,
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingRight: 0,
                    //   width: "90%"
                };
            }
        },
    },
    incNameDropDown: {
        marginBottom: 30,
        screen: ({ width }) => {
            if (width >= 991)
                return {
                    maxWidth: "49%",
                };
        },
    },
    textAlign: {
        alignItems: "center",
        height: "55%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginBottom: 0,
                    alignItems: "flex-start",
                };
            }
        },
    },
    width100: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    minWidth100: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minWidth: "100%",
                };
            }
        },
    },
    customMarginBottom30: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 30,
                };
            }
        },
    },

    filterContainer: {
        flex: 1,
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'rgba(21, 27, 38, 0.4)',
        borderStyle: 'solid',
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 16,
        paddingRight: 16,
        position: 'relative',
        marginBottom: 16,
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16,
        maxWidth: "100%"
    },

    filterLabel: {
        position: 'absolute',
        top: -11,
        left: 8,
        backgroundColor: 'white',
        paddingLeft: 5,
        paddingRight: 5
    },
    marginBottom16: {
        marginBottom: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 32,
                };
            }
        },
    },
    marginTop10: {
        screen: ({ width }) => {
            if (width > 991) {
                return {
                    marginTop: 10,
                };
            }
        }
    },
    customMarginLeft16: {
        marginLeft: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 0,
                };
            }
        }
    },
    paymentStatusWrapper: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    paddingTop: 0,
                };
            }
        }
    },
    btnWidth108: {
        width: 108
    },
    customWidth49: {
        width: "100%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: Platform.OS !== "web" ? "88%" : "100%",
                };
            }
        }
    },
    customMarginBottom10: {
        marginBottom: 10,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 16,
                };
            }
        }
    }
});
